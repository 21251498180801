import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import logo from '../../assets/images/Logo.svg';
import { authCall } from '../../services';
import arrowIcon from '../../assets/images/arrow-circle.svg';
import AppConstants from '../../appConstants';

const Section1SuccessComponent = (props) => {
  const [modalStatus, setModalStatus] = useState(false);

  const handleSubmit = async () => {
    const resp = await authCall('auth/register-section', {
      profileLevel: 0 // 4
    });

    if (resp && resp.success === true) {
      const { goToNextStep, history } = props;
      goToNextStep({ step: 6 });
      // if (resp.isSentWelcomeMail) {
      //   // goToNextStep({ step: 6 });
      //   setTimeout(() => {
      //     history.push(AppConstants.routes.afterLogin);
      //   }, 4000);
      // } else {
      //   history.push(AppConstants.routes.afterLogin);
      // }
      history.push(AppConstants.routes.afterLogin);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleSubmit();
    }, 5000);
  }, [setTimeout]);

  return (
    <>
      <section className="registerWrapper succuss_reg1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center d-none">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="reg-inner second_step">
              <div className="col-12">
                <h1>
                  Congratulations
                  <br /> on completing
                  <br /> your Basic Profile <br />
                  Section 1!
                </h1>
              </div>
              <div className="col-12">
                <p className="read-more">
                  Our experts will review your profile to determine if our
                  <br />
                  service and the Lovrican community are a good fit for you at
                  <br />
                  this time based on the information you have provided. The
                  <br />
                  approval process usually takes 1-2 days. You’ll either get
                  <br />
                  an ‘approved to join the community’ email, or ‘not approved
                  <br />
                  to join the community at this time’ email.
                </p>
                <p className="mt-3 mb-4">
                  <span
                    className="d-inline-block cursor-pointer"
                    onClick={() => setModalStatus(true)}
                    aria-hidden="true"
                  >
                    <span className="dot_wrapper">
                      <span className="dot" />
                      <span className="dot" />
                      <span className="dot" />
                    </span>
                    <span className="read-more-text">READ MORE</span>
                  </span>
                </p>
                <p className="d-none">
                  Our goal at Lovrican is to provide each member an exceptional{' '}
                  <br />
                  experience. Screening to only accept new members who meet{' '}
                  <br />
                  the high expectations that our members expect is one of the{' '}
                  <br />
                  ways in which we achieve this goal. If you are not accepted at
                  this <br />
                  time, you’ll have the opportunity to be considered at another
                  time. <br />
                  We can’t wait for you to join us!
                </p>
              </div>
              <div className="col-12 join-links pt-4">
                <div
                  className="search-btn-common"
                  onClick={handleSubmit}
                  onKeyDown={(event) => event.key === 'Enter' && handleSubmit()}
                  role="button"
                  tabIndex="0"
                >
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="filter-model lovrician-work-model gift-readmore"
        show={modalStatus}
        onClick={() => setModalStatus(false)}
        centered
        // backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <h5 className="modal-title w-100">
              Congratulations
              <br /> on completing
              <br /> your Basic Profile
              <br /> Section 1!
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Our experts will review your profile to determine if our service and
            the Lovrican community are a good fit for you at this time based on
            the information you have provided. The approval process usually
            takes 1-2 days. You’ll either get an ‘approved to join the
            community’ email, or ‘not approved to join the community at this
            time’ email.
          </p>
          <p>
            Our goal at Lovrican is to provide each member an exceptional
            experience. Screening to only accept new members who meet the high
            expectations that our members expect is one of the ways in which we
            achieve this goal. If you are not accepted at this time, you’ll have
            the opportunity to be considered at another time. We can’t wait for
            you to join us!
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Section1SuccessComponent;
