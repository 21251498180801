import React from 'react';
import checkIcon from '../../adminAssets/images/dashboard_check.svg'
import blackCheckIcon from '../../adminAssets/images/dashboard_check_dark.svg'
import crossIcon from '../../adminAssets/images/dashbaord_X.svg'
import whiteCrossIcon from '../../adminAssets/images/dashbaord_X_white.svg'

const UserStatusButtonsComponent = (props) => {
	const { user, approveButtonHandler, rejectButtonHandler } = props;

	if (!user?.status) return null;

	const btnClickHandler = (event, cb = null) => {
		event.preventDefault();
		event.stopPropagation();

		if (cb !== null) {
			cb();
		}
	};

	return (
		<div className="bottom">
			{user.status === 'PENDING' && (
				<>
					<div
						role="button"
						className="checked checked_white"
						onClick={event => btnClickHandler(event, approveButtonHandler)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={blackCheckIcon} alt="" />
					</div>
					<div
						role="button"
						className="cross red_cross"
						onClick={event => btnClickHandler(event, rejectButtonHandler)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={whiteCrossIcon} alt="" />
					</div>
				</>
			)}
			{user.status === 'ACTIVE' && (
				<>
					<div
						role="button"
						className="approved"
						onClick={event => btnClickHandler(event)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={checkIcon} alt="" /> <span>approved</span>
					</div>
					<div
						role="button"
						className="cross white_cross"
						onClick={event => btnClickHandler(event, rejectButtonHandler)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={crossIcon} alt="" />
					</div>
				</>
			)}
			{user.status === 'INACTIVE' && (
				<>
					<div
						role="button"
						className="checked checked_blue"
						onClick={event => btnClickHandler(event, approveButtonHandler)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={checkIcon} alt="" />
					</div>
					<div
						role="button"
						className="declined"
						onClick={event => btnClickHandler(event)}
						onKeyPress={() => { }}
						tabIndex={0}
					>
						<img src={whiteCrossIcon} alt="" /> <span>Declined</span>
					</div>
				</>
			)}
		</div>
	);
};

export default UserStatusButtonsComponent;
