import React from 'react';
import logoWhite from '../../../assets/images/Logo.svg';
import arrowIcon from '../../../assets/images/arrow-circle.svg';

const PaymentDetailsComponent = ({
  goToNextStep,
  formValidation,
  values,
  handleChange
}) => (
  <div className="giftWrapper almostThere">

      <div className='giftBack' role="button" aria-hidden="true" onClick={() => goToNextStep(2)}>
    <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
    </div>

      <div className='gift-logo'>
        <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
        </div>
    <div className="container-fluid">
      <div className="row">
        <div className="innerWrapper step-two">
          <div className="col-12 p-0">
            <h1>Almost there...</h1>
            <p>
              Enter your information & pay to send <br />
              your gift of love to {values?.firstName}.
            </p>
            <div className="row customForm">
              <div className="col-md-6">
                <div className="box">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="First Name"
                    value={values.pFirstName}
                    onChange={handleChange('pFirstName')}
                  />
                </div>
                <span className="validation-error">
                  {formValidation.pFirstName}
                </span>
              </div>
              <div className="col-md-6">
                <div className="box">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="Last Name"
                    value={values.pLastName}
                    onChange={handleChange('pLastName')}
                  />
                </div>
                <span className="validation-error">
                  {formValidation.pLastName}
                </span>
              </div>
              <div className="col-md-12">
                <div className="box">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="Enter your Email"
                    value={values.pEmail}
                    onChange={handleChange('pEmail')}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && goToNextStep(4)
                    }
                  />
                </div>
                <span className="validation-error">
                  {formValidation.pEmail}
                </span>
              </div>
              <div className="col-12 text-right mt-4">
                <span
                  className="search-btn"
                  role="button"
                  aria-hidden="true"
                  onClick={() => goToNextStep(4)}
                >
               <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PaymentDetailsComponent;
