import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppConstants from '../appConstants';

const ProfileImages = ({ images }) => {
  if (!images || !images.length) {
    return null;
  }

  return (
    <>
      {images.map((image) => (
        <div key={image} className="col-md-6">
          <div className="photo-frame">
            <img src={image} alt="" />
          </div>
        </div>
      ))}
    </>
  );
};

const ViewProfilePhotosPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [images, setImages] = useState([]);
  const [imagesToRemove] = useState([]);

  useEffect(() => {
    if (location.state) {
      setImages(location.state.imagesDetail.images);
    }
    else{
      history.push({
        pathname: AppConstants.routes.matchQueue
      });
    }
  }, [location.state]);

  const profileImages = useMemo(
    () =>
      (images || [])
        .filter((imageName) => !imagesToRemove.includes(imageName))
        .map(
          (imageName) => `${process.env.REACT_APP_PIC_BASE_URL}${imageName}`
        ),
    [images, imagesToRemove]
  );

  return (
    <>
      <div className="photo-container">
        <div className="my-photo">
          <div className="top d-sm-flex align-items-center mb-4">
            <div className="mr-4 cursor-pointer" aria-hidden="true" onClick={() => history.goBack()}>
              <div className="back">
                <i className="fa fa-angle-left" aria-hidden="true" />
              </div>
            </div>
            <span>
              <b>My Photos</b>
            </span>
          </div>
          <div className="photo-outer-wrapper">
            <div className="row">
              <ProfileImages images={profileImages} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfilePhotosPage;
