import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as moment from 'moment';
import webServices from '../services/webServices';
import AppConstants from '../appConstants';
import whiteRose from '../assets/images/rose.svg';
import whiteFlowser from '../assets/images/lovrican-white.svg';
import whiteLike from '../assets/images/hear-white.svg';

// import download from '../assets/images/download.svg';
// import visa from '../assets/images/visa.png';
// import master from '../assets/images/maestor.png';
// import paypal from '../assets/images/paypal.png';

import { pageTitle } from '../redux/actions/home.actions';
import Spinner from '../components/general/spinner';

const BillingDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState([]);
  const [plans, setPlans] = useState([]);
  const [isSpinner, setIsSpinner] = useState(true);

  useEffect(async () => {
    dispatch(pageTitle('Billing Details'));

    const resp = await webServices.getOrderDetails();
    if (resp && resp.success) {
      if (resp.data.length > 0) {
        setInvoice(resp.data);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      } else {
        history.push({
          pathname: 'plans'
        });
      }
    }

    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
      }
    })();
  }, []);

  /*
  const createPayment = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    (async () => {
      const response = await webServices.createOrder({
        planId: plan.id
      });
      if (response && response.data.result.status === 'CREATED') {
        const approveLink = response.data.result.links.find(
          (link) => link.rel === 'approve'
        );
        if (approveLink) {
          window.location.replace(approveLink.href);
        }
      }
    })();
  };
  */

  const handleClickRedirectToPlan = (plan) => {
    history.push({
      pathname: AppConstants.routes.plans,
      state: { planDetail: plan, planDetails: location?.state?.planDetails }
    });
  };

  return (
    <>
      <div className="full-page planWrapper">
        <div className="container">
          {isSpinner ? (
            <Spinner active={isSpinner} />
          ) : (
            <>
              <div className="paymentDetailsWrapper">
                <div className="mid-container panConatiner">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center">
                        {!!plans.length &&
                          plans.map((plan) => (
                            <div
                              key={plan.id}
                              className="top-nav"
                              onClick={() => handleClickRedirectToPlan(plan)}
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                            >
                              <div
                                className={`top-nav-inner ${
                                  location?.state?.planDetails?.currentPlan ===
                                  plan.name
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <i className="fa fa-check-circle" />
                              </div>
                              <p>{plan.name}</p>
                            </div>
                          ))}
                      </div>
                      <div className="listWrapper">
                        {!!invoice.length &&
                          invoice.map((value) => {
                            const paymentDetails = value.orderDetails
                              ? JSON.parse(value.orderDetails)
                              : '';

                            const total = paymentDetails
                              ? paymentDetails?.purchase_units[0]?.payments
                                  ?.captures[0]?.amount?.value
                              : 0;
                            let plan = value?.plan?.name;
                            // let total =
                            //   value?.plan?.price *
                            //   (value?.plan?.numberOfDays / 30);
                            let iconImage = whiteFlowser;
                            let planColor = 'rose';

                            if (value?.plan?.name === 'Gold') {
                              planColor = 'orange';
                            } else if (value?.plan?.name === 'Platinum') {
                              planColor = 'green';
                            } else if (value?.plan?.name === 'Diamond') {
                              planColor = 'rose';
                            } else if (value?.plan?.name === 'Chocolate') {
                              planColor = 'black';
                            } else if (value?.plan?.plan_type === '2') {
                              const orderDetais = JSON.parse(
                                value?.orderDetails
                              );
                              plan = `${orderDetais?.likes} ${
                                orderDetais?.likes > 1 ? 'Likes' : 'Like'
                              }`;
                              // total = orderDetais?.price;
                              iconImage = whiteLike;
                            } else {
                              // total = value?.plan?.price;
                              plan = `${value?.plan?.name} ${
                                value?.plan?.name > 1 ? 'Roses' : 'Rose'
                              }`;
                              iconImage = whiteRose;
                            }

                            return (
                              <>
                                <div
                                  key={value.id}
                                  className={`card ${planColor}`}
                                >
                                  <div className="card-header">
                                    <div className="icon">
                                      <img
                                        src={iconImage}
                                        className="img-fluid"
                                        alt="Lovrican rose"
                                      />
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="planName">{plan}</div>
                                    <div className="date">
                                      {moment(value.createdAt).format(
                                        'MMM DD, YYYY'
                                      )}
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="card-details">
                                          <p>
                                            Order ID <br />
                                            <span>{value.orderId}</span>
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="col-md-6">
                                        <div className="card-details">
                                          <p>
                                            <img
                                              src={visa}
                                              className="img-fluid"
                                              alt="Lovrican rose"
                                            />{' '}
                                            <br />
                                            <span>Card ending 0234</span>
                                          </p>
                                        </div>
                                      </div> */}
                                      <div className="col-md-6">
                                        <div className="card-details total">
                                          <p>
                                            <span>Total $</span>
                                          </p>
                                          <div className="price">${total}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingDetailsPage;
