import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step13Component = ({ userDetails, masterData, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(
    new SimpleReactValidator({
      validators: {
        max_five: {
          message: 'Maximum of 5 values allowed.',
          rule: (val) => val.length < 6,
          required: true
        }
      }
    })
  );
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    hobbies: userDetails?.hobbies ? userDetails?.hobbies : []
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        hobbies: JSON.stringify(values.hobbies)
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step13" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Share your interests with us. It assists us in finding matches
                aligned to your preferences
              </div>
            </div>
          </div>
          <div className="row join-reg inner_bottom">
            <div className="col-md-12 col-lg-12 custome_select">
              <Select
                placeholder="Hobbies"
                multi
                options={masterData?.hobbies}
                limit={5}
                autoComplete="off"
                className="custom_input"
                values={values.hobbies}
                onChange={(value) => setValues({ ...values, hobbies: value })}
              />
              {validator.message(
                'hobbies',
                values.hobbies,
                'required|max_five'
              )}
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12">
              <div className="ul-li-box hobbies-wrapper">
                {values.hobbies && (
                  <ul className="color-list">
                    {values.hobbies.map((prop) => (
                      <li key={prop.label}>{prop.label}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join">
            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step13Component;
