import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../redux/actions/home.actions';
import MembershipSold from './MembershipSold';
import CampaignPerformance from './CampaignPerformance';

const AccordionCustomToggle = ({
  title,
  eventKey,
  parentCallBack,
  status,
  arrowRight
}) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={() => decoratedOnClick(title, status)}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      {arrowRight ? (
        <span>
          <i className="fa fa-angle-left" />
        </span>
      ) : (
        <span>
          {status === 'open' ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      )}
    </div>
  );
};

const ReferalsPage = () => {
  const myRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const [initOcc, setInitOcc] = useState(location?.state?.occordian || '0');

  const [accStatus, setAccStatus] = useState({
    personal: 'open',
    basic: 'close'
  });

  useEffect(async () => {
    dispatch(pageTitle('Referals'));
  }, [dispatch]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    init.basic = 'close';

    if (key === '0') {
      init.personal = data;
    }
    if (key === '1') {
      init.basic = data;
    }
    setAccStatus(init);
    if (data === 'close') {
      setInitOcc(null);
    } else {
      setInitOcc(key);
    }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign profile-wrapper-outer">
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <Accordion
                activeKey={initOcc}
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card ref={myRef}>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="0"
                      title="Membership Sold"
                      status={accStatus.personal}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <MembershipSold />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="1"
                      title="Campaign Performance"
                      status={accStatus.basic}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <CampaignPerformance />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferalsPage;
