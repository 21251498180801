import React from 'react';
import { connect } from 'react-redux';
// import toastr from 'toastr';
// import { ToastContainer } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import logoWhite from '../../assets/images/logo-white.png';
import AppConstants from '../../appConstants';
import { setUser } from '../../redux/actions/user.actions';
import { authCall } from '../../services';
import authService from '../../services/authServices';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            u_email: '',
            password: '',
            request: true
        };

        this.validator = new SimpleReactValidator({
            validators: {
                confirm: {
                    message: 'The :attribute must be same as Password.',
                    // eslint-disable-next-line react/destructuring-assignment
                    rule: (val) => val === this.state.password,
                    required: true // optional
                },
                password: {
                    message:
                        'The :attribute must have min 8 characters, with at least a symbol, number, upper and lower case.',
                    rule: (val, params, validator) =>
                        validator.helpers.testRegex(
                            val,
                            /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/i
                        ) && params.indexOf(val) === -1,
                    required: true // optional
                }
            }
        });
    }


    componentDidMount() {
        const { history } = this.props;
        authService.throwBackAdmin(history);
    }

    submitForm = async () => {
        let request = {};
        let flag = false;
        if (
            this.validator.fieldValid('email') &&
            this.validator.fieldValid('password')
        ) {
            const { u_email: username, password } = this.state;
            request = {
                username,
                password,
                usertype: 'ADMIN'
            };
            flag = true;
        } else {
            this.validator.showMessageFor('email');
            this.validator.showMessageFor('password');
            this.forceUpdate();
        }

        if (request && flag) {
            this.setState({ request: false });
            const resp = await authCall('auth/login', request);
            if (resp && resp.success === true) {
                // logout main site when admin login
                localStorage.removeItem('lovUserDetails');
                localStorage.removeItem('lovPermit');
                localStorage.removeItem('userDetails');

                authService.setAdminToken(resp.data);
                const { history, dispatch } = this.props;
                dispatch(setUser(resp.data.user));
                history.push(AppConstants.routes.admin.dashboard);
            }
            // else{
            //   toastr.error(`ERROR! Invalid login credentials`);
            // }

            this.setState({ request: true });
        }
    };

    render() {
        const { u_email: uEmail, request, password } = this.state;
        return (
            <section>
                <div className="login-page text-center">
                    <form className="shadow-md border-radius login-container">
                        <div className="row">
                            <div className="col-12">
                                <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
                            </div>
                            <div className="col-12">
                                <h3>Admin Login</h3>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email address"
                                    className="form-control"
                                    value={uEmail}
                                    onChange={(e) => this.setState({ u_email: e.target.value })}
                                    onKeyDown={(event) => event.key === 'Enter' && this.submitForm()}
                                />
                                {this.validator.message('email', uEmail, 'required|email')}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    onKeyDown={(event) => event.key === 'Enter' && this.submitForm()}
                                />
                                {this.validator.message('password', password, 'required')}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-12">
                                <div className="join-links">
                                    <span
                                        className={`btn btn-danger btn-block round-button mt-4 ${!request ? 'hks-btn-disable' : ''
                                            } mt-2 mb-2`}
                                        onClick={this.submitForm}
                                        onKeyDown={(event) =>
                                            event.key === 'Enter' && this.submitForm()
                                        }
                                        role="button"
                                        tabIndex="0"
                                    >
                                        Login
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}
export default connect()(Login);
