import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/Logo.svg';
import AppConstants from '../../appConstants';
import { authCall } from '../../services';

const SectionCompleteSuccessComponent = () => {
  const history = useHistory();

  useEffect(async () => {
    const resp = await authCall('auth/register-section', {
      profileLevel: 2
    });
    if (resp && resp.success === true) {
      setTimeout(() => {
        history.push(AppConstants.routes.afterLogin);
      }, 4000);
    }
  }, []);

  // const { history } = props;
  return (
    <>
      <section className="registerWrapper succuss_reg3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center d-none">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="reg-inner1 second_step">
              <div className="col-12">
                <h1>
                  Welcome to
                  <br /> Lovrican!
                </h1>
              </div>
              <div className="col-12">
                <p>
                  You are joining a very special community. We
                  <br /> ask you to do your part to keep this community
                  <br /> exceptional. At a minimum, please follow
                  <br /> our community standards at all times.
                </p>
                <ul className="pl-3">
                  <li>Be kind and respectful.</li>
                  <li>Be welcoming, especially to newer members.</li>
                  <li>
                    Keep it clean: no profane or obscene language or photos.
                  </li>
                  <li>
                    No posts containing or encouraging spam, advertising,
                    <br />
                    fraudulent/illegal activity or personally identifiable
                    <br />
                    information (email address, etc.).
                  </li>
                  <li>Respect the Lovrican staff.</li>
                  <li>Do your best to communicate as clearly as possible.</li>
                </ul>
              </div>
              {/* this section hide on 05-02-2024 */}
              <div className="col-12 join-links d-none">
                <span
                  className="common-button"
                  // onClick={() => {
                  //   history.push(AppConstants.routes.afterLogin);
                  // }}
                  // onKeyDown={(event) =>
                  //   event.key === 'Enter' && redirect()
                  // }
                  role="button"
                  tabIndex="0"
                >
                  Go to Matches
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionCompleteSuccessComponent;
