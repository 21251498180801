import { useEffect, useState } from 'react';
import React, { useDispatch } from 'react-redux';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import rose from '../../assets/images/rose-yellow.svg';
import themeRose from '../../assets/images/rose-theme.svg';
import greenRose from '../../assets/images/rose-green.svg';
import Spinner from '../general/spinner';
// import Checkout from '../../services/Checkout';
import PayPalCheckoutPage from '../../services/PayPalCheckout';

const RosePlansComponent = () => {
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [availableRoses, setAvailableRoses] = useState(0);
  // const [planDetails, setPlanDetails] = useState('Gold');
  const [isSpinner, setIsSpinner] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await webServices.getRosePlans();
      if (response && response.success) {
        setPlans(response.data);
        setAvailableRoses(response?.roses?.my_roses || 0);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      }
    })();
    dispatch(pageTitle('Rose Plans'));
  }, []);

  useEffect(() => {
    if (!plans.length) {
      return;
    }
    setSelectedPlan(
      plans.reduce((acc, value) => (acc.price > value.price ? acc : value), {})
    );
  }, [plans]);

  const handlePlanClick = (event, plan) => {
    event.preventDefault();
    setSelectedPlan(plan);
    // setPlanDetails(plan.name);
  };

  const createPayment = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    (async () => {
      const response = await webServices.createOrder({
        planId: selectedPlan.id
      });
      if (response && response.data.result.status === 'CREATED') {
        const approveLink = response.data.result.links.find(
          (link) => link.rel === 'approve'
        );
        if (approveLink) {
          window.location.replace(approveLink.href);
        }
      }
    })();
  };

  return (
    <>
      {isSpinner ? (
        <Spinner active={isSpinner} />
      ) : (
        <>
          <div className="full-page planWrapper d-none1">
            <div className="container">
              <div className="plan-container">
                <h1 className="ext-left text-lg-center text-md-center">
                  Simple, Transparent Pricing
                </h1>
                {/* <p className="text-center text-muted">
            No contract, No surprise feels.
          </p> */}
              </div>
              {/* <h5 className="text-center">Available Roses - {availableRoses}</h5> */}
              <div className="text-left text-lg-center text-md-center">
                <div className="availableRose">
                  <div className="w-100 d-flex justify-content-around align-items-center">
                    <img
                      src={themeRose}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />
                    <span>
                      <em>{availableRoses}</em>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mid-container-outer">
                <div className="mid-container panConatiner roseContainer">
                  <div className="row">
                    <div className="col-md-6 d-none" />
                    <div className="col-md-12">
                      <div className="plan-details rose-plan-details">
                        <div className="nav flex-column nav-pills">
                          {!!plans.length &&
                            plans.map((plan) => {
                              let roseType;
                              if (plan.name === '1') {
                                roseType = rose;
                              } else if (plan.name === '5') {
                                roseType = greenRose;
                              } else {
                                roseType = themeRose;
                              }

                              return (
                                <a
                                  className={`nav-link ${
                                    selectedPlan.id === plan.id ? 'active' : ''
                                  }`}
                                  data-toggle="pill"
                                  href="/"
                                  onClick={(event) =>
                                    handlePlanClick(event, plan)
                                  }
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="lft d-flex align-items-center">
                                      <div className="icon mr-3">
                                        <i
                                          className="fa fa-circle-thin"
                                          aria-hidden="true"
                                        />
                                        <i
                                          className="fa fa-check-circle"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div className="rose-box">
                                        <img
                                          // src={rose}
                                          src={roseType}
                                          className="img-rose-fluid"
                                          alt="Lovrican rose"
                                        />
                                      </div>
                                      <div className="plan_title">
                                        <div
                                          className={`save d-flex align-items-center ${plan.name.toLowerCase()}`}
                                        >
                                          <h3>{plan.name}</h3>
                                          Roses
                                        </div>
                                      </div>
                                    </div>
                                    <div className="plan-center">
                                      {(
                                        ((plan.name * plans[0].price -
                                          plan.price) *
                                          100) /
                                        (plan.name * plans[0].price)
                                      ).toFixed(0) > 0 && (
                                        <div className="bottom">
                                          <div
                                            className={`save ${plan.name.toLowerCase()}`}
                                          >
                                            Save{' '}
                                            {(
                                              ((plan.name * plans[0].price -
                                                plan.price) *
                                                100) /
                                              (plan.name * plans[0].price)
                                            ).toFixed(0)}
                                            %
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="rgt">
                                      <div className="price">${plan.price}</div>
                                    </div>
                                  </div>
                                </a>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="white-border" />
                <div id="paypal-button-container" />
                <div className="mid-container mt-4">
                  <div className="mobile_plan justify-content-center text-center mobileFooter">
                    {/* <Checkout
                      description="Rose Purchase"
                      amount={selectedPlan?.price}
                      details={{
                        price: selectedPlan?.price,
                        planId: selectedPlan?.id,
                        roses: selectedPlan?.name
                      }}
                    /> */}
                    <PayPalCheckoutPage
                      planId={selectedPlan.id}
                      amount={selectedPlan?.price}
                      selectedPlan={selectedPlan}
                    />
                  </div>
                  <div className="d-sm-flex justify-content-center text-center mobileFooter d-none">
                    <button
                      onClick={createPayment}
                      type="button"
                      className="btn btn-info theme-btn ml-3 gr_color mb-2 mb-md-0 d-none"
                      disabled={isProcessing}
                    >
                      Pay with PayPal
                    </button>
                    <button
                      onClick={createPayment}
                      type="button"
                      className="btn btn-info theme-btn ml-3 r_color d-none"
                      disabled={isProcessing}
                    >
                      Pay with Card
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <footer className="bottom-footer">
        <div className="d-md-flex justify-content-center align-items-center">
          <div>
            If you are new want to start then join 30 days free trail !!
          </div>
          <a
            href="/"
            className="btn btn-info theme-btn ml-3 g_color"
            role="button"
          >
            30 days free trail
          </a>
        </div>
      </footer> */}
          </div>
        </>
      )}
    </>
  );
};

export default RosePlansComponent;
