import React from 'react';
import { ToastContainer } from 'react-toastify';
import ChangeEmailSetting from './changeEmailSetting';
import ChangePasswordSetting from './changePasswordSetting';
import EmailSettings from './emailSetting';

const PasswordAndEmailSetting = ({ parentCallBackWhenUpdate, userSettings }) => (
  <>
    <ChangePasswordSetting />

    <ChangeEmailSetting userSettings={userSettings} parentCallBackWhenUpdate={parentCallBackWhenUpdate} />

    <EmailSettings userSettings={userSettings} />

    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={3}
      pauseOnFocusLoss
      draggable
      pauseOnHover


    />
  </>
);

export default PasswordAndEmailSetting;
