import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import EditIcon from '../../assets/images/edit.svg';
import lock from '../../assets/images/lock.svg';

const Menu = (props) => {
  const { children, getValue } = props;
  const optionSelectedLength = getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 7 ? (
        children
      ) : (
        <div style={{ margin: 15 }}>
          You can only have a maximum of 5 items. You have reached the max
          allowed. Please remove some items to add others
        </div>
      )}
    </components.Menu>
  );
};

const SelectComponent = ({ limit, ...restProps }) => {
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 6;

  return (
    <Select
      classNamePrefix="comon-scroll"
      {...restProps}
      components={{ Menu }}
      isValidNewOption={isValidNewOption}
    />
  );
};

const DropdownWithTextSection = ({
  label,
  value,
  valueText,
  nameField,
  options,
  formControlName,
  saveDetails,
  isMulti,
  isViewMode,
  color,
  isLock,
  limit
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [localValueText, setLocalValueText] = useState(valueText);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const isOther = JSON.stringify(localValue).includes('Other');

  const goToEditMode = () => {
    if (!isViewMode) {
      setIsEditMode(true);
    }
  };

  const handleFormSubmit = (event) => {
    if (localValue !== '') {
      const isOtherInclude = JSON.stringify(localValue).includes('Other');
      if (isOtherInclude && localValueText === '') {
        setIsEditMode(true);
      } else {
        event.preventDefault();
        (async () => {
          const response = await saveDetails({
            [formControlName]: localValue,
            [nameField]: localValueText
          });
          if (response && response.success) {
            // toast.dismiss();
            toast.success('Updated successfully');
            setIsEditMode(false);
          }
        })();
      }
    } else {
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    setLocalValue(value);
    if (!isOther) {
      setLocalValueText('');
    }
  }, [value]);

  useEffect(() => {
    if (window.innerWidth <= 800 && window.innerHeight <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window]);

  return (
    isLock && (
      <div className="card-box position-relative">
        {isLock && (
          <div className="text-right lockSection">
            <img src={lock} alt="lock" />
          </div>
        )}

        <form onSubmit={handleFormSubmit}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={!isMobile && goToEditMode}
            aria-hidden="true"
          >
            <div className="card-header d-flex justify-content-between align-items-center">
              <span>{label}</span>

              {!isEditMode && !isViewMode && (
                <div
                  className={`edit ${isMobile ? '' : 'd-none'}`}
                  onClick={goToEditMode}
                  onKeyDown={(event) => event.key === 'Enter' && goToEditMode()}
                  role="button"
                  tabIndex="0"
                >
                  <img src={EditIcon} alt="Edit icon" />
                </div>
              )}
            </div>
            <div className="card-container">
              {!isEditMode &&
                (!!value && value.length !== 0 ? (
                  <ul className="list-unstyled list1 mb-0">
                    {(Array.isArray(value) ? value : [value]).map(
                      (valueInner) => (
                        <li
                          key={valueInner.label}
                          style={{ backgroundColor: color || '' }}
                        >
                          {(valueInner &&
                            valueInner.label !== 'Other' &&
                            valueInner.label) ||
                            localValueText}
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <span className="text-danger-not">Not answered yet</span>
                ))}
              {isEditMode && (
                <>
                  <SelectComponent
                    options={options}
                    value={localValue}
                    onChange={setLocalValue}
                    isMulti={isMulti}
                    limit={limit}
                    onBlur={!isMobile && handleFormSubmit}
                  />
                  {isOther && (
                    <input
                      type="text"
                      placeholder="Other"
                      value={localValueText}
                      onChange={(event) =>
                        setLocalValueText(event.target.value)
                      }
                      onBlur={!isMobile && handleFormSubmit}
                      className="form-control mt-4"
                    />
                  )}
                </>
              )}
            </div>
            {isEditMode && (
              <div className={`${isMobile ? '' : 'd-none'}`}>
                <button
                  className="btn cancel-btn mr-2"
                  onClick={() => setIsEditMode(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button className="btn theme-btn" type="submit">
                  Save
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    )
  );
};

export default DropdownWithTextSection;
