import React from 'react';
import lock from '../../assets/images/lock.svg';

const RadioButtonSection = ({
  value,
  options,
  label,
  formControlName,
  saveDetails,
  isViewMode,
  isLock
}) => {
  // const [localValue, setLocalValue] = useState(value?.label);
  const handleClick = (val) => async () => {
    if (!isViewMode) {
      let updateData = { value: '', label: '' };
      if (value?.label !== val.label) {
        updateData = { value: val.value, label: val.label };
      }
      const response = await saveDetails({ [formControlName]: updateData });
      if (response && response.success) {
        // setLocalValue(val);
      }
    }
  };
  return (
    <div className="card-box position-relative">
      <div className="card-header d-flex justify-content-between align-items-center">
        <span>{label}</span>
      </div>
      {isLock && (
        <div className="text-right lockSection">
          <img src={lock} alt="lock" />
        </div>
      )}
      <div className="card-container">
        <ul className="list-unstyled list1 mb-0">
          {!isViewMode ? (
            options.map((valueInner) => (
              <li
                key={valueInner.label}
                onClick={handleClick(valueInner)}
                aria-hidden="true"
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    valueInner.label === value?.label ? '#B800E0' : '#E4DCE6'
                }}
              >
                {valueInner && valueInner.label}
              </li>
            ))
          ) : (
            <li
              style={{
                backgroundColor: value?.label ? '#B800E0' : ''
              }}
            >
              {value?.label}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RadioButtonSection;
