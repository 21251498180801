import React from 'react';
// import { Link } from 'react-router-dom';
// import logoWhite from '../../assets/images/Logo.svg';
import HomeHeaderMobile from './homeHeaderMobile';

const Header = ({ callBack }) => {
  const callbackfunction = () => {
    callBack(true);
  };

  return (
    <>
      <div className="top-bar col-12 text-left text-lg-center text-md-center full-view">
        <div
          className="mobile-menu"
          onClick={callbackfunction}
          aria-hidden="true"
        >
          <i className="fa fa-bars" aria-hidden="true" />
        </div>
        <HomeHeaderMobile />
        {/* <Link to="/" className="logo-db">
          <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
        </Link> */}
      </div>
  {/* mobile menu */}
      {/* <div className="container small-view">
        <div className='mobile-header'>
          <div className='burger' onClick={callbackfunction} aria-hidden="true">
            <span />
            <span />
            <span />
          </div>
          <Link to="/" className="logo-mobile">
            <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
          </Link>
        </div>
      </div>
      <div className="navbar-mobile">
            <div className="menu-wrapper">
              <div className="d-block position-relative">
                <button type="button" className="close">
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a className="active" href="/">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/">Contact Us</a>
                </li>
              </ul>
              <div className="d-block link-btn">
                <span
                  className="round-button mt-0 active"
                  role="button"
                  tabIndex="0"
                >
                  Join
                </span>
                <span className="round-button mt-0" role="button" tabIndex="0">
                  Login
                </span>
              </div>
            </div>
          </div> */}
      {/* for mobile view End */}
   
    </>
  );
};

export default Header;
