import React, { useState, useEffect } from 'react';
import lock from '../../assets/images/lock.svg';

const DateOfBirth = ({ label, value, isLock }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    isLock && (
      <div className="card-box position-relative">
        <div className="text-right lockSection">
          <img src={lock} alt="lock" />
        </div>

        <form>
          <div style={{ cursor: 'pointer' }} aria-hidden="true">
            <div className="card-header d-flex justify-content-between align-items-center">
              <span>{label}</span>
            </div>
            <div className="card-container mt-2 mb-2">{localValue}</div>
          </div>
        </form>
      </div>
    )
  );
};

export default DateOfBirth;
