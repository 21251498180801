import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';
// redux
import { useSelector } from 'react-redux';
import logoBlack from '../../assets/images/logo_black.svg';
import verfied from '../../assets/images/verified.svg';
import notVerfied from '../../assets/images/verified_Grey.svg';
import AppConstants from '../../appConstants';
import authService from '../../services/authServices';
import NoActivePlanPopup from '../../pages/noActivePlanPopup';
import AffilateSidebar from './affilateSidebar';
import Section2remainderPopup from '../../pages/section2remainderPopup';
import VerifiedNotificationPopup from '../../pages/verifiedNotificationPopup';

const AffiliateHeader = ({ callBack }) => {
  const [fullName, setFullName] = useState('');
  const [profilePic, setProfilePic] = useState('male-female.png');
  const { pageTitle } = useSelector((state) => state.home);
  const { logout } = authService;
  const history = useHistory();
  const [showActivePlan, setShowActivePlan] = useState(false);
  const handleCloseActivePlan = () => setShowActivePlan(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const { planDetails, current } = useSelector((state) => state.user);
  const [menuToggle1, setMenuToggle1] = useState(false);
  const [section2RemainderMsg, setSection2RemainderMsg] = useState(false);
  const handleCloseSection2Remainder = () => setSection2RemainderMsg(false);

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const handleClickMenuToggle1 = (status) => {
    setMenuToggle1(status);
  };
  const callbackfunction = () => {
    callBack(true);
  };

  useEffect(async () => {
    setFullName(
      `${current?.first_name || ''} ${current?.last_name || ''}`.trim()
    );
    setProfilePic(current?.profile_image || 'profile_pic.svg');
  }, [current]);

  const handleClickMenuToggle = (status) => {
    setMenuToggle(status);
  };

  const isDashboardLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.dashboard].includes(pathname),
    []
  );
  const isReferalLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.referals].includes(pathname),
    []
  );
  const isEarningsLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.earnings].includes(pathname),
    []
  );
  const isProfileLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.profile].includes(pathname),
    []
  );
  const isSettingLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.affiliate.settings].includes(pathname)
  );
  const isContactUsLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.affiliate.contactsUs].includes(pathname)
  );

  return (
    <>
      <div className="innerHeader mobile">
        <div
          className="mobile-menu d-none"
          onClick={callbackfunction}
          aria-hidden="true"
        >
          <i className="fa fa-bars" aria-hidden="true" />
        </div>

        <header className="d-flex align-items-center">
          <div className="col-md-4 col-12 text-md-left text-center mobile-top">
            <Link to="/match-queue" className="logo-db">
              <img src={logoBlack} className="img-fluid" alt="Lovrican Logo" />
            </Link>
          </div>
          <div className="col-md-4 text-md-center desktop-only">
            <div className="pageName">
              <h1>{pageTitle}</h1>
            </div>
          </div>

          <div className="col-md-4 text-md-right desktop-only">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex">
                {pageTitle === 'Match Queue' && (
                  <div className="text-md-right desktop-only">
                    <div className="likeToday">
                      <div className="numeric">
                        {planDetails.dailyLikeLimit}
                      </div>
                      <span>likes left today</span>
                    </div>
                  </div>
                )}
                <div className="profileSection position-relative">
                  <span className="frfl_name" title={fullName}>
                    {fullName}
                  </span>
                  <Link
                    to={AppConstants.routes.publicProfile}
                    className="logo-db"
                  >
                    <img
                      src={process.env.REACT_APP_PIC_BASE_URL + profilePic}
                      className="img-fluid d-inline-block"
                      alt=" profile img"
                    />
                  </Link>
                  <span className="active" />
                </div>
              </div>

              <div className="three_dot">
                <div
                  className="toggle-icon"
                  aria-hidden="true"
                  onClick={() => handleClickMenuToggle1(!menuToggle1)}
                >
                  <span />
                  <span />
                  <span />
                </div>
                {menuToggle1 && <AffilateSidebar />}
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* mobile menu start */}
      <div className="mobile-view-inner">
        <div
          className="burger"
          aria-hidden="true"
          onClick={() => handleClickMenuToggle(!menuToggle)}
        >
          <span />
          <span />
          <span />
        </div>
        <div className="logo-mobile" role="button">
          <Link to="/match-queue" className="logo-db">
            <img src={logoBlack} className="img-fluid" alt="Lovrican Logo" />
          </Link>
        </div>
      </div>
      <div className={`navbar-mobile ${menuToggle ? 'block' : 'd-none'}`}>
        <div className="menu-wrapper">
          <div className="d-block position-relative">
            <button
              type="button"
              className="close"
              onClick={() => setMenuToggle(false)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <ul className="list-unstyled">
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.dashboard}
                activeClassName="active"
                isActive={isDashboardLinkActive}
              >
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.referals}
                activeClassName="active"
                isActive={isReferalLinkActive}
              >
                Referals
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.earnings}
                activeClassName="active"
                isActive={isEarningsLinkActive}
              >
                Earnings
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.profile}
                activeClassName="active"
                isActive={isProfileLinkActive}
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.settings}
                activeClassName="active"
                isActive={isSettingLinkActive}
              >
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.affiliate.contactsUs}
                activeClassName="active"
                isActive={isContactUsLinkActive}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
          <div className="d-block link-btn">
            <span
              className="round-button mt-0 active"
              role="button"
              aria-hidden="true"
              onClick={() => {
                logout(history);
              }}
            >
              Logout
            </span>
          </div>
        </div>
      </div>
      {/* mobile menu End */}
      <div className="mobile-only">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8">
              <div className="d-flex left align-items-center">
                <span className="active mr-2" />
                <span className="profile-name-mbl">{fullName}</span>
                <span>
                  {current?.status === 'ACTIVE' && current?.phone !== null ? (
                    <img
                      src={verfied}
                      className="img-fluid verifid_img"
                      alt="arrow icon"
                      aria-hidden="true"
                    />
                  ) : (
                    <img
                      src={notVerfied}
                      className="img-fluid verifid_img cursor-pointer"
                      alt="arrow icon"
                      aria-hidden="true"
                      onClick={() => {
                        setVerifyMsg(true);
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex left align-items-center justify-content-end">
                {pageTitle === 'Match Queue' && (
                  <div className="mbl-like">
                    <div className="num_like">{planDetails.dailyLikeLimit}</div>
                    <div>Likes left today</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
        currentPlan={planDetails}
      />

      <Section2remainderPopup
        modalStatus={section2RemainderMsg}
        handleClose={handleCloseSection2Remainder}
        userDetails={current}
      />

      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={current}
      />
    </>
  );
};

export default AffiliateHeader;
