import React from 'react';
// import { Link } from 'react-router-dom';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
// import FaqBox from '../../components/general/faqBox';

function FaqPage() {
  return (
    <>
      <div className="row">
        <div className="col col-md-12">
          <div className="service-inne">
            <div className="service-content">
              <div className="sub-inner ">
                <div className="sub-heading text-center">
                  <h5 className='d-inline-block text-left'>
                    Frequently <br />
                    Asked <br />
                    Questions
                  </h5>
                  {/* <p className="subhead">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. <br /> Lorem Ipsum has been the
                    industry’s standard dummy text ever since the 1500s.
                  </p> */}
                </div>
                <div className="row faq_accordidion">
                  <div className="col-12 container-custome">
                  <Accordion>
                    <AccordionItem header="How does Lovrican work?">
                      <div>
                        Lovrican employs a sophisticated matching algorithm that considers your preferences, values, and aspirations to curate highly compatible matches. You can explore profiles, connect with matches, and embark on a journey toward finding your special African soulmate.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="Is Lovrican only for Africans in America?">
                      <div>
                      Yes, Lovrican is exclusively tailored for single, successful Africans in America who are looking for genuine long-term relationships with other Africans who share their interests, values, and aspirations.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="What makes Lovrican different from other dating sites?">
                      <div>
                        Lovrican is curated for affluent individuals who share
                        cultural and professional backgrounds, ensuring that you
                        connect with like-minded singles who meet your high
                        standards and preferences.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="Is my information secure on Lovrican?">
                      <div>
                        Yes, we prioritize your privacy and security. We employ
                        advanced encryption and security measures to protect
                        your personal information.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="How do I find matches on Lovrican?">
                      <div>
                        After approval, you’ll receive curated and
                        pre-screened matches in your match queue based on your
                        preferences. You can then explore profiles and connect
                        with exceptional matches.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="How do Lovrican's gift cards work?">
                      <div>
                        When you purchase a Lovrican gift card, we engage the
                        lucky recipient, enroll them into the Lovrican
                        community, and connect them with exceptional potential
                        matches.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="How often are new matches added to my queue?">
                      <div>
                        New matches are added regularly based on your
                        preferences and the availability of compatible profiles.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="What types of plans does Lovrican offer?">
                      <div>
                        Lovrican offers Gold, Platinum, and Diamond plans, each
                        with varying benefits to enhance your dating experience.
                      </div>
                   </AccordionItem>

                    <AccordionItem header="Is payment required for Lovrican’s service?">
                      <div>
                      Yes, Lovrican operates as a members-only service. Each member is required to pay a membership fee. This keeps the Lovrican community exclusive to like-minded individuals who share your appreciation of the African culture and aspiration for the future.
                        <br />
                        <br />
                        Here’s the process: Once a profile undergoes screening
                        and receives approval, the profile owner becomes
                        eligible to pay a membership fee and join as a member.
                        Please note: Although anyone can create a basic profile
                        to initiate the screening and approval procedure, only
                        approved profile owners can become Lovrican members.
                        This underscores Lovrican’s emphasis on
                        prioritizing quality over quantity. Consequently, your
                        interactions will exclusively be with purposeful
                        individuals who are committed and genuinely dedicated to
                        discovering their ideal life partner. We firmly believe
                        that this approach offers the most direct route to
                        finding your perfect African soulmate.
                      </div>
                   </AccordionItem>
                    </Accordion>
                  </div>
                </div>


           
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default FaqPage;
