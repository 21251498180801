import { format } from 'date-fns';
import { useLocation, useHistory, Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
// import Header from '../../layouts/partial/header';
import { pageTitle } from '../../redux/actions/home.actions';
import { getUserPlansDetails } from '../../redux/actions/user.actions';
import webServices from '../../services/webServices';
import AppConstants from '../../appConstants';
import trash from '../../assets/images/delete-white.svg';
import verfied from '../../assets/images/verified.svg';
import notVerfied from '../../assets/images/verified_Grey.svg';
import leftArrow from '../../assets/images/left-arrow.png';
import sentImg from '../../assets/images/sent.svg';
import roseImg from '../../assets/images/rose.svg';
// import prflImg from '../../assets/images/who_we_are-old.webp';
import sendBTn from '../../assets/images/sendMessage.svg';
import bckBtn from '../../assets/images/back-arrow-responsive.svg';
// import RightSidebar from '../../layouts/partial/rightSidebar';
import Spinner from '../../components/general/spinner';
import RoseSendPopup from '../roseSendPopup';
import RoseExceedPopup from '../roseExceedPopup';
import VerifiedNotificationPopup from '../verifiedNotificationPopup';
import { authCall } from '../../services';

const ChatMessage = ({ selectedUser, userDetails, ChatDelete, message }) => {
  const fromMe = message.senderId === userDetails.id;
  if (fromMe) {
    return (
      <div className="receiver">
        {message.is_rose === '0' && (
          <div className="msg">
            {message.message}
            <div className="deleteicon_wrapper">
              <img
                className="delete_icon"
                src={trash}
                alt="trash"
                aria-hidden="true"
                // onClick={() => ChatDelete(message.id)}
                onClick={() => {
                  // eslint-disable-next-line
                  const confirmBox = window.confirm(
                    'Do you really want to delete this?'
                  );
                  if (confirmBox === true) {
                    ChatDelete(message.id);
                  }
                }}
              />
            </div>
            <br />
            <span style={{ fontSize: '10px' }}>
              {format(new Date(message.createdAt), 'hh:mm a')}
            </span>
          </div>
        )}

        {message.is_rose === '1' && message.message > 0 && (
          <>
            <div className="notification-wrapper">
              <div className="notification-chat">
                Love outgoing! You just sent {message.message}{' '}
                {message.message > 1 ? 'Roses' : 'Rose'} to{' '}
                {selectedUser?.user_name}!
              </div>
            </div>
            <div className="rose-count-wrapper">
              <div className="roseCount">
                {[...Array(parseInt(message.message, 10))].map((index) => (
                  <div className="rose">
                    <img src={roseImg} className="img-fluid" alt={index} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
    // return (
    //   <div className="chat_list_right" key={message.id}>
    //     <div className="right_inner">
    //       <div className="chat_list_box d-flex align-items-end">
    //         <div className="meggage">
    //           <div className="text-message position-relative">
    //             {message.message}
    //             {/* <a href="/" className="invisibleq"> */}
    //             <img
    //               className="delete_icon"
    //               src={trash}
    //               alt="trash"
    //               aria-hidden="true"
    //               // onClick={() => ChatDelete(message.id)}
    //               onClick={() => {
    //                 // eslint-disable-next-line
    //                 const confirmBox = window.confirm(
    //                   'Do you really want to delete this?'
    //                 );
    //                 if (confirmBox === true) {
    //                   ChatDelete(message.id);
    //                 }
    //               }}
    //             />
    //             {/* </a> */}
    //           </div>
    //           <p className="m-0 pt-2 text-right time">
    //             {format(new Date(message.createdAt), 'hh:mm a')}
    //           </p>
    //         </div>
    //         <img
    //           className="profile ml-2"
    //           src={`${process.env.REACT_APP_PIC_BASE_URL}${
    //             userDetails && userDetails.profile_image
    //           }`}
    //           alt=""
    //         />
    //       </div>
    //     </div>
    //     <div className="clearfix" />
    //   </div>
    // );
  }

  return (
    // <div className="chat_list_left" key={message.id}>
    //   <div className="left_inner">
    //     <div className="chat_list_box d-flex align-items-end">
    //       <img
    //         className="profile mr-2"
    //         src={`${process.env.REACT_APP_PIC_BASE_URL}${selectedUser.profile_image}`}
    //         alt=""
    //       />
    //       <div className="meggage">
    //         <div className="text-message">{message.message}</div>
    //         <p className="m-0 pt-2 text-right time">
    //           {format(new Date(message.createdAt), 'h:mm a')}
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="sender">
      {message.is_rose === '0' && (
        <div className="msg">
          {message.message}
          <br />
          <span style={{ fontSize: '10px' }}>
            {format(new Date(message.createdAt), 'hh:mm a')}
          </span>
        </div>
      )}
      {message.is_rose === '1' && message.message > 0 && (
        <>
          <div className="notification-wrapper">
            <div className="notification-chat">
              Love incoming! {selectedUser?.user_name} just sent you{' '}
              {message.message} {message.message > 1 ? 'Roses' : 'Rose'}!
            </div>
          </div>
          <div className="rose-count-wrapper">
            <div className="roseCount">
              {[...Array(parseInt(message.message, 10))].map((index) => (
                <div className="rose">
                  <img src={roseImg} className="img-fluid" alt={index} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const ChatMain = () => {
  const chatContainer = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [online, setOnline] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [q, setQ] = useState('');
  const [finalChats, setFinalChats] = useState([]);
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );
  const [isSpinner, setIsSpinner] = useState(true);
  // const { planDetails } = useSelector((state) => state.user);
  const [menuToggleChat, setMenuToggleChat] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({
    type: '',
    msg: '',
    status: false
  });
  const [showRoseSend, setShowRoseSend] = useState(false);
  const [roseSent, setRoseSent] = useState(false);
  const [roseExceed, setRoseExceed] = useState(false);
  const [roseCountSend, setRoseCountSend] = useState(0);
  const [myTotalRoses, setMyTotalRoses] = useState(0);
  const [mobileViewUserSelect, setMobileViewUserSelect] = useState(false);

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  // send rose
  const handleCloseRoseSend = () => {
    setShowRoseSend(false);
    setRoseSent(false);
  };

  const handleCloseRoseExceed = () => setRoseExceed(false);

  const handleConfirmRoseSend = async (response) => {
    if (response.roses) {
      const { data, success } = await webServices.getMyRoses();
      if ((success && data.my_roses < 1) || data.my_roses < response.roses) {
        setShowRoseSend(false);
        setRoseExceed(true);
        setRoseCountSend(response.roses);
        setMyTotalRoses(data.my_roses);
      } else {
        // await webServices.userLike({
        //   userId: uDetails.userId,
        //   action: 'rose',
        //   roses: response.roses
        // });
        socket.emit('send_message', {
          senderId: userDetails.id,
          receiverId: selectedUser.user_id,
          message: response.roses,
          is_rose: '1',
          remainRoses: data.my_roses - response.roses,
          createdAt: new Date()
        });
        setMessage('');
        setRoseSent(true);
        // setShowRoseSend(false);
      }
    }
  };

  // check chat permisison exist or not
  useEffect(async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'chat' });
    if (resp.data === 'noActivePlan') {
      history.push({
        pathname: AppConstants.routes.plans
      });
    }
  }, []);

  // end send rose

  useEffect(() => {
    setSocket(
      io(process.env.REACT_APP_BACKEND_BASE_URL, {
        transports: ['websocket']
      })
    );
    dispatch(pageTitle('Chat'));
    dispatch(getUserPlansDetails());
  }, []);

  // if no plan active redirect to plan page
  // useEffect(() => {
  //   if (!planDetails?.haveActivePlan) {
  //     history.push({
  //       pathname: AppConstants.routes.afterLogin
  //     });
  //   }
  // }, [planDetails?.haveActivePlan]);

  const scrollToMyRef = () => {
    const scroll =
      chatContainer?.current?.scrollHeight -
      chatContainer?.current?.clientHeight;
    if (scroll >= 0) {
      chatContainer?.current.scrollTo(0, scroll);
    }
  };

  const getConversationDetails = async (details, isScroll = true) => {
    if (details?.user_id) {
      setSelectedUser(details);
      setMenuToggleChat(false);
      const resp = await webServices.getConversations({
        receiverId: details?.user_id
      });
      if (resp && resp.success === true) {
        setFinalChats(resp.data);
        // getUList();
      }
      if (isScroll) {
        scrollToMyRef();
      }
      // setMenuToggleChat(false);

      setMobileViewUserSelect(true);
      setMessage('');
    }
  };

  const getUList = async () => {
    const resp = await webServices.getMyMatches(true);
    if (resp && resp.success === true) {
      if (resp.data.length <= 0) {
        history.push({
          pathname: AppConstants.routes.afterLogin
        });
      }
      setUserList(resp.data);
      if (
        (!location.state && !selectedUser) ||
        showConfirmation.type === 'Delete'
      ) {
        getConversationDetails(resp.data[0]);
      }
    }
  };

  useEffect(async () => {
    getUList();
    setTimeout(() => {
      setIsSpinner(false);
    }, 400);
  }, []);

  useEffect(
    () => () => {
      socket?.disconnect();
    },
    [socket]
  );

  useEffect(() => {
    if (!socket || !userDetails?.id) {
      return;
    }
    socket.emit('user_connected', {
      id: userDetails.id,
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,
      profileImage: userDetails.profile_image
    });
  }, [socket, userDetails]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('new_message', async (messageDetails) => {
      setFinalChats((chat) => {
        if (Object.keys(chat).length > 0) {
          return {
            ...chat,
            Today: [...chat.Today, messageDetails]
          };
        }
        return { ...chat };
      });
      scrollToMyRef();
      getUList();
    });

    socket.on('online', async (details) => {
      setOnline({ ...online, [details]: true });
      getUList();
    });
    socket.on('offline', async (details) => {
      setOnline({ ...online, [details]: false });
      getUList();
    });
  }, [socket]);

  const sendMessage = async (event) => {
    if (message !== '') {
      event.preventDefault();

      socket.emit('send_message', {
        senderId: userDetails.id,
        receiverId: selectedUser.user_id,
        is_rose: '0',
        message,
        createdAt: new Date()
      });
      setMessage('');
    }
  };

  useEffect(() => {
    if (location?.state?.userDetail) {
      getConversationDetails(location.state.userDetail);
    }
  }, [location.state]);

  const ChatDelete = async (id) => {
    if (id) {
      const resp = await webServices.chatDelete(id);
      if (resp && resp.success === true) {
        getConversationDetails(selectedUser, false);
      }
    }
  };

  const handleClickMenuToggleChat = (status) => {
    setMenuToggleChat(status);
  };

  // user's block, pause and delste
  const handleClickOnUserAction = (prop) => {
    if (prop === 'block') {
      setShowConfirmation({
        type: 'Block',
        msg: `Hi ${userDetails?.first_name || ''} ! Blocking ${
          selectedUser.user_name
        } means that we will stop all communications between you & ${
          selectedUser.user_name
        } and also remove the from your match list.`,
        status: true
      });
    } else if (prop === 'pause') {
      setShowConfirmation({
        type: 'Pause',
        msg: `Hi ${userDetails?.first_name || ''} ! Blocking ${
          selectedUser.user_name
        } means that we will stop all communications between you & ${
          selectedUser.user_name
        } and also remove the from your match list.`,
        status: true
      });
    } else if (prop === 'delete') {
      setShowConfirmation({
        type: 'Delete',
        msg: `Deleting ${selectedUser.user_name} will remove them from your match list and you will no longer be able to communicate with them.`,
        status: true
      });
    } else if (prop === 'resume') {
      setShowConfirmation({
        type: 'Resume',
        msg: `Hi ${userDetails?.first_name || ''} ${
          userDetails?.last_name || ''
        }! Resuming ${
          selectedUser.user_name
        } means that we will start all communications between you & ${
          selectedUser.user_name
        }.`,
        status: true
      });
    } else if (prop === 'unblock') {
      setShowConfirmation({
        type: 'Unblock',
        msg: `Hi ${userDetails?.first_name || ''} ${
          userDetails?.last_name || ''
        }! Unblocking ${
          selectedUser.user_name
        } means that we will start all communications between you & ${
          selectedUser.user_name
        }.`,
        status: true
      });
    }
  };

  // confirmation modal
  const handleConfirmFinal = async () => {
    const response = await webServices.userChatActions({
      toId: selectedUser.user_id,
      status: showConfirmation.type
    });
    if (response && response.success) {
      toast.success(response.message);
      setShowConfirmation(false);
      getUList(true);
      setSelectedUser({
        ...selectedUser,
        blockedByMe:
          showConfirmation.type === 'Unblock' ||
          showConfirmation.type === 'Resume'
            ? ''
            : showConfirmation.type
      });
    }
  };

  return (
    <>
      {isSpinner && userDetails ? (
        <Spinner active={isSpinner} />
      ) : (
        <>
          {userDetails && (
            <div className="dashboard-common">
              <div className="dashbaod-sidebar d-none">
                <div className="mobile-close-menu">
                  <i className="fa fa-window-close" aria-hidden="true" />
                </div>
                <Link className="db-profile1 position-relative" to="/">
                  <div className="active-point" />
                  <img
                    src={`${process.env.REACT_APP_PIC_BASE_URL}${
                      userDetails && userDetails.profile_image
                    }`}
                    alt={`${userDetails.first_name || ''} ${
                      userDetails && userDetails.last_name
                    }`}
                  />
                  <div className="profile_active text-left">
                    <div className="prfl_name">{`${
                      userDetails.first_name || ''
                    } ${userDetails && userDetails.last_name}`}</div>
                    <div className="aval">Available</div>
                  </div>
                </Link>
                <div className="activate-lov-plan search-lov-plan p-0 position-relative">
                  <i className="fa fa-search position-absolute" />
                  <input
                    type="text"
                    className="form-control search-username-input"
                    placeholder="Search by name here"
                    onChange={(e) => setQ(e.target.value)}
                  />
                </div>
                <div className="matchqueue-box mt-4">
                  {userList
                    .filter(
                      (user) =>
                        user.user_name
                          .toString()
                          .toLowerCase()
                          .indexOf(q.toLowerCase()) > -1
                    )
                    .map((userDetail) => (
                      <div
                        className="db-profile1 db-profile_active position-relative"
                        onClick={() => getConversationDetails(userDetail)}
                        aria-hidden="true"
                        key={userDetail.user_id}
                      >
                        {userDetail.is_online && (
                          <div className="active-point" />
                        )}
                        <div className="active_profile_left d-flex">
                          <img
                            src={`${process.env.REACT_APP_PIC_BASE_URL}${userDetail.profile_image}`}
                            alt=""
                          />
                          <div className="profile_active text-left">
                            <div className="prfl_name">{`${userDetail.user_name}`}</div>
                            <div
                              className={`aval ${
                                userDetail.last_message ? '' : 'invisible'
                              }`}
                            >
                              {userDetail.last_message}
                            </div>
                          </div>
                        </div>
                        <div className="active_profile_right">
                          <div
                            className={`time_span mt-1 ${
                              userDetail.message_time ? '' : 'invisible'
                            }`}
                          >
                            {userDetail.message_time}
                          </div>
                          <div className="text-right d-flex justify-content-end mt-1">
                            <span
                              className={`badge ${
                                userDetail.message_badge ? '' : 'invisible'
                              }`}
                            >
                              {userDetail.message_badge}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="dashbaod-rightbar d-none">
                {/* <Header /> */}
                {selectedUser && (
                  <div className="chat-container">
                    <div className="chat-inner">
                      <div className="chat-profile-name">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="chat-left">
                            <div className="d-flex align-items-center">
                              <div
                                className="chat_back"
                                onClick={() => setSelectedUser(null)}
                                aria-hidden="true"
                              >
                                <img src={leftArrow} alt="leftArrow" />
                              </div>
                              <div className="chat_prfl_header d-flex ml-3 align-items-center">
                                <img
                                  src={`${process.env.REACT_APP_PIC_BASE_URL}${selectedUser.profile_image}`}
                                  alt={selectedUser.user_name}
                                />
                                <div className="profile_active text-left">
                                  <div className="prfl_name">
                                    {selectedUser.user_name}
                                    {/* {selectedUser.is_online && <span />} */}
                                  </div>
                                  <div className="aval">
                                    {selectedUser.email}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="chat-right">
                        <a className="three_dot" href="/">
                          <span />
                          <span />
                          <span />
                        </a>
                      </div> */}
                        </div>
                      </div>
                      <div className="chat_details">
                        <div className="chat-list">
                          {!!Object.keys(finalChats).length &&
                            Object.keys(finalChats).map((chatMessage) => (
                              <>
                                <div
                                  key={chatMessage}
                                  style={{
                                    textAlign: 'center',
                                    marginBottom: '25px'
                                  }}
                                >
                                  {chatMessage}
                                </div>
                                {finalChats[chatMessage].map((chat) => (
                                  <ChatMessage
                                    selectedUser={selectedUser}
                                    userDetails={userDetails}
                                    message={chat}
                                    ChatDelete={ChatDelete}
                                  />
                                ))}
                              </>
                            ))}
                        </div>
                      </div>
                      <div className="typing-box">
                        <div className="border-box d-flex justify-content-between">
                          <input
                            className="typing_input form-control form-control-sm"
                            type="text"
                            placeholder="Type message here..."
                            value={message}
                            onChange={({ target: { value } }) =>
                              setMessage(value)
                            }
                            onKeyDown={(event) =>
                              event.key === 'Enter' && sendMessage(event)
                            }
                          />
                          <a href="#0" onClick={sendMessage}>
                            <img src={sentImg} className="send-icon" alt="" aria-label="Send Icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* new page  */}
              <div className="inner-wrapper-newdesign">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="largeScreen">
                      <div className="d-flex justify-content-between Chat-wrapper">
                        <div className="w-100 chatLeft">
                          <div className="row">
                            <div
                              className={`col-md-4 chat-left ${
                                mobileViewUserSelect
                                  ? 'chatLeft-mobile-hide'
                                  : 'chatLeft-mobile-show'
                              }`}
                            >
                              {/* <div className="top cursor-pointer">
                            <i
                              className="fa fa-chevron-left cursor-pointer"
                              onClick={() => setSelectedUser(null)}
                              aria-hidden="true"
                            />
                          </div> */}
                              <div className="topbar">
                                <div className="back ">
                                  <div>
                                    <i className="fa fa-chevron-left d-none" />
                                  </div>
                                </div>

                                <div className="search-lov-plan position-relative">
                                  <i className="fa fa-search position-absolute" />
                                  <input
                                    type="text"
                                    className="form-control search-username-input border-0"
                                    placeholder="Search by name here"
                                    onChange={(e) => setQ(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="chat-lest-left scroll">
                                {userList
                                  .filter(
                                    (user) =>
                                      user.user_name
                                        .toString()
                                        .toLowerCase()
                                        .indexOf(q.toLowerCase()) > -1
                                  )
                                  .map((userDetail) => (
                                    <div
                                      className={`cursor-pointer chat-emp d-flex ${
                                        selectedUser?.user_name ===
                                        userDetail.user_name
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        getConversationDetails(userDetail)
                                      }
                                      aria-hidden="true"
                                      key={userDetail.user_id}
                                    >
                                      <div className="left">
                                        <span
                                          className={`${
                                            userDetail.is_online ? 'active' : ''
                                          }`}
                                        />
                                        <img
                                          src={`${process.env.REACT_APP_PIC_BASE_URL}${userDetail.profile_image}`}
                                          alt=""
                                        />
                                      </div>
                                      <div className="right">
                                        <div className="name">
                                          {`${userDetail.first_name}`}
                                          <img
                                            src={
                                              userDetail.status === 'ACTIVE' &&
                                              userDetail.phone !== null
                                                ? verfied
                                                : notVerfied
                                            }
                                            className="img-fluid verifid_img"
                                            style={{
                                              cursor:
                                                userDetails?.status !==
                                                  'ACTIVE' ||
                                                userDetails?.phone === null
                                                  ? 'pointer'
                                                  : 'default'
                                            }}
                                            alt="arrow icon"
                                            aria-hidden="true"
                                            onClick={() =>
                                              setVerifyMsg(
                                                userDetails?.status !==
                                                  'ACTIVE' ||
                                                  userDetails?.phone === null ||
                                                  false
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="dec">
                                          {userDetail.last_message
                                            ? `You: ${
                                                userDetail.last_message
                                              } ${
                                                userDetail.is_rose === '1'
                                                  ? 'Rose'
                                                  : ''
                                              }`
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div
                              className={`col-md-8 chat-right ${
                                mobileViewUserSelect
                                  ? 'chatLeft-mobile-show'
                                  : 'chatLeft-mobile-hide'
                              }`}
                            >
                              {selectedUser && (
                                <>
                                  <div className="top text-center position-relative">
                                    <a
                                      className="backBtn"
                                      href="#0"
                                      onClick={() =>
                                        setMobileViewUserSelect(false)
                                      }
                                    >
                                      <img
                                        src={bckBtn}
                                        className="img-fluid"
                                        alt="back-arrow"
                                      />
                                    </a>
                                    <div className="chatProfole">
                                      <img
                                        src={
                                          selectedUser?.status === 'ACTIVE' &&
                                          selectedUser.phone !== null
                                            ? verfied
                                            : notVerfied
                                        }
                                        className={`img-fluid verifid_img ${
                                          userDetails?.status === 'ACTIVE' &&
                                          userDetails?.phone !== null
                                            ? ''
                                            : 'cursor-pointer'
                                        }`}
                                        alt="arrow icon"
                                        aria-hidden="true"
                                        onClick={() =>
                                          setVerifyMsg(
                                            userDetails?.status !== 'ACTIVE' ||
                                              userDetails?.phone === null ||
                                              false
                                          )
                                        }
                                      />
                                      <img
                                        src={`${process.env.REACT_APP_PIC_BASE_URL}${selectedUser.profile_image}`}
                                        alt={selectedUser.first_name}
                                      />
                                      <span>{selectedUser.first_name}</span>
                                      <span
                                        className={`${
                                          selectedUser.is_online ? 'active' : ''
                                        }`}
                                      />
                                    </div>
                                    <div className="chatToggle">
                                      <div className="position-relative">
                                        <div
                                          className="toggle-icon"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleClickMenuToggleChat(
                                              !menuToggleChat
                                            )
                                          }
                                        >
                                          <span />
                                          <span />
                                          <span />
                                        </div>
                                        {menuToggleChat && (
                                          <div className="toggleMenu">
                                            <ul className="list-unstyled">
                                              <li>
                                                <Link
                                                  to="#/"
                                                  onClick={() =>
                                                    handleClickOnUserAction(
                                                      selectedUser.blockedByMe ===
                                                        'Block'
                                                        ? 'unblock'
                                                        : 'block'
                                                    )
                                                  }
                                                >
                                                  {selectedUser.blockedByMe ===
                                                  'Block'
                                                    ? 'Unblock'
                                                    : 'Block'}{' '}
                                                  user
                                                </Link>
                                              </li>
                                              {/* <li>
                                            <Link
                                              to="#/"
                                              onClick={() =>
                                                handleClickOnUserAction(
                                                  selectedUser.blockedByMe ===
                                                    'Pause'
                                                    ? 'resume'
                                                    : 'pause'
                                                )
                                              }
                                            >
                                              {selectedUser.blockedByMe ===
                                              'Pause'
                                                ? 'Resume'
                                                : 'Pause'}{' '}
                                              user
                                            </Link>
                                          </li> */}
                                              <li>
                                                <Link
                                                  to="#/"
                                                  onClick={() =>
                                                    handleClickOnUserAction(
                                                      'delete'
                                                    )
                                                  }
                                                >
                                                  Delete user
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="messageBox"
                                    ref={chatContainer}
                                  >
                                    {!!Object.keys(finalChats).length &&
                                      Object.keys(finalChats).map(
                                        (chatMessage) => (
                                          <>
                                            {finalChats[chatMessage].length >
                                              0 && (
                                              <div
                                                key={chatMessage}
                                                className="dateFormate"
                                              >
                                                <div className="inner">
                                                  {chatMessage}
                                                </div>
                                              </div>
                                            )}
                                            {finalChats[chatMessage].map(
                                              (chat) => (
                                                <ChatMessage
                                                  selectedUser={selectedUser}
                                                  userDetails={userDetails}
                                                  message={chat}
                                                  ChatDelete={ChatDelete}
                                                />
                                              )
                                            )}
                                          </>
                                        )
                                      )}
                                  </div>
                                  <div className="chatInput position-relative">
                                    {(selectedUser.blockedByMe ||
                                      selectedUser.blockedByUser) && (
                                      <>
                                        {(selectedUser.blockedByMe !== '' ||
                                          selectedUser.blockedByUser !==
                                            '') && (
                                          <div className="blockUser">
                                            {selectedUser.blockedByMe
                                              ? `You blocked. ${selectedUser.user_name} is not accepting
                                    messages at this time`
                                              : `${selectedUser.user_name} is not accepting
                                    messages at this time`}
                                          </div>
                                        )}
                                      </>
                                    )}

                                    {!selectedUser.blockedByMe &&
                                      !selectedUser.blockedByUser && (
                                        <>
                                          <div className="left">
                                            <input
                                              type="text"
                                              className="form-control search-username-input border-0"
                                              placeholder="Enter your message here"
                                              value={message}
                                              onChange={({
                                                target: { value }
                                              }) => setMessage(value)}
                                              onKeyDown={(event) =>
                                                event.key === 'Enter' &&
                                                sendMessage(event)
                                              }
                                              // disabled={
                                              //   selectedUser.blockedByMe !==
                                              //     '' ||
                                              //   selectedUser.blockedByUser !==
                                              //     ''
                                              // }
                                            />
                                          </div>

                                          <div className="rgt">
                                            <a
                                              href="#0"
                                              type="btn"
                                              onClick={sendMessage}
                                            >
                                              <img
                                                src={sendBTn}
                                                className="img-fluid"
                                                alt=""
                                                aria-label="Send Icon"
                                              />
                                            </a>
                                          </div>
                                          <div className="rgt rose">
                                            <a
                                              href="#0"
                                              type="btn"
                                              onClick={() =>
                                                setShowRoseSend(true)
                                              }
                                            >
                                              <img
                                                src={roseImg}
                                                className="img-fluid"
                                                alt=""
                                                aria-label = "Send Rose Icon"
                                              />
                                            </a>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* right ridebar */}
                        {/* <RightSidebar /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* new page ending */}
            </div>
          )}
          <Modal
            className="filter-model PlanStatusModel"
            show={showConfirmation.status}
            onHide={showConfirmation.status}
            centered
            // backdrop="static"
          >
            <Modal.Header>
              <Modal.Title className="w-100">
                <h5 className="modal-title text-center w-100">
                  {showConfirmation.type} this user?
                </h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{showConfirmation.msg}</p>
              <div className="text-center">
                <button
                  type="button"
                  className="btn theme-btn themebtn1 mr-0"
                  onClick={() => handleConfirmFinal()}
                >
                  {showConfirmation.type} user
                </button>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn theme-btn themebtn2"
                  onClick={() =>
                    setShowConfirmation({ ...showConfirmation, status: false })
                  }
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <RoseSendPopup
            modalStatus={showRoseSend}
            handleClose={handleCloseRoseSend}
            userData={selectedUser}
            handleConfirmRoseSend={handleConfirmRoseSend}
            roseSent={roseSent}
            fromMatched
            handleCloseCancel={handleCloseRoseSend}
          />

          <RoseExceedPopup
            modalStatus={roseExceed}
            userData={selectedUser}
            roseCountSend={roseCountSend}
            myTotalRoses={myTotalRoses}
            handleClose={handleCloseRoseExceed}
            fromMatched
          />

          <VerifiedNotificationPopup
            modalStatus={verifyMsg}
            handleClose={handleCloseVerifyMsg}
            userDetails={userDetails}
          />
        </>
      )}
    </>
  );
};

export default ChatMain;
