export default Object.freeze({
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  UNSET_USER_DETAILS: 'UNSET_USER_DETAILS',
  USER_PLANS_DETAILS: 'USER_PLANS_DETAILS',
  POTENTIAL_MATCHES: 'POTENTIAL_MATCHES',
  USER_LIST: 'USER_LIST',
  USER_ADDED_DATA_LIST: 'USER_ADDED_DATA_LIST',
  USER_MATCH_COUNT: 'USER_MATCH_COUNT',
  CONTACT_USER_LIST: 'CONTACT_USER_LIST',
});
