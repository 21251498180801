import React from 'react';
import { useHistory } from 'react-router-dom';
import logoWhitePng from '../../../assets/images/logo-white.png';
import paymentFailPng from '../../../assets/images/payment_fail.png';
import AppConstants from '../../../appConstants';

const OrderFailure = ({ isLoading, planDetails, userDetails, selectedPlan }) => {
  const history = useHistory();
  if (isLoading) {
    return (
      <div className="container text-center py-5 my-5">
        <h1>Please wait...</h1>
        <div className="py-5 my-5" />
      </div>
    );
  }
  const handleClickPlan = () => {
    history.push({
      pathname: AppConstants.routes.plans,
      state: { planDetails }
    });
  };

  return (
    <>
      <div className="mid-page mb-5 d-none">
        <div className="container">
          <div className="midPage-container text-center">
            <div>
              <img src={logoWhitePng} alt="Lovrican logo" />
            </div>
            <div>
              <div className="thank_you">
                <h1>Payment Failed Sorry</h1>
                <img src={paymentFailPng} alt="/" className="img-fluid" />
              </div>
            </div>
            <a
              href={AppConstants.routes.plans}
              className="btn btn-info theme-btn mt-4 "
              role="button"
            >
              Retry
            </a>
          </div>
        </div>
      </div>
      <div
        className={`inner-wrapper-newdesign paymentStatus paymentHeight ${
          userDetails.looking_for === 'WOMAN' ? 'womanFailed' : 'manFailed'
        }`}
      >
        <div className="row">
          <div className="col-md-12 ">
            <div className="largeScreen">
              <div className="title">
                <h1>
                  Payment
                  <br />
                  failed!
                </h1>
                <p className="pt-3 pb-3">Oh no, {userDetails?.first_name}!</p>
                <p>
                  We’re sorry to inform you that there was an issue
                  <br />
                  processing your payment for {selectedPlan} subscription at
                  <br />
                  Lovrican.
                </p>
                <p className="pt-2">
                  Please ensure that your payment details are up to date and
                  <br /> try again. If you continue to face difficulties, kindly
                  reach
                  <br /> out to our support team at support@lovrican.com for
                  assistance.
                </p>

                <ul className="list-unstyled pt-2">
                  <li>
                    <button
                      type="button"
                      className="btn addPhoto"
                      onClick={() => handleClickPlan()}
                    >
                      Try Again
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderFailure;
