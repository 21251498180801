import { UserConstants } from '../constants';
import webServices from '../../services/webServices';

export const setUser = (payload) => {
  window.localStorage.setItem('userDetails', JSON.stringify(payload || ''));
  window.localStorage.setItem('plan_expiry_date', payload.plan_expiry_date);
  return {
    type: UserConstants.SET_USER_DETAILS,
    payload
  };
};

export const unsetUser = () => ({
  type: UserConstants.UNSET_USER_DETAILS
});

export const getUserPlansSuccess = (payload) => ({
  type: UserConstants.USER_PLANS_DETAILS,
  payload
});

export const potentialMatchSuccess = (payload) => ({
  type: UserConstants.POTENTIAL_MATCHES,
  payload
});

export const userListSuccess = (payload) => ({
  type: UserConstants.USER_LIST,
  payload
});

export const contactUserListSuccess = (payload) => ({
  type: UserConstants.CONTACT_USER_LIST,
  payload
});

export const userAddedDataListSuccess = (payload) => ({
  type: UserConstants.USER_ADDED_DATA_LIST,
  payload
});

export const getUserMatchCountSuccess = (payload) => ({
  type: UserConstants.USER_MATCH_COUNT,
  payload
});

export const getUserPlansDetails = () => {
  const result = async (dispatch) => {
    await webServices.getUserPlans().then((response) => {
      if (response && response.success) {
        if (response.data.isTrialExpired) {
          // history.push({
          //   pathname: "/plans",
          // });
        } else {
          // dispatch(getUserPlansSuccess(response.data));
        }
        dispatch(getUserPlansSuccess(response.data));
      }
    });
  };
  return result;
};

export const getPotentialMatches = () => (dispatch) => {
  webServices.getPotentialMatches().then((response) => {
    if (response && response.success) {
      dispatch(potentialMatchSuccess(response.data));
    }
  });
};

// get user count
export const getUserMatchCount = () => (dispatch) => {
  webServices.getMatchCount().then((response) => {
    if (response && response.success) {
      dispatch(getUserMatchCountSuccess(response.data.count));
    }
  });
};

// Admin's
export const getUserList = (type) => (dispatch) => {
  webServices.getUserList(type).then((response) => {
    if (response && response.success) {
      dispatch(userListSuccess(response.data));
    }
  });
};

export const getUserAddedDataList = () => (dispatch) => {
  webServices.getUserAddedDataList().then((response) => {
    if (response && response.success) {
      dispatch(userAddedDataListSuccess(response.data));
    }
  });
};

export const getContactUserList = () => (dispatch) => {
  webServices.getContactUserList().then((response) => {
    if (response && response.success) {
      dispatch(contactUserListSuccess(response.data));
    }
  });
};
