import React from 'react';
import { NavLink } from 'react-router-dom';
import AppConstants from '../appConstants';
import logo from '../assets/images/Logo.svg';
import eye from '../assets/images/eye_white.svg';
import { authCall } from '../services';
import arrowIcon from '../assets/images/arrow-circle.svg';

function PasswordComponent() {
  const [values, setValues] = React.useState({
    email: ''
  });

  const [errorValues, setErrorValues] = React.useState({
    emailError: ''
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
    setErrorValues({ ...errorValues, [`${name}Error`]: '' });
  };

  const resetFormValidation = () => {
    let isValid = true;
    const errors = { ...errorValues };
    if (!values.email) {
      errors.emailError = 'The email field is required.';
      isValid = false;
    }
    setErrorValues(errors);
    return isValid;
  };

  const sendCode = async () => {
    const isValid = resetFormValidation();
    if (isValid) {
      const resp = await authCall('auth/action/send-email-otp-to-user', {
        email: values.email
      });
      if (resp && resp.success === true) {
        // this.setState({
        //   step: 2
        // });
      }
    }
  };

  return (
    <>
      {/* enter email for sent otp */}
      <section className="loginWrapper text-light position-relative forgetpassword">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>

            <div className="reg-inner">
              <div className="col-12">
                <h1>
                  Reset <br />
                  Password
                </h1>
              </div>

              <div className="col-12 d-flex reg-login justify-content-between pr-0">
                <input
                  className="login"
                  type="email"
                  name="user-email"
                  id="user-email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  onChange={handleChange('email')}
                />
              </div>
              <span style={{ color: 'red' }}>{errorValues.emailError}</span>
              <div className="col-12 declaration-box reg-login border-0">
                <p className="mb-0">
                  We&#x2019;ll send a code to your registered email to reset your
                  password.
                </p>
              </div>
              <div
                className="col-12 d-flex reg-login justify-content-end pr-0 border-0"
                onClick={sendCode}
                aria-hidden="true"
              >
                <span className="search-btn" role="button" tabIndex="0">
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
              <div className="col-12 text-right link-btn">
                <p>
                  <span> I now remember my</span>
                  <NavLink to={AppConstants.routes.login}>
                    <span className="link-color" role="button">
                      Login
                    </span>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* reset password form */}
      <section className="loginWrapper text-light position-relative forget_password_final">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>

            <div className="reg-inner">
              <div className="col-12">
                <h1>
                  Reset <br />
                  Password
                </h1>
              </div>

              <div className="col-12 d-flex reg-login justify-content-between pr-0 mb-1">
                <div className="d-flex align-items-end password">
                  <div className="icon">
                    <img src={eye} className="img-fluid mr-2" alt="" />
                  </div>
                  <div className="field">
                    <input
                      className="login"
                      type="password"
                      name="user-email"
                      id="user-email"
                      placeholder="Enter Password"
                      autoComplete="off"
                    />
                  </div>
                  <div className="hint">
                    <ul className="list-unstyled">
                      <li>Atleast:</li>
                      <li>• 8 characters</li>
                      <li>• 1 special character</li>
                      <li>• 1 capital letter</li>
                      <li>• 1 number</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex w-100 mt-1 align-items-center pb-4">
                  <div className="progress">
                    <div className="progress-bar" style={{ width: '70%' }} />
                  </div>
                  <div className="password_status">Password is strong</div>
                </div>
              </div>
              <div className="col-12 d-flex reg-login justify-content-between pr-0">
                <div className="d-flex align-items-end password">
                  <div className="icon">
                    <img src={eye} className="img-fluid mr-2" alt="" />
                  </div>
                  <div className="field">
                    <input
                      className="login"
                      type="password"
                      name="user-email"
                      id="user-email"
                      placeholder="Confirm Password"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex reg-login justify-content-end pr-0 border-0">
                <span className="search-btn" role="button" tabIndex="0">
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordComponent;
