import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logoWhitePng from '../../../assets/images/logo-white.png';
import paymentPng from '../../../assets/images/payment.png';
import { getUserPlansDetails } from '../../../redux/actions/user.actions';

const OrderSuccess = ({ isLoading, response, userDetails, selectedPlan }) => {
  if (isLoading) {
    return (
      <div className="container text-center py-5 my-5">
        <h1>Please wait...</h1>
        <div className="py-5 my-5" />
      </div>
    );
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const { planDetails } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getUserPlansDetails());
  }, [dispatch]);

  // useEffect(() => {
  //   if (!response || !response.result) {
  //     return;
  //   }

  //   setTimeout(() => {
  //     history.push('/');
  //   }, 5000);
  // }, [response]);

  const shouldShowTransactionId = !!response && !!response.result;

  return (
    <>
      <div className="mid-page mb-5 d-none">
        <div className="container">
          <div className="midPage-container text-center">
            <div>
              <img src={logoWhitePng} alt="Lovrican logo" />
            </div>
            <div>
              <div className="thank_you">
                <h1 className={shouldShowTransactionId ? 'mb-0' : ''}>
                  Payment has been done!
                </h1>
                {shouldShowTransactionId && (
                  <p>Transaction id: #{response.result.id}</p>
                )}
                <img src={paymentPng} alt="/" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`inner-wrapper-newdesign paymentStatus paymentHeight ${
          userDetails.looking_for === 'WOMAN' ? 'womanSuccess' : 'manSuccess'
        }`}
      >
        <div className="row">
          <div className="col-md-12 ">
            <div className="largeScreen">
              <div className="title">
                <h1>
                  Payment <br />
                  Successful!
                </h1>
                <p className="pt-3 pb-3">
                  Congratulations, {userDetails?.first_name}!
                </p>
                <p>
                  Your payment has been successfully processed, and you <br />
                  are now subscribed to the {selectedPlan} at Lovrican! Get{' '}
                  <br />
                  ready to embark on an incredible journey of love and <br />
                  connections with our exclusive features and premium <br />
                  matchmaking services.
                </p>
                <p>
                  With your subscription, you can now unlock a world of <br />
                  romantic possibilities. Explore curated matches and engage{' '}
                  <br />
                  in meaningful conversations with like-minded individuals.
                </p>
                <ul className="list-unstyled pt-2 d-flex">
                  <li className="mr-md-3 meetMatch">
                    <button
                      type="button"
                      className="btn addPhoto"
                      onClick={() => history.push('/match-queue')}
                    >
                      Meet Your Matches
                    </button>
                  </li>
                  <li className="patmentHistory">
                    <button
                      type="button"
                      style={{ backgroundColor: '#634a65' }}
                      className="btn addPhoto"
                      onClick={() =>
                        history.push({
                          pathname: '/billing-details',
                          state: { planDetails }
                        })
                      }
                    >
                      Payment History
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
