import React from 'react';
import eyeIcon from '../../adminAssets/images/dashbaord_eye.svg'
import closeEyeIcon from '../../adminAssets/images/dashbaord_eye_off.svg'
import archiveIcon from '../../adminAssets/images/dashbaord_suspend-white.svg'
import greyArchiveIcon from '../../adminAssets/images/dashbaord_suspend-Grey.svg'
import deleteIcon from '../../adminAssets/images/dashboard_delete.svg'

const UserFooterButtonComponent = (props) => {
    const { user, hideButtonHandler, visibleButtonHandler, suspendButtonHandler, deleteButtonHandler } = props;

    if (!user?.status || !user?.activityStatus) return null;

    const btnClickHandler = (event, cb = null) => {
        event.preventDefault();
        event.stopPropagation();

        if (cb !== null) {
            cb();
        }
    };

    return (
        <>
            {user.activityStatus === 'VISIBLE' && (
                <a href="/" className="eye_blue" aria-label="Hide" onClick={event => btnClickHandler(event, hideButtonHandler)}>
                    <img src={eyeIcon} alt="" />
                </a>
            )}
            {user.activityStatus === 'HIDDEN' && (
                <a href="/" className="eye_red" aria-label="Visible" onClick={event => btnClickHandler(event, visibleButtonHandler)}>
                    <img src={closeEyeIcon} alt="" />
                </a>
            )}

            {user.activityStatus !== 'SUSPENDED' && (
                <a href="/" className="suspend_active" aria-label="Suspend" onClick={event => btnClickHandler(event, suspendButtonHandler)}>
                    <img src={archiveIcon} alt="" />
                </a>
            )}

            {user.activityStatus === 'SUSPENDED' && (
                <a href="/" className="suspend" aria-label="Resume Suspended" onClick={event => btnClickHandler(event, visibleButtonHandler)}>
                    <img src={greyArchiveIcon} alt="" />
                </a>
            )}

            {user.status !== 'DELETED' && (
                <a href="/" className="delete_active" aria-label="Delete" onClick={event => btnClickHandler(event, deleteButtonHandler)}>
                    <img src={deleteIcon} alt="" />
                </a>
            )}
        </>
    );
};

export default UserFooterButtonComponent;
