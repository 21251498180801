import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { useLocation, useHistory } from 'react-router-dom';
import * as moment from 'moment';
import webServices from '../../../services/webServices';
// import { isEmpty, isValidEmail } from '../../../helpers/validator';
toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: '3000',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};

const GiftEditComponent = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [giftUsed, setGiftUsed] = useState(false);
  const [giftDetails, setGiftDetails] = useState({});
  const [planDetail, setPlanDetail] = useState('');
  const [formValidation, setFormValidation] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  useEffect(async () => {
    const queryParams = new URLSearchParams(search);
    const systemOrderId = queryParams.get('sysorderid');
    setPlanDetail(queryParams.get('plan'));
    if (!systemOrderId) {
      history.push('/gift');
      return;
    }
    const response = await webServices.giftPaymentRetry({ systemOrderId });
    if (response && response.success) {
      setGiftDetails({
        ...response.data,
        ...response.userDetails,
        ...response.planDetails
      });
      setValues({
        ...values,
        firstName: response.userDetails?.first_name,
        lastName: response.userDetails?.last_name,
        email: response.userDetails?.email
      });
      setGiftUsed(response.isNew);
    } else {
      toastr.clear();
      toastr.error('ERROR! Order id invalid');
      history.push('/gift');
    }
  }, []);

  const handleChange = (e) => {
    setFormValidation({ ...formValidation, [e.target.name]: '' });
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const queryParams = new URLSearchParams(search);
    const systemOrderId = queryParams.get('sysorderid');
    const response = await webServices.changeRecipient({
      ...values,
      systemOrderId,
      userId: giftDetails.id
    });
    if (response.success) {
      toastr.success('Reciepent changed successfully.');
      const res = await webServices.giftPaymentRetry({ systemOrderId });
      setGiftUsed(res.isNew);
      setTimeout(() => {
        history.push('/gift');
      }, 2000);
    } else {
      toastr.clear();
      toastr.error(response.message);
    }
  };

  const RecipientMessage = () => (
    <div className="giftWrapper editGiftNotUsed">
      <div className="container-fluid">
        <div className="row">
          <div className="innerWrapper">
            <div className="col-12 col-md-6">
              <h1>Welcome {giftDetails?.senderDetails?.firstName}!</h1>
              <p className="mb-0">
                Excitement is in the air! You sent a wonderful gift to{' '}
                {giftDetails?.receiverDetails?.firstName}. We are happy to let
                you know that your gift has already been redeemed and{' '}
                {giftDetails?.receiverDetails?.firstName}’s love journey has
                begun. Thank you for joining our mission to spread love
                everywhere! <br />
                {/* Your generosity is making the world a better place,
                one gift at a time. Want to continue gifting? Use the link
                below. Excitement is in the air! You’ve just a sent a wonderful{' '}
                <br />
                gift plan {giftDetails?.orderId} to{' '}
                {giftDetails?.receiverDetails?.firstName}.<br />
                <br />
                This gift card has already been redeemed. */}
                {/* {giftDetails?.receiverDetails?.firstName}{' '}
                {giftDetails?.receiverDetails?.lastName} on <br />{' '}
                {moment(giftDetails?.createdAt).format('DD . MM . YYYY')}. This
                gift card has been used. */}
              </p>
              <p>
                Your generosity is making the world a better place, one gift at
                a time.
              </p>
              <p className="mt-4">
                Want to continue gifting? Use the link below.
              </p>
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-theme btn-large"
                aria-hidden="true"
                onClick={() => history.push('/gift')}
              >
                Purchase Gift Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!giftUsed ? (
        <div className="giftWrapper editGiftUsed">
          <div className="container-fluid">
            <div className="row">
              <div className="innerWrapper">
                <div className="col-12">
                  <h1>Welcome {giftDetails?.senderDetails?.firstName}!</h1>
                  <p>
                    You gifted {planDetail} Plan to {giftDetails?.first_name} on
                    <br />{' '}
                    {moment(giftDetails?.createdAt).format('DD . MM . YYYY')}.
                    If you wish to change the recipient,
                    <br /> you can update the details below.
                  </p>
                  <h1>
                    Edit the gift card
                    <br /> Recipient.
                  </h1>
                  <div className="row customForm">
                    <div className="col-md-6">
                      <div className="box">
                        <input
                          className="themeInput"
                          type="text"
                          placeholder="First Name"
                          value={values.firstName}
                          name="firstName"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <span className="validation-error">
                        {formValidation?.firstName}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <div className="box">
                        <input
                          className="themeInput"
                          type="text"
                          placeholder="Last Name"
                          value={values.lastName}
                          name="lastName"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <span className="validation-error">
                        {formValidation?.lastName}
                      </span>
                    </div>
                    <div className="col-md-12">
                      <div className="box">
                        <input
                          className="themeInput"
                          type="text"
                          placeholder="Enter your Email"
                          value={values.email}
                          name="email"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <span className="validation-error">
                        {formValidation?.email}
                      </span>
                    </div>
                    <div className="col-12 text-right">
                      <span
                        className="theme-Circle-btn  top-space"
                        role="button"
                        aria-hidden="true"
                        onClick={handleSubmit}
                      >
                        <i className="fa fa-arrow-right" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <RecipientMessage />
      )}
    </>
  );
};

export default GiftEditComponent;
