import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import webServices from '../../../services/webServices';
import logoWhite from '../../../assets/images/Logo.svg';

const SuccessDetailsComponent = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    (async () => {
      const queryParams = new URLSearchParams(search);
      const systemOrderId = queryParams.get('sysorderid');
      if (!systemOrderId) {
        history.push('/gift');
        return;
      }
      await webServices.updateOrderForGift({
        systemOrderId
      });
      setIsLoading(false);
      setTimeout(() => {
        history.push('/');
      }, 5000);
    })();
  }, []);

  if (isLoading) {
    return (
      <div className="container text-center py-5 my-5">
        <h1>Please wait...</h1>
        <div className="py-5 my-5" />
      </div>
    );
  }

  return (
    <>
      <div className="giftWrapper succesfull">
        <div className="gift-logo">
          <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="innerWrapper">
              <div className="col-12">
                <h1>Payment Successful!</h1>
                <p className="mb-0">
                  Thank you for entrusting us with the most important search
                  <br /> of your life. We are commited to doing a very best to
                  make
                  <br /> your Lovrican experience enjoyable.
                </p>
                <p className="mt-0">
                  Thank you for entrusting us with the most important search
                  <br /> of your life. We are commited to doing a very best to
                  make
                  <br /> your Lovrican experience enjoyable.
                </p>
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="btn  btn-large"
                  aria-hidden="true"
                  onClick={() => history.push('/gift')}
                >
                  Meet Your Matches
                  {/* Go to Home */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessDetailsComponent;
