import React, { useState, useEffect } from 'react';
import moment from 'moment';
import download from '../../../../assets/images/affiliate/download.svg';
import readmore from '../../../../assets/images/affiliate/read-more.svg';
import DatepickerInput from '../../../general/datepickerInput';
import { authCall, fetchData } from '../../../../services';

const AllPayments = () => {
  const [amounts, setAmounts] = useState({});
  const [paymentList, setPaymentList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offset, setOffset] = useState(0);
  const limit = 2;

  const getAllPayments = async (condi) => {
    setLoadMoreLoading(true);
    const resp2 = await authCall('affiliate/allPayments', condi);
    if (resp2 && resp2?.success) {
      const newItems = resp2?.data;
      if (newItems.length < limit) {
        setIsLoadMoreDisabled(true);
      }

      if (offset === 0) {
        setPaymentList(newItems);
      } else {
        setPaymentList([...paymentList, ...newItems]);
      }

      setOffset(offset + limit);

      setLoadMoreLoading(false);
    }
  };

  useEffect(async () => {
    const resp = await fetchData('affiliate/amounts');
    if (resp && resp?.success) {
      setAmounts(resp?.data);
    }
  }, []);

  const loadMoreItems = () => {
    getAllPayments({
      filterType: filter,
      startDate,
      endDate,
      limit,
      offset
    });
    setIsLoadMoreDisabled(false);
  };

  useEffect(() => {
    loadMoreItems();
  }, [filter, startDate, endDate]);

  const handleClickFilter = (sel) => {
    setPaymentList([]);
    setOffset(0);
    setFilter(sel);
    setIsCustomRange(false);
  };

  const handleChangeFromDate = (date) => {
    const dateFormate = moment(date).format('YYYY-MM-DD');
    setStartDate(dateFormate);
    setPaymentList([]);
    setOffset(0);
    setFilter('custom');
  };

  const handleChangeToDate = (date) => {
    const dateFormate = moment(date).format('YYYY-MM-DD');
    setEndDate(dateFormate);
    setPaymentList([]);
    setOffset(0);
    setFilter('custom');
  };

  return (
    <>
      <div className="allPayment_wrapper">
        <div className="all_payment_earning">
          <div className="download_btn">
            <button type="button" className="">
              <img src={download} className="img-fluid" alt="paypal img" />
            </button>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <p>Total Earnings:</p>
              <div className="heading_color">
                ${amounts?.totalEarnings || 0}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <p>Total Paid:</p>
              <div className="heading_color">${amounts?.totalPaid || 0}</div>
              <div className="time">Last payout: 22 March</div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <p>Upcoming Payout:</p>
              <div className="heading_color">
                ${amounts?.upcomingPayout || 0}
              </div>
              <div className="time">To be paid: 31st March</div>
            </div>
          </div>
        </div>

        <div className="affiliate_radio_wrapper">
          <div className="customeRadio_Inner ">
            <div className="row left_right">
              <div className="col-md-4 mr-0 ">
                <span>
                  <input
                    type="radio"
                    id="id1"
                    name="radio-group"
                    onChange={() => handleClickFilter('all')}
                    checked={filter === 'all' || false}
                  />
                  <label htmlFor="id1">All Transactions</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                {' '}
                <span>
                  <input
                    type="radio"
                    id="id2"
                    name="radio-group"
                    onChange={() => handleClickFilter('currentMonth')}
                  />
                  <label htmlFor="id2">This Month</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                {' '}
                <span>
                  <input
                    type="radio"
                    id="id3"
                    name="radio-group"
                    onChange={() => handleClickFilter('currentYear')}
                  />
                  <label htmlFor="id3">Current Year</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                <span>
                  <input
                    type="radio"
                    id="id4"
                    name="radio-group"
                    onChange={() => handleClickFilter('previousYear')}
                  />
                  <label htmlFor="id4">Previous Year</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                <span>
                  <input
                    type="radio"
                    id="id5"
                    name="radio-group"
                    onChange={() => {
                      setIsCustomRange(true);
                      setStartDate('');
                      setEndDate('');
                    }}
                  />
                  <label htmlFor="id5">Specify Range</label>
                </span>
              </div>
            </div>
          </div>
        </div>

        {isCustomRange && (
          <div className="affilate_custom_field_wrapper">
            <div className="row">
              <div className="col-3 border-custom">
                <div className="d-flex align-items-end">
                  {/* <div className='icon'>s</div> */}
                  <div className="field w-100">
                    <DatepickerInput
                      placeholderText="From"
                      value={startDate ? new Date(startDate) : null}
                      onChange={handleChangeFromDate}
                    />
                  </div>
                </div>
              </div>
              <div className="col-1" />
              <div className="col-3 border-custom">
                <div className="d-flex align-items-end">
                  {/* <div className='icon'>s</div> */}
                  <div className="field w-100">
                    <DatepickerInput
                      placeholderText="To"
                      value={endDate ? new Date(endDate) : null}
                      onChange={handleChangeToDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row aff_table_wrapper mb-4">
          <div className="col-12">
            <div className="table_heading">Paid Referalls</div>
            <div className="table-responsive ">
              <table
                className={`table redmore_table ${
                  !isLoadMoreDisabled ? 'blurOuter' : ''
                }`}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Method</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList.map((list) => (
                    <tr key={list.id}>
                      <td>
                        {moment(list.payment_date).format('MMM, DD, YYYY')}
                      </td>
                      <td>{list.transaction_id}</td>
                      <td>
                        <span>
                          ${list.earning}
                        </span>
                      </td>
                      <td>{list.payment_method}</td>
                    </tr>
                  ))}
                  {paymentList.length < 1 && (
                    <tr>
                      <td className="text-center" colSpan={4}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {!isLoadMoreDisabled && (
            <div className="col-12 mt-2 text-center">
              {!loadMoreLoading && (
                <button
                  type="button"
                  className="btn mb-3 p-0"
                  onClick={loadMoreItems}
                >
                  <img src={readmore} className="img-fluid" alt="arrow icon" />
                </button>
              )}
              {loadMoreLoading ? 'Loading...' : ''}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllPayments;
