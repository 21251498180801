import React, { useEffect, useState, useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AppConstants from '../../appConstants';
import authService from '../../services/authServices';
import webServices from '../../services/webServices';

const Sidebar = ({ callBack, isExtend }) => {
  // console.log('props', props);
  const { logout } = authService;
  const history = useHistory();
  const [fullName, setFullName] = useState('');
  const [profilePic, setProfilePic] = useState('male-female.png');
  const [matchCount, setMatchCount] = useState(0);

  const isProfileLinkActive = useCallback(
    (match, { pathname }) =>
      [
        AppConstants.routes.editProfile,
        AppConstants.routes.editProfilePhotos,
        AppConstants.routes.publicProfile
      ].includes(pathname),
    []
  );

  const isSettingLinkActive = useCallback(
    (match, { pathname }) => [AppConstants.routes.settings].includes(pathname),
    []
  );

  const isPlanViewLinkActive = useCallback(
    (match, { pathname }) =>
      [
        AppConstants.routes.plansView,
        AppConstants.routes.billingDetails,
        AppConstants.routes.plans,
        AppConstants.routes.rosePlans
      ].includes(pathname),
    []
  );

  const isMatchesLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.afterLogin, AppConstants.routes.home].includes(
        pathname
      ),
    []
  );

  useEffect(async () => {
    const userData = localStorage.getItem('userDetails');
    if (!userData) {
      return;
    }
    const userDetails = JSON.parse(userData);
    setFullName(
      `${userDetails.first_name || ''} ${userDetails.last_name || ''}`.trim()
    );
    setProfilePic(userDetails.profile_image || 'male-female.png');

    const resp = await webServices.getMatchCount();
    if (resp && resp.success === true) {
      setMatchCount(resp.data.count);
    }
  }, []);

  const handleRedirectChat = () => {
    if (matchCount > 0) {
      history.push({
        pathname: AppConstants.routes.chat
      });
    } else {
      toast.error(
        `We haven’t found any matches for you yet. While we continue to look for the best profiles that match your preferences, feel free to update your profile settings.`
      );
    }
  };

  const callbackfunction = () => {
    callBack(false);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
      <div className="dashboard-common d-none">
        <div className={`dashbaod-sidebar ${isExtend ? 'expend' : ''}`}>
          <div
            className="mobile-close-menu"
            onClick={callbackfunction}
            aria-hidden="true"
          >
            <i className="fa fa-window-close" />
          </div>
          <div className="row">
            <Link
              className="db-profile1 col-md-12"
              to={AppConstants.routes.publicProfile}
            >
              <div
                className="user-profile-thumb mr-2 position-static"
                style={{
                  backgroundImage: `url("${
                    process.env.REACT_APP_PIC_BASE_URL + profilePic
                  }")`
                }}
              />
              <h4>{fullName}</h4>
            </Link>
            <div className="col-md-12 first-sidebar-menu martop2">
              <h5>My Matches</h5>
              <Link className="desc-box" to={AppConstants.routes.matchQueue}>
                <div className="img-thumb">
                  {' '}
                  <i className="fa" aria-hidden="true">
                    {matchCount}
                  </i>{' '}
                </div>
                <div className="text-box">
                  <p>
                    Spotlight is the easiest way to up your odds of a match!
                  </p>
                  <p className="lovrican-color">Learn more</p>
                </div>
              </Link>
            </div>
            <div className="col-md-12 first-sidebar-menu">
              <h5>Conversation</h5>
              <div
                className="desc-box"
                aria-hidden="true"
                onClick={handleRedirectChat}
              >
                <div className="img-thumb">
                  <i className="fa fa-comments" aria-hidden="true" />
                </div>
                <div className="text-box">
                  <p>
                    Messanger is the easiest way to up your odds of a match!
                  </p>
                  <p className="lovrican-color">Learn more</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 db-menu first-sidebar-menu">
              <ul className="list-unstyled m-0">
                <li>
                  <NavLink
                    to={AppConstants.routes.afterLogin}
                    activeClassName="active"
                    isActive={isMatchesLinkActive}
                  >
                    <span>Match Queue</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.publicProfile}
                    activeClassName="active"
                    isActive={isProfileLinkActive}
                  >
                    <span>Profile</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.settings}
                    activeClassName="active"
                    isActive={isSettingLinkActive}
                  >
                    <span>Settings</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={AppConstants.routes.plansView}
                    activeClassName="active"
                    isActive={isPlanViewLinkActive}
                  >
                    <span>Plans</span>
                  </NavLink>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn theme-btn mt-4"
                    onClick={() => {
                      logout(history);
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
