import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import authService from '../services/authServices';

const AccountDeleteFeedbacksPage = () => {
  const dispatch = useDispatch();
  const { logout } = authService;
  const history = useHistory();

  const [values, setValues] = useState({
    reason: '',
    otherReason: '',
    improvement: '',
    feedbackType: 'DELETE'
  });

  const [errors, setErrors] = useState({
    reasonError: '',
    otherReasonError: '',
    improvementError: ''
  });

  useEffect(async () => {
    dispatch(pageTitle('Account'));
  }, []);

  // const validations = () => {
  //   let isError = false;
  //   const errorsMsg = { ...errors };
  //   if (values.reason === '') {
  //     isError = true;
  //     errorsMsg.reasonError = 'Please choose any reason';
  //   }

  //   if (values.otherReason === '' && values.reason === 'other') {
  //     isError = true;
  //     errorsMsg.otherReasonError = 'Please specify other reason';
  //   }

  //   if (values.improvement === '') {
  //     isError = true;
  //     errorsMsg.improvementError = 'Please enter action';
  //   }

  //   setErrors(errorsMsg);

  //   return isError;
  // };

  const handleClickClose = async () => {
    // if (!validations()) {
      await webServices.createFeedbacks(values);
      await webServices.updateUserSettings({
        account_delete: 'DELETED'
      });
      toast.success('Your account has been deleted successfully!');
      logout(history);
    // }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign">
        <div className="reward">
          <h2 className="text-center">Closing your account</h2>
          <p className="text-center">
            We’re sorry to see you leave. We appreciate your business and value
            your feedback.
          </p>
          <div className="reward_container">
            <div className="head">
              <p className="text-theme m-0">
                I want to close my <br />
                account because...
              </p>
            </div>
            <div className="body">
              <div className="customeRadio_Inner ">
                <div>
                  <input
                    type="radio"
                    id="reward2"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'I needed more privacy for my account'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward2">
                    I needed more privacy for my account
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward3"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Prices is too high'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward3">Prices is too high</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward4"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Registration proccess is too long'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward4">
                    Registration proccess is too long
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward5"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'There were too many unverified profiles'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward5">
                    There were too many unverified profiles
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward6"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason:
                          'I encountered individuals with whom I have history'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward6">
                    I encountered individuals with whom I have history
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward7"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'I was receiving too many emails'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward7">
                    I was receiving too many emails
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward8"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'other'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                  />
                  <label htmlFor="reward8">Other (please specify)</label>
                </div>
              </div>
              <div className="mt-3" style={{ color: 'red' }}>
                {errors.reasonError}
              </div>
            </div>

            {values?.reason === 'other' && (
              <div className="body">
                <div className="feedback_field">
                  <div className="label">I did not like ...</div>
                  <textarea
                    className="form-control third"
                    name="review"
                    rows="5"
                    placeholder="Tell us what you like about Lovrican"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        otherReason: e.target.value
                      });
                      setErrors({ ...errors, otherReasonError: '' });
                    }}
                  />
                  <div className="mt-3" style={{ color: 'red' }}>
                    {errors.otherReasonError}
                  </div>
                </div>
              </div>
            )}

            <div className="head">
              <p className="text-theme m-0">
                What single action could we have <br />
                taken to retain your business?
              </p>
            </div>
            <div className="body">
              <div className="feedback_field">
                <div className="label">I wish Lovrican ...</div>
                <textarea
                  className="form-control second"
                  name="review"
                  rows="5"
                  placeholder="Tell us what you like about Lovrican"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      improvement: e.target.value
                    });
                    setErrors({ ...errors, improvementError: '' });
                  }}
                />
                <div className="mt-3" style={{ color: 'red' }}>
                  {errors.improvementError}
                </div>
              </div>
            </div>
            <span
              className="submit-btn"
              role="button"
              aria-hidden="true"
              onClick={handleClickClose}
            >
              Delete Account
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDeleteFeedbacksPage;
