import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-dropdown-select';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const FEETS = [
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 }
];

const INCHES = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 }
];

const Step7Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [intPart, decPart] = userDetails?.height
    ? userDetails?.height.split('.')
    : useState(0);
  const [values, setValues] = useState({
    heightFeet: userDetails?.height ? [{ label: intPart, value: intPart }] : [],
    heightInch: userDetails?.height ? [{ label: decPart, value: decPart }] : []
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        height: `${values.heightFeet[0].value}.${values.heightInch[0].value}`
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step7" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
              Make finding you in a crowd a breeze—tell
              your matches your height
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-6 col-lg-6 col-6 pb-4 custome_select mb-3">
              <Select
                placeholder="Feet"
                options={FEETS}
                autoComplete="off"
                className="custom_input feet_border"
                values={values.heightFeet}
                onChange={(value) =>
                  setValues({ ...values, heightFeet: value })
                }
              />
              <div className="feet_text">ft</div>
              {validator.message('heightFeet', values.heightFeet, 'required')}
            </div>
            <div className="col-md-6 col-lg-6 col-6 pb-4 custome_select mb-3">
              <Select
                placeholder="Inches"
                options={INCHES}
                autoComplete="off"
                className="custom_input feet_border"
                values={values.heightInch}
                onChange={(value) =>
                  setValues({ ...values, heightInch: value })
                }
              />

              {validator.message('heightInch', values.heightInch, 'required')}
              <div className="feet_text">in”</div>
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step7Component;
