import React from 'react';
import { Helmet } from 'react-helmet';

function SafetyTipsPage() {
  return (
    <>
      <Helmet>
        <title>Dating Safety Tips | Lovrican</title>
        <meta
          name="description"
          content="Prioritize your safety with Lovrican's Dating Safety Tips. Empower yourself with knowledge on safe online interactions and navigate your love journey with ease."
        />
        <meta name="keywords" content="Dating Safety Tips" />
      </Helmet>
      <div className="row">
        <div className="col col-md-12">
          <div className="">
            <div className="service-content">
              <div className="sub-inner">
                <div className="sub-heading">
                  <h5 className="text-left subhead">Lovrican’s Safety Tips</h5>
                  <div className="wrapper">
                    <h3 className="subhead">1. Your Safety comes First!</h3>
                    <div className="pl-4 subhead">
                      <p>
                        At Lovrican, we only have one mission: to help you find
                        true and lasting love. Lovrican works hard to deliver
                        you the most compatible matches possible. We believe in
                        empowering you to make the best choices by providing
                        guidance and support to help you discover your best
                        qualities and those of your potential partners
                        throughout your search.
                      </p>
                      <p>
                        As with anyone you meet—online or offline—your sound
                        judgment and instincts are necessary to protect
                        yourself. There is no substitution for acting with
                        caution when communicating with someone you are just
                        getting to know. Here are some essential
                        ’rules-of-the-road’ for safely navigating any
                        relationship, but especially one that begins
                        online&nbsp;
                      </p>
                    </div>
                    <h3 className="subhead  pt-4">
                      2. Always Use Your Best Judgment
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        While Lovrican routinely monitors account activity and
                        investigates all complaints of unusual, inappropriate or
                        falsified accounts, Lovrican does not conduct criminal
                        background checks at the time an account is registered.
                        As with any personal interaction, keep in mind it is
                        always possible for people to misrepresent themselves.
                        Assessing a match’s truthfulness and honesty is
                        ultimately your responsibility. Don’t ignore any facts
                        that seem inconsistent or &quot;off.&quot; Trust your
                        instincts and remember that you have control over the
                        situation. If you’re talking to someone online or by
                        phone and they say things that raise your suspicions,
                        consider ending the conversation. If you’re out on a
                        date and you feel uncomfortable, leave.
                      </p>
                      <p>
                        Also, be aware of these common red flags when you’re
                        getting to know someone new. Watch out for someone who:
                      </p>
                      <ul className="inner-list">
                        <li>
                          Immediately requests to talk or chat on an outside
                          email or messaging service.
                        </li>
                        <li>
                          Claims that your introduction was “destiny” or “fate,”
                          especially early in communication.
                        </li>
                        <li>
                          Claims to be from the U.S. but is currently living,
                          working, or traveling abroad.
                        </li>
                        <li>
                          Asks for money, goods or any similar type of
                          assistance, especially if you have never met in
                          person.
                        </li>
                        <li>
                          Asks you to assist with personal transactions
                          (depositing funds, shipping merchandise, etc.).
                        </li>
                        <li>
                          Wants the relationship to progress faster than you are
                          comfortable going.
                        </li>
                        <li>
                          Reports a sudden personal crisis and pressures you to
                          provide financial assistance. Be especially wary if
                          the person’s demands become increasingly aggressive.
                        </li>
                        <li>Asks inappropriate questions.</li>
                        <li>
                          Requests your home or work address under the guise of
                          sending flowers or gifts.
                        </li>
                        <li>Tells inconsistent or grandiose stories.</li>
                        <li>Gives vague answers to specific questions.</li>
                        <li>Urges you to compromise your principles.</li>
                        <li>
                          Constantly blames others for troubles in his or her
                          life.
                        </li>
                        <li>Insists on getting overly close, very fast.</li>
                        <li>Claims to be recently widowed.</li>
                        <li>
                          Disappears suddenly from the site then reappears under
                          a different name.
                        </li>
                      </ul>
                      <p className="center-small">
                        If a match does any of the above activities or makes you
                        suspicious in any other way, please report this as a
                        Match Concern by emailing{' '}
                        <a href="mailto:matchconcerns@lovrican.com">
                          matchconcerns@Lovrican.com
                        </a>
                        &nbsp;so that we can investigate the matter.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">
                      3. NEVER Share Financial Information or Certain Personal
                      Information
                    </h3>
                    <div className="subhead pl-4">
                      <ul>
                        <li>
                          NEVER give out your credit card number or bank
                          information.
                        </li>
                        <li>
                          NEVER share your Social Security Number, mother’s
                          maiden name, or other private information that can be
                          used to access your financial information.
                        </li>
                        <li>
                          NEVER send money to someone you meet online,
                          especially by wire transfer.
                        </li>
                      </ul>
                      <p>
                        Immediately stop communicating with anyone who pressures
                        you for personal or financial information or attempts in
                        any way to trick you into revealing it.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">4. Protect Your Account</h3>
                    <div className="subhead pl-4">
                      <p>
                        We recommend not using public or shared computers to
                        access Lovrican. If you must, when accessing your
                        account from a public or shared computer, use caution
                        and be aware of your surroundings so that others are not
                        able to view or record your password or other personal
                        information.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">
                      5. Be Cautious When Sharing Personal Information
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        We strongly encourage you to be cautious when sharing
                        personal information that could reveal your identity.
                        Never include your last name, email address, home
                        address, telephone number, place of work or any other
                        identifying information in your Internet profile or
                        initial email messages. Take full advantage of our
                        secure and completely anonymous Guided Communication
                        process, and resist the urge to provide a personal email
                        or phone number right away, no matter how strong a
                        connection to your match may feel. Once you’ve given out
                        personal information, you cannot take it back! We
                        encourage you to get to know a match well via our secure
                        Lovrican Mail system before taking the next step and
                        sharing a telephone number, instant messenger handle or
                        email address.
                      </p>
                      <p>
                        Phoning with the other person is an important step in
                        getting to know each other better. But before you share
                        phone numbers, make sure you have discussed the need to
                        respect each other’s privacy. If either of you decide to
                        end communication in the future, agree not to use the
                        phone number as a means to pursue an unwanted
                        relationship. For added security, try using your phone’s
                        privacy features when you call, like private number
                        blocking.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">6. Do Your Own Research</h3>
                    <div className="subhead pl-4">
                      <p>
                        We do investigate the backgrounds of our members. And
                        while many of our members are genuinely seeking quality
                        life partners, we are unable to guarantee the character
                        of our members or the accuracy of the information they
                        provide. So, when it come to your safety, you are
                        ultimately in charge. Regardless of the connection you
                        feel with any of your matches, we encourage you to do
                        your own research before meeting in person. This can
                        include typing your match’s name into a search engine,
                        contacting your state or local municipalities to obtain
                        public information, or using a paid service to obtain a
                        full background report. Above all else, use common
                        sense. Pay attention to the details someone shares with
                        you. If you find anything that doesn’t seem to add up,
                        follow your intuition and stop communicating with that
                        person.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">
                      7. Use Caution When Deciding to Click on Any URL Link
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        We understand that a match may share a URL link to a
                        favorite website or article as part of the
                        get-to-know-you communication process. However, as a
                        general practice, you should always use good judgment
                        and be cautious when deciding to click on any URL links.
                        In particular, be aware of links that lead to web pages
                        which look similar or identical to the Lovrican
                        homepage, including its log-in fields. These links are
                        typically sent as attempts to ’phish’ for log-in
                        information from users in order to compromise user
                        accounts. You should report any such phishing attempts
                        to Lovrican immediately. The only way to log in to your
                        Lovrican account (through our webpage) is by directly
                        accessing the Lovrican website at{' '}
                        <a
                          href={process.env.REACT_APP_BASE_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          www.Lovrican.com
                        </a>
                        .
                      </p>
                    </div>
                    <h3 className="subhead pt-4">8. Take Your Time</h3>
                    <div className="subhead pl-4">
                      <p>
                        While online communication can accelerate one’s sense of
                        comfort and intimacy, we can’t stress enough how
                        important it is for you to take your time getting to
                        know your match. We encourage you to use Lovrican’s
                        anonymous Lovrican Mail system as long as necessary to
                        find out as much about your match as is reasonably
                        possible.
                      </p>
                      <p>
                        Even though Lovrican’s matching system creates extremely
                        compatible matches, compatibility alone does not replace
                        the need for real-life experience. It is vital to date
                        and get to know each other in a deep, revealing and
                        meaningful way by sharing a broad base of experiences
                        together. The more experiences you share, the better
                        your chances of avoiding hidden and sometimes unpleasant
                        surprises. So allow time for a variety of experiences to
                        occur, particularly your everyday routines. In cases of
                        long-distance relationships, if possible, you should
                        consider living in the same area for a significant time
                        before committing to a more serious relationship. Move
                        slowly and pay attention to the reality of your new
                        relationship.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">
                      9. Make Your First Date Safe and Successful
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        Meeting in person is exciting. Have fun and explore the
                        level of chemistry you share with your date, but don’t
                        let high hopes cloud your ability to exercise reasonable
                        caution.
                      </p>
                      <p className="lovrican-color">Do :</p>
                      <ul className="inner-list">
                        <li>
                          Choose the time and place of your date wisely. Meet in
                          a public place at a decent hour during which lots of
                          people will be around. Lunch dates work especially
                          well.
                        </li>
                        <li>
                          Limit alcohol consumption or (even better) abstain
                          entirely until you know the person better.
                        </li>
                        <li>
                          Use your own transportation, even when meeting someone
                          who lives a great distance away from you. Never agree
                          to be picked up at your home. It’s never a good idea
                          to get into someone’s personal vehicle on a first
                          date. Wherever possible, drive yourself or take a
                          taxi.
                        </li>
                        <li>
                          Tell at least one friend or family member about your
                          plans and when you will return. Arrange to check in
                          with them after each of the first few dates.
                        </li>
                        <li>
                          Carry a fully charged mobile phone with easily
                          accessible emergency numbers.
                        </li>
                      </ul>
                      <p className="lovrican-color">Don’t :</p>
                      <ul className="inner-list">
                        <li>
                          Leave unattended personal belongings such as purses,
                          wallets, or jackets with pockets that may contain
                          items that could reveal personal information about
                          you, such as a driver’s license, credit cards and ATM
                          receipts.
                        </li>
                        <li>
                          Meet at your house or place of work or give that
                          information out until you have had a good opportunity
                          to know the other person better.
                        </li>
                        <li>
                          Incur large expenses on a date without first
                          discussing how the cost will be divided.
                        </li>
                        <li>
                          Go home with someone, even if it feels like everything
                          is going great. You have not spent enough time with
                          them to assess whether your safety is at risk.
                        </li>
                      </ul>
                    </div>
                    <h3 className="subhead pt-4">
                      10. Always be Respectful and Kind
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        Always be respectful and treat your matches as you would
                        want them to treat you. Not every match is going to be
                        right for you so closing communication with matches and
                        having matches close communication with you is a natural
                        and healthy part of the process. Lovrican is about
                        bringing two compatible people together who have a solid
                        foundation from which a long-term relationship would
                        have a high probability of success. You still need to
                        carefully consider whether this particular person is one
                        with whom you would like to further a relationship. If
                        you feel the need to end communication, then be honest,
                        direct and polite. The sooner you address this
                        determination, the better for both of you.
                      </p>
                      <p>
                        If a match feels the need to close communication with
                        you, please respect their wishes.
                      </p>
                    </div>
                    <h3 className="subhead pt-4">
                      11. Report Concerns About a Match to Lovrican
                    </h3>
                    <div className="subhead pl-4">
                      <p>
                        Lovrican works hard to identify and close individuals
                        who misrepresent who they are on our site, and we take
                        our members complaints seriously. If you have concerns
                        about a particular match, please do not hesitate to
                        report your match to us so that appropriate steps can be
                        taken to keep Lovrican safe. You can report your match
                        directly from their profile page, from within your
                        communication or by emailing
                        <a href="mailto:matchconcerns@lovrican.com">
                          matchconcerns@Lovrican.com
                        </a>
                        &nbsp;. Once reported, this information will be sent
                        directly to our Trust &amp; Safety team for review.
                        Please be assured that all reports are kept completely
                        confidential.
                      </p>
                      <p>
                        If Lovrican closes an account for suspect activity,
                        inappropriate behavior or falsified identity, you may be
                        notified via email to discontinue communicating with the
                        match in question. We strongly encourage all of our
                        members to review emails received from Lovrican to
                        ensure this communication is received promptly.
                      </p>
                      <p>
                        We hope these guidelines will help to make your Lovrican
                        experience a success, and that you will find your soul
                        mate as so many others before you have done.
                      </p>
                      <div className="safety-team">
                        <p>Sincerely,&nbsp;</p>
                        <p>The Lovrican Team</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SafetyTipsPage;
