import React from 'react';
import { ToastContainer } from 'react-toastify';
import SectionsLandingComponent from './registerSections/SectionsLandingComponent';
// import Section1RegisterComponent from './registerSections/Section1RegisterComponent';
import Section2RegisterComponent from './registerSections/Section2RegisterComponent';
import Section1SuccessComponent from './registerSections/Section1SuccessComponent';
import Section2SuccessComponent from './registerSections/Section2SuccessComponent';
import NewRegisterAction from './newRegisterSections/NewRegisterAction';
import SectionCompleteSuccessComponent from './registerSections/SectionCompleteSuccessComponent';
import authService from '../services/authServices';
import webServices from '../services/webServices';
import AppConstants from '../appConstants';
import Spinner from '../components/general/spinner';

class RegisterSectionsComponent extends React.Component {
  constructor(props) {
    // const { history } = props;
    super(props);
    this.state = {
      step: 2, // history?.location?.state?.step || 1,
      masterData: {},
      userDetails: {},
      isSpinner: true
    };
  }

  async componentDidMount() {
    const { history } = this.props;
    // authService.throwBackRev(history);
    authService.throwBackRevRegister(history);
    const resp = await webServices.getCommonMasterData();
    if (resp && resp.success) {
      this.setState({ masterData: resp.data });
    }

    // get user details
    const user = await webServices.getUserDetail();
    if (user && user.success === true) {
      this.setState({
        userDetails: user.data,
        step: 2, // user.data.profileLevel,
        isSpinner: false
      });
    } else {
      history.push(AppConstants.routes.home);
    }
  }

  goToNextStep = (data) => {
    this.setState({
      step: data.step
    });
  };
  
  renderSwitch = (param) => {
    const { masterData, userDetails } = this.state;
    switch (param) {
      case 1:
        return (
          <SectionsLandingComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
          />
        );
      case 2:
        // return (
        //   <Section1RegisterComponent
        //     {...this.props}
        //     goToNextStep={this.goToNextStep}
        //     masterData={masterData}
        //     userDetails={userDetails}
        //   />
        // );
        return (
          <NewRegisterAction
            {...this.props}
            masterData={masterData}
            userDetails={userDetails}
          />
        );
      case 3:
        return (
          <Section1SuccessComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
          />
        );
      case 4:
        return (
          <Section2RegisterComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
            masterData={masterData}
            userDetails={userDetails}
          />
        );
      case 5:
        return (
          <Section2SuccessComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
          />
        );
      case 6:
        return (
          <SectionCompleteSuccessComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
          />
        );
      default:
        return (
          <SectionsLandingComponent
            {...this.props}
            goToNextStep={this.goToNextStep}
          />
        );
    }
  };

  render() {
    const { step, isSpinner } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={3}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className={isSpinner ? 'spinner-row' : ''}>
          {isSpinner ? (
            <>
              <Spinner active={isSpinner} />
            </>
          ) : (
            this.renderSwitch(step)
          )}
        </div>
      </>
    );
  }
}

export default RegisterSectionsComponent;
