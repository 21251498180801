import React, { useCallback } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import webServices from '../../services/webServices';
import AppConstants from '../../appConstants';
import authService from '../../services/authServices';

const AffilateSidebar = () => {
  const { logout } = authService;
  const history = useHistory();
  // const { planDetails, current } = useSelector((state) => state.user);

  const isDashboardLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.dashboard].includes(pathname),
    []
  );
  const isReferalLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.referals].includes(pathname),
    []
  );
  const isEarningsLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.earnings].includes(pathname),
    []
  );
  const isProfileLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.affiliate.profile].includes(pathname),
    []
  );
  const isSettingLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.affiliate.settings].includes(pathname)
  );
  const isContactUsLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.affiliate.contactsUs].includes(pathname)
  );

  return (
    <>
      <div className="toggleMenu">
        <ul className="list-unstyled">
          <li
            className="position-relative topmenu"
          >
            <NavLink
              to={AppConstants.routes.affiliate.dashboard}
              activeClassName="active"
              isActive={isDashboardLinkActive}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.affiliate.referals}
              activeClassName="active"
              isActive={isReferalLinkActive}
            >
              Referals
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.affiliate.earnings}
              activeClassName="active"
              isActive={isEarningsLinkActive}
            >
              Earnings
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.affiliate.profile}
              activeClassName="active"
              isActive={isProfileLinkActive}
            >
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.affiliate.settings}
              activeClassName="active"
              isActive={isSettingLinkActive}
            >
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.affiliate.contactsUs}
              activeClassName="active"
              isActive={isContactUsLinkActive}
            >
              Contact us
            </NavLink>
          </li>
        </ul>
        <button
          type="button"
          className="btn logout-btn"
          onClick={() => {
            logout(history);
          }}
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default AffilateSidebar;
