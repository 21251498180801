import React, { useState } from 'react';
import copy from '../../../../assets/images/affiliate/copy.svg';
import download from '../../../../assets/images/affiliate/download.svg';

const MyAffiliateInfo = ({ userDetail }) => {
  const [copied, setCopied] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState(false);
  const URL = `${process.env.REACT_APP_BASE_URL}/register?referralCode=${userDetail?.referal_code}`;
  const copyToClipboard = (id, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(id);
        setTimeout(() => setCopied(0), 2000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to copy: ', err);
      });
  };

  const imageUrl =
    'http://localhost:4000/uploads/qrcodes/qrcode_201_1723358590317.png';
  const fileName = 'download-qr.webp';

  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDownloadStatus(3);
      setTimeout(() => setDownloadStatus(false), 2000);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to download image', error);
    }
  };

  return (
    <>
      <div className="my_aff_info">
        <div className="row ">
          <div className="col-8">
            <div className="heading_color">My Affiliate link:</div>
            <div className="dec">{URL}</div>
          </div>
          <div className="col-4 text-center align-self-end">
            <div className="copyWrapper">
              <button
                type="button"
                className="btn copy_btn"
                onClick={() => copyToClipboard(1, URL)}
              >
                <img src={copy} className="img-fluid" alt="arrow icon" />
              </button>
              {copied === 1 && <div className="toolTip">Link copied!</div>}
            </div>
          </div>
        </div>
        <div className="row mt-4 pt-4">
          <div className="col-8">
            <div className="heading_color">My Affiliate QR CODE:</div>
            <div className="scaner">
              <img
                src={`${process.env.REACT_APP_PIC_BASE_URL}/qrcodes/${userDetail?.affiliate_qr_code}`}
                className="img-fluid"
                alt="QR icon"
              />
            </div>
          </div>
          <div className="col-4 text-center align-self-center">
            <div className="copyWrapper">
              <button
                type="button"
                className="btn copy_btn"
                onClick={downloadImage}
              >
                <img src={download} className="img-fluid" alt="arrow icon" />
              </button>
              {downloadStatus && (
                <div className="toolTip">QR Code Downloaded</div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-8">
            <div className="heading_color">My Affiliate Code:</div>
            <div className="scaner_code">{userDetail?.referal_code}</div>
          </div>
          <div className="col-4 text-center align-self-end">
            <div className="copyWrapper">
              <button
                type="button"
                className="btn copy_btn"
                onClick={() => copyToClipboard(2, userDetail?.referal_code)}
              >
                <img src={copy} className="img-fluid" alt="arrow icon" />
              </button>
              {copied === 2 && <div className="toolTip">Code copied!</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAffiliateInfo;
