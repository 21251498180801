import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputRange from 'react-input-range';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import Spinner from '../general/spinner';
// import Checkout from '../../services/Checkout';
import PayPalCheckoutPage from '../../services/PayPalCheckout';

const LikesPlansComponent = () => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSpinner, setIsSpinner] = useState(true);
  const [values, setValues] = useState(1);
  const [plans, setPlans] = useState([]);

  useEffect(async () => {
    dispatch(pageTitle('Likes Plans'));
    const response = await webServices.getLikePlans();
    if (response && response.success) {
      setPlans(response.data);
      setTimeout(() => {
        setIsSpinner(false);
      }, 400);
    }
  }, []);

  const getUrlParams = (urlString) => {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);
    const paramsObj = {};
    params.forEach((value, key) => {
      paramsObj[key] = value;
    });
    return paramsObj;
  };

  const createPayment = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    (async () => {
      const response = await webServices.createOrder({
        planId: plans.id,
        likes: values
      });
      if (response && response.data.result.status === 'CREATED') {
        const approveLink = response.data.result.links.find(
          (link) => link.rel === 'approve'
        );
        // if(approveLink.href){
        //   window.location.replace(approveLink.href);
        // }
        const params = getUrlParams(approveLink.href);
        if (params?.token) {
          const url = `https://www.sandbox.paypal.com/checkoutweb/signup?token=${params.token}`;
          window.location.replace(url);
        }
      }
    })();
  };

  return (
    <div className="full-page planWrapper">
      <div className="container">
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <>
            <h1 className="ext-left text-lg-center text-md-center">
              Purchase More Likes for the Day
            </h1>
            <div className="linkPlanSubHead">
              Select the number of likes that you want to buy. You will get more
              free likes tomorrow!
            </div>

            <div className="mid-container-outer">
              <div className="mid-container panConatiner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="plan-details">
                      <div className="nav flex-column nav-pills">
                        <div className="card-container mt-4 mb-4 pb-2 innerRange roseRanger likeRanger">
                          <InputRange
                            allowSameValues
                            formatLabel={(v1) => (
                              <>
                                <span className="likeSign">
                                  {v1} {v1 > 1 ? 'Likes' : 'Like'}
                                </span>
                                <span className="dollerSign">
                                  {' '}
                                  ${plans?.price * v1}
                                </span>
                              </>
                            )}
                            maxValue={7}
                            minValue={1}
                            value={values}
                            step={1}
                            onChange={(val) => setValues(val)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="white-border" />
              <div id="paypal-button-container" />
              <div className="mid-container mt-4 panConatiner">
                <div className="mobile_plan justify-content-center text-center mobileFooter">
                  {/* <Checkout
                    description="Like Purchase"
                    amount={plans?.price * values}
                    details={{
                      price: plans?.price * values,
                      planId: plans.id,
                      likes: values
                    }}
                  /> */}
                  <PayPalCheckoutPage
                    planId={plans.id}
                    amount={plans?.price * values}
                    likes={values}
                    selectedPlan={plans}
                  />
                </div>
                <div className="d-sm-flex justify-content-center text-center">
                  <button
                    onClick={createPayment}
                    type="button"
                    className="btn btn-info theme-btn ml-3 gr_color mb-2 mb-md-0 d-none"
                    disabled={isProcessing}
                  >
                    Pay with Card
                  </button>
                  <button
                    onClick={createPayment}
                    type="button"
                    className="btn btn-info theme-btn ml-3 r_color d-none"
                    disabled={isProcessing}
                  >
                    Pay with PayPal
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LikesPlansComponent;
