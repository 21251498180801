import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import verfied from '../assets/images/verified.svg';

const Section2remainderPopup = ({ modalStatus, handleClose, userDetails }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(AppConstants.routes.registerSections);
  };

  const handleCancel = async () => {
    handleClose(false);
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel complete_your_reg"
        show={modalStatus}
        onHide={modalStatus}
        centered
        size="lg"
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            <img
              src={verfied}
              className="img-fluid cursor-pointer verifid_img"
              alt="arrow icon"
              aria-hidden="true"
            />
            <h5 className="modal-title text-center w-100">Get Verified</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Hello {userDetails?.first_name.trim()}! We encourage you to verify
            your Lovrican account to enhance trust and credibility within our
            community. Completing the verification process is simple; just fill
            out the verification form below.
            <p className="text-theme pb-3 mt-2">
              We’re pleased to inform you that Lovrican verification is offered
              completely free of charge. Additionally, to show our appreciation,
              you’ll 🎁 receive a delightful gift of 10 Free Roses upon
              successful verification.
            </p>
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleClick()}
            >
              Get Verified
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleCancel()}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Section2remainderPopup;
