const WdyhauList = [
  {
    value: 1,
    label: 'Blog'
  },
  {
    value: 2,
    label: 'Facebook'
  },
  {
    value: 3,
    label: 'Google'
  },
  {
    value: 4,
    label: 'Instagram'
  },
  {
    value: 5,
    label: 'LinkedIn'
  },
  {
    value: 6,
    label: 'Newspaper'
  },
  {
    value: 7,
    label: 'Pinterest'
  },
  {
    value: 8,
    label: 'Tik Tok'
  },
  {
    value: 9,
    label: 'Twitter'
  },
  {
    value: 10,
    label: 'WhatsApp'
  },
  {
    value: 11,
    label: 'YouTube'
  },
  {
    value: 12,
    label: 'Other'
  }
];

export default WdyhauList;
