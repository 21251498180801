import { React, useState } from 'react';
import { toast } from 'react-toastify';
import zxcvbn from 'zxcvbn';
import eye from '../../assets/images/eye.svg';
import webServices from '../../services/webServices';

const PASSWORDSTRENGHT = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

const ChangePasswordSetting = () => {
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    strenght: 0
  });

  const [formValidation, setFormValidation] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleValidation = () => {
    let isError = false;
    const formerr = { ...formValidation };

    if (!values.currentPassword) {
      isError = true;
      formerr.currentPassword = 'The current password field is required.';
      setFormValidation(formerr);
    }

    if (!values.newPassword) {
      isError = true;
      formerr.newPassword = 'The create password field is required.';
      setFormValidation(formerr);
    }

    if (!values.confirmPassword) {
      isError = true;
      formerr.confirmPassword = 'The confirm password field is required.';
      setFormValidation(formerr);
    }

    if (values.newPassword !== values.confirmPassword) {
      isError = true;
      formerr.confirmPassword =
        'The password and confirm password should be same.';
      setFormValidation(formerr);
    }

    if (values.newPassword === values.currentPassword) {
      isError = true;
      formerr.newPassword =
        'Create password should not same current password.';
      setFormValidation(formerr);
    }

    return isError;
  };

  const handleChange = (props) => (e) => {
    setFormValidation({ ...formValidation, [props]: '' });
    if (props === 'newPassword') {
      const { score } = zxcvbn(e.target.value);
      setValues({ ...values, strenght: score, [props]: e.target.value });
    }
    else{
      setValues({ ...values, [props]: e.target.value });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) return false;
    (async () => {
      const data = {
        password: values.currentPassword,
        new_password: values.confirmPassword
      };
      const response = await webServices.changePassword(data);
      if (response && response.success) {
        toast.success(response.message);
        const reset = { ...values };
        reset.currentPassword = '';
        reset.newPassword = '';
        reset.confirmPassword = '';
        reset.strenght = 0;
        setValues(reset);
      }
    })();
    return null;
  };

  return (
    <>
      <div className="card-box">
        <div className="row settingEmail pb-3">
          <div className="">
            <label htmlFor="label">Change Password</label>
          </div>
          <div className="col-12 border-custom">
            <div className="d-flex align-items-end password">
              <div
                className="icon"
                style={{ cursor: 'pointer' }}
                aria-hidden="true"
              >
                <img src={eye} className="img-fluid mr-2" alt="" />
              </div>
              <div className="field w-100">
                <input
                  value={values.currentPassword}
                  type="password"
                  name="currentPassword"
                  id="user-password"
                  autoComplete="new-password"
                  placeholder="Current Password"
                  className="custom_input"
                  onChange={handleChange('currentPassword')}
                />
              </div>
            </div>
          </div>
          <span className="validation-error">
            {formValidation.currentPassword}
          </span>
          <div className="col-12 border-custom">
            <div className="d-flex align-items-end password">
              <div
                className="icon"
                style={{ cursor: 'pointer' }}
                aria-hidden="true"
              >
                <img src={eye} className="img-fluid mr-2" alt="" />
              </div>
              <div className="field w-100">
                <input
                  value={values.newPassword}
                  type="password"
                  name="newPassword"
                  id="user-password"
                  autoComplete="new-password"
                  placeholder="Create Password"
                  className="custom_input"
                  onChange={handleChange('newPassword')}
                />
              </div>
              <div className="hint">
                <ul className="list-unstyled">
                  <li>Atleast:</li>
                  <li>• 8 characters</li>
                  <li>• 1 special character</li>
                  <li>• 1 capital letter</li>
                  <li>• 1 number</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="d-flex w-100 mt-1 align-items-center visible ">
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${(values.strenght + 1) * 20}%` }}
                />
              </div>
              <div className="password_status">
                Password is {PASSWORDSTRENGHT[values.strenght]}
              </div>
            </div>
          </div>
          <span className="validation-error">{formValidation.newPassword}</span>
          <div className="col-12 border-custom mt-4 pt-2">
            <div className="d-flex align-items-end password">
              <div className="field w-100">
                <input
                  value={values.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  id="user-password"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                  className="custom_input"
                  onChange={handleChange('confirmPassword')}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && handleFormSubmit(event)
                  }
                />
              </div>
            </div>
          </div>
          <span className="validation-error">
            {formValidation.confirmPassword}
          </span>
          <div className="col-12 p-0">
            <span
              className="round-button"
              role="button"
              onClick={handleFormSubmit}
              aria-hidden="true"
            >
              Change Password
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordSetting;
