import React from 'react';
import { ToastContainer } from 'react-toastify';
import ChangeEmailSetting from '../../../account-status/changeEmailSetting';
import ChangePasswordSetting from '../../../account-status/changePasswordSetting';

const PasswordAndEmailSetting = ({
  parentCallBackWhenUpdate,
  userSettings
}) => (
  <>
    <ChangePasswordSetting />

    <ChangeEmailSetting
      userSettings={userSettings}
      parentCallBackWhenUpdate={parentCallBackWhenUpdate}
    />

    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={3}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
);

export default PasswordAndEmailSetting;
