import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import webServices from '../../services/webServices';
import AppConstants from '../../appConstants';
import authService from '../../services/authServices';
import NoActivePlanPopup from '../../pages/noActivePlanPopup';
import { authCall } from '../../services';
import Section2remainderPopup from '../../pages/section2remainderPopup';

const InnerSidebar = ({ matchCountLive }) => {
  const { logout } = authService;
  const history = useHistory();
  const { planDetails, current } = useSelector((state) => state.user);
  const [matchCount, setMatchCount] = useState(matchCountLive);
  const [showActivePlan, setShowActivePlan] = useState(false);
  const [section2RemainderMsg, setSection2RemainderMsg] = useState(false);
  const handleCloseActivePlan = () => setShowActivePlan(false);
  const handleCloseSection2Remainder = () => setSection2RemainderMsg(false);

  const getMatchCount = async () => {
    const resp = await webServices.getMatchCount();
    if (resp && resp.success === true) {
      setMatchCount(resp.data.count);
    }
  };

  useEffect(async () => {
    getMatchCount();
  }, []);

  // const handleRedirectChat = async () => {
  //   const resp = await authCall('user/userCheckPermission', { action: 'chat' });
  //   if (resp.data === 'noActivePlan') {
  //     setShowActivePlan(true);
  //   } else if (matchCount > 0) {
  //     history.push({
  //       pathname: AppConstants.routes.chat
  //     });
  //   } else {
  //     toast.dismiss();
  //     toast.error(
  //       `We haven’t found any matches for you yet. While we continue to look for the best profiles that match your preferences, feel free to update your profile settings.`
  //     );
  //   }
  // };
  const handleRedirectChat = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'chat' });
    if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } 
    else if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } 
    else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    } else if (matchCount > 0) {
      history.push({
        pathname: AppConstants.routes.chat
      });
    } else {
      toast.dismiss();
      toast.error(
        `We haven’t found any matches for you yet. While we continue to look for the best profiles that match your preferences, feel free to update your profile settings.`
      );
    }
  };

  const handleRedirectPlan = async () => {
    history.push({
      pathname: AppConstants.routes.plansView
    });
  };

  const handleRedirectMyMatch = () => {
    if (matchCount > 0) {
      history.push({
        pathname: AppConstants.routes.matchQueue
      });
    } else {
      toast.dismiss();
      toast.error(
        `We haven’t found any matches for you yet. In the meantime, you may update your matching preferences to increase your chances of getting a match.`
      );
      setTimeout(() => {
        history.push({
          pathname: AppConstants.routes.afterLogin
        });
      }, 3000);
    }
  };

  const isProfileLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.publicProfile].includes(pathname),
    []
  );
  const isMatchQueueLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.afterLogin].includes(pathname),
    []
  );
  const isPlansLinkActive = useCallback((match, { pathname }) =>
    [
      AppConstants.routes.plans,
      AppConstants.routes.rosePlans,
      AppConstants.routes.billingDetails,
      AppConstants.routes.plansView
    ].includes(pathname)
  );
  const isSettingLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.settings].includes(pathname)
  );

  const isChatLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.chat].includes(pathname)
  );

  const isMyMatchLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.matchQueue].includes(pathname)
  );

  return (
    <>
      <div className="toggleMenu">
        <ul className="list-unstyled">
          <li
            className="position-relative topmenu"
            onClick={handleRedirectMyMatch}
            aria-hidden="true"
          >
            {/* <NavLink activeClassName="active1" to={AppConstants.routes.matchQueue}>
            My Matches
          </NavLink> */}
            <NavLink
              to="#0"
              activeClassName="active"
              isActive={isMyMatchLinkActive}
            >
              My Matches
            </NavLink>
            {/* <div className="budge">{matchCountLive || matchCount}</div> */}
          </li>
          <li onClick={handleRedirectChat} aria-hidden="true">
            <NavLink
              to="#0"
              activeClassName="active"
              isActive={isChatLinkActive}
            >
              Chat
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.afterLogin}
              activeClassName="active"
              isActive={isMatchQueueLinkActive}
            >
              Match Queue
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.publicProfile}
              activeClassName="active"
              isActive={isProfileLinkActive}
            >
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              to={AppConstants.routes.settings}
              activeClassName="active"
              isActive={isSettingLinkActive}
            >
              Settings
            </NavLink>
          </li>
          <li onClick={handleRedirectPlan} aria-hidden="true">
            <NavLink
              to="#0"
              activeClassName="active"
              isActive={isPlansLinkActive}
            >
              Plans
            </NavLink>
          </li>
        </ul>
        <button
          type="button"
          className="btn logout-btn"
          onClick={() => {
            logout(history);
          }}
        >
          Logout
        </button>
      </div>
      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
        currentPlan={planDetails}
      />

      <Section2remainderPopup
        modalStatus={section2RemainderMsg}
        handleClose={handleCloseSection2Remainder}
        userDetails={current}
      />
    </>
  );
};

export default InnerSidebar;
