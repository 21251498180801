import React from 'react';
// import '../assets/css/site.css';
import { connect } from 'react-redux';
import authService from '../services/authServices';
// import Header from './partial/header';
import Sidebar from './partial/sidebar';
// import Sidebar2 from './partial/sidebar2';
import AdminHeader from './partial/adminHeader';
import InnerHeader from './partial/innerHeader';
import Spinner from '../components/general/spinner';
// import AppConstants from '../appConstants';

class InnerLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: props.sidebar === 3 ? props.sidebar : 1,
      isExtendStatus: false,
      isError: false,
      redirectPath: ''
    };
  }

  componentDidMount() {
    // this.SpinnerRender();
    const { history } = this.props;
    authService.throwBack(history);
  }

  handleRedirect = () => {
    const { history } = this.props;
    const { isError, redirectPath } = this.state;
    if (isError) {
      // setTimeout(() => {
        history.push(redirectPath);
      // }, 1000);
    }
  };

  // SpinnerRender = () => {
  //   const { current } = this.props;
  //   if (current?.status === 'ACTIVE' && current?.is_email_verified === '0' && current?.days === 0) {
  //     this.setState({
  //       isError: true,
  //       redirectPath: `${AppConstants.routes.emailVerification}?email=${current?.email}`
  //     });
  //   } else if (current?.status === 'PENDING') {
  //     this.setState({ isError: true, redirectPath: AppConstants.routes.home });
  //   }
  // };

  callbackfunction = (data) => {
    this.setState({ isExtendStatus: data });
  };

  render() {
    const { sidebar, isExtendStatus, isError } = this.state;
    const { children } = this.props;
    return (
      <div className="muli-fonts">
        {/* {current?.status === 'ACTIVE' ? ( */}
        {!isError ? (
          <section className="section lov-inner-layout">
            {/* <Sidebar {...this.props} /> */}

            <Sidebar
              {...this.props}
              callBack={this.callbackfunction}
              isExtend={isExtendStatus}
            />
            <div className="dashboard-common">
              <div
                className={`innerWrapper ${sidebar === 3 ? 'full-header' : ''}`}
              >
                {sidebar === 3 ? (
                  <>
                    <AdminHeader {...this.props} />
                  </>
                ) : (
                  <>
                    <InnerHeader
                      {...this.props}
                      callBack={this.callbackfunction}
                    />
                  </>
                )}
                {children}
              </div>
            </div>
          </section>
        ) : (
          <>
            {this.handleRedirect()}
            <Spinner active />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { current } = state.user;
  return {
    current
  };
}

// export default InnerLayout;
export default connect(mapStateToProps)(InnerLayout);
