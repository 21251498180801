import React from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import AppConstants from '../../appConstants';
import { setUser } from '../../redux/actions/user.actions';
import { authCall } from '../../services';
import authService from '../../services/authServices';
import OtpInputCustom from '../general/otpInput';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      u_email: '',
      password: '',
      confirm_password: '',
      step: 1,
      otp: null,
      otpAttamp: 1,
      request: true,
      showModel: false,
      forgot_password: false
    };

    this.validator = new SimpleReactValidator({
      validators: {
        confirm: {
          message: 'The :attribute must be same as Password.',
          // eslint-disable-next-line react/destructuring-assignment
          rule: (val) => val === this.state.password,
          required: true // optional
        },
        password: {
          message:
            'The :attribute must have min 8 characters, with at least a symbol, number, upper and lower case.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(
              val,
              /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/i
            ) && params.indexOf(val) === -1,
          required: true // optional
        }
      }
    });
  }

  goToLogin = () => {
    this.setState({
      step: 1,
      forgot_password: false
    });
  };

  resetUserPassword = async () => {
    if (
      this.validator.fieldValid('password') &&
      this.validator.fieldValid('confirm_password')
    ) {
      const { request } = this.state;
      if (request) {
        this.setState({ request: false });

        const { u_email: email, password, otp } = this.state;
        const resp = await authCall('auth/action/reset-password-by-email', {
          email,
          password,
          otp
        });
        if (resp && resp.success === true) {
          toast.success('Password has been successfuly changed.');
          this.setState({
            step: 1,
            forgot_password: false
          });
        }

        this.setState({ request: true });
      }
    } else {
      this.validator.showMessageFor('confirm_password');
      this.validator.showMessageFor('password');
      this.forceUpdate();
    }
  };

  sendCode = async () => {
    const { request, u_email: email } = this.state;
      if (this.validator.fieldValid('email')) {
        if (request) {
          this.setState({ request: false });
          const resp = await authCall('auth/action/send-email-otp-to-user', {
            email
          });
          if (resp && resp.success === true) {
            this.setState({
              step: 2
            });
          }

          this.setState({ request: true });
        }
      } else {
        this.validator.showMessageFor('email');
        this.forceUpdate();
      }
  };

  goToNextStep = (data) => {
    // console.log('Next step data =>', data);
    this.setState({
      step: data.step,
      u_email: data.email,
      otp: data.otp
    });
  };

  closePopup = () => {
    this.goToLogin();
    const { closePopup } = this.props;
    closePopup();
  };

  submitForm = async () => {
    let request = {};
    let flag = false;
      if (
        this.validator.fieldValid('email') &&
        this.validator.fieldValid('password')
      ) {
        const { u_email: username, password } = this.state;
        request = {
          username,
          password
        };
        flag = true;
      } else {
        this.validator.showMessageFor('email');
        this.validator.showMessageFor('password');
        this.forceUpdate();
      }

    if (request && flag) {
      this.setState({ request: false });

      const resp = await authCall('auth/login', request);
      if (resp && resp.success === true) {
        authService.setToken(resp.data);
        const { history, dispatch } = this.props;
        dispatch(setUser(resp.data.user));
        history.push(AppConstants.routes.afterLogin);
      }

      this.setState({ request: true });
    }
  };

  render() {
    const { isOpen } = this.props;
    const {
      forgot_password: forgotPassword,
      confirm_password: confirmPassword,
      step,
      u_email: uEmail,
      request,
      password
    } = this.state;
    return (
      <>
        {isOpen ? (
          <>
            <div
              id="MemberLoginModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              // data-backdrop="static"
              data-keyboard="false"
              className="modal show"
              style={{ display: 'block' }}
            >
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={3}
                pauseOnFocusLoss
                draggable
                pauseOnHover

              />
              <div
                role="document"
                className="modal-dialog modal-dialog-centered"
              >
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h5 id="exampleModalLongTitle" className="modal-title">
                      {forgotPassword
                        ? 'Reset Password'
                        : 'Login into Lovrican'}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={this.closePopup}
                    >
                      <span aria-hidden="true">X</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {step < 2 ? (
                      <>
                        <form data-vv-scope="loginData">
                          <div className="tab-content" id="loginWayTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="email"
                              role="tabpanel"
                              aria-labelledby="email-tab"
                            >
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email address"
                                  className="form-control"
                                  value={uEmail}
                                  onChange={(e) =>
                                    this.setState({ u_email: e.target.value })
                                  }
                                />
                                {this.validator.message(
                                  'email',
                                  uEmail,
                                  'required|email'
                                )}
                              </div>
                            </div>
                          </div>
                          {forgotPassword ? (
                            <>
                              <div className="form-group">
                                <p className="display-message">
                                  We’ll send a code to your registered
                                  Email to reset the Password.
                                </p>
                              </div>
                              <div className="form-group">
                                <div className="form-check pull-left">
                                  <label
                                    htmlFor="gridCheck"
                                    className="form-check-label"
                                  >
                                    I remember now
                                  </label>
                                </div>
                                <div
                                  className="pull-right home-popup-fp"
                                  onClick={this.goToLogin}
                                  onKeyDown={(event) =>
                                    event.key === 'Enter' && this.goToLogin()
                                  }
                                  role="button"
                                  tabIndex="0"
                                >
                                  Login
                                </div>
                              </div>
                              <div className="form-group text-center">
                                <div className="join-links">
                                  <span
                                    className={`round-button ${
                                      !request ? 'hks-btn-disable' : ''
                                    }`}
                                    onClick={this.sendCode}
                                    onKeyDown={(event) =>
                                      event.key === 'Enter' && this.sendCode()
                                    }
                                    role="button"
                                    tabIndex="0"
                                  >
                                    Send Code
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="form-group">
                                <input
                                  type="password"
                                  name="password"
                                  placeholder="Enter your password"
                                  className="form-control"
                                  value={password}
                                  onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                  }
                                />
                                {this.validator.message(
                                  'password',
                                  password,
                                  'required'
                                )}
                              </div>
                              <div className="form-group">
                                <div className="form-check pull-left">
                                  <input
                                    type="checkbox"
                                    id="gridCheck"
                                    className="form-check-input"
                                  />
                                  <label
                                    htmlFor="gridCheck"
                                    className="form-check-label"
                                  >
                                    Remember me
                                  </label>
                                </div>
                                <div
                                  className="pull-right home-popup-fp"
                                  onClick={() => {
                                    this.setState({ forgot_password: true });
                                  }}
                                  onKeyDown={(event) =>
                                    event.key === 'Enter' &&
                                    this.setState({ forgot_password: true })
                                  }
                                  role="button"
                                  tabIndex="0"
                                >
                                  Forgot Password ?
                                </div>
                              </div>
                              <div className="form-group text-center">
                                <div className="join-links">
                                  <span
                                    className={`round-button ${
                                      !request ? 'hks-btn-disable' : ''
                                    }`}
                                    onClick={this.submitForm}
                                    onKeyDown={(event) =>
                                      event.key === 'Enter' && this.submitForm()
                                    }
                                    role="button"
                                    tabIndex="0"
                                  >
                                    Login
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </form>
                      </>
                    ) : (
                      <>
                        {step < 3 ? (
                          <>
                            <div className="row verify-otp-popup text-center">
                              <OtpInputCustom
                                {...this.props}
                                {...this.state}
                                resendOtpUrl="auth/action/resend-email-otp-to-user"
                                verifyOtpUrl="auth/action/verify-email-otp-for-password"
                                goToNextStep={this.goToNextStep}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                className="form-control"
                                value={password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                              />
                              {this.validator.message(
                                'password',
                                password,
                                'password'
                              )}
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                name="confirm_password"
                                placeholder="Confirm your Password"
                                className="form-control"
                                value={confirmPassword}
                                onChange={(e) =>
                                  this.setState({
                                    confirm_password: e.target.value
                                  })
                                }
                              />
                              {this.validator.message(
                                'confirm_password',
                                confirmPassword,
                                'confirm'
                              )}
                            </div>
                            <div className="form-group text-center">
                              <div className="join-links">
                                <span
                                  className={`round-button ${
                                    !request ? 'hks-btn-disable' : ''
                                  }`}
                                  onClick={this.resetUserPassword}
                                  onKeyDown={(event) =>
                                    event.key === 'Enter' &&
                                    this.resetUserPassword()
                                  }
                                  role="button"
                                  tabIndex="0"
                                >
                                  Reset Password
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <> </>
        )}
      </>
    );
  }
}

// MyModal.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     show: PropTypes.bool.isRequired
// };

export default connect()(Login);
