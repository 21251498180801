import React from 'react';
import { connect } from 'react-redux';
import authService from '../services/authServices';
// import Sidebar from './partial/sidebar';
import AffiliateHeader from './partial/affiliateHeader';
import Spinner from '../components/general/spinner';


class AffiliateInnerLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: props.sidebar === 3 ? props.sidebar : 1,
      // isExtendStatus: false,
      isError: false,
      redirectPath: ''
    };
  }

  componentDidMount() {
    const { history } = this.props;
    authService.throwBack(history);
  }

  handleRedirect = () => {
    const { history } = this.props;
    const { isError, redirectPath } = this.state;
    if (isError) {
      // setTimeout(() => {
      history.push(redirectPath);
      // }, 1000);
    }
  };

  // callbackfunction = (data) => {
  // this.setState({ isExtendStatus: data });
  // };

  render() {
    const { sidebar, isError } = this.state;
    const { children } = this.props;
    return (
      <div className="muli-fonts">
        {!isError ? (
          <section className="section lov-inner-layout">
            {/* <Sidebar
              {...this.props}
              callBack={this.callbackfunction}
              isExtend={isExtendStatus}
            /> */}
            <div className="dashboard-common">
              <div
                className={`innerWrapper ${sidebar === 3 ? 'full-header' : ''}`}
              >
                <AffiliateHeader {...this.props} callBack={this.callbackfunction} />
                {children}
              </div>
            </div>
          </section>
        ) : (
          <>
            {this.handleRedirect()}
            <Spinner active />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { current } = state.user;
  return {
    current
  };
}

export default connect(mapStateToProps)(AffiliateInnerLayout);
