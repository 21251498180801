import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step12Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    lookingFor: userDetails?.userDetails?.lookingFor || ''
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        lookingFor: values.lookingFor
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step12" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Tell us about your ideal match. Together, we’ll find you the
                perfect partner
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3 large_height_textarea">
              <textarea
                type="text"
                name="lookingFor"
                placeholder="I am looking for..."
                className="custom_input resize-none"
                autoComplete="off"
                value={values.lookingFor}
                onChange={(e) =>
                  setValues({ ...values, lookingFor: e.target.value })
                }
              />
              {validator.message(
                'lookingFor',
                values.lookingFor,
                'required|min:50|max:300'
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step12Component;
