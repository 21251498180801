import React, { useEffect, useState } from 'react';
import toast from 'toastr';
import SimpleReactValidator from 'simple-react-validator';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import { pageTitle } from '../../../../redux/actions/home.actions';
import webService from '../../../../services/webServices';

const categories = [
  {
    value: 'Category One',
    label: 'Compliment'
  },
  {
    value: 'Category Two',
    label: 'Complaint'
  },
  {
    value: 'Category Three',
    label: 'Service Support Need'
  },
  {
    value: 'Category Four',
    label: 'Business Partnerships'
  },
  {
    value: 'Category Five',
    label: 'Other'
  }
];

const ContactPage = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    queryType: null,
    query: ''
  });

  const forceUpdate = useState()[1].bind(null, {});

  useEffect(async () => {
    dispatch(pageTitle('Customer Support'));
  }, []);

  const submitForm = async () => {
    if (validator.allValid()) {
      setLoading(true);
      const formData = new FormData();
      formData.append('email', current.email);
      formData.append('category', values.queryType.label);
      formData.append('message', values.query);
      const response = await webService.submitContactQuery(formData);
      if (response.success) {
        // setValues({ ...values, query: '', queryType: null });
        toast.success(
          'Your query has been submitted successfully. Our team will contact you shortly.'
        );
        setLoading(false);
      } else {
        toast.error('Some error occurred');
        setLoading(false);
      }
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign profile-wrapper-outer aff_contact ">
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="card">
                <h1>
                  Lovrican <br />
                  24x7 Support
                </h1>
                <div className="sub_head">
                  We are always available for you <br />
                  to support you and solve your any question
                </div>

                <div className="row">
                  <div className="col-md-12 custome_select">
                    <Select
                      placeholder="Query type"
                      options={categories}
                      autoComplete="off"
                      className="custom_input"
                      value={values.query}
                      clearable
                      searchable
                      onChange={(value) =>
                        setValues({ ...values, queryType: value[0] })
                      }
                    />
                    {validator.message(
                      'Query Type',
                      values.queryType,
                      'required'
                    )}
                  </div>
                  <div className="col-md-12 custome_select b">
                    <textarea
                      className="form-control custom_input"
                      placeholder="Write about your query"
                      onChange={(e) =>
                        setValues({ ...values, query: e.target.value })
                      }
                      value={values.query}
                    />
                    {validator.message('query', values.query, 'required')}
                  </div>
                  <div className="col-12 text-right mt-4">
                    <button
                      type="button"
                      className="btn btn_aff_common mt-4"
                      onClick={submitForm}
                      disabled={loading || false}
                    >
                      Raise Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
