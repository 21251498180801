import AppConstants from '../appConstants';

/**
 * User logout method, removes user object from browser localstorage and reloads page
 * @void
 */
function logout(history) {
  // remove user from local storage to log user out
  localStorage.removeItem('lovUserDetails');
  localStorage.removeItem('lovPermit');
  localStorage.removeItem('userDetails');
  // window.location.reload();
  history.push(AppConstants.routes.home);
  // history.push(AppConstants.routes.login);
}

function logoutAdmin(history) {
  localStorage.removeItem('lovUserDetails');
  localStorage.removeItem('lovPermitAdmin');
  localStorage.removeItem('userDetails');
  history.push(AppConstants.routes.admin.login);
}

/**
 * Removes user object from browser localstorage
 * @void
 */
function clear() {
  // remove user from local storage to log user out
  localStorage.removeItem('lovUserDetails');
  localStorage.removeItem('lovPermit');
  localStorage.removeItem('userDetails');
}

/**
 * Returns flag if the user is already logged in
 * @void
 */
function isLoggedIn() {
  return !!localStorage.getItem('lovPermit');
}

function throwBackRevRegister(history) {
  if (localStorage.getItem('lovPermit')) {
    const details = localStorage.getItem('userDetails');
    const { is_profile_completed: profileStatus, role } = JSON.parse(details);

    if (profileStatus === 'YES') {
      history.push(AppConstants.routes.afterLogin);
    } else if (role === 'AFFILIATE') {
      history.push(AppConstants.routes.affiliate.registerSection);
    } else {
      history.push(AppConstants.routes.registerSections);
    }
  }
}

/**
 * Removes user object from browser localstorage and redirects to login page
 * @void
 */
function throwBack(history) {
  if (!localStorage.getItem('lovPermit')) {
    // history.push(AppConstants.routes.home);
    history.push(AppConstants.routes.login);
  } else {
    const details = localStorage.getItem('userDetails');
    const profileStatus = JSON.parse(details)?.is_profile_completed;

    if (!profileStatus || profileStatus === 'NO') {
      history.push(AppConstants.routes.registerSections);
    }
  }
}

function throwBackAdmin(history) {
  if (!localStorage.getItem('lovPermitAdmin')) {
    history.push(AppConstants.routes.admin.login);
  }
}

function throwBackRev(history) {
  if (localStorage.getItem('lovPermit')) {
    const details = localStorage.getItem('userDetails');
    // const profileStatus = JSON.parse(details)?.is_profile_completed;
    const { is_profile_completed: profileStatus, role } = JSON.parse(details);

    // if (!profileStatus || profileStatus === 0) {
    //   history.push(AppConstants.routes.afterLogin);
    // } else {
    //   history.push({
    //     pathname: AppConstants.routes.registerSections,
    //     state: { step: profileStatus }
    //   });
    // }

    // history.push({
    //   pathname: AppConstants.routes.afterLogin,
    //   state: { step: profileStatus }
    // });

    if (profileStatus === 'YES') {
      history.push(AppConstants.routes.afterLogin);
    } else if (role === 'AFFILIATE') {
      history.push(AppConstants.routes.affiliate.registerSection);
    } else {
      history.push(AppConstants.routes.registerSections);
    }
  } else if (localStorage.getItem('lovPermitAdmin')) {
    history.push(AppConstants.routes.admin.dashboard);
  }
}

function getToken() {
  let data;
  if (localStorage.getItem('lovPermitAdmin')) {
    data = localStorage.getItem('lovPermitAdmin');
  } else {
    data = localStorage.getItem('lovPermit');
  }

  if (data) {
    data = JSON.parse(data);
    if (data.lovToken) return data.lovToken;
  }
  return null;
}

function getUser() {
  let data = localStorage.getItem('lovPermit');
  if (data) {
    data = JSON.parse(data);
    if (data.user) return data.user;
  }
  return null;
}

function setToken(data) {
  localStorage.setItem('lovPermit', JSON.stringify(data));
}

function setAdminToken(data) {
  localStorage.setItem('lovPermitAdmin', JSON.stringify(data));
}

function setUserDetails(data) {
  localStorage.setItem('lovUserDetails', JSON.stringify(data));
}

function getUserDetails() {
  let data = localStorage.getItem('userDetails');
  if (data) {
    data = JSON.parse(data);
    if (data.id) return data;
  }
  return null;
}

export default {
  logout,
  clear,
  isLoggedIn,
  throwBack,
  throwBackRev,
  setToken,
  getToken,
  setUserDetails,
  getUserDetails,
  getUser,
  setAdminToken,
  logoutAdmin,
  throwBackAdmin,
  throwBackRevRegister
};
