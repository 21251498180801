import authService from '../../services/authServices';
import { UserConstants } from '../constants';

export const initialState = {
  current: authService.getUserDetails(),
  planDetails: {},
  potentialMetches: [],
  userList: [],
  userAddedDataList: [],
  userMatchCount: 0,
  contactUserList: []
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserConstants.SET_USER_DETAILS:
      return { ...state, current: action.payload };
    case UserConstants.UNSET_USER_DETAILS:
      return { ...state, current: null };
    case UserConstants.USER_PLANS_DETAILS:
      return { ...state, planDetails: action.payload };
    case UserConstants.POTENTIAL_MATCHES:
      return { ...state, potentialMetches: action.payload };
    case UserConstants.USER_LIST:
      return { ...state, userList: action.payload };
    case UserConstants.USER_ADDED_DATA_LIST:
      return { ...state, userAddedDataList: action.payload };
    case UserConstants.USER_MATCH_COUNT:
      return { ...state, userMatchCount: action.payload };
    case UserConstants.CONTACT_USER_LIST:
      return { ...state, contactUserList: action.payload };
    default:
      return { ...state };
  }
};

export default userReducer;
