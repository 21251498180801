import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import csc from 'country-state-city';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';
import AppConstants from '../../appConstants';

const Step10Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(
    new SimpleReactValidator({
      validators: {
        max_five: {
          message: 'Maximum of 5 values allowed.',
          rule: (val) => val.length < 6,
          required: true
        }
      }
    })
  );
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    countryOrigin: userDetails?.userDetails?.origin1
      ? [userDetails?.userDetails?.origin1]
      : [],
    stateOrigin: userDetails?.userDetails?.origin2
      ? [userDetails?.userDetails?.origin2]
      : [],
    cityOrigin: userDetails?.userDetails?.origin3
      ? [userDetails?.userDetails?.origin3]
      : []
  });

  const [countriesOrigin, setCountriesOrigin] = useState([]);
  const [statesOrigin, setStatesOrigin] = useState([]);
  const [citiesOrigin, setCitiesOrigin] = useState([]);

  const getCountriesOptions = async () => {
    const data = await csc.getAllCountries();

    const countriesList = [];
    data.forEach(el => {
      if (!AppConstants.COUNTRIES_TO_REMOVE_ARR.includes(el.isoCode)) {
        countriesList.push({value: el.isoCode, label: `${el.flag} ${el.name} (${el.isoCode})`})
      }
    });

    setCountriesOrigin(countriesList);
  };

  const handleGetStates = async (value) => {
    if (value[0]) {
      const data = await csc.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      setStatesOrigin(options);
    } else {
      setStatesOrigin([]);
    }
  };

  const handelCountryChangeOrigin = async (value) => {
    if (value[0]) {
      const data = await csc.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      setStatesOrigin(options);
      setCitiesOrigin([]);
      setValues({
        ...values,
        countryOrigin: value,
        stateOrigin: [],
        cityOrigin: [],
      });
    } else {
      setValues({
        ...values,
        countryOrigin: [],
        stateOrigin: [],
        cityOrigin: [],
      });
    }
  };

  const handelStateChangeOrigin = async (value) => {
    if (value[0]) {
      const { countryOrigin } = values;
      const data = await csc.getCitiesOfState(
        countryOrigin[0].value,
        value[0].value
      );
      const options = data.map((d) => ({
        value: d.name,
        label: d.name
      }));
      setCitiesOrigin(options);
      setValues({
        ...values,
        stateOrigin: value,
        cityOrigin: [],
      });
    } else {
      setValues({
        ...values,
        stateOrigin: [],
        cityOrigin: [],
      });
    }
  };

  const handelCityChangeOrigin = async (value) => {
    if (value[0]) {
      setValues({
        ...values,
        cityOrigin: value
      });
    } else {
      setValues({
        ...values,
        cityOrigin: []
      });
    }
  };

  useEffect(() => {
    getCountriesOptions();
    handleGetStates(values.countryOrigin);
  }, []);

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        countryOrigin: values.countryOrigin[0],
        stateOrigin: values.stateOrigin[0],
        cityOrigin: values.cityOrigin[0]
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step10" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
              Add your origin to find matches who share
              your roots, or explore new traditions
              </div>
            </div>
          </div>
          <div className="row join-reg inner_bottom">
            <div className="col-md-12 col-lg-12  custome_select">
              <Select
                placeholder="Origin Country"
                options={countriesOrigin}
                autoComplete="off"
                className="custom_input"
                values={values.countryOrigin}
                onChange={(value) => handelCountryChangeOrigin(value)}
              />
              {validator.message(
                'country',
                values.countryOrigin,
                'required'
              )}
            </div>

            <div className="col-md-12 col-lg-12  mt-3 custome_select">
              <Select
                placeholder="Origin  State"
                options={statesOrigin}
                autoComplete="off"
                className="custom_input"
                values={values.stateOrigin}
                onChange={(value) => handelStateChangeOrigin(value)}
              />
              {validator.message('state', values.stateOrigin, 'required')}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mt-3 custome_select">
              <Select
                placeholder="Origin City"
                autoComplete="off"
                options={citiesOrigin}
                className="custom_input"
                values={values.cityOrigin}
                onChange={(value) => handelCityChangeOrigin(value)}
              />
              {validator.message('city', values.cityOrigin, 'required')}
            </div>
          </div>
          <div className="row join-reg bottom-join">
            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step10Component;
