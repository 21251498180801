import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';

// import { setUser } from '../redux/actions/user.actions';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import verfied from '../assets/images/verified.svg';
import notVerfied from '../assets/images/verified_Grey.svg';
import ageIcon from '../assets/images/Cake.svg';
import BasicDetails from '../components/edit-profile/basicDetails';
import Lifestyle from '../components/edit-profile/lifestyle';
import PersonalInfo from '../components/edit-profile/personal-info';
// import EditProfilePhotosPage from './editProfilePhotos';
import ViewProfilePhotosPage from './viewProfilePhotosPage';
import AppConstants from '../appConstants';
import VerifiedNotificationPopup from './verifiedNotificationPopup';

const AccordionCustomToggle = ({ title, eventKey, status, parentCallBack }) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={decoratedOnClick}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      <span>
        {status === 'open' ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    </div>
  );
};

const ViewProfilePage = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({});
  const location = useLocation();
  const history = useHistory();

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const [accStatus, setAccStatus] = useState({
    personal: 'close',
    basic: 'close',
    lifestyle: 'close',
    photo: 'close'
  });

  useEffect(() => {
    dispatch(pageTitle('Profile Details'));
  }, []);
  useEffect(async () => {
    if (location.state) {
      const resp = await webServices.getUserDetails(location.state.userId);
      if (resp && resp.success === true) {
        setUserDetails(resp.data);
      }
    } else {
      history.push({
        pathname: AppConstants.routes.matchQueue
      });
    }
  }, [location.state]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    init.basic = 'close';
    init.lifestyle = 'close';
    init.photo = 'close';

    if (key === '0') {
      init.personal = data;
    }
    if (key === '1') {
      init.basic = data;
    }
    if (key === '2') {
      init.lifestyle = data;
    }
    if (key === '3') {
      init.photo = data;
    }
    setAccStatus(init);
  };

  const convertHeightToFeetAndInches = (decimalHeight) => {
    const feet = Math.floor(decimalHeight);
    const inches = Math.round((decimalHeight - feet) * 12);
    return `${feet}’ ${inches}”`;
  };

  return (
    <>
      <div className="inner-wrapper-newdesign  profile-wrapper-outer">
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="image-slider-box">
                <div
                  className="user-profile-image"
                  style={{
                    backgroundImage: `url("${
                      process.env.REACT_APP_PIC_BASE_URL
                    }${
                      userDetails.profile_image
                        ? userDetails.profile_image
                        : 'no_Image.svg'
                    }")`
                  }}
                />
              </div>
              <div className="user-detail-box profileSection">
                <div className="usr-info-box">
                  <h5>
                    <span className="profile_name">
                      {userDetails.first_name} {userDetails.last_name}
                    </span>
                    {/* <span className='dotAge'>&#8226;</span>{' '}
                    {userDetails.age} */}
                    <img
                      src={
                        userDetails?.status === 'ACTIVE' &&
                        userDetails?.phone !== null
                          ? verfied
                          : notVerfied
                      }
                      className={`img-fluid verifid_img ${
                        current?.status === 'ACTIVE' && current?.phone !== null
                          ? ''
                          : 'cursor-pointer'
                      }`}
                      alt="arrow icon"
                      aria-hidden="true"
                      onClick={() => {
                        setVerifyMsg(
                          current?.status !== 'ACTIVE' ||
                            current?.phone === null ||
                            false
                        );
                      }}
                    />
                  </h5>
                  {userDetails.occupationLabel ? (
                    <p className="occupation">
                      {userDetails.occupationLabel} (
                      {userDetails?.userDetails?.jobTitle})
                      {userDetails?.userDetails?.companyName
                        ? ` at ${userDetails?.userDetails?.companyName}`
                        : ''}
                    </p>
                  ) : (
                    <p className="about-dec missting_section_match">
                      Occupation to be added
                    </p>
                  )}

                  <p className="address">{userDetails.user_add}</p>
                </div>
                <div>
                  <p className="prf_height age_icon">
                    <img src={ageIcon} alt="/" /> {userDetails.age}
                  </p>
                  <p className="prf_height">
                    {convertHeightToFeetAndInches(userDetails?.height)}
                  </p>
                </div>

                <div className="about-me-box">
                  <p className="head">Education:</p>

                  <p className="about-dec">
                    {userDetails?.userDetails?.educationLabel} (
                    {userDetails?.userDetails?.whatStudy})
                    <br />
                    from {userDetails?.userDetails?.schoolName}
                  </p>
                </div>

                <div className="about-me-box">
                  <p className="head">About Me</p>
                  {userDetails.about ? (
                    <p className="about-dec">{userDetails.about}</p>
                  ) : (
                    <p className="about-dec missting_section_match">
                      Intro summary to be added
                    </p>
                  )}
                </div>

                <div className="about-me-box">
                  <p className="head">My Roots:</p>

                  <p className="about-dec">
                    {userDetails?.userDetails?.origin3?.label},{' '}
                    {userDetails?.userDetails?.origin2?.label},{' '}
                    {userDetails?.userDetails?.origin1?.label}
                  </p>
                </div>

                <div className="about-me-box">
                  <p className="head">Looking for:</p>

                  <p className="about-dec">
                  {userDetails?.userDetails?.lookingFor}
                  </p>
                </div>

                <div className="ul-li-box hobbies-wrapper pt-2">
                  <p className="head pb-2">Hobbies</p>
                  {userDetails?.hobbies ? (
                    <ul className="color-list">
                      {userDetails.hobbies.map((prop) => (
                        <li key={prop.label}>{prop.label}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className="about-dec missting_section_match">
                      Hobbies to be added
                    </p>
                  )}
                </div>
              </div>
              <Accordion
                // defaultActiveKey=""
                alwaysOpen
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="3"
                      title="My Photos"
                      status={accStatus.photo}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <ViewProfilePhotosPage userDetail={userDetails} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="0"
                      title="Personal Info"
                      status={accStatus.personal}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <PersonalInfo
                        userDetail={userDetails}
                        masterData={{}}
                        viewMode
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="1"
                      title="Professional Info"
                      status={accStatus.basic}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <BasicDetails
                        userDetail={userDetails}
                        masterData={{}}
                        viewMode
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="2"
                      title="Lifestyle"
                      status={accStatus.lifestyle}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <Lifestyle
                        userDetail={userDetails}
                        masterData={{}}
                        viewMode
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={current}
      />
    </>
  );
};

export default ViewProfilePage;
