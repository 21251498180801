import React from 'react';
import DropdownSection from './dropdown-section';
import RangeSection from './range-section';
import TextSection from './text-section';
import SwitchButton from './switchButton';
import RadioButtonSection from './radioButton-section';
import DropdownResidenceSection from './dropdown-residence-section';
import DateOfBirth from './date-of-birth';
import DropdownWithTextSection from './dropdown-with-text-section';

const RenderFormElement = ({ type, ...rest }) => {
  switch (type) {
    case 'dropdown':
      return <DropdownSection {...rest} />;
    case 'range':
      return <RangeSection {...rest} />;
    case 'text':
      return <TextSection {...rest} />;
    case 'switch':
      return <SwitchButton {...rest} />;
    case 'radio':
      return <RadioButtonSection {...rest} />;
    case 'residence':
      return <DropdownResidenceSection {...rest} />;
    case 'dob':
      return <DateOfBirth {...rest} />;
    case 'dropdownText':
      return <DropdownWithTextSection {...rest} />;
    default:
      return null;
  }
};

export default RenderFormElement;
