import React from 'react';
// import { useDispatch } from 'react-redux';
// import * as moment from 'moment';
// import { setUser } from '../../../../redux/actions/user.actions';
// import webServices from '../../../../services/webServices';
import paypal from '../../../../assets/images/affiliate/Paypal.svg';
import cashapp from '../../../../assets/images/affiliate/cashapp.svg';
import paypalIcon from '../../../../assets/images/affiliate/paypal-fav.svg';
import cashappIcon from '../../../../assets/images/affiliate/cashapp-fav.svg';

const PaymentSettings = () => (
  <>
    <div className="aff_payment_setting">
      <div className="heading">Latest Payments</div>
      <div className="payment_box_wrapper">
        <div className="box">
          <img src={paypal} className="img-fluid" alt="paypal img" />
          <div className="count">$200.00</div>
        </div>
        <div className="box">
          <img src={cashapp} className="img-fluid" alt="paypal img" />
          <div className="count">$102.00</div>
        </div>
      </div>
      <div className="heading">How you would like to be paid</div>

      <div className="pain_wrapper">
        <div className="affilate_custom_field_wrapper">
          <div className="row">
            <div className="col-12 border-custom">
              <div className="d-flex align-items-end">
                <div className="icon">
                  <img
                    src={paypalIcon}
                    className="img-fluid"
                    alt="paypal img"
                  />
                </div>
                <div className="field w-100">
                  <input
                    type="text"
                    name="from"
                    id="from"
                    placeholder="Enter Email"
                    className="custom_input"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="status confirmed">confirmed</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 border-custom mt-4">
              <div className="d-flex align-items-end">
                <div className="icon">
                  <img
                    src={cashappIcon}
                    className="img-fluid"
                    alt="paypal img"
                  />
                </div>
                <div className="field w-100">
                  <input
                    type="text"
                    name="from"
                    id="from"
                    placeholder="Enter Email"
                    className="custom_input"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className="status pending">pending</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 mt-4 pl-0 pr-0">
              <button type="button" className="btn btn_aff_common saveBtn">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default PaymentSettings;
