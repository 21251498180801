import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import Resizer from 'react-image-file-resizer';
import DropInput from '../../components/general/dropInput';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';
import CropperPopup from '../cropperPopup';

const Step14Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    profilePicture: userDetails?.profile_image || '',
    fileError: '',
    imageSizeValidation: false,
    imageSRC: null,
    show: false,
    imagePreview: userDetails?.profile_image
      ? process.env.REACT_APP_PIC_BASE_URL + userDetails?.profile_image
      : null
  });
  const [validator] = useState(
    new SimpleReactValidator({
      validators: {
        custom_file: {
          message: values.fileError || 'The profile picture is required.',
          rule: () => values.fileError,
          required: true
        }
      }
    })
  );

  const submitForm = async () => {
    if (validator.allValid()) {
      setLoading(true);
      const formData = new FormData();
      formData.append('picture', values.profilePicture);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  const handleFileChange = (files) => {
    const [firstFile] = files;
    if (!firstFile) {
      return;
    }

    Resizer.imageFileResizer(
      firstFile, // the file from input
      700, // width
      750, // height
      'WEBP', // compress format WEBP, JPEG, PNG
      90, // quality
      0, // rotation
      async (uri) => {
        setValues({...values, imageSRC: uri, show: true });
      },
      'base64' // blob or base64 default base64
    );
  };

  const changeImage = () => setValues({ ...values, imagePreview: null });

  const close = (val) => setValues({ ...values, show: val });

  const callbak = async (action, data) => {
    if (action === 'click') {
      setValues({
        ...values,
        profilePicture: data.blobFile,
        imageSizeValidation: false,
        imagePreview: data.uri,
        show: false
      });
    }
  };

  return (
    <>
       <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step14" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
          <div className="col-md-12 col-lg-12">
              <div className='subtitle'>Make a captivating first impression
with your best photo</div>
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12 custome_select">
              <DropInput
                handleFileChange={handleFileChange}
                imagePreview={values.imagePreview}
                imageSizeValidation={false}
                changeImage={changeImage}
              />
              <div className="col-12 mt-3">
                {validator.message(
                  'profilePicture',
                  values.profilePicture,
                  'required'
                )}
              </div>
              <div className="col-12 mt-3">
                {values.imageSizeValidation && (
                  <div className="srv-validation-message">
                    Image size not more than 2 MB.
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-center">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <CropperPopup
        modalStatus={values.show}
        handleClose={close}
        parentCallback={callbak}
        imageSRC={values.imageSRC}
      />
    </>
  );
};

export default Step14Component;
