import React from 'react';
// import { isEmpty, isValidEmail } from '../../../helpers/validator';
import logoWhite from '../../../assets/images/Logo.svg';
import arrowIcon from '../../../assets/images/arrow-circle.svg';

const PersonDetailsComponent = ({
  goToNextStep,
  formValidation,
  values,
  handleChange
}) => (
  <div className="giftWrapper personDetails">
    <div className='giftBack' role="button" aria-hidden="true" onClick={() => goToNextStep(1)}>
    <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
    </div>
    <div className="gift-logo">
      <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="innerWrapper">
          <div className="col-12 p-0">
            <h1>
              Enter the email of the
              <br /> lucky person receiving
              <br /> your gift.
            </h1>
            <div className="row customForm">
              <div className="col-md-6">
                <div className="box">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="First Name"
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                  />
                </div>
                <span className="validation-error">
                  {formValidation.firstName}
                </span>
              </div>
              <div className="col-md-6">
                <div className="box">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="Last Name"
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                  />
                </div>
                <span className="validation-error">
                  {formValidation.lastName}
                </span>
              </div>
              <div className="col-md-12">
                <div className="box top-space-line">
                  <input
                    className="themeInput"
                    type="text"
                    placeholder="Enter Recipient’s Email"
                    value={values.email}
                    onChange={handleChange('email')}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && goToNextStep(3)
                    }
                  />
                </div>
                <span className="validation-error">{formValidation.email}</span>
              </div>
              <div className="col-12 text-right mt-4">
                
                <span
                  className="search-btn"
                  role="button"
                  aria-hidden="true"
                  onClick={() => goToNextStep(3)}
                >
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PersonDetailsComponent;
