import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import footerLogo from '../../assets/images/LogoPurplenew.svg';
import twitterIcon from '../../assets/images/twitter-white.svg';
import Login from '../../components/popups/login';
import { loginPopupAction } from '../../redux/actions/home.actions';

class Footer extends Component {
  closePopup = () => {
    const { loginPopupActionLocal } = this.props;
    loginPopupActionLocal(false);
  };

  render() {
    const { history, home } = this.props;
    return (
      <>
        <section className="lovrican-section">
          <div className="love-footer">
            <div className="col-12 text-center">
              <img className="logo" src={footerLogo} alt="" />
            </div>

            <div className="row footerMenu">
              <div className="col-md-6 col-lg-3">
                <div className="loveFooterWrapper">
                  <h5>ABOUT<br/> LOVRICAN</h5>
                  <ul className="love-footer-menu">
                    <li>
                      <a
                        href="https://lovrican.com/blog/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="/about-us" target="_blank" rel="noreferrer">
                        About Us
                      </a>
                      {/* <span
                        onClick={() => history.push('/about-us')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && history.push('/about-us')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        About Us
                      </span> */}
                    </li>

                    <li>
                      <a
                        href="/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>
                      {/* <span
                        onClick={() => history.push('/terms-of-service')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          history.push('/terms-of-service')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Terms and Conditions
                      </span> */}
                    </li>
                    <li>
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      {/* <span
                        onClick={() => history.push('/privacy-policy')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          history.push('/privacy-policy')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Privacy Policy
                      </span> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="loveFooterWrapper">
                  <h5>HELPFUL<br/> LINKS</h5>
                  <ul className="love-footer-menu">
                    <li>
                      <a href="/contact-us" target="_blank" rel="noreferrer">
                        Contact Us
                      </a>

                      {/* <span
                        onClick={() => history.push('/contact-us')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && history.push('/contact-us')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Contact Us
                      </span> */}
                    </li>
                    <li>
                      <a href="/faq" target="_blank" rel="noreferrer">
                        FAQ
                      </a>
                      {/* <span
                        onClick={() => history.push('/faq')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && history.push('/faq')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        FAQ
                      </span> */}
                    </li>

                    <li>
                      <a href="/safety-tips" target="_blank" rel="noreferrer">
                        Safety Tips
                      </a>
                      {/* <span
                        onClick={() => history.push('/safety-tips')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && history.push('/safety-tips')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Safety Tips
                      </span> */}
                    </li>
                    <li>
                      <a
                        href="/community-standards"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Community Standards
                      </a>
                      {/* <span
                        onClick={() => history.push('/community-standards')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          history.push('/community-standards')
                        }
                        role="button"
                        tabIndex="0"
                      >
                        Community Standards
                      </span> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="loveFooterWrapper">
                  <h5 className='text-uppercase'>CONNECT WITH Nigerian <br/>Communities in America</h5>
                  <div className="row">
                    <div className="col-md-5">
                      <ul className="love-footer-menu">
                      <li>
                          <a
                            href="https://lovrican.com/blog/meet-africans-in-new-york/"
                            target="_blank"
                            rel="noreferrer"
                          >
                           New York
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://lovrican.com/blog/meet-africans-in-new-jersey/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            New Jersey
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="love-footer-menu">
                        <li>
                          <a
                            href="https://lovrican.com/blog/meet-africans-in-connecticut/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Connecticut
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://lovrican.com/blog/african-community-in-pennsylvania/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Pennsylvania
                          </a>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="loveFooterWrapper">
                  <h5>FOLLOW US</h5>
                  <ul className=" list-unstyled d-flex social-media">
                    <li>
                      <a
                        href="https://www.instagram.com/lovrican.love/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-instagram fa-fw"
                          aria-hidden="true"
                          aria-label="Instagram Icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/lovrican.love/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-facebook fa-fw"
                          aria-hidden="true"
                          aria-label="Facebook Icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/LovricanLove"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="twitter_icon" src={twitterIcon} alt="" aria-label="Twitter Icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/lovrican/about/?viewAsMember=true"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-linkedin fa-fw"
                          aria-hidden="true"
                          aria-label="Linkedin Icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@LovricanLove"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa fa-youtube-play fa-fw"
                          aria-hidden="true"
                          aria-label="Youtube Icon"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row love-footer copyRight">
            <div className="col col-md-12">
              <div className="loveFooterWrapper d-md-flex justify-content-center align-items-center">
                <p className="m-0">
                  Copyright © {new Date().getFullYear()} Lovrican | All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </section>
        <Login
          isOpen={home.loginPopup}
          closePopup={this.closePopup}
          history={history}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginPopupActionLocal: (payload) => dispatch(loginPopupAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
