import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';

import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalCardFieldsForm
} from '@paypal/react-paypal-js';
import webServices from './webServices';
import paypalLogo from '../assets/images/powered-by-Paypal.png';
import AppConstants from '../appConstants';
import VerifiedNotificationPopup from '../pages/verifiedNotificationPopup';

const PayPalCheckoutPage = ({ planId, amount, likes, selectedPlan }) => {
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const createOrder = async () => {
    const res = await webServices.createOrderNew({ planId, likes });
    return res.id;
  };

  const onApprove = async (data) => {
    const res = await webServices.captureOrder({ orderID: data.orderID });
    if (res?.id) {
      setIsPaying(false);
      setShow(false);
      // toastr.success('Payment done successfully');
      await webServices.updateOrder({ systemOrderId: data.orderID, likes });
      history.push({
        pathname: AppConstants.routes.paymentSuccess,
        state: { selectedPlan }
      });
    } else {
      history.push({
        pathname: AppConstants.routes.paymentFail,
        state: { selectedPlan }
      });
    }
    return res;
  };

  const handleClose = async () => {
    setShow(false);
  };

  const handleMsg = (msg) => {
    setErrorMsg(msg);
  };

  const handleClickPay = () => {
    if (userDetails?.status !== 'ACTIVE') {
      // setVerifyMsg(true);
      history.push({
        pathname: AppConstants.routes.congratulations
      });
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-info theme-btn ml-3 r_color mb-2 mb-md-0"
        onClick={handleClickPay}
      >
        Pay Now
      </button>
      <p className="d-block w-100 powered">
        <img src={paypalLogo} className="img-fluid" alt="paypal logo" />
      </p>
      <Modal
        className="filter-model PlanStatusModel paymentModel"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Add your credit card details:
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={handleClose}
            >
              &times;
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="loader-payment">
          <PayPalScriptProvider
            options={{
              clientId: process.env.REACT_APP_PAYPAL_CLIENT,
              components: 'card-fields'
            }}
          >
            <PayPalCardFieldsProvider
              createOrder={createOrder}
              onApprove={onApprove}
              onError={(err) => {
                toastr.error(err);
              }}
            >
              {errorMsg}
              <PayPalCardFieldsForm />
              {/* Custom client component to handle card fields submit */}
              <SubmitPayment
                isPaying={isPaying}
                setIsPaying={setIsPaying}
                handleClose={handleClose}
                errorMsg={errorMsg}
                handleMsg={handleMsg}
                amount={amount}
              />
            </PayPalCardFieldsProvider>
          </PayPalScriptProvider>
        </Modal.Body>
      </Modal>

      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={userDetails}
      />
    </>
  );
};

const SubmitPayment = ({
  isPaying,
  setIsPaying,
  handleClose,
  handleMsg,
  amount
}) => {
  const { cardFieldsForm } = usePayPalCardFields();

  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        'Unable to find any child components in the <PayPalCardFieldsProvider />';

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    handleMsg('');
    if (!formState.isFormValid) {
      handleMsg('The payment form is invalid');
      return;
    }

    setIsPaying(true);

    cardFieldsForm.submit().catch(() => {
      setIsPaying(false);
    });

    // return true;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <button
            type="button"
            className={isPaying ? 'btn btn-theme w-100' : 'btn btn-theme w-100'}
            onClick={handleClick}
            disabled={isPaying || false}
          >
            {isPaying ? 'Processing...' : `Pay $${amount}`}
          </button>
          <button
            type="button"
            className="btn btn-cancel w-100 d-none"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default PayPalCheckoutPage;
