import React, { useCallback, useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import EditIcon from '../../assets/images/edit.svg';
import lock from '../../assets/images/lock.svg';

const Menu = (props) => {
  const { children, getValue } = props;
  const optionSelectedLength = getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        children
      ) : (
        <div style={{ margin: 15 }}>
          You can only have a maximum of 5 items. You have reached the max
          allowed. Please remove some items to add others
        </div>
      )}
    </components.Menu>
  );
};

const SelectComponent = ({ isCreatable, limit, ...restProps }) => {
  const formatCreateLabel = useCallback(
    (inputValue) => `Add "${inputValue}"`,
    []
  );

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5;

  if (isCreatable) {
    return (
      <CreatableSelect
        classNamePrefix="comon-scroll"
        {...restProps}
        formatCreateLabel={formatCreateLabel}
        components={{ Menu }}
        isValidNewOption={isValidNewOption}
        placeholder={<div>Choose or Add a new one</div>}
      />
    );
  }
  return (
    <Select
      classNamePrefix="comon-scroll"
      {...restProps}
      components={{ Menu }}
      isValidNewOption={isValidNewOption}
    />
  );
};

const DropdownSection = ({
  label,
  value,
  options,
  formControlName,
  saveDetails,
  isMulti,
  isCreatable,
  isViewMode,
  color,
  isLock,
  limit
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const goToEditMode = () => {
    if (!isViewMode) {
      setIsEditMode(true);
    }
  };

  const handleFormSubmit = (event) => {
    if (localValue !== '') {
      event.preventDefault();
      (async () => {
        const response = await saveDetails({ [formControlName]: localValue });
        if (response && response.success) {
          // toast.dismiss();
          toast.success('Updated successfully');
          setIsEditMode(false);
        }
      })();
    } else {
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (window.innerWidth <= 800 && window.innerHeight <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window]);

  return (
    <div className="card-box position-relative">
      {isLock && (
        <div className="text-right lockSection">
          <img src={lock} alt="lock" />
        </div>
      )}

      <form onSubmit={handleFormSubmit}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={!isMobile && goToEditMode}
          aria-hidden="true"
        >
          <div className="card-header d-flex justify-content-between align-items-center">
            <span>{label}</span>

            {!isEditMode && !isViewMode && (
              <div
                className={`edit ${isMobile ? '' : 'd-none'}`}
                onClick={goToEditMode}
                onKeyDown={(event) => event.key === 'Enter' && goToEditMode()}
                role="button"
                tabIndex="0"
              >
                <img src={EditIcon} alt="Edit icon" />
              </div>
            )}
          </div>
          <div className="card-container">
            {!isEditMode &&
              (!!value && value.length !== 0 ? (
                <ul className="list-unstyled list1 mb-0">
                  {(Array.isArray(value) ? value : [value]).map(
                    (valueInner) => (
                      <li
                        key={valueInner.label}
                        style={{ backgroundColor: color || '' }}
                      >
                        {valueInner && valueInner.label}
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <span className="text-danger-not">Not answered yet</span>
              ))}
            {isEditMode && (
              <SelectComponent
                options={options}
                value={localValue}
                onChange={setLocalValue}
                isMulti={isMulti}
                // closeMenuOnSelect={false}
                // openMenuOnFocus = {true}
                // menuIsOpen ={1}
                limit={limit}
                isCreatable={isCreatable}
                onBlur={!isMobile && handleFormSubmit}
                // onKeyDown={handleFormSubmit}
              />
            )}
          </div>
          {isEditMode && (
            <div className={`${isMobile ? '' : 'd-none'}`}>
              <button
                className="btn cancel-btn mr-2"
                onClick={() => setIsEditMode(false)}
                type="button"
              >
                Cancel
              </button>
              <button className="btn theme-btn" type="submit">
                Save
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  )
};

export default DropdownSection;
