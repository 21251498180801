import React from 'react';
import { Helmet } from 'react-helmet';
import RegisterComponent from '../components/home/RegisterComponent';
import RegisterDetailsComponent from '../components/home/RegisterDetailsComponent';
import RegisterOtpComponent from '../components/home/RegisterOtpComponent';
import authService from '../services/authServices';
// import RegisterSuccessComponent from '../components/home/RegisterSuccessComponent'

class RegisterPageComponent extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const referralCode = queryParams.get('referralCode');

    this.state = {
      step: email ? 3 : 1,
      u_email: email || '',
      uOtp: null,
      referralCode
    };
  }

  componentDidMount() {
    const { history } = this.props;
    // authService.throwBackRev(history);
    authService.throwBackRevRegister(history);
  }

  goToNextStep = (data) => {
    this.setState({
      step: data.step,
      u_email: data.email,
      uOtp: data.otp
    });
  };

  render() {
    const { step } = this.state;
    return (
      <>
        <Helmet>
          <title>Register on Lovrican | Meet High-Quality African Professionals in America</title>
          <meta
            name="description"
            content="Join our vibrant community of single African professionals in America. Register on Lovrican now and begin your journey to find your ideal soulmate."
          />
          <meta name="keywords" content="Register on Lovrican" />
        </Helmet>
        {step === 3 ? (
          <>
            <RegisterDetailsComponent {...this.props} {...this.state} />
          </>
        ) : (
          <>
            {step === 2 ? (
              <>
                <RegisterOtpComponent
                  {...this.props}
                  {...this.state}
                  goToNextStep={this.goToNextStep}
                />
              </>
            ) : (
              <>
                <RegisterComponent
                  {...this.props}
                  goToNextStep={this.goToNextStep}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default RegisterPageComponent;
