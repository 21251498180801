import React from 'react';
import { Helmet } from 'react-helmet';

function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Lovrican</title>
        <meta
          name="description"
          content="At Lovrican, your privacy is paramount. Review our Privacy Policy to understand how we safeguard your personal information and create a safe and secure dating environment."
        />
        <meta name="keywords" content="Privacy Policy" />
      </Helmet>
      <div className="row privacy_policy">
        <div className="col col-md-12">
          <div className="">
            <div className="service-content">
              <div className="sub-inner">
                <div className="sub-heading">
                  <h5 className="text-left subhead">
                    Lovrican’s Privacy Policy
                  </h5>
                  <p className="subhead">
                    This privacy statement discloses the privacy practices of
                    Lovrican, LLC. and all websites owned or operated by
                    Lovrican, LLC. and its subsidiaries and its affiliates.
                  </p>
                </div>
                <h3 className="subhead pt-4">1. What Information We Collect</h3>
                <div className="subhead pl-4">
                  <p>
                    The types of information that we collect fall under two
                    general categories: personally, identifiable information
                    (PII) and non-personally identifiable information (non-PII).
                    PII consists of any information which can be used to
                    specifically identify you as an individual, whereas non-PII
                    consists of aggregate information or any information that
                    does not reveal your identity. The following sections
                    describe how your PII and non-PII are collected by us, and
                    how we use such information.
                  </p>
                </div>
                <h3 className="subhead pt-4">
                  2. How We Collect and Use Information
                </h3>
                <div className="subhead pl-4">
                  <ul>
                    <li>
                      <span>Log Files.</span> Any time you visit any of our
                      websites, our servers automatically gather information
                      from your browser (such as your IP addresses, browser
                      type, Internet service provider (ISP), referring/exit
                      pages, platform type, date/time stamp, and number of
                      clicks) to analyze trends, administer the site, prevent
                      fraud, track visitor movement in the aggregate, and gather
                      broad demographic information. For example, we may log
                      your IP address for system administration purposes. IP
                      addresses are logged to track a user’s session. This gives
                      us an idea of which parts of our site users are visiting.
                      We do not share the log files externally.{' '}
                    </li>
                    <li>
                      <span>Cookies.</span> We use “cookies” to keep track of
                      some types of information while you are visiting our
                      website or using our services. Cookies are very small
                      files placed on your computer, and they allow us to count
                      the number of visitors to our website and distinguish
                      repeat visitors from new visitors. They also allow us to
                      save user preferences and track user trends. We rely on
                      cookies for the proper operation of our website;
                      therefore, if your browser is set to reject all cookies,
                      the website will not function properly. Users who refuse
                      cookies assume all responsibility for any resulting loss
                      of functionality. We do not link the cookies to any PII.
                    </li>
                    <li>
                      <span>Web Beacons.</span> “Web beacons” (also known as
                      “clear gifs” and “pixel tags”) are small transparent
                      graphic images that are often used in conjunction with
                      cookies in order to further personalize our website for
                      our users and to collect a limited set of information
                      about our visitors. We may also use web beacons in email
                      communications in order to understand the behavior of our
                      customers. We do not link the web beacons to any PII.
                    </li>
                    <li>
                      <span>Mobile Analytics.</span> We use mobile analytics
                      software to allow us to better understand the
                      functionality of our mobile software on your phone. This
                      software may record information such as how often you use
                      the application, the events that occur within the
                      application, aggregated usage, performance data, and where
                      the application was downloaded from. We do not link the
                      information we store within the analytics software to any
                      PII you submit within the mobile application.{' '}
                    </li>
                    <li>
                      <span>Information About You.</span> When you register or
                      subscribe to any of our services, we collect a wide
                      variety of information about you. For our Singles service,
                      for example, you provide us with answers to our
                      proprietary questionnaire, and we use your answers to
                      build a profile for you and to match you with other
                      compatible singles. Your individual responses to the
                      questions about your personality in our questionnaire will
                      be held strictly confidential, while other non-PII about
                      yourself (such as first name, occupation, height, etc.)
                      will be used to build your &quot;My Profile.&quot; We may
                      also display your marital status and most recent log-in
                      date and other profile/account information to your
                      matches. Finally, for interactive content and community
                      services such as Advice where you may choose to have a
                      public profile, we may display your information to other
                      community members and visitors. You may choose to provide
                      us with your photo(s) or video(s) and by providing us such
                      content, you agree that we may make them available to your
                      matches and users of Lovrican’s services. Except as
                      otherwise stated in this privacy statement, we do not
                      disclose your PII to your matches or other users of our
                      services.
                    </li>
                    <li>
                      <span>Purchase Information.</span> To process purchases, we may require your name, address, phone number, email address and credit card information. Such information is used primarily to process your order or as otherwise described herein. Credit card transactions are processed through industry standard Secure Socket Layer (SSL) and/or strong encryption (3DES) by a third party, which only uses your information for that purpose. By paying with your card, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at PayPal.com
                    </li>
                    <li>
                      <span>E-mails and Telephone Calls.</span> We require an
                      e-mail address from you when you register for our
                      services. We use your e-mail for both transactional (e.g.,
                      order confirmation, refund and renewal processing,
                      notifying of matches for our Singles service) and
                      promotional (e.g., newsletters, new product offerings,
                      special discounts, event notifications, special
                      third-party offers) purposes. E-mail messages we send you
                      may contain code that enables our database to track your
                      usage of the e-mails, including whether the e-mail was
                      opened and what links (if any) were clicked. If you would
                      rather not receive promotional e-mails from us, please see
                      the section below labeled “Choice/Opt-Out”. We reserve the
                      right to send you certain communications relating to the
                      Lovrican services, such as service announcements and
                      administrative messages, without offering you the
                      opportunity to opt out of receiving them. We may also
                      contact you by telephone or text message (including to any
                      wireless number you may provide to us) solely in
                      connection with Lovrican’s services. If you would rather
                      not receive telephone calls or text messages from us, you
                      may change or delete your number from your account
                      preferences page(s), or ask to be removed from our contact
                      list if you receive a call or text message from us. We
                      fully comply with the requirements of the U.S. CAN-SPAM
                      Act.{' '}
                    </li>
                    <li>
                      <span>Mobile Device.</span> If you use a mobile device to
                      access our site or download any of our applications, we
                      may collect device information (such as your mobile device
                      ID, model and manufacturer), operating system and version
                      information, and IP address. We collect your geolocational
                      information only upon your consent.{' '}
                    </li>
                    <li>
                      <span>Demographic Data.</span> Demographic data is also
                      collected at our site. We use this data to tailor our
                      visitors’ experience at our site, showing them content
                      that we think they might be interested in, and displaying
                      the content according to their preferences. Some of this
                      information may be shared with advertisers on a
                      non-personally identifiable basis.
                    </li>
                    <li>
                      <span>Online Survey Data.</span> We may periodically
                      conduct voluntary member surveys. We encourage our members
                      to participate in such surveys because they provide us
                      with important information regarding the improvement of
                      our services. You may also volunteer for certain surveys
                      that we may offer to our users, and any additional rules
                      regarding the conduct of such surveys will be disclosed to
                      you prior to your participation. We do not link the survey
                      responses to any PII, and all responses are anonymous.{' '}
                    </li>
                    <li>
                      <span>Information Regarding Your Friends.</span> We
                      encourage you to refer a friend to our Singles service by
                      sending us a friend’s name and email address. We will keep
                      this information in our database, and send that person a
                      one-time e-mail containing your name and inviting them to
                      visit our site. This e-mail will also include instructions
                      on how to remove their information from our database. You
                      agree that you will not abuse this feature by entering
                      names and addresses of those who would not be interested
                      in Lovrican’s services. For some of our services, you may
                      also give gift memberships to your friends, in which case
                      we will use your friends’ e-mail address that you provide
                      in order to deliver the gift.
                    </li>
                    <li>
                      <span>Public Forums.</span> Our Advice blog contains posts
                      which are relevant to dating and relationships and is
                      available to our users and visitors. Any information that
                      is disclosed in the comments section of any Advice post
                      becomes public information and you should exercise caution
                      when deciding to disclose any PII. Please consult the
                      community guidelines for each Lovrican discussion board
                      when posting comments on them for other specific
                      information. To request removal of your personal
                      information from our blog, contact us at{' '}
                      <a href="mailto:contentalert@lovrican.com">
                        ContentAlert@Lovrican.com
                      </a>
                      . In some cases, we may not be able to remove your
                      personal information, in which case we will let you know
                      if we are unable to do so.{' '}
                    </li>
                    <li>
                      <span>Use for Research.</span> In addition to the uses
                      outlined above, by using our site, you agree to allow us
                      to anonymously use the information from you and your
                      experiences to continue our research into successful
                      relationships. This research, conducted by psychologists
                      and behavior research scientists, may be published in
                      academic journals. However, all your responses will be
                      kept anonymous, and no PII will be published.
                    </li>
                  </ul>
                </div>
                <h3 className="subhead pt-4">
                  3. Mobile Device Additional Terms
                </h3>
                <div className="subhead pl-4">
                  <ul>
                    <li>
                      <span>Mobile Device.</span> If you use a mobile device to
                      access our site or download any of our applications, we
                      may collect device information (such as your mobile device
                      ID, model and manufacturer), operating system and version
                      information, and IP address.{' '}
                    </li>
                    <li>
                      <span>Geo-Location Information.</span> We do not ask you
                      for, access or track any location-based information from
                      your mobile device at any time while downloading or using
                      our Mobile Apps or services.{' '}
                    </li>
                    <li>
                      <span>Push Notifications.</span> We send you push
                      notifications if you choose to receive them for notifying
                      you of matches for our singles service, letting you know
                      when someone has sent you a message, or for other
                      service-related matters. If you wish to opt-out from
                      receiving these types of communications you may turn them
                      off at the device level.{' '}
                    </li>
                  </ul>
                </div>
                <h3 className="subhead pt-4">
                  4. Disclosure of Your Information
                </h3>
                <div className="subhead pl-4">
                  <ul>
                    <li>
                      <span>Disclosure to Your Matches.</span> As an integral
                      part of providing the Lovrican service, we will disclose
                      your profile information to your matches. Photos and other
                      profile information posted by you will be available to
                      subscribers that are matched with you. We may also make
                      features available enabling your matches to share your
                      profile information, including photos, with members of
                      their social network(s). We never share your contact
                      information with your matches.
                    </li>
                    <li>
                      <span>Disclosure by Law.</span> You acknowledge and agree
                      that we may disclose information you provide if required
                      to do so by law, at the request of a third party, or if
                      we, in our sole discretion, believe that disclosure is
                      reasonable to (1) comply with the law, requests or orders
                      from law enforcement, or any legal process (whether or not
                      such disclosure is required by applicable law); (2)
                      protect or defend Lovrican’s, or a third party’s, rights
                      or property; or (3) protect someone’s health or safety,
                      such as when harm or violence against any person
                      (including you) is threatened.{' '}
                    </li>
                    <li>
                      <span>Disclosure to Protect Abuse Victims.</span>{' '}
                      Notwithstanding any other provision of this privacy
                      statement or our Terms and Conditions of Service, we
                      reserve the right, but have no obligation, to disclose any
                      information that you submit to the services, if in our
                      sole opinion, we suspect or have reason to suspect, that
                      the information involves a party who may be the victim of
                      abuse in any form. Abuse may include, without limitation,
                      elder abuse, child abuse, spousal abuse, neglect, or
                      domestic violence. Information may be disclosed to
                      authorities that we, in our sole discretion, deem
                      appropriate to handle such disclosure. Appropriate
                      authorities may include, without limitation, law
                      enforcement agencies, child protection agencies, or court
                      officials. You hereby acknowledge and agree that we are
                      permitted to make such disclosure.
                    </li>
                    <li>
                      <span>Disclosure to Trusted Third Parties By Us.</span> We
                      may share your non-PII with third parties, but not in a
                      manner that would reveal your identity. We may share your
                      PII, sometimes in conjunction with your non-PII, with
                      service providers that may be associated with us to
                      perform functions on our behalf. For example, outsourced
                      customer care agents or technology assistants may need
                      access to your information to perform services for you.
                      Your information will be treated as private and
                      confidential by such service providers and not used for
                      any other purpose than we authorize. In addition, from
                      time to time, we may share PII (such as e-mail or mailing
                      address) about our user base with carefully selected third
                      parties, so they can offer goods and services that we
                      believe may be of interest to our users. If you do not
                      wish to receive offers from our trusted partners, you can
                      change your e-mail preferences at any time by following
                      the steps outlined in the “Choice/Opt-Out” section below.
                    </li>
                    <li>
                      <span>
                        Disclosure to Trusted Third Parties at Your Request.
                      </span>{' '}
                      When purchasing a Lovrican subscription or while visiting
                      our site, we may provide you with advertisements,
                      promotions, sweepstakes and offers from third party
                      providers. If you choose to accept any such offers, you
                      may either (depending on the situation) directly provide
                      your information in connection with the offer to such
                      third-party provider, or we will provide your information,
                      including billing information, to the third party provider
                      by pre-populating the offer registration forms. Your
                      information will not be transferred until you actually
                      accept the offer. You may opt-out of the offer at any time
                      up until this point. Please refer to the third-party
                      provider’s own privacy policy (provided on the offer
                      pages) if you have any questions regarding how your
                      information is used by such providers. Please be aware
                      that these offers may be presented on pages framed by an
                      Lovrican website. We do this to provide a seamless
                      experience. Although these offer pages have the look and
                      feel of our websites, you will be submitting your
                      information directly to the third-party advertiser. You
                      agree that we will not be responsible or liable for any
                      loss or damage of any sort incurred as the result of any
                      such dealings, including the sharing of the information
                      you supply to us with third party providers described
                      here, or as the result of the presence of such providers
                      on an Lovrican site.
                    </li>
                    <li>
                      <span>Search Engine.</span> Some portions of your profile
                      may be available to search engine crawlers and therefore
                      may appear in internet search results, but no PII (e.g.,
                      last name, photos) will be included in such search
                      results. Because your submissions to the About Me sections
                      of your profile may be publicly available, we strongly
                      encourage you not to disclose PII or contact information
                      in such areas of your profile.
                    </li>
                    <li>
                      <span>
                        Information Transferred As a Result of Sale of Business.
                      </span>{' '}
                      As we develop our business, we may buy or sell assets and,
                      depending on the transaction, your PII may be one of the
                      transferred assets. In the event that we are acquired by
                      another company, your PII may be part of the assets
                      transferred to the acquiring party. If this occurs, you
                      will be notified if there are any material changes to the
                      way your PII is collected or used.
                    </li>
                  </ul>
                </div>
                <h3 className="subhead pt-4">5. Third-Party Advertising</h3>
                <div className="subhead pl-4">
                  <p>
                    We may use third-party advertising agencies or other service
                    providers to serve ads on our website on behalf of Lovrican
                    or third-party advertisers (e.g., retailers of goods or
                    services). These companies may employ cookies and web
                    beacons to measure advertising effectiveness. Any
                    information that these third parties collect via cookies and
                    web beacons is completely anonymous.{' '}
                  </p>
                  <p>
                    Third Parties, with whom we partner to display advertising
                    based upon your web browsing activity, use Flash cookies to
                    collect and store information. Flash cookies are different
                    from browser cookies because of the amount of, type of, and
                    how data is stored. Cookie management tools provided by your
                    browser will not remove Flash cookies.{' '}
                  </p>
                  <p>
                    We may share PII you have submitted to us (your e-mail
                    address) in hashed format with trusted third parties for the
                    purpose of matching it to hashed values of the same PII that
                    you volunteer when you visit other first party websites.
                    Coded data from this &quot;match&quot; may be placed into a
                    cookie that would allow these first party websites to serve
                    online display ads to you as you surf the Web.&nbsp;{' '}
                  </p>
                  <p>
                    We will only share your information with third parties in
                    the ways defined in this privacy statement.
                  </p>
                </div>
                <h3 className="subhead pt-4">6. Age Restrictions</h3>
                <div className="subhead pl-4">
                  <p>
                    Our age restrictions for different services are set forth in
                    each applicable Terms of Service. We do not target our sites
                    or services to children under the age of 13, and in
                    compliance with the Children’s Online Privacy Protection
                    Act, any information we may receive from users we believe to
                    be under the age of 13 will be purged from our database.
                  </p>
                </div>
                <h3 className="subhead pt-4">7. Security</h3>
                <div className="subhead pl-4">
                  <p>
                    We have extensive security measures in place to protect the
                    loss, misuse and alteration of the information stored in our
                    database. These measures include the use of Secure Socket
                    Layer (SSL) and/or strong encryption (3DES) technology
                    during credit card transactions and administrative access to
                    site data, as well as other proprietary security measures
                    which are applied to all repositories and transfers of user
                    information. We will exercise reasonable care in providing
                    secure transmission of information between your computer and
                    our servers, but given that no information transmitted over
                    the Internet can be guaranteed 100% secure, we cannot ensure
                    or warrant the security of any information transmitted to us
                    over the Internet and hence accept no liability for any
                    unintentional disclosure.
                  </p>
                </div>
                <h3 className="subhead pt-4">
                  8. Links to or Access from Other Sites
                </h3>
                <div className="subhead pl-4">
                  <p>
                    You may be able to access your account or content of your
                    account from third party sites, such as social networking
                    sites, by way of various applications. The privacy policies
                    and practices of such sites in connection with information
                    you disclose on such sites may differ from the practices of
                    Lovrican as set forth in this privacy statement, and you
                    should review their policies and practices to ensure that
                    the privacy of the information you submit on their site does
                    not conflict with and is consistent with how you wish your
                    information to be treated. Such sites may also place their
                    own cookies or other files on your computer, collect data or
                    solicit personal information from you. We are not
                    responsible for the privacy policies or practices or the
                    content of any other websites that may provide access to, or
                    be linked to or from, this site, including that of any
                    social networking sites and third-party advertisers whose
                    offerings require you to navigate away from our site.
                  </p>
                </div>
                <h3 className="subhead pt-4">9. Choice/Opt-Out</h3>
                <div className="subhead pl-4">
                  <p>
                    You may use the following options for removing your
                    information, including an unauthorized profile, from our
                    e-mail database if you wish to opt out of receiving
                    promotional e-mails and newsletters.
                  </p>
                  <ol type="number">
                    <li>
                      Click on the &quot;unsubscribe&quot; link on the bottom of
                      the e-mail;
                    </li>
                    <li className="lovrican-llc">
                      Send mail to the following postal address letting us know
                      which promotional e-mails you wish to opt-out of:
                      <p className="m-0">Lovrican, LLC.</p>
                      <p className="m-0">P.O. Box 437</p>
                      <p className="m-0">Raritan, NJ, 08869 USA</p>
                    </li>
                    <li>
                      For any services that allow you to control which e-mails
                      you receive, go to the e-mail settings page from your
                      account home page, and un-check the undesired promotions.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">10. Updating Your Information</h3>
                <div className="subhead pl-4">
                  <p>
                    Lovrican provides users the following options for changing
                    and modifying information previously submitted, including
                    deletion of your personal information.
                  </p>
                  <ol type="number">
                    <li>
                      For any services where you have an account page, you may
                      change your credit card (if applicable), e-mail address,
                      password and other information from the account settings
                      or preferences page; or
                    </li>
                    <li>
                      You can send mail to the postal address listed above,
                      providing us with your account information and letting us
                      know which information you wish to update.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">11. Data Retention</h3>
                <div className="subhead pl-4">
                  <p>
                    Because many users tend to use our service at different
                    points of their relationship-seeking life, we retain your
                    personal information for continued service and convenience
                    purposes until you advise us not to do so. Notwithstanding
                    the foregoing, we may retain personal information in order
                    to comply with applicable laws, keep accurate records,
                    resolve disputes, prevent fraud, enforce our Terms and
                    Conditions of Service or other agreements, or for any other
                    legally permissible purpose.
                  </p>
                </div>
                <h3 className="subhead pt-4">12. Contacting the Website</h3>
                <div className="subhead pl-4">
                  <p>
                    If you have any questions about this privacy statement, the
                    practices of this site, or your dealings with any Lovrican
                    websites, you can contact:
                  </p>
                  <div className="center-small">
                    <p className="m-0">Privacy Policy</p>
                    <p className="m-0">Lovrican, LLC.</p>
                    <p className="m-0">P.O. Box 437</p>
                    <p className="m-0">Raritan, NJ, 08869 USA</p>
                  </div>
                </div>
                <h3 className="subhead pt-4">13. Dispute Resolution</h3>
                <div className="subhead pl-4">
                  <p>
                    If you have an unresolved privacy or data use concern that
                    we have not addressed satisfactorily, please contact us at:
                  </p>
                  <div className="center-small">
                    <p className="m-0">Privacy Policy</p>
                    <p className="m-0">Lovrican, LLC.</p>
                    <p className="m-0">P.O. Box 437</p>
                    <p className="m-0">Raritan, NJ, 08869 USA</p>
                  </div>
                </div>
                <h3 className="subhead pt-4">
                  14. Acceptance of Privacy Statement
                </h3>
                <div className="subhead pl-4">
                  <p>
                    Your use of our website(s), including any dispute concerning
                    privacy, is subject to this privacy statement and the
                    applicable Terms and Conditions of Service. BY USING OUR
                    WEBSITE, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS
                    PRIVACY STATEMENT AND THE APPLICABLE TERMS AND CONDITIONS OF
                    SERVICE.
                  </p>
                  <p>
                    If we decide to change our privacy policy, we will post
                    those changes to this privacy statement page and any other
                    places we deem appropriate so that you are aware of what
                    information we collect, how we use it, and under what
                    circumstances, if any, we disclose it. We reserve the right
                    to modify this privacy statement at any time, so please
                    review it frequently. If we make material changes to this
                    policy, we will notify you here, or by other means, such as
                    e-mail, at our discretion.
                  </p>
                  <p>
                    Your continued use of any portion of our website following
                    posting of the updated privacy statement will constitute
                    your acceptance of the changes.
                  </p>
                </div>
                <h3 className="subhead pt-4">15. Effective Date</h3>
                <div className="subhead pb-4 mb-4">
                  <p className="pb-4 mb-4">
                    This privacy statement was last revised on March 1, 2022.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicyPage;
