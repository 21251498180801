import { React, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmEmailPopup = ({
  modalStatus,
  handleClose,
  handleConfirm,
  affiliate
}) => {
  useEffect(() => {
    setTimeout(() => {
      handleConfirm();
    }, 3000);
  }, []);

  return (
    <Modal
      className="filter-model confirmEmail-model confirm_email"
      show={modalStatus}
      onHide={handleClose}
      centered
      // backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          <h5 className="modal-title w-100">Confirm Email</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!affiliate && (
          <>
            <p>We have sent a One Time Passcode (OTP) to your email.</p>
            <p>Kindly utilize this OTP to verify and confirm your email.</p>
          </>
        )}
        {affiliate && (
          <>
            <p>
              We just sent you an email with One Time Passcode (OTP). Use that
              OTP code to confirm your email.
            </p>
            <p>
              We recommend that you confirm your email today.
              <br />
              Note: to prevent fake accounts, all emails must be confirmed
              within 3 days.
            </p>
          </>
        )}
        {/* <div className="text-center">
          <button
            type="button"
            className="btn theme-btn themebtn2"
            onClick={() => handleConfirm()}
          >
            Continue
          </button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmEmailPopup;
