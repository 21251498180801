import React from 'react';
import { Modal } from 'react-bootstrap';

const PlanMoreInfoModalComponent = (props) => {
    const { show, handelClose } = props;

    return (
        <>
            <Modal
                className="filter-model PlanStatusModel new_plan_status"
                show={show}
                onHide={handelClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100">
                        {/* <h5 className="modal-title text-center w-100">Modal Title Here</h5> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='scroll'>
                    <p className='color'><span>Our Mission</span></p>
                    <p>We started Lovrican to serve our community. We built 
Lovrican to bring together our young men and women so 
that they more conveniently find love, happiness, and 
career success - enabling them to build strong and happy 
families with a life partner who shares their culture, faith, 
values, and aspirations. That is why we charge a modest 
fee, despite our premium service. Your payment and 
support enables us to sustain Lovrican for the long term to 
best serve you and future generations of Nigerians in America</p>
<p className='color'><span>Our cost Structure</span></p>
<p>We strive be efficient in all we do. It helps us to keep our 
costs low. Still, there is a cost to deliver the service. We 
keep a dedicated, highly capable, and passionate team of 
designers, developers, support team, who build, support 
and continually enhance the product. We must also pay 
fees to our service providers - web hosting providers, 
micro-services providers, encryption certificate providers, 
and so on to keep our services running.</p>
<p className='color'><span>Our Pricing</span></p>
<p>We keep our pricing simple. You pay $9.99 per month.</p>
<p>If you have any questions on pricing (or other matters) 
please do not hesitate to contact our support team:  <span>support@lovrican.com</span></p>
</div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PlanMoreInfoModalComponent;