import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logoWhite from '../../../assets/images/Logo.svg';

const FaliureDetailsComponent = () => {
  const history = useHistory();
  const { search } = useLocation();
  const handleClick = () => {
    const queryParams = new URLSearchParams(search);
    const systemOrderId = queryParams.get('sysorderid');
    const tokenId = queryParams.get('token');
    history.push(
      `/gift?status=fail&sysorderid=${systemOrderId}&token=${tokenId}`
    );
  };
  return (
    <>
      <div className="giftWrapper succesfull failed">
      <div className="gift-logo">
          <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="innerWrapper">
              <div className="col-12">
                <h1>Your Payment has Failed!</h1>
                

                <p>
                Unfortunately, we are unable to process your payment. Kindly check your payment details and try again.
                </p>
                <p>
                If the issue persists, feel free to contact our support team at: support@lovrican.com
                </p>
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-orange btn-large"
                  aria-hidden="true"
                  onClick={handleClick}
                >
                  Try Again
                  {/* Meet Your Matches */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaliureDetailsComponent;
