import React, { useState, useEffect } from 'react';
import moment from 'moment';
import readmore from '../../../../assets/images/affiliate/read-more.svg';
import DatepickerInput from '../../../general/datepickerInput';
import { authCall, fetchData } from '../../../../services';

const MembershipSold = () => {
  const [membership, setMembership] = useState({});
  const [referalList, setReferalList] = useState([]);
  const [planEarning, setPlanEarning] = useState([]);
  const [filter, setFilter] = useState('all');
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offset, setOffset] = useState(0);
  const limit = 2;

  const getAllPayments = async (condi) => {
    setLoadMoreLoading(true);
    const resp2 = await authCall('affiliate/getPaidReferals', condi);
    if (resp2 && resp2?.success) {
      const newItems = resp2?.data;
      if (newItems.length < limit) {
        setIsLoadMoreDisabled(true);
      }

      if (offset === 0) {
        setReferalList(newItems);
      } else {
        setReferalList([...referalList, ...newItems]);
      }

      setOffset(offset + limit);

      setLoadMoreLoading(false);
    }
  };

  useEffect(async () => {
    const resp = await fetchData('affiliate/membershipSoldDetails');
    if (resp && resp?.success) {
      setMembership(resp?.data);
      setPlanEarning(resp?.data?.plansEarnings || []);
    }
  }, []);

  const loadMoreItems = () => {
    getAllPayments({
      filterType: filter,
      startDate,
      endDate,
      limit,
      offset
    });
    setIsLoadMoreDisabled(false);
  };

  useEffect(() => {
    loadMoreItems();
  }, [filter, startDate, endDate]);

  const handleClickFilter = (sel) => {
    setReferalList([]);
    setOffset(0);
    setFilter(sel);
    setIsCustomRange(false);
  };

  const handleChangeFromDate = (date) => {
    const dateFormate = moment(date).format('YYYY-MM-DD');
    setStartDate(dateFormate);
    setReferalList([]);
    setOffset(0);
    setFilter('custom');
  };

  const handleChangeToDate = (date) => {
    const dateFormate = moment(date).format('YYYY-MM-DD');
    setEndDate(dateFormate);
    setReferalList([]);
    setOffset(0);
    setFilter('custom');
  };

  return (
    <>
      <div className="membership_solid">
        <div className="earning_wrap">
          <div className="total_ref">
            <p>Total Referals</p>
            <div className="count">{membership.totalReferals}</div>
          </div>
          <div className="divider" />
          <div className="total_ref">
            <p>Total Earnings</p>
            <div className="count">${membership.totalEarnings}</div>
          </div>
        </div>

        <div className="plan_wrap">
          {planEarning.map((list) => {
            let color = 'gray';
            if (list.plan === 'Gold') {
              color = 'yellow';
            } else if (list.plan === 'Diamond') {
              color = 'purple';
            } else if (list.plan === 'Platinum') {
              color = 'green';
            }
            // else if (list.plan === 'Platinum') {
            //   color = 'silver';
            // }

            return (
              <div className={`box ${color}`} key={list.id}>
                <div className="left">
                  <div className="plan">{list.plan} Plan </div>
                 
                </div>
                <div className='center text-center'>
                <div className="price">{list.count}</div>
                <div className="sub">Sold</div>
                </div>
                <div className="right">
                  <div className="plan">
                    <i className="fa fa-caret-down" aria-hidden="true" /> 100%
                  </div>
                  <div className="price">${list.totalEarnings.toFixed(2)}</div>
                  <div className="sub">Earned</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="affiliate_radio_wrapper">
          <div className="customeRadio_Inner ">
            <div className="row left_right">
              <div className="col-md-4 mr-0 ">
                <span>
                  <input
                    type="radio"
                    id="id1"
                    name="radio-group"
                    onChange={() => handleClickFilter('all')}
                    checked={filter === 'all' || false}
                  />
                  <label htmlFor="id1">All</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                {' '}
                <span>
                  <input
                    type="radio"
                    id="id2"
                    name="radio-group"
                    onChange={() => handleClickFilter('currentWeek')}
                  />
                  <label htmlFor="id2">This Week</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                {' '}
                <span>
                  <input
                    type="radio"
                    id="id3"
                    name="radio-group"
                    onChange={() => handleClickFilter('currentMonth')}
                  />
                  <label htmlFor="id3">This Month</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                <span>
                  <input
                    type="radio"
                    id="id4"
                    name="radio-group"
                    onChange={() => handleClickFilter('previousYear')}
                  />
                  <label htmlFor="id4">Previous Year</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                <span>
                  <input
                    type="radio"
                    id="id5"
                    name="radio-group"
                    onChange={() => handleClickFilter('currentYear')}
                  />
                  <label htmlFor="id5">Current Year</label>
                </span>
              </div>
              <div className="col-md-4 mr-0">
                <span>
                  <input
                    type="radio"
                    id="id6"
                    name="radio-group"
                    onChange={() => {
                      setIsCustomRange(true);
                      setStartDate('');
                      setEndDate('');
                    }}
                  />
                  <label htmlFor="id6">Specify Range</label>
                </span>
              </div>
            </div>
          </div>
        </div>

        {isCustomRange && (
          <div className="affilate_custom_field_wrapper">
            <div className="row">
              <div className="col-3 border-custom">
                <div className="d-flex align-items-end">
                  {/* <div className='icon'>s</div> */}
                  <div className="field w-100">
                    <DatepickerInput
                      placeholderText="From"
                      value={startDate ? new Date(startDate) : null}
                      onChange={handleChangeFromDate}
                    />
                  </div>
                </div>
              </div>
              <div className="col-1" />
              <div className="col-3 border-custom">
                <div className="d-flex align-items-end">
                  {/* <div className='icon'>s</div> */}
                  <div className="field w-100">
                    <DatepickerInput
                      placeholderText="To"
                      value={endDate ? new Date(endDate) : null}
                      onChange={handleChangeToDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row aff_table_wrapper mb-4">
          <div className="col-12">
            <div className="table_heading">Paid Referalls</div>
            <div className="table-responsive ">
              <table
                className={`table redmore_table ${
                  !isLoadMoreDisabled ? 'blurOuter' : ''
                }`}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Country</th>
                    <th>Earned</th>
                  </tr>
                </thead>
                <tbody>
                  {referalList.map((list) => (
                    <tr key={list.id}>
                      <td>{moment(list.createdAt).format('MMM, DD, YYYY')}</td>
                      <td>{list?.referals?.firstCharOfName}***</td>
                      <td>
                        <span>${list.amount}</span>
                      </td>
                      <td>{list?.referals?.countryLabel}</td>
                      <td>
                        <span>${list.earning}</span>
                      </td>
                    </tr>
                  ))}
                  {referalList.length < 1 && (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {!isLoadMoreDisabled && (
            <div className="col-12 mt-2 text-center">
              {!loadMoreLoading && (
                <button
                  type="button"
                  className="btn mb-3 p-0"
                  onClick={loadMoreItems}
                >
                  <img src={readmore} className="img-fluid" alt="arrow icon" />
                </button>
              )}
              {loadMoreLoading ? 'Loading...' : ''}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MembershipSold;
