import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import eye from '../../assets/images/eye.svg';
import webServices from '../../services/webServices';
import { authCall } from '../../services';

const ChangeEmailSetting = ({ parentCallBackWhenUpdate, userSettings }) => {
  const [show, setShow] = useState({
    status: false,
    email: '',
    primary: false
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpSentEmailVerification, setIsOtpSentEmailVerification] =
    useState(false);
  const [verifiyingEmail, setVerifiyingEmail] = useState('');

  const [values, setValues] = useState({
    newEmail: '',
    password: '',
    otp: '',
    isError: false
  });

  const [formValidation, setFormValidation] = useState({
    newEmail: '',
    password: '',
    isError: false
  });

  const handleValidation = () => {
    let isError = false;
    const formerr = { ...formValidation };

    if (!values.newEmail) {
      isError = true;
      formerr.newEmail = 'The email field is required.';
      setFormValidation(formerr);
    }

    if (!values.password) {
      isError = true;
      formerr.password = 'The password field is required.';
      setFormValidation(formerr);
    }

    return isError;
  };

  const handleChange = (props) => (e) => {
    setFormValidation({ ...formValidation, [props]: '' });
    setValues({ ...values, [props]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) return false;
    (async () => {
      const data = {
        newEmail: values.newEmail,
        password: values.password
      };
      const response = await webServices.addEmail(data);
      if (response && response.success) {
        toast.dismiss();
        toast.success(response.message);
        setIsOtpSent(true);
      }
    })();
    return null;
  };

  const [emails, setEmails] = useState([]);
  useEffect(async () => {
    setEmails(userSettings?.email_list || []);
  }, [userSettings]);

  const otpChange = (otpInner) => {
    setValues({ ...values, otp: otpInner });
    setTimeout(() => {
      const values1 = Array.from(
        document.querySelectorAll('.inputStyleNew')
      ).map((ele) => {
        if (ele.value) {
          ele.className = 'inputStyleNew focus-color'; // eslint-disable-line no-param-reassign
        }
        return ele.value;
      });
      /* eslint-disable no-console */
      console.log('Inputs Values : ', values1);
      /* eslint-enable no-console */
    }, 100);
  };

  const handleOtpSubmit = async () => {
    if (values.otp.length === 4 && values.newEmail) {
      const response = await webServices.otpVerifyForAddEmail(values);
      if (response && response.success) {
        toast.dismiss();
        toast.success(response.message);
        const reset = { ...values };
        reset.password = '';
        reset.newEmail = '';
        reset.otp = '';
        setValues(reset);
        setIsOtpSent(false);
        parentCallBackWhenUpdate('updated');
      }
    } else {
      toast.error('Invalid otp');
    }
  };

  const handleFinalClick = async () => {
    if (show.email) {
      let updateValue = {};
      let msg = '';
      if (show.primary) {
        emails.map((obj) => {
          /* eslint no-param-reassign: "error" */
          if (show.email === obj.email) {
            obj.primary = true;
          } else {
            obj.primary = false;
          }
          return obj;
        });
        updateValue = {
          primary_email: show.email,
          email_list: emails
        };
        msg = 'Your primary email has been updated successfully!';
      } else {
        const finalEmailList = emails.filter((obj) => obj.email !== show.email);
        updateValue = {
          remove_email: finalEmailList
        };
        msg = 'Email removed successfully!';
      }

      const response = await webServices.updateUserSettings(updateValue);
      if (response && response.success) {
        toast.dismiss();
        toast.success(msg);
        parentCallBackWhenUpdate('updated');
        setShow({ ...show, status: false, email: '', primary: false });
      }
    }
  };

  // verify email
  const handleClickEmailVerify = async (email) => {
    if (email) {
      setVerifiyingEmail(email);
      const resendOtpUrl = 'auth/action/email-verification-otp';
      const resp = await authCall(resendOtpUrl, {
        email
      });
      if (resp && resp.success === true) {
        // toast.dismiss();
        // toast.success(response.message);
        setIsOtpSentEmailVerification(true);
      }
    }
  };

  const handleOtpSubmitEmailVerify = async () => {
    if (values.otp.length === 4 && verifiyingEmail) {
      const resendOtpUrl = 'auth/action/verify-email-otp-for-password';
      const resp = await authCall(resendOtpUrl, {
        email: verifiyingEmail,
        otp: values.otp
      });
      if (resp && resp.success === true) {
        setIsOtpSentEmailVerification(false);
        parentCallBackWhenUpdate('updated');
        toast.dismiss();
        toast.success(`Congratulations!
        Your email has been successfully verified.`);
      }
    } else {
      toast.error('Invalid otp');
    }
  };
  // end verify email

  const checkPrimaryVerified = (email, verified) => {
    if (!verified) {
      toast.dismiss();
      toast.error('To set your email as primary, you need to verify it first.');
    } else {
      setShow({
        ...show,
        status: true,
        email,
        primary: true
      });
    }
  };

  return (
    <>
      <div className="card-box pt-3">
        <div className="row settingEmail pb-3">
          <div className="w-100 d-block">
            <label htmlFor="label">Change email</label>
          </div>
          {!!emails.length &&
            emails.map((emailObj) => (
              <div className="col-12 pl-0 pr-0">
                <div className="row emailChange" key={emailObj.email}>
                  <div className="col-9">
                    <div className="mt-1 mb-0 position-relative">
                      {' '}
                      <a type="button" href="#0">
                        {emailObj.email}
                      </a>
                      {!emailObj.primary && (
                        <i
                          className="fa fa-times ml-2 email-verification-delete"
                          aria-hidden="true"
                          onClick={() =>
                            setShow({
                              ...show,
                              status: true,
                              email: emailObj.email,
                              primary: false
                            })
                          }
                        />
                      )}
                      <br />
                    </div>
                  </div>
                  <div className="col-3">
                    {!emailObj?.verify ? (
                      <button
                        type="button"
                        className="verify purple"
                        onClick={() => handleClickEmailVerify(emailObj.email)}
                      >
                        Verify
                      </button>
                    ) : (
                      <button type="button" className="verify green">
                        <i className="fa fa-check mr-1" />
                        Verified
                      </button>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="mt-0 mb-4">
                      {emailObj?.primary ? (
                        <div className="d-flex align-items-center active">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          />
                          <span>Primary</span>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center deactive">
                          <div
                            aria-hidden="true"
                            onClick={() =>
                              checkPrimaryVerified(
                                emailObj.email,
                                emailObj.verify
                              )
                            }
                          >
                            <i
                              className="fa fa-circle-o plan"
                              aria-hidden="true"
                            />
                            <span className="plan">Make Primary</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* email verification section */}
          {isOtpSentEmailVerification && (
            <div className="card-box pt-3">
              <div className="row settingEmail">
                <div className="">
                  <div className="headOtp">
                    Enter the 4 digit code that we just sent to your email
                    address
                  </div>
                </div>
                <div className="row w-100 settingOtp">
                  <div className="col-12 ">
                    <OtpInput
                      value={values.otp}
                      onChange={(otpInner) => otpChange(otpInner)}
                      containerStyle="userOtpBbox"
                      // inputStyle="inputStyleNew"
                      shouldAutoFocus
                      className="inputStyleNew focus-color otpInput"
                      isInputNum
                      hasErrored={values.isError}
                      // isDisabled={ (otpAttamp > AppConstants.OTP_ATTEMP_LIMIT) }
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <p className="resendSetting">
                      Didn&lsquo;t get the code?{' '}
                      <span
                        onClick={() => handleClickEmailVerify(verifiyingEmail)}
                        aria-hidden="true"
                      >
                        Resend code
                      </span>{' '}
                    </p>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <span
                    className="round-button"
                    role="button"
                    onClick={handleOtpSubmitEmailVerify}
                    aria-hidden="true"
                  >
                    Verify
                  </span>
                </div>
              </div>
            </div>
          )}
          {/* end email verification section */}

          <div className="col-12 border-custom mb-4 mt-4">
            <div className="d-flex align-items-end password">
              <div className="field w-100">
                <input
                  type="text"
                  name="user-password"
                  id="user-password"
                  autoComplete="new-password"
                  placeholder="Enter your new email"
                  className="custom_input"
                  value={values.newEmail}
                  onChange={handleChange('newEmail')}
                />
              </div>
            </div>
          </div>
          <span className="validation-error">{formValidation.newEmail}</span>

          <div className="col-12 border-custom mb-4">
            <div className="d-flex align-items-end password">
              <div
                className="icon"
                style={{ cursor: 'pointer' }}
                aria-hidden="true"
              >
                <img src={eye} className="img-fluid mr-2" alt="" />
              </div>
              <div className="field w-100">
                <input
                  type="password"
                  name="user-password"
                  id="user-password"
                  autoComplete="new-password"
                  placeholder="Enter your current password"
                  className="custom_input"
                  value={values.password}
                  onChange={handleChange('password')}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && handleFormSubmit(event)
                  }
                />
              </div>
            </div>
          </div>
          <span className="validation-error">{formValidation.password}</span>

          <div className="col-12 pt-2 pl-0">
            {!isOtpSent && (
              <span
                className="search-btn "
                role="button"
                aria-hidden="true"
                onClick={handleFormSubmit}
              >
                <i className="fa fa-arrow-right" />
              </span>
            )}
          </div>
        </div>
      </div>

      {isOtpSent && (
        <div className="card-box pt-3">
          <div className="row settingEmail">
            <div className="">
              <div className="headOtp">
                Enter the 4 digit code that we just sent to your new email
                address
              </div>
            </div>
            <div className="row w-100 settingOtp">
              <div className="col-12 ">
                <OtpInput
                  value={values.otp}
                  onChange={(otpInner) => otpChange(otpInner)}
                  containerStyle="userOtpBbox"
                  // inputStyle="inputStyleNew"
                  shouldAutoFocus
                  className="inputStyleNew focus-color otpInput"
                  isInputNum
                  hasErrored={values.isError}
                  // isDisabled={ (otpAttamp > AppConstants.OTP_ATTEMP_LIMIT) }
                />
              </div>
              <div className="col-12 mt-2">
                <p className="resendSetting">
                  Didn&lsquo;t get the code?{' '}
                  <span onClick={handleFormSubmit} aria-hidden="true">
                    Resend code
                  </span>{' '}
                </p>
              </div>
            </div>
            <div className="col-12 p-0">
              <span
                className="round-button"
                role="button"
                onClick={handleOtpSubmit}
                aria-hidden="true"
              >
                Add new email
              </span>
            </div>
          </div>
        </div>
      )}

      <Modal
        className="alertPhoto"
        show={show.status}
        onHide={() =>
          setShow({ ...show, status: false, email: '', primary: false })
        }
        size="sm"
        centered
        // backdrop="static"
      >
        <Modal.Body className="text-center ">
          <p>
            {show.primary
              ? 'Confirm: Do you want this your primary email?'
              : 'Are you sure, you want to delete?'}
          </p>
          <div className="btn-group mr-2" role="group" aria-label="First group">
            <button
              type="button"
              className="btn btn-success"
              onClick={() =>
                setShow({
                  ...show,
                  status: false,
                  email: '',
                  primary: false
                })
              }
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleFinalClick}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeEmailSetting;
