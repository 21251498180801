import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import { authCall } from '../services';

const DailyLikeRemainderForRosePopup = ({
  modalStatus,
  handleClose,
  currentPlan,
  userDetails
}) => {
  const history = useHistory();

  const handleClickPlan = async () => {
    const resp = await authCall('user/userCheckPermission', {
      action: 'buyLike'
    });
    if (resp.data === 'profileNotApproved') {
      history.push({
        pathname: AppConstants.routes.congratulations
      });
    } else {
      history.push({
        pathname: AppConstants.routes.likesPlans
      });
    }
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Need a Like to send Roses
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="expireAlert d-none1">
            {currentPlan?.dailyLikeLimit} <br />
            <span>Likes left today</span>
          </div>

          <p>
            Hi {`${userDetails?.first_name || ''}`.trim()}! You have{' '}
            {currentPlan?.myRoses}{' '}
            {currentPlan?.myRoses < 2 ? 'rose' : 'roses'}{' '}
            available to send but {currentPlan?.dailyLikeLimit}{' '}
            {currentPlan?.dailyLikeLimit < 2 ? 'likes' : 'likes'} left for today!
            You need a like to send roses. You will get new free likes tomorrow.
            If you want to send the roses today, you can buy more likes.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleClickPlan()}
            >
              Buy More Likes
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleClose(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DailyLikeRemainderForRosePopup;
