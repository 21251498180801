import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { authCall, fetchData } from '../../../../services';
import AppConstants from '../../../../appConstants';

const MyEarnings = () => {
  const history = useHistory();
  const [amounts, setAmounts] = useState({});
  const [paymentList, setPaymentList] = useState([]);

  useEffect(async () => {
    const resp = await fetchData('affiliate/amounts');
    if (resp && resp?.success) {
      setAmounts(resp?.data);
    }
    const resp2 = await authCall('affiliate/allPayments', { limit: 5 });
    if (resp2 && resp2?.success) {
      setPaymentList(resp2?.data);
    }
  }, []);

  const handleClickViewAll = () => {
    history.push(AppConstants.routes.affiliate.earnings);
  };
  
  return (
    <>
      <div className="aff_myEarning">
        <div className="row mb-4">
          <div className="col-12">
            <p>Total Earnings:</p>
            <div className="heading_color">${amounts?.totalEarnings || 0}</div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <p>Total Paid:</p>
            <div className="heading_color">${amounts?.totalPaid || 0}</div>
            <div className="time">Last payout: 22 March</div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <p>Upcoming Payout:</p>
            <div className="heading_color">${amounts?.upcomingPayout || 0}</div>
            <div className="time">To be paid: 31st March</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="divider_aff" />
          </div>
        </div>

        <div className="row aff_table_wrapper mb-4">
          <div className="col-12">
            <div className="table_heading">Recent Payments</div>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Method</th>
                </tr>
              </thead>
              <tbody>
                {paymentList.map((list) => (
                  <tr key={list.id}>
                    <td>{moment(list.payment_date).format('MMM, DD, YYYY')}</td>
                    <td>{list.transaction_id}</td>
                    <td>
                      <span>${list.earning}</span>
                    </td>
                    <td>{list.payment_method}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              className="btn btn_aff_common mb-3"
              onClick={handleClickViewAll}
            >
              View All
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyEarnings;
