import React, { useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import EditIcon from '../../assets/images/edit.svg';
import lock from '../../assets/images/lock.svg';

const RangeSection = ({
  label,
  value,
  // localValue = value,
  handleRangeChange,
  min = 0,
  max = 0,
  lableType,
  step = null,
  // isShowLevelPoints = true
  isViewMode,
  isLock,
  handleRangeChangeComplate
}) => {
  // const [localValue, setLocalValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(true);
  const goToEditMode = () => setIsEditMode(true);

  return (
    <div className="card-box position-relative">
      {isLock && (
        <div className="text-right lockSection">
          <img src={lock} alt="lock" />
        </div>
      )}
      <div className="card-header d-flex justify-content-between align-items-center">
        <span>{label}</span>
        {!isEditMode && (
          <div
            className="edit position-relative"
            onClick={goToEditMode}
            onKeyDown={(event) => event.key === 'Enter' && goToEditMode()}
            role="button"
            tabIndex="0"
          >
            <img src={EditIcon} alt="Edit icon" />
          </div>
        )}
      </div>
      <div className="card-container mt-4 mb-4 pb-2 pl-4 pr-4 innerRange">
        {/* {!isEditMode &&
          (value ? (
            `${localValue} ${lableType}`
          ) : (
            <span className="text-danger-not">Not answered yet</span>
          ))}
        {isEditMode && (
          <> */}
        <InputRange
          disabled={isViewMode}
          allowSameValues
          formatLabel={(v1) => {            
            const lab = Number(v1);
            let frmatLbl;
            if (label === 'Height' || label === 'Height between?') {
              const newVal = lab.toFixed(1).split('.').join("' ");
              frmatLbl = `${newVal}"`;
            } else if (lableType === 'K') {
              frmatLbl = `${lab}${lableType}`;
            } else {
              frmatLbl = `${lab} ${lableType}`;
            }
            return frmatLbl;
          }}
          maxValue={max}
          minValue={min}
          value={value}
          step={step}
          onChange={(val) => handleRangeChange(val)}
          onChangeComplete={(val) => handleRangeChangeComplate(val)}
        />
        {/* <input type="range" value={value} style={{ cursor: 'pointer' }} className="form-range w-100" step={step} min={min} max={max} onChange={handleRangeChange}/> */}
        {/* </>
        )} */}
      </div>
    </div>
  );
};

export default RangeSection;
