import React, { createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/site.scss';
import '../assets/css/media.scss';
import '../adminAssets/css/dashboard.css';
import authService from '../services/authServices';
import AdminSidebarFilter from './partial/adminSidebarFilter';
import AdminHeader from './partial/adminHeader';

class InnerLayoutAdmin extends React.Component {
	constructor(props) {
		super(props);

		this.dashboardRef = createRef();
		this.searchUsersCallbackHandler = this.searchUsersCallbackHandler.bind(this); // Bind method to this

		this.sidebarRef = createRef();
		this.toggleFilterButtonHandler = this.toggleFilterButtonHandler.bind(this); // Bind method to this
	}

	componentDidMount() {
		const { history } = this.props;
		authService.throwBackAdmin(history);
	}

	toggleFilterButtonHandler() {
		if (this.sidebarRef.current) {
			this.sidebarRef.current.toggleVisibilityMethod();
		}
	}

	searchUsersCallbackHandler(data) {
		if (this.dashboardRef.current) {
			this.dashboardRef.current.searchUsersHandlerMethod(data);
		}
	}

	render() {
		const { children } = this.props;

		// Clone children and pass the ref
		const childrenWithRef = React.Children.map(children, (child) => {
			// Only add ref to valid React elements
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { ref: this.dashboardRef });
			}
			return child;
		});

		return (
			<div className="dashboard_admin">
				<AdminHeader {...this.props} onFilterBtnClickHandler={this.toggleFilterButtonHandler} />
				<AdminSidebarFilter {...this.props} ref={this.sidebarRef} searchUsersCallbackHandler={this.searchUsersCallbackHandler} />
				<section className="admin_container">
                    {childrenWithRef}
				</section>
			</div>
		);
	}
}

export default InnerLayoutAdmin;
