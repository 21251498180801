import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import privatelock from '../../assets/images/private_lock.svg';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';
import LoveGoals from '../../appConstants/loveGoals';
import WdyhauList from '../../appConstants/wdyhau';

const Step11Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    loveGoal: userDetails?.loveGoal ? userDetails?.loveGoal : [],
    wdyhau: userDetails?.hearAbout ? [userDetails?.hearAbout] : [],
    loveGoalHelp: false,
    wdyhauHelp: false
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        loveGoal: JSON.stringify(values.loveGoal),
        wdyhau: JSON.stringify(values.wdyhau)
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step11" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Tell us about your love goals to help us find you like-minded
                individuals who share your vision for the future
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join1 six_step">
            <div className="col-md-12 col-lg-12 pb-4 custome_select private_wrapper">
              <Select
                multi
                limit={5}
                placeholder="Love Goals"
                options={LoveGoals}
                autoComplete="off"
                className="custom_input"
                values={values.loveGoal}
                onChange={(value) => setValues({ ...values, loveGoal: value })}
              />

              {validator.message('loveGoal', values.loveGoal, 'required')}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      loveGoalHelp: true,
                      wdyhauHelp: false
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.loveGoalHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({ ...values, loveGoalHelp: false })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="How did you hear about us?"
                options={WdyhauList}
                className="custom_input"
                values={values.wdyhau}
                onChange={(value) => setValues({ ...values, wdyhau: value[0] })}
              />
              {validator.message('wdyhau', values.wdyhau, 'required')}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      loveGoalHelp: false,
                      wdyhauHelp: true
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.wdyhauHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({ ...values, wdyhauHelp: false })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Step11Component;
