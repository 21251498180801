/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/no-did-update-set-state */
import csc from 'country-state-city';
// import * as moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import Switch from 'react-switch';
import { ToastContainer } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import zxcvbn from 'zxcvbn';
import { setUser } from '../../redux/actions/user.actions';
import AppConstants from '../../appConstants';
// import logo from '../../assets/images/Logo.svg';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import eye from '../../assets/images/eye.svg';
import privatelock from '../../assets/images/private_lock.svg';
import { datediff } from '../../helpers/validator';
import { authCall } from '../../services';
import DatepickerInput from '../general/datepickerInput';
// import webServices from '../../services/webServices';
import authService from '../../services/authServices';

const PASSWORDSTRENGHT = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

class RegisterDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    const { u_email: uMail, uOtp, referralCode } = this.props;
    
    this.state = {
      u_email: uMail,
      u_otp: uOtp,
      gender: '', // 'MAN'
      looking_for: '', // 'WOMAN',
      first_name: '',
      last_name: '',
      u_country: '',
      password: '',
      dob: null,
      countries: [],
      request: true,
      file_error: '',
      showPassword: false,
      strenght: 0,
      dobHelp: false,
      passwordError: '',
      referralCode
    };

    const { file_error: fileError } = this.state;
    this.validator = new SimpleReactValidator({
      validators: {
        custom_date: {
          message: 'Your age must be greater then 18.',
          rule: (val = new Date()) => {
            const valLocal = val || new Date();
            const curDate = new Date();
            const diff = datediff(valLocal, curDate);
            return valLocal.getTime() <= curDate.getTime() && diff > 365 * 18;
          },
          required: true
        },
        custom_file: {
          message: fileError || 'The profile picture is required.',
          rule: () => fileError,
          required: true
        }
        /* password: {
          message:
            'The :attribute must have min 8 characters, with at least a symbol, number, upper and lower case.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(
              val,
              // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/i
              // /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./]).{8,}$/
            ) && params.indexOf(val) === -1,
          required: true // optional
        } */
      }
    });
  }

  async componentDidMount() {
    this.getCountriesOptions();
  }

  componentDidUpdate(previousProps) {
    const { u_email: uEmail, u_otp: uOtp } = this.props;
    if (previousProps.u_email !== uEmail) {
      this.setState({
        u_email: uEmail
      });
    }
    if (previousProps.u_otp !== uOtp) {
      this.setState({
        u_otp: uOtp
      });
    }
  }

  getCountriesOptions = async () => {
    const data = await csc.getAllCountries();

    const countriesList = [];
    data.forEach((el) => {
      if (!AppConstants.COUNTRIES_TO_REMOVE_ARR.includes(el.isoCode)) {
        countriesList.push({
          value: el.isoCode,
          label: `${el.flag} ${el.name} (${el.isoCode})`
        });
      }
    });
    this.setState({ countries: countriesList });
  };

  handleChange = (date) => {
    this.setState({
      dob: date
    });
  };

  handelCountryChange = async (value) => {
    if (value[0]) {
      this.setState({
        u_country: value[0]
      });
      window.localStorage.setItem('selectedCountry', JSON.stringify(value));
    } else {
      this.setState({
        u_country: ''
      });
      window.localStorage.setItem('selectedCountry', '');
    }
  };

  handelGender = (nextChecked) => {
    const gend = nextChecked.currentTarget.value;
    this.setState({
      gender: gend, // ? 'WOMAN' : 'MAN',
      looking_for: gend === 'WOMAN' ? 'MAN' : 'WOMAN'
    });
  };

  handelLookingFor = (nextChecked) => {
    this.setState({
      looking_for: nextChecked ? 'WOMAN' : 'MAN',
      gender: nextChecked ? 'MAN' : 'WOMAN'
    });
  };

  validatePassword = (password) => {
    let isError = false;
    const symbolRegex = /[-!@$%^&*#()_+|~=`{}[\]:";'<>?,./]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;

    const hasSymbol = symbolRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasUppercase = uppercaseRegex.test(password);
    const hasLowercase = lowercaseRegex.test(password);
    const isLengthValid = password.length >= 8;

    if (password === '') {
      isError = true;
      this.setState({
        passwordError: 'The password field is required.'
      });
    } else if (!isLengthValid) {
      isError = true;
      this.setState({
        passwordError: 'Password must be at least 8 characters long.'
      });
    } else if (!hasSymbol) {
      isError = true;
      this.setState({
        passwordError: 'Password must contain at least one symbol.'
      });
    } else if (!hasNumber) {
      isError = true;
      this.setState({
        passwordError: 'Password must contain at least one number.'
      });
    } else if (!hasUppercase) {
      isError = true;
      this.setState({
        passwordError: 'Password must contain at least one uppercase letter.'
      });
    } else if (!hasLowercase) {
      isError = true;
      this.setState({
        passwordError: 'Password must contain at least one lowercase letter.'
      });
    } else {
      isError = false;
      this.setState({
        passwordError: ''
      });
    }
    return isError;
  };

  submitForm = async () => {
    const {
      request,
      u_email: uEmail,
      u_otp: uOtp,
      first_name: firstName,
      last_name: lastName,
      u_country: uCountry,
      password,
      dob,
      gender,
      looking_for: lookingFor,
      referralCode
    } = this.state;
    const { history, dispatch, location } = this.props;

    if (
      !this.validatePassword(password) &&
      this.validator.allValid() &&
      request
    ) {
      this.setState({ request: false });
      const formData = new FormData();
      formData.append('email', uEmail);
      formData.append('otp', uOtp);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('country', JSON.stringify(uCountry));
      formData.append('password', password);
      formData.append('dob', dob);
      formData.append('gender', gender);
      formData.append('looking_for', lookingFor);
      formData.append('profileLevel', 2);
      formData.append('referralCode', referralCode);
      formData.append(
        'otpNew',
        location?.state?.otp ? location?.state?.otp : uOtp
      );
      // formData.append('profileActiveMsgReadDt', moment().add(10, 'days'));

      const resp = await authCall('auth/signup', formData);
      if (resp && resp.success === true) {
        authService.setToken(resp.data);
        dispatch(setUser(resp.data.user));
        history.push(AppConstants.routes.registerSections);
        // const { goToNextStep } = this.props;
        // goToNextStep({ step: 6 });
        // setTimeout(() => {
        //   history.push(AppConstants.routes.afterLogin);
        // }, 4000);
      }

      this.setState({ request: true });
    } else {
      // window.scrollTo(0, 0);
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleClickShowPassword = (status) => {
    this.setState({ showPassword: status });
  };

  handleChangePassword = (password) => {
    const { score } = zxcvbn(password);
    this.setState({ password, strenght: score, passwordError: '' });
  };

  render() {
    const {
      gender,
      first_name: firstName,
      last_name: lastName,
      u_country: uCountry,
      countries,
      password,
      dob,
      request,
      showPassword,
      strenght,
      dobHelp,
      passwordError,
      referralCode
    } = this.state;
    const { history } = this.props;

    return (
      <>
        <section className="registerDetails">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            limit={3}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <div
                  className="logo cursor-pointer"
                  onClick={() => {
                    history.push('/');
                  }}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && history.push('/')
                  }
                  role="button"
                  tabIndex="0"
                >
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </div>
              </div>
            </div>

            <div className="step1">
              <div className="row join-reg top-join">
                <div className="col-md-12 col-lg-12">
                  <h1>
                    Join Today! <br /> Create your account
                  </h1>
                </div>
                <div className="col-md-12 col-lg-12 mt-1 mb-4">
                  <div className="d-flex customeRadio_wrapper">
                    <div className="box">
                      <div className="head">I am a</div>
                      <div className="customeRadio_Inner">
                        <div>
                          <input
                            type="radio"
                            id="man"
                            name="radio-group"
                            value="MAN"
                            onChange={this.handelGender}
                          />
                          <label htmlFor="man">Man</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="woman"
                            name="radio-group"
                            value="WOMAN"
                            onChange={this.handelGender}
                          />
                          <label htmlFor="woman">Woman</label>
                        </div>
                      </div>
                      {this.validator.message('gender', gender, 'required')}
                      <div className="custom-switch d-none">
                        <Switch
                          checked={gender === 'WOMAN'}
                          onChange={this.handelGender}
                          handleDiameter={28}
                          offColor="#E4DCE6"
                          onColor="#B800E0"
                          offHandleColor="#B800E0"
                          onHandleColor="#E4DCE6"
                          height={40}
                          width={120}
                          uncheckedIcon={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                fontSize: 14,
                                fontWeight: 'bold',
                                paddingRight: 20,
                                color: '#624B69'
                              }}
                            >
                              MAN
                            </div>
                          }
                          checkedIcon={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: '#fff',
                                paddingRight: 5,

                                marginLeft: '35px'
                              }}
                            >
                              WOMAN
                            </div>
                          }
                          className="react-switch"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* {this.validator.message('gender', gender, 'required')} */}
                  </div>
                </div>
                <div className="row w-100 pl-4 p-md-4 join-reg">
                  <div className="col-md-12 col-lg-4 mb-4">
                    <input
                      type="text"
                      name="user-first_name"
                      id="user-first_name"
                      placeholder="First Name"
                      className="custom_input"
                      autoComplete="off"
                      value={firstName}
                      onChange={(e) =>
                        this.setState({ first_name: e.target.value })
                      }
                    />
                    {this.validator.message(
                      'first_name',
                      firstName,
                      'required|alpha'
                    )}
                  </div>
                  <div className="col-md-12 col-lg-4 mb-4 ">
                    <input
                      type="text"
                      name="user-last_name"
                      id="user-last_name"
                      placeholder="Last Name"
                      className="custom_input"
                      autoComplete="off"
                      value={lastName}
                      onChange={(e) =>
                        this.setState({ last_name: e.target.value })
                      }
                    />
                    {this.validator.message(
                      'last_name',
                      lastName,
                      'required|alpha'
                    )}
                  </div>
                </div>
                <div className="row w-100 pl-4 p-md-4 join-reg ">
                  <div className="col-md-12 col-lg-4 mb-4 mb-lg-0 mb-md-0 custome_select">
                    <Select
                      placeholder="I Live In (country)"
                      options={countries}
                      autoComplete="off"
                      className="custom_input"
                      onChange={(value) => this.handelCountryChange(value)}
                    />
                    {this.validator.message('country', uCountry, 'required')}
                  </div>
                  <div className="col-md-12 col-lg-4 mb-4 mb-lg-0 mb-md-0 custome_select private_wrapper">
                    <DatepickerInput
                      placeholderText="Select Date of Birth"
                      value={dob ? new Date(dob) : null}
                      onChange={this.handleChange}
                    />

                    {this.validator.message(
                      'date_of_birth',
                      dob,
                      'required|custom_date'
                    )}
                    <div
                      className="private_btn"
                      aria-hidden="true"
                      onClick={() => this.setState({ dobHelp: true })}
                    >
                      Private
                      <img
                        src={privatelock}
                        className="lock_private"
                        alt="Lock"
                        aria-hidden="true"
                      />
                    </div>
                    {dobHelp && (
                      <div className="toolTipHelp d-flex">
                        <p>
                          This information is private and never shared with
                          anyone. Lovrican uses this information to ensure all
                          members meet the high standards our members expect.
                        </p>
                        <div className="ml-3">
                          <i
                            className="fa fa-times cursor-pointer"
                            aria-hidden="true"
                            onClick={() => this.setState({ dobHelp: false })}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row w-100 pl-4 p-md-4 join-reg bottom-join">
                  <div className="col-md-12 col-lg-4">
                    <div className="d-flex align-items-end password">
                      <div
                        className="icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          this.handleClickShowPassword(!showPassword)
                        }
                        aria-hidden="true"
                      >
                        <img src={eye} className="img-fluid mr-3" alt="" />
                      </div>
                      <div className="field w-100">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="user-password"
                          id="user-password"
                          autoComplete="new-password"
                          placeholder="Create Password"
                          className="custom_input"
                          value={password}
                          onChange={(e) =>
                            this.handleChangePassword(e.target.value)
                          }
                          onKeyDown={(event) =>
                            event.key === 'Enter' && this.submitForm()
                          }
                        />
                      </div>
                      <div className="hint">
                        <ul className="list-unstyled">
                          <li>At least:</li>
                          <li>• 8 characters</li>
                          <li>• 1 special character</li>
                          <li>• 1 capital letter</li>
                          <li>• 1 number</li>
                        </ul>
                      </div>
                    </div>
                    {/* {password && ( */}
                    <div
                      className={`d-flex w-100 mt-1 align-items-center ${
                        password ? 'visible' : 'invisible'
                      } `}
                    >
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${(strenght + 1) * 20}%` }}
                        />
                      </div>
                      <div className="password_status">
                        Password is {PASSWORDSTRENGHT[strenght]}
                      </div>
                    </div>
                    {/* )} */}
                    {/* <div className="d-block">
                      {this.validator.message(
                        'password',
                        password,
                        'required|password'
                      )}
                    </div> */}

                    {passwordError && (
                      <div className="d-block">
                        <div className="srv-validation-message">
                          {passwordError}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 col-lg-4">
                    <input
                      type="text"
                      name="referralCode"
                      id="referralCode"
                      placeholder="Referral Code (optional)"
                      className="custom_input"
                      autoComplete="off"
                      value={referralCode}
                      onChange={(e) =>
                        this.setState({ referralCode: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row w-100 pl-4 p-md-4 join-reg bottom-join">
                  <div className="col-md-12 col-lg-4"> </div>
                  <div className="col-md-12 col-lg-4 join-links marb7 text-right">
                    <span
                      className={`submit-btn ${
                        !request ? 'hks-btn-disable' : ''
                      }`}
                      onClick={this.submitForm}
                      onKeyDown={(event) =>
                        event.key === 'Enter' && this.submitForm()
                      }
                      role="button"
                      tabIndex="0"
                    >
                      Create Account
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect()(RegisterDetailsComponent);
