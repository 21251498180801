import React from 'react';
import logoBlack from '../../../assets/images/Lovrican-black-Logo-Single.svg';
import arrowIcon from '../../../assets/images/arrow-circle.svg';
import PayPalCheckoutPage from '../../../services/PayPalCheckout';

const PlanDetailsComponent = ({
  goToNextStep,
  plans,
  handlePlanClick,
  selectedPlan
  // createPayment,
  // isProcessing
}) => (
  <div className="giftWrapper paymentPage">
    <div
      className="giftBack"
      role="button"
      aria-hidden="true"
      onClick={() => goToNextStep(3)}
    >
      <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
    </div>
    <div className="gift-logo">
      <img src={logoBlack} className="img-fluid" alt="Lovrican Logo" />
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="innerWrapper selectPlanFinal">
          <div className="col-12 p-0">
            <h1 className="text-head-col">Select the plan you want to gift!</h1>
            <div className="planWrapper">
              <div className="nav flex-column nav-pills">
                {!!plans.length &&
                  plans.map((plan) => (
                    <a
                      key={plan.id}
                      className={`nav-link ${
                        selectedPlan.id === plan.id ? 'active' : ''
                      }`}
                      data-toggle="pill"
                      href="/"
                      onClick={(event) => handlePlanClick(event, plan)}
                    >
                      <div className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
                        <div className="lft d-flex align-items-center">
                          <div className="icon mr-3">
                            <i
                              className="fa fa-circle-thin"
                              aria-hidden="true"
                            />
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="plan_title">
                            <h3>{plan.name}</h3>
                          </div>
                        </div>
                        <div className="plan-center">
                          <div className="top">
                            {(plan.numberOfDays / 30).toFixed(0)}-month access
                          </div>
                          {(
                            ((plans[0].price - plan.price) * 100) /
                            plans[0].price
                          ).toFixed(0) > 0 && (
                            <div className="bottom">
                              <div
                                className={`save ${plan.name.toLowerCase()}`}
                              >
                                Save{' '}
                                {(
                                  ((plans[0].price - plan.price) * 100) /
                                  plans[0].price
                                ).toFixed(0)}
                                %
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="rgt">
                          <div className="price">
                            ${plan.price} / Month <br />
                            <span>
                              Total ({(plan.numberOfDays / 30).toFixed(0)} x $
                              {plan.price}) = $
                              {plan.price * (plan.numberOfDays / 30).toFixed(0)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
              </div>

              {/* <div className="col-md-12 d-flex d-none  text-center dektop-payment d-none">
                <button
                  type="button"
                  className="btn btn-green btn-large mr-3"
                  aria-hidden="true"
                  // onClick={createPayment}
                  onClick={(e) => createPayment(e, 'payPal')}
                  disabled={isProcessing}
                >
                  {isProcessing === 'payPal'
                    ? 'Redirecting...'
                    : 'Pay with PayPal'}
                </button>
                <button
                  type="button"
                  className="btn btn-purple btn-large"
                  aria-hidden="true"
                  onClick={(e) => createPayment(e, 'card')}
                  // onClick={createPayment}
                  disabled={isProcessing}
                >
                  {isProcessing === 'card' ? 'Redirecting...' : 'Pay with Card'}
                </button>
              </div> */}
              <PayPalCheckoutPage
                planId={selectedPlan.id}
                amount={(
                  (selectedPlan.numberOfDays / 30).toFixed(0) *
                  selectedPlan.price
                ).toFixed(2)}
              />
            </div>
          </div>
        </div>
        {/* <div className="mobile-payment d-none">
          <button
            type="button"
            className="btn btn-green btn-large mr-3"
            aria-hidden="true"
            // onClick={createPayment}
            onClick={(e) => createPayment(e, 'payPal')}
            disabled={isProcessing}
          >
            {isProcessing === 'payPal' ? 'Redirecting...' : 'Pay with PayPal'}
          </button>
          <button
            type="button"
            className="btn btn-purple btn-large"
            aria-hidden="true"
            // onClick={createPayment}
            onClick={(e) => createPayment(e, 'card')}
            disabled={isProcessing}
          >
            {isProcessing === 'card' ? 'Redirecting...' : 'Pay with Card'}
          </button>
        </div> */}
      </div>
    </div>
  </div>
);

export default PlanDetailsComponent;
