import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../../../redux/actions/home.actions';
import { getUserPlansDetails } from '../../../redux/actions/user.actions';
import webServices from '../../../services/webServices';
import OrderFailure from './Failure';
import OrderSuccess from './Success';

const Order = ({ location, match }) => {
  const dispatch = useDispatch();
  const { planDetails, current } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    dispatch(pageTitle('Order'));
    dispatch(getUserPlansDetails());
    (async () => {
      const queryParams = new URLSearchParams(location.search);
      const systemOrderId = queryParams.get('sysorderid');
      const likes = queryParams.get('likes');
      const plantype = queryParams.get('plantype');
      if(plantype === "2"){
        setSelectedPlan(`${likes} ${likes > 1 ? 'Likes' : 'Like'}`);
      }
      else if(plantype === "1"){
        setSelectedPlan(`${queryParams.get('plan')} ${queryParams.get('plan') > 1 ? 'Roses' : 'Rose'}`);
      }
      else{
        setSelectedPlan(`${queryParams.get('plan')} Plan`);
      }
      
      if (!systemOrderId) {
        return;
      }
      const innerResponse = await webServices.updateOrder({
        systemOrderId,
        likes
      });
      setResponse(innerResponse.data || {});
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <Route
        path={`${match.path}/success`}
        render={() => (
          <OrderSuccess
            isLoading={isLoading}
            response={response}
            userDetails={current}
            selectedPlan={selectedPlan}
          />
        )}
      />
      <Route
        path={`${match.path}/fail`}
        render={() => (
          <OrderFailure
            isLoading={isLoading}
            response={response}
            planDetails={planDetails}
            userDetails={current}
            selectedPlan={selectedPlan}
          />
        )}
      />
    </>
  );
};

export default Order;
