import { HomeConstants } from '../constants';

export const initialState = {
  loginPopup: false,
  pageTitle: ''
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case HomeConstants.LOGIN_POPUP:
      return { ...state, loginPopup: action.payload };
    case HomeConstants.PAGE_TITLE:
      return { ...state, pageTitle: action.payload };
    default:
      return { ...state };
  }
};

export default loginReducer;
