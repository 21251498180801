import { React } from 'react';
import { Modal } from 'react-bootstrap';
import verfied from '../assets/images/verified.svg';
import notVerfied from '../assets/images/verified_Grey.svg';

const PrivateProfileViewPopup = ({
  modalStatus,
  handleClose,
  userDetails,
  userName,
  userAge,
  userOccupation,
  userAdd,
  userAbout,
  userHobbies,
  privateImage
}) => (
  <>
    <Modal
      className="filter-model PlanStatusModel private_profile_view"
      show={modalStatus}
      onHide={modalStatus}
      centered
      size="lg"
      // backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="w-100 text-center">
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleClose()}
            >
              Close Preview
            </button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="prive_profile_container">
          <div className="row">
            <div className="col-12 col-md-5">
              <img
                src={privateImage}
                className="private_img"
                alt="img"
              />
            </div>
            <div className="col-12 col-md-7">
              <div className="user-detail-box">
                <div className="usr-info-box">
                  <h5>
                    <span className="profile_name" title={userName}>
                      {userName.charAt(0).toUpperCase()}
                    </span>
                    <span className='dotAge'>&#8226;</span>{userAge}{' '}
                    {userDetails?.status === 'ACTIVE' &&
                    userDetails?.phone !== null ? (
                      <img
                        src={verfied}
                        className="img-fluid verifid_img"
                        alt="arrow icon"
                        aria-hidden="true"
                      />
                    ) : (
                      <img
                        src={notVerfied}
                        className="img-fluid verifid_img cursor-pointer"
                        alt="arrow icon"
                        aria-hidden="true"
                        // onClick={() => {
                        //   setVerifyMsg(true);
                        // }}
                      />
                    )}
                  </h5>
                  {userOccupation ? (
                    <p className="occupation cursor-pointer" aria-hidden="true">
                      {userOccupation}
                      {userDetails?.userDetails?.companyName ? ' at ' : ''}
                      {userDetails?.userDetails?.companyName}
                    </p>
                  ) : (
                    <p
                      className="occupation missting_section_match cursor-pointer"
                      aria-hidden="true"
                    >
                      Occupation to be added
                    </p>
                  )}

                  {userAdd && <p className="address">{userAdd}</p>}
                </div>

                <div className="about-me-box pt-3">
                  <p className="head">About Me</p>
                  {userAbout ? (
                    <p className="about-dec cursor-pointer" aria-hidden="true">
                      {userAbout}
                    </p>
                  ) : (
                    <p
                      className="about-dec missting_section_match cursor-pointer"
                      aria-hidden="true"
                    >
                      Intro summary to be added
                    </p>
                  )}
                </div>

                <div className="ul-li-box hobbies-wrapper">
                  <p className="head pb-2">My Hobbies</p>
                  {userHobbies ? (
                    <ul
                      className="color-list cursor-pointer"
                      aria-hidden="true"
                    >
                      {userHobbies.map((prop) => (
                        <li key={prop.label}>{prop.label}</li>
                      ))}
                    </ul>
                  ) : (
                    <ul
                      className="color-list missting_section_match cursor-pointer"
                      aria-hidden="true"
                    >
                      <li>Hobbies to be added</li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
);
export default PrivateProfileViewPopup;
