import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { validate } from 'validate.js';
import Select from 'react-dropdown-select';
import thankYouBottomPng from '../../assets/images/you-bottom.png';
import thankYouTopPng from '../../assets/images/you-top.png';
import authServices from '../../services/authServices';
import webService from '../../services/webServices';

function ContactUsPage() {
  const isUserLoggedOut = useMemo(() => !authServices.isLoggedIn(), []);
  const [shouldShowThankYou, setShouldShowThankYou] = useState(false);
  const history = useHistory();
  const categories = [
    {
      value: 'Category One',
      label: 'Compliment'
    },
    {
      value: 'Category Two',
      label: 'Complaint'
    },
    {
      value: 'Category Three',
      label: 'Service Support Need'
    },
    {
      value: 'Category Four',
      label: 'Business Partnerships'
    },
    {
      value: 'Category Five',
      label: 'Other'
    }
    // {
    //   value: "Category Six",
    //   label: "Category Six"
    // },
    // {
    //   value: "Category Seven",
    //   label: "Category Seven"
    // }
  ];

  const setInitialFormValues = useCallback(
    () => ({
      ...(isUserLoggedOut && { email: '' }),
      category: '',
      file: null,
      message: ''
    }),
    [isUserLoggedOut]
  );

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null
  });
  const [formValues, setFormValues] = useState(setInitialFormValues());

  const handelCategoryChange = (value) => {
    if (value[0]) {
      setFormValues((prev) => ({
        ...prev,
        category: value[0].label
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        category: ''
      }));
    }
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value, files } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: name === 'file' ? files[0] : value
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setFormDetails((prev) => ({ ...prev, submitted: true }));
    if (formDetails.errors) {
      return;
    }

    (async () => {
      const formData = new FormData();
      Object.keys(formValues).forEach((key) => {
        if (key === 'file' && formValues[key]) {
          formData.append(key, formValues[key], formValues[key].name);
        } else {
          formData.append(key, formValues[key]);
        }
      });

      const response = await webService.submitContactQuery(formData);
      if (response.success) {
        setFormValues(setInitialFormValues);
        setFormDetails({ submitted: false, errors: null });
        setShouldShowThankYou(true);
      } else {
        toast.error('Some error occurred');
      }
    })();
  };

  useEffect(() => {
    setFormDetails((prev) => ({
      ...prev,
      errors: validate(formValues, {
        ...(isUserLoggedOut && {
          email: {
            presence: { allowEmpty: false },
            email: true
          }
        }),
        category: { presence: { allowEmpty: false } },
        message: { presence: { allowEmpty: false }, length: { maximum: 250 } }
      })
    }));
  }, [formValues, isUserLoggedOut]);

  useEffect(() => {
    if (!shouldShowThankYou) {
      return;
    }

    setTimeout(() => {
      history.push('/');
    }, 500000);
  }, [shouldShowThankYou]);

  if (shouldShowThankYou) {
    return (
      <div className="text-center">
        <div className="thank_you">
          <div className="top_thank">
            <img src={thankYouTopPng} alt="/" className="img-fluid" />
          </div>
          <img src={thankYouBottomPng} alt="/" className="img-fluid" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Contact Us | Lovrican</title>
        <meta
          name="description"
          content="Contact us at Lovrican for any inquiries, assistance, or feedback. We're here to ensure that your journey to find true love and happiness is exceptional in every way."
        />
        <meta name="keywords" content="Contact Us" />
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="row privacy_policy">
        <div className="col col-md-12 text-center">
          <div className="contact-wrapper">
            <div className="sub-heading">
              <h5 className="text-left contactHead pb-1">Contact us!</h5>
              <p className="subhead">Please fill out the form for any query.</p>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="contact-bg text-left">
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        {isUserLoggedOut && (
                          <>
                            <div className="form-group">
                              <input
                                className="form-control"
                                name="email"
                                value={formValues.email}
                                autoComplete="off"
                                placeholder="Enter your email"
                                onChange={handleInputChange}
                              />
                            </div>
                            <span className="msgErr">
                              {formDetails.submitted &&
                                formDetails.errors &&
                                formDetails.errors.email && (
                                  <small className="text-danger">
                                    {formDetails.errors.email[0]}
                                  </small>
                                )}
                            </span>
                          </>
                        )}
                        <div className="form-group pt-1">
                          {/* <select
                            className="form-control"
                            value={formValues.category}
                            autoComplete = "off"
                            onChange={handleInputChange}
                          >
                            <option value="">Select a category</option>
                            <option value="Category One">Category One</option>
                            <option value="Category Two">Category Two</option>
                          </select> */}
                          <Select
                            name="category"
                            placeholder="Select Category"
                            options={categories}
                            autoComplete="off"
                            className="custom_input"
                            onChange={(value) => handelCategoryChange(value)}
                          />
                        </div>
                        <span className="msgErr">
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.category && (
                              <small className="text-danger">
                                {formDetails.errors.category[0]}
                              </small>
                            )}
                        </span>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Your message"
                            rows="6"
                            value={formValues.message}
                            name="message"
                            autoComplete="off"
                            onChange={handleInputChange}
                          />
                        </div>
                        <span className="msgErr">
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.message && (
                              <small className="text-danger">
                                {formDetails.errors.message[0]}
                              </small>
                            )}
                        </span>
                        <div className="form-group pt-1 border-0">
                          <div className="upload-btn-wrapper position-relative">
                            <button className="btn" type="button">
                              {formValues.file
                                ? formValues.file.name
                                : 'Upload file'}
                              <i className="fa fa-upload" />
                            </button>
                            <input
                              type="file"
                              name="file"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 text-right pb-4">
                        <button type="submit" className="btn submit-btn1">
                          <i className="fa fa-arrow-right" aria-label = "Send Icon" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsPage;
