import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/Logo.svg';

class RegisterSuccessComponent extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <>
        <section className="registerWrapper succuss_reg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <div
                  className="logo cursor-pointer"
                  onClick={() => {
                    history.push('/');
                  }}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && history.push('/')
                  }
                  role="button"
                  tabIndex="0"
                >
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </div>
              </div>
              <div className="reg-inner last-step">
                <div className="col-12">
                  <h1>You are almost there!</h1>
                </div>
                <div className="col-12">
                  <p>
                    Our experts will review your profile to determine if our
                    service and the Lovrican community are a good fit for you at
                    this time, based on the information you have provided.
                    You’ll either get an ‘approved to join’ the community email,
                    or ‘not approved to join the community at this time’ email.
                  </p>
                  <p>
                    Our goal at Lovrican is to provide each member an
                    exceptional experience. Screening to only accept new members
                    who meet the high expectations of our existing members is
                    one of the ways in which we achieve this goal. If you are
                    not accepted at this time, you’ll have the opportunity to be
                    considered at another time. We can’t wait for you to join
                    us!
                  </p>
                </div>
                <div className="col-12 join-links">
                  <span
                    className="round-button"
                    onClick={() => {
                      history.push('/');
                    }}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && this.submitForm()
                    }
                    role="button"
                    tabIndex="0"
                  >
                    Go to Homepage
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default RegisterSuccessComponent;
