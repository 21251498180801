import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import Spinner from '../general/spinner';
import PayPalCheckoutPage from '../../services/PayPalCheckout';
import offer from '../../assets/images/Offer.svg';
import paypalLogo from '../../assets/images/powered-by-Paypal.png';
import PlanMoreInfoModalComponent from './PlanMoreInfoModalComponent';
// const TEXTS = ['Forest', 'Building', 'Tree', 'Color'];

const PlansComponent = () => {
  const bottomRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isSpinner, setIsSpinner] = useState(true);
  const [showFirstParagraph, setShowFirstParagraph] = useState(true);
  const [showPlanMoreInfoModal, setShowPlanMoreInfoModal] = useState(false);

  const getPlanPrice = plan => (plan.price * (plan.numberOfDays / 31)).toFixed(2);
  
  const displayPlanPrice = (plan) => {
    const planPrice = getPlanPrice(plan).split('.');
    return `$${planPrice[0]}.<sup>${planPrice[1]}</sup>`;
  };

  useEffect(async () => {
    dispatch(pageTitle('Plans'));
    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      }
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstParagraph((prevState) => !prevState);
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!plans.length) {
      return;
    }
    if (!location?.state?.planDetail) {
      setSelectedPlan(
        plans.reduce(
          (acc, value) => (acc.numberOfDays > value.numberOfDays ? acc : value),
          {}
        )
      );
    }
  }, [plans]);

  const handlePlanClick = (event, plan) => {
    event.preventDefault();
    setSelectedPlan(plan);
  };

  const handelPlanMoreInfoPopupCloseBtn = () => {
    setShowPlanMoreInfoModal(false);
  };

  const handelPlanMoreInfoPopupShowIcon = () => {
    setShowPlanMoreInfoModal(true);
  };

  useEffect(() => {
    if (location?.state?.planDetail) {
      setSelectedPlan(location.state.planDetail);
    }
  }, [location.state]);

  return (
    <>
        <div className="full-page planWrapper">
        <div className="container">
            {isSpinner ? (
            <Spinner active={isSpinner} />
            ) : (
            <>
                <h1 className="text-center text-lg-center text-md-center">
                Simple, Transparent Pricing
                </h1>
                <div className="animates_wrapper">
                {showFirstParagraph ? (
                    <div className="sale glowWrap">
                    {' '}
                    <img
                        src={offer}
                        className="img-rose-fluid"
                        alt="Lovrican rose"
                    />{' '}
                    <span className="">
                        <span className="sun_font">I Love Africa </span>2024 Summer Sale
                    </span>
                    <img
                        src={offer}
                        className="img-rose-fluid"
                        alt="Lovrican rose"
                    />
                    </div>
                ) : (
                    <div className="text-center text-lg-center text-md-center mt-4 glowWrap day-rem">
                    <span> {location?.state?.planDetails?.daysRemaining} </span>
                    DAYS remaining in your current plan
                    </div>
                )}
                </div>
                <p className="sale_details">
                {/* We are doing our part to help you find your special someone
                sooner. For limited time we are giving the highest discounts in
                our company’s history:
                <span>
                    {' '}
                    Chocolate plan is less than $0.3 / day and Diamond plan = $0.4 /
                    day
                </span> */}
            We keep our pricing simple. You pay $9.9 per month. You can pay for one month at a time, 
            or pay for up to 6 months at once. <span>Paying for longer periods (3 or 6 months) </span> saves time <span>(you don’t have to re-enter your payment information each month)</span> (you don’t have to re-enter your payment information each month
            <span className='lear_more'>
                Learn more 
                <span className='dot_wrapper ml-2' href='/' onClick={handelPlanMoreInfoPopupShowIcon} onKeyDown={() => {}} role='presentation'>
                    <span className='dot' />
                    <span className='dot' />
                    <span className='dot' />
                </span>
            </span>
                
                </p>
                {/* {location?.state?.planDetails?.daysRemaining > 0 && (
                <div className="text-left text-lg-center text-md-center mt-4 d-none">
                    {location?.state?.planDetails?.daysRemaining} DAYS remaining in
                    your current plan
                </div>
                )} */}
                <div className="mid-container-outer" ref={bottomRef}>
                <div className="mid-container panConatiner">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="plan-details">
                        <div className="nav flex-column nav-pills">
                            {!!plans.length &&
                            plans.map((plan) => (
                                <a
                                className={`nav-link ${
                                    selectedPlan.id === plan.id ? 'active' : ''
                                }`}
                                data-toggle="pill"
                                href="/"
                                onClick={(event) => handlePlanClick(event, plan)}
                                key={plan.id}
                                >
                                <div className="d-flex justify-content-between align-items-start flex-wrap flex-md-nowrap">
                                    <div className="lft d-flex align-items-center">
                                    <div className="icon mr-3">
                                        <i
                                        className="fa fa-circle-thin"
                                        aria-hidden="true"
                                        />
                                        <i
                                        className="fa fa-check-circle"
                                        aria-hidden="true"
                                        />
                                    </div>
                                    <div className="plan_title">
                                        <h3>{plan.name}</h3>
                                    </div>
                                    </div>
                                    <div className="plan-center ">
                                    <span className="month">
                                        {plan.numberOfDays < 30
                                        ? '1-week'
                                        : `${(plan.numberOfDays / 30).toFixed(
                                            0
                                            )}-month`}{' '}
                                        access
                                    </span>
                                    {(
                                        ((plans[0].price - plan.price) * 100) /
                                        plans[0].price
                                    ).toFixed(0) > 0 && (
                                        <div className="bottom d-none">
                                        <div
                                            className={`save ${plan.name.toLowerCase()}`}
                                        >
                                            Save{' '}
                                            {(
                                            ((plans[0].price - plan.price) *
                                                100) /
                                            plans[0].price
                                            ).toFixed(0)}
                                            %{/* </span> */}
                                        </div>
                                        </div>
                                    )}
                                    </div>
                                    <div className="rgt">
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div className="price" dangerouslySetInnerHTML={{__html: displayPlanPrice(plan)}} /> 
                                    </div>
                                    {/* <div className="rgt d-none">
                                        <div className="price">
                                            ${plan.price} / Week <br />
                                            <span>
                                            Total = $
                                            {(
                                                plan.price *
                                                (plan.numberOfDays / 7)
                                            ).toFixed(2)}
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                                </a>
                            ))}
                        </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p className=" w-100 powered powerd-mbl text-center mt-4">
                        <img
                            src={paypalLogo}
                            className="img-fluid"
                            alt="paypal logo"
                        />
                        </p>
                    </div>
                    </div>
                </div>

                <div className="white-border" />
                <div id="paypal-button-container" />
                <div className="mid-container mt-4 panConatiner">
                    <div className="mobile_plan justify-content-center text-center mobileFooter">
                    <PayPalCheckoutPage
                        planId={selectedPlan.id}
                        amount={getPlanPrice(selectedPlan)}
                        selectedPlan={selectedPlan}
                    />
                    </div>
                </div>
                </div>
            </>
            )}
        </div>
        </div>

        <PlanMoreInfoModalComponent show={showPlanMoreInfoModal} handelClose={handelPlanMoreInfoPopupCloseBtn} />
    </>
  );
};

export default PlansComponent;
