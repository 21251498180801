import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
// import AppConstants from '../../appConstants';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step3Component = ({ userDetails, masterData, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    education: userDetails?.userDetails?.educations
      ? [userDetails?.userDetails?.educations]
      : [],
    whatStudy: userDetails?.userDetails?.whatStudy || ''
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        education: JSON.stringify(values.education),
        whatStudy: values.whatStudy
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step3" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className='subtitle'>Your educational journey is valuable to your
              matches. Share your achievements with pride</div>
            </div>
            </div>
            <div className="row join-reg bottom-join third_step">
              <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3">
                <Select
                  placeholder="Highest Level of Education"
                  options={masterData?.degree}
                  autoComplete="off"
                  className="custom_input"
                  values={values.education}
                  onChange={(value) =>
                    setValues({ ...values, education: value[0] })
                  }
                />
                {validator.message('education', values.education, 'required')}
              </div>

              <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3">
                <input
                  type="text"
                  name="whatStudy"
                  id="whatStudy"
                  placeholder="What did you study (your major)"
                  className="custom_input"
                  autoComplete="off"
                  value={values.whatStudy}
                  onChange={(e) =>
                    setValues({ ...values, whatStudy: e.target.value })
                  }
                />
                {validator.message('whatStudy', values.whatStudy, 'required')}
              </div>

              <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-right">
                <button
                  className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                  onClick={submitForm}
                  onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                  type="button"
                  tabIndex="0"
                  disabled={loading || false}
                >
                 <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
                </button>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Step3Component;
