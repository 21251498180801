import { useState, React, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import InputRange from 'react-input-range';
// import Loader from 'react-loader-spinner';
import webServices from '../services/webServices';

const MatchesFilterPopup = ({
  modalStatus,
  loaderStatus,
  handleClose,
  parentCallBack
}) => {
  const [userSettings, setUserSettings] = useState(null);
  const [values, setValues] = useState({
    photo: '0',
    news: '0',
    contact: '0',
    similarity: '0',
    favorite: '0',
    country: '0',
    state: '0',
    city: '0',
    lookingEveryone: '0',
    ageBetween: {
      min: 0,
      max: 0
    },
    heightBetween: {
      min: 0,
      max: 0
    },
    annualIncomeBetween: {
      min: 0,
      max: 0
    }
  });

  const handleChange = (props) => (e) => {
    setValues({ ...values, [props]: e.target.checked ? '1' : '0' });
  };
  const handleRangeChange = (event, type) => {
    setValues({ ...values, [type]: event });
  };

  // const handleClickLooking = (type)=>() => {
  //     let value = '1';
  //     if(type === 'gender'){
  //       value = '0';
  //     }
  //     setValues({ ...values, 'lookingEveryone': value });
  // };

  useEffect(async () => {
    const response = await webServices.getUserSettings();
    if (response && response.success) {
      setUserSettings(response.data);
    }
  }, []);

  useEffect(() => {
    if (!userSettings) {
      return;
    }
    setValues({
      photo: userSettings?.on_photo,
      news: userSettings?.on_news,
      contact: userSettings?.on_contact,
      similarity: userSettings?.on_similarities,
      favorite: userSettings?.on_favorite,
      country: userSettings?.on_country,
      state: userSettings?.on_state,
      city: userSettings?.on_city,
      lookingEveryone: userSettings?.looking_everyone,
      ageBetween: {
        min: userSettings?.ageBetween?.min || 0,
        max: userSettings?.ageBetween?.max || 0
      },
      heightBetween: {
        min: userSettings?.heightBetween?.min || 0,
        max: userSettings?.heightBetween?.max || 0
      },
      annualIncomeBetween: {
        min: userSettings?.annualIncomeBetween?.min || 0,
        max: userSettings?.annualIncomeBetween?.max || 0
      }
    });
  }, [userSettings]);

  const sendBackData = async () => {
    parentCallBack(values);
  };

  const clearFilter = async () => {
    const clearData = {
      country: '0',
      state: '0',
      city: '0',
      ageBetween: {
        min: 0,
        max: 0
      },
      heightBetween: {
        min: 0,
        max: 0
      },
      annualIncomeBetween: {
        min: 0,
        max: 0
      }
    };
    setValues(clearData);
    parentCallBack(clearData);
  };

  return (
    <>
      <Modal
        className="filter-model"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <h5 className="modal-title">I want to network with ...</h5> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item cursor-pointer" onClick={handleClickLooking('gender')} aria-hidden="true">
              <div aria-hidden="true" className={`nav-link ${values.lookingEveryone === '0' ? 'active' : ''}`}>
                Woman Only
              </div>
            </li>
            <li className="nav-item cursor-pointer" onClick={handleClickLooking('everyone')} aria-hidden="true">
              <div aria-hidden="true" className={`nav-link ${values.lookingEveryone === '1' ? 'active' : ''}`}>
                Everyone
              </div>
            </li>
          </ul> */}
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="categories">
                <ul className="list-unstyled d-flex flex-wrap">
                  {/* <li
                      className={values.photo === '1' ? 'active' : ''}
                      aria-hidden="true"
                    >
                      <input
                        type="checkbox"
                        value={values.photo}
                        onChange={handleChange('photo')}
                        checked={values.photo === '1'}
                      />
                      <i className="fa fa-check" />
                      Photo
                    </li> */}
                  {/* <li
                      className={values.news === '1' ? 'active' : ''}
                      aria-hidden="true"
                    >
                      <input
                        type="checkbox"
                        value={values.news}
                        onChange={handleChange('news')}
                        checked={values.news === '1'}
                      />
                      <i className="fa fa-check" /> News
                    </li> */}
                  {/* <li
                      className={values.contact === '1' ? 'active' : ''}
                      aria-hidden="true"
                    >
                      <input
                        type="checkbox"
                        value={values.contact}
                        onChange={handleChange('contact')}
                        checked={values.contact === '1'}
                      />
                      <i className="fa fa-check" /> In Contact
                    </li>
                    <li
                      className={values.similarity === '1' ? 'active' : ''}
                      aria-hidden="true"
                    >
                      <input
                        type="checkbox"
                        value={values.similarity}
                        onChange={handleChange('similarity')}
                        checked={values.similarity === '1'}
                      />
                      <i className="fa fa-check" /> Similarities
                    </li> */}
                  {/* <li
                      className={values.favorite === '1' ? 'active' : ''}
                      aria-hidden="true"
                    >
                      <input
                        type="checkbox"
                        value={values.favorite}
                        onChange={handleChange('favorite')}
                        checked={values.favorite === '1'}
                      />
                      <i className="fa fa-check" /> Fovorties
                    </li> */}
                  <li
                    className={values.country === '1' ? 'active' : ''}
                    aria-hidden="true"
                  >
                    <label htmlFor="country" className="mb-0 cursor-pointer">
                      <input
                        className="d-none"
                        type="checkbox"
                        value={values.country}
                        onChange={handleChange('country')}
                        checked={values.country === '1'}
                        id="country"
                      />{' '}
                      <i className="fa fa-check" /> With in Country
                    </label>
                  </li>
                  <li
                    className={values.state === '1' ? 'active' : ''}
                    aria-hidden="true"
                  >
                    <label htmlFor="state" className="mb-0 cursor-pointer">
                      <input
                        className="d-none"
                        id="state"
                        type="checkbox"
                        value={values.state}
                        onChange={handleChange('state')}
                        checked={values.state === '1'}
                      />{' '}
                      <i className="fa fa-check" /> With in State
                    </label>
                  </li>
                  {/* <li
                    className={values.city === '1' ? 'active' : ''}
                    aria-hidden="true"
                  >
                    <label htmlFor="city" className="mb-0 cursor-pointer">
                      <input
                        className="d-none"
                        id="city"
                        type="checkbox"
                        value={values.city}
                        onChange={handleChange('city')}
                        checked={values.city === '1'}
                      />{' '}
                      <i className="fa fa-check" /> With in City
                    </label>
                  </li> */}
                </ul>
              </div>
              <div className="row">
                <div className="col-12">
                  <strong>Age</strong>
                  <div className="pt-1 m-4">
                    <InputRange
                      formatLabel={(v1) => `${v1 === 0 ? 18 : v1} Years`}
                      maxValue={parseInt(process.env.REACT_APP_MAX_AGE, 10)}
                      minValue={parseInt(process.env.REACT_APP_MIN_AGE, 10)}
                      value={values.ageBetween}
                      onChange={(val) => handleRangeChange(val, 'ageBetween')}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="divider-white" />
                </div>

                <div className="col-12 mt-2">
                  <strong>Height</strong>
                  <div className="pt-1 m-4">
                    <InputRange
                      formatLabel={(v1) => `${v1 === 0 ? 4 : v1} ft`}
                      maxValue={parseInt(process.env.REACT_APP_MAX_HEIGHT, 10)}
                      minValue={parseInt(process.env.REACT_APP_MIN_HEIGHT, 10)}
                      value={values.heightBetween}
                      step={0.5}
                      onChange={(val) =>
                        handleRangeChange(val, 'heightBetween')
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="divider-white" />
                </div>
                <div className="col-12 mt-2">
                  <strong>Annual Income</strong>
                  <div className="pt-1 m-4">
                    <InputRange
                      formatLabel={(v1) => `${v1} k`}
                      maxValue={parseInt(process.env.REACT_APP_MAX_INCOME, 10)}
                      minValue={parseInt(process.env.REACT_APP_MIN_INCOME, 10)}
                      value={values.annualIncomeBetween}
                      step={50}
                      onChange={(val) =>
                        handleRangeChange(val, 'annualIncomeBetween')
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              Comming soon every one data
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer border-0">
          <button
            type="button"
            className="btn btn-link themebtn1"
            onClick={clearFilter}
          >
            Clear All
          </button>
          <button
            type="button"
            className="btn theme-btn themebtn2"
            onClick={sendBackData}
            disabled={loaderStatus}
          >
            Save Changes
            {/* <Loader
              type="Oval"
              color="#fff"
              height={20}
              width={20}
              style={{ display: loaderStatus ? 'block' : 'none' }}
            /> */}
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
    </>
  );
};

export default MatchesFilterPopup;
