import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { isEmpty, isValidEmail } from '../helpers/validator';
import webServices from '../services/webServices';
import GiftLandingComponent from '../components/home/gift/GiftLandingComponent';
import PersonDetailsComponent from '../components/home/gift/PersonDetailsComponent';
import PlanDetailsComponent from '../components/home/gift/PlanDetailsComponent';
import PaymentDetailsComponent from '../components/home/gift/PaymentDetailsComponent';
import Spinner from '../components/general/spinner';

const GiftPageComponent = () => {
  const { search } = useLocation();
  const [step, setStep] = useState(1);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isProcessing, setIsProcessing] = useState('');
  const [isSpinner, setIsSpinner] = useState(true);
  const [formValidation, setFormValidation] = useState({
    firstName: '',
    lastName: '',
    email: '',
    pFirstName: '',
    pLastName: '',
    pEmail: ''
  });
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    pFirstName: '',
    pLastName: '',
    pEmail: ''
  });

  useEffect(async () => {
    const queryParams = new URLSearchParams(search);
    const systemOrderId = queryParams.get('sysorderid');
    const status = queryParams.get('status');
    if (systemOrderId && status === 'fail') {
      const response = await webServices.giftPaymentRetry({ systemOrderId });
      if (response && response.success) {
        setValues({
          ...values,
          firstName: response.data.receiverDetails.firstName,
          lastName: response.data.receiverDetails.lastName,
          email: response.data.receiverDetails.email,
          pFirstName: response.data.senderDetails.firstName,
          pLastName: response.data.senderDetails.lastName,
          pEmail: response.data.senderDetails.email
        });
      }
      setStep(4);
    }

    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);
  }, []);

  useEffect(async () => {
    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (!plans.length) {
      return;
    }
    setSelectedPlan(
      plans.reduce(
        (acc, value) => (acc.numberOfDays > value.numberOfDays ? acc : value),
        {}
      )
    );
  }, [plans]);

  const handleValidationPersonal = () => {
    let isError = false;
    const formerr = { ...formValidation };

    if (isEmpty(values.firstName)) {
      isError = true;
      formerr.firstName = 'The first name field is required.';
      setFormValidation(formerr);
    }

    if (isEmpty(values.lastName)) {
      isError = true;
      formerr.lastName = 'The last name field is required.';
      setFormValidation(formerr);
    }

    if (isEmpty(values.email)) {
      isError = true;
      formerr.email = 'The email field is required.';
      setFormValidation(formerr);
    }

    if (values.email && !isValidEmail(values.email)) {
      isError = true;
      formerr.email = 'Please enter a valid email.';
      setFormValidation(formerr);
    }

    return isError;
  };

  const handleValidationPDetails = () => {
    let isError = false;
    const formerr = { ...formValidation };

    if (isEmpty(values.pFirstName)) {
      isError = true;
      formerr.pFirstName = 'The first name field is required.';
      setFormValidation(formerr);
    }

    if (isEmpty(values.pLastName)) {
      isError = true;
      formerr.pLastName = 'The last name field is required.';
      setFormValidation(formerr);
    }

    if (isEmpty(values.pEmail)) {
      isError = true;
      formerr.pEmail = 'The email field is required.';
      setFormValidation(formerr);
    }

    if (values.pEmail && !isValidEmail(values.pEmail)) {
      isError = true;
      formerr.pEmail = 'Please enter a valid email.';
      setFormValidation(formerr);
    }

    return isError;
  };

  const handleChangeValue = (props) => (e) => {
    setFormValidation({ ...formValidation, [props]: '' });
    setValues({ ...values, [props]: e.target.value });
  };

  const handlePlanClick = (event, plan) => {
    event.preventDefault();
    setSelectedPlan(plan);
  };

  const createPayment = (event, type) => {
    event.preventDefault();
    setIsProcessing(type);
    (async () => {
      const response = await webServices.createOrderForGift({
        planId: selectedPlan.id,
        ...values
      });
      if (response && response.data.result.status === 'CREATED') {
        const approveLink = response.data.result.links.find(
          (link) => link.rel === 'approve'
        );
        if (approveLink) {
          if (type === 'card') {
            const url = new URL(approveLink.href);
            const params = new URLSearchParams(url.search);
            const token = params.get('token');
            if (token) {
              const redirectUrl = `${process.env.REACT_APP_PAYPAL_ENV === 'prod' ? process.env.REACT_APP_CARD_URL : process.env.REACT_APP_CARD_URL_QA}${token}`;
              window.location.replace(redirectUrl);
            }
          } else {
            window.location.replace(approveLink.href);
          }
        }
      }
    })();
  };

  const goToNextStep = (nextStep) => {
    // console.log('vvvvvvvvvvv', nextStep);
    if (nextStep === 3) {
      if (handleValidationPersonal()) return false;
    }
    if (nextStep === 4) {
      if (handleValidationPDetails()) return false;
    }
    setStep(nextStep);
    return null;
  };

  const RenderComponent = () => {
    let components = '';
    if (step === 2) {
      components = (
        <PersonDetailsComponent
          goToNextStep={goToNextStep}
          handleChange={handleChangeValue}
          formValidation={formValidation}
          values={values}
        />
      );
    } else if (step === 3) {
      components = (
        <PaymentDetailsComponent
          goToNextStep={goToNextStep}
          handleChange={handleChangeValue}
          formValidation={formValidation}
          values={values}
        />
      );
    } else if (step === 4) {
      components = (
        <PlanDetailsComponent
          plans={plans}
          goToNextStep={goToNextStep}
          handlePlanClick={handlePlanClick}
          selectedPlan={selectedPlan}
          createPayment={createPayment}
          isProcessing={isProcessing}
        />
      );
    } else {
      components = <GiftLandingComponent goToNextStep={goToNextStep} />;
    }
    return components;
  };
  return (
    <>
      <Helmet>
        <title>
          Be a Matchmaker and give the gift of lasting love | Lovrican
        </title>
        <meta
          name="description"
          content="Gift the gift of lasting love and happiness to a friend or a relative with Lovrican Matchmaker service. Your gift could result in a lifetime of love and happiness for the special someone as they discover their ideal soulmate. Give the gift of love and joy today!"
        />
        <meta name="keywords" content="Become a Matchmaker" />
      </Helmet>
      {isSpinner ? <Spinner active={isSpinner} /> : RenderComponent()}
    </>
  );
};

export default GiftPageComponent;
