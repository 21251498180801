import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

const SwitchButton = ({ value, saveDetails }) => {
  const [gender, setGender] = useState(value?.value);
  const [checked, setChecked] = useState(value?.value === 'WOMAN');
  const handleChange = async (nextChecked) => {
    setChecked(nextChecked);
  };

  const handelGender = async (sex) => {
    if (sex) {
      const gen = sex === 'MAN' ? 'WOMAN' : 'MAN';
      const genObj = { gender: { value: gen, label: gen } };
      const response = await saveDetails(genObj);
      if (response && response.success) {
        toast.success('Updated successfully');
        setGender(gen);
      }
    }
  };

  return (
    <>
      <div className="card-header d-flex justify-content-between align-items-center">
        <span>Gender</span>
      </div>
      <div className="custom-switch mb-4 mt-lg-2 pb-4 inner-Switch">
        <button
        type="button"
        // className="btn btn-sm btn-toggle"
        className={`btn btn-sm btn-toggle ${gender === 'MAN' && 'active'} d-none`}
        data-toggle="button"
        aria-pressed={gender === 'WOMAN'}
        autoComplete="off"
        onClick={() => handelGender(gender)}
      >
        {/* <div className="handle" /> */}
      </button>
        <Switch
          disabled
          checked={checked}
          onChange={handleChange}
          handleDiameter={28}
          offColor="#E4DCE6"
          onColor="#b298b4"
          offHandleColor="#B800E0"
          onHandleColor="#98769b"
          height={40}
          width={120}
          // borderRadius={6}
          // activeBoxShadow="0px 0px 1px 2px #fffc35"
          uncheckedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: 18,
                fontWeight: 'bold',
                paddingRight: 20,
                paddingTop: 2
              }}
            >
              Man
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: 18,
                fontWeight: 'bold',
                color: '#fff',
                paddingRight: 5,
                paddingTop: 2,
                marginLeft: '35px'
              }}
            >
              Woman
            </div>
          }
          className="react-switch"
        />
      </div>
    </>
  );
};

export default SwitchButton;
