import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { toast } from 'react-toastify';
import UserProfileGridCardComponent from './UserProfileGridCardComponent';
import UserProfileListCardComponent from './UserProfileListCardComponent';
import { postRequest } from '../../services/index';
import gridGreyIcon from '../../adminAssets/images/Grid-grey.svg';
import gridWhiteIcon from '../../adminAssets/images/Grid-white.svg';
import listGreyIcon from '../../adminAssets/images/List-grey.svg';
import listWhiteIcon from '../../adminAssets/images/List-white.svg';
import webServices from '../../services/webServices';

const DashboardComponent = forwardRef((props, ref) => {
    const { location } = props;
    const listType = location.pathname.split('/')[2].replace('-profiles', '');
    const userProfileCardTypes = {
        GRID: 'grid',
        LIST: 'list',
    }
    const sortByOptions = {
        MOST_LIKED: {
            text: 'Most Liked',
            value: 'most_liked'
        },
        RECENT: {
            text: 'Newest',
            value: 'recent'
        },
        OLDEST: {
            text: 'Oldest',
            value: 'oldest'
        },
    }

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [pageNo, setPageNo] = useState(0);
    const [userProfileCardType, setUserProfileCardType] = useState(userProfileCardTypes.GRID);
    const [sortByDropdownState, setSortByDropdownState] = useState({
        isOpen: false,
        text: sortByOptions.RECENT.text,
        value: sortByOptions.RECENT.value
    });
    const [usersSearchData, setUsersSearchData] = useState({
        name: '',
        email: '',
        state: '',
        city: '',
        originCountry: '',
        originState: '',
        originCity: '',
        registrationDate: '',
        userId: '',
        hasPhoto: '',
        hasPhoneNo: '',
        hasLinkedinUrl: '',
        hasAboutMe: '',
        hasLookingFor: '',
        salaryRange: { min: 0, max: 500 },
    });

    const [draggedItem, setDraggedItem] = useState(null);
    const [originalIndex, setOriginalIndex] = useState(null);

    const localRef = useRef();
    const loaderRef = useRef(null);
    const currentPageRef = useRef(listType);

    const fetchUsersList = async () => {
        const result = await postRequest(`admin/getUserList/${listType}`, {
            page: pageNo,
            ...usersSearchData,
        });

        setHasMore(() => result.data.length > 0);
        if (result.data.length > 0) {
            setItems(prevItems => [...prevItems, ...result.data]);
            setPageNo(prevPageNo => prevPageNo + 1);
        }
    };

    const searchUsersHandler = (searchFormData = {}) => {
        setItems([]);
        setHasMore(true);
        setPageNo(0);
        setUsersSearchData({
            ...usersSearchData,
            ...searchFormData,
            sortBy: sortByDropdownState.value
        });
    };

    const changeProfileCardViewClickHandler = (typeVal) => {
        setUserProfileCardType(typeVal);
    }

    const updateSortByToggleHandler = () => {
        setSortByDropdownState(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));
    }

    const updateSortByChangeHandler = (event, sortByVal) => {
        event.preventDefault();
        event.stopPropagation();

        const sortByOption = sortByOptions[sortByVal];

        setSortByDropdownState(() => ({
            isOpen: false,
            text: sortByOption.text,
            value: sortByOption.value
        }));
    }

    // Handle drag start
    const handleDragStart = (index) => {
        setDraggedItem(items[index]);
        setOriginalIndex(index);
    };

    // Handle drag over
    const handleDragOver = (index) => {
        const draggedOverItem = items[index];

        // Ignore if the item is dragged over itself
        if (draggedItem === draggedOverItem) {
            return;
        }

        // Filter out the dragged item and reorder the array
        const remainingItems = items.filter((item) => item !== draggedItem);

        // Insert dragged item before the dragged over item
        remainingItems.splice(index, 0, draggedItem);

        setItems(remainingItems);
    };

    // Handle drag end and update the server
    const handleDragEnd = async () => {
        const newIndex = items.indexOf(draggedItem);

        if (originalIndex !== newIndex) {
            webServices.updateUserPremiumProfileOrder({ user_id: draggedItem.id, new_order: (newIndex + 1) })
                .then((response) => {
                    if (response && response.success) {
                        toast.success('Updated successfully');
                    }
                    return response;
                });
        }

        setDraggedItem(null);
        setOriginalIndex(null);
    };

    const removeUserFromUsersListHandler = user => {
        if (currentPageRef.current === 'premium') {
            const newItems = items.filter(el => el.id !== user.id);
            setItems(newItems);
            if (newItems.length === 0) {
                setHasMore(false);
                setPageNo(0);
            }
        }
    }

    useEffect(() => searchUsersHandler(), [sortByDropdownState.value]);

    useEffect(() => {
        if (!hasMore) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchUsersList();
            }
        }, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [hasMore, pageNo]);

    useImperativeHandle(ref, () => ({
        searchUsersHandlerMethod(data) {
            searchUsersHandler(data);
        },
        getElement() {
            return localRef.current;
        }
    }));

    return (
        <>
            <div className="container">
                <div className="top_action_container">
                    <div className="left">
                        {currentPageRef.current === 'premium' && (
                            <div className="toggle_list">
                                <div
                                    className={userProfileCardType === userProfileCardTypes.GRID ? 'active' : ''}
                                    role='button'
                                    onClick={() => changeProfileCardViewClickHandler(userProfileCardTypes.GRID)}
                                    onKeyDown={() => { }}
                                    tabIndex={0}
                                >
                                    <img className="purple" src={gridGreyIcon} alt="" />
                                    <img className="white" src={gridWhiteIcon} alt="" />
                                </div>
                                <div
                                    className={userProfileCardType === userProfileCardTypes.LIST ? 'active' : ''}
                                    role='button'
                                    onClick={() => changeProfileCardViewClickHandler(userProfileCardTypes.LIST)}
                                    onKeyDown={() => { }}
                                    tabIndex={0}
                                >
                                    <img className="purple" src={listGreyIcon} alt="" />
                                    <img className="white" src={listWhiteIcon} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="right">
                        {!['recent', 'premium', 'rejected'].includes(currentPageRef.current) && (
                            <div
                                className="custom_dropdown"
                                role='button'
                                tabIndex={0}
                                onKeyDown={() => { }}
                                onClick={updateSortByToggleHandler}
                            >
                                <span>{sortByDropdownState.text}</span>
                                <i className="fa fa-angle-down" aria-hidden="true" />
                                <div className={`dropdown_list ${sortByDropdownState.isOpen ? 'open' : ''}`}>
                                    <ul className="list-unstyled m-0 p-0">
                                        {Object.keys(sortByOptions).map((key) => {
                                            const { text, value } = sortByOptions[key];

                                            const isActive = value === sortByDropdownState.value ? 'active' : '';

                                            return <li className={`${isActive}`} key={key}>
                                                <a href="#/" onClick={(event) => updateSortByChangeHandler(event, key)}>{text}</a>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {userProfileCardType === userProfileCardTypes.GRID ? (
                <div className="container">
                    <div className="list_group" ref={localRef}>
                        {items.map(user => <UserProfileGridCardComponent user={user} key={user.id} removeUserFromUsersListHandler={removeUserFromUsersListHandler} />)}
                    </div>
                </div>
            ) : (
                <div className="listing_container">
                    <div className="list_wrapper_scroll">
                        <ul className="list-unstyled">
                            {items.map((user, index) => <UserProfileListCardComponent
                                user={user}
                                key={user.id}
                                itemIndex={index}
                                handleDragStartHandler={handleDragStart}
                                handleDragOverHandler={handleDragOver}
                                handleDragEndHandler={handleDragEnd}
                            />)}
                        </ul>
                    </div>
                </div>
            )}


            <div className="container">
                {hasMore && <div ref={loaderRef} style={{ textAlign: 'center' }}>Loading...</div>}
                {!hasMore && pageNo === 0 && <div style={{ textAlign: 'center' }}>No data found.</div>}
            </div>
        </>
    );
});

export default DashboardComponent;
