import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import * as moment from 'moment';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { getAge } from '../helpers';
import { setUser } from '../redux/actions/user.actions';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import verfied from '../assets/images/verified.svg';
import ageIcon from '../assets/images/Cake.svg';
import eyePrivare from '../assets/images/private-profile-icon.svg';
import notVerfied from '../assets/images/verified_Grey.svg';
import BasicDetails from '../components/edit-profile/basicDetails';
import Lifestyle from '../components/edit-profile/lifestyle';
import PersonalInfo from '../components/edit-profile/personal-info';
import EditProfilePhotosPage from './editProfilePhotos';
import VerifiedNotificationPopup from './verifiedNotificationPopup';
import PrivateProfileViewPopup from './privateProfileViewPopup';
import womanPrivate from '../assets/images/woman-blur-preview-private-profile.webp';
import manPrivate from '../assets/images/man-blur-preview-private-profile.webp';

const AccordionCustomToggle = ({
  title,
  eventKey,
  parentCallBack,
  status,
  arrowRight
}) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={() => decoratedOnClick(title, status)}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      {arrowRight ? (
        <span>
          <i className="fa fa-angle-left" />
        </span>
      ) : (
        <span>
          {status === 'open' ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      )}
    </div>
  );
};

const PublicProfilePage = () => {
  // const [checked, setChecked] = useState(false);
  const [provateProfileStatus, setProvateProfileStatus] = useState(false);
  const myRef = useRef(null);
  const location = useLocation();
  const [profilePic, setProfilePic] = useState('no_Image.svg');
  const [userName, setUserName] = useState('');
  const [userAge, setUserAge] = useState(18);
  const [userAdd, setUserAdd] = useState('City, State, Country');
  const [userPrivatedAdd, setUserPrivateAdd] = useState('State, Country');
  const [userOccupation, setUserOccupation] = useState('');
  const [userAbout, setUserAbout] = useState('');
  const [userHobbies, setUserHobbies] = useState([]);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );

  const [masterData, setMasterData] = useState({});
  // const [initOcc] = useState(location?.state?.occordian || '0');
  const [initOcc, setInitOcc] = useState(location?.state?.occordian || '0');

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const [accStatus, setAccStatus] = useState({
    personal: 'open',
    basic: 'close',
    lifestyle: 'close',
    photo: 'close'
  });

  useEffect(() => {
    if (location?.state?.occordian) {
      const init = { ...accStatus };
      let scroll = 0;
      if (location?.state?.occordian === '3') {
        init.photo = 'open';
        scroll = 1450;
      } else {
        init.personal = 'open';
        scroll = 1550;
      }
      setTimeout(() => {
        window.scrollTo(0, scroll);
      }, 100);

      setAccStatus(init);
    }
  }, [location?.state]);

  useEffect(async () => {
    dispatch(pageTitle('Profile'));
    webServices.getMasterData().then((response) => {
      if (response && response.success) {
        setMasterData(response.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails) {
      (async () => {
        const resp = await webServices.getUserDetail();
        if (resp && resp.success === true) {
          dispatch(setUser(resp.data));
        }
      })();
      return;
    }

    if (userDetails && userDetails.id) {
      setProfilePic(userDetails.profile_image || 'no_Image.svg');
      setUserName(
        `${userDetails.first_name || ''} ${userDetails.last_name || ''}`.trim()
      );
      setUserAge(getAge(userDetails.dob));
      setUserAdd(
        `${userDetails.cityLabel ? `${userDetails.cityLabel}, ` : ''} 
        ${userDetails.stateLabel ? `${userDetails.stateLabel}, ` : ''} 
        ${userDetails.countryLabel || ''}`
      );
      setUserPrivateAdd(
        `${userDetails.stateLabel ? `${userDetails.stateLabel}, ` : ''} 
        ${userDetails.countryLabel || ''}`
      );
      setUserAbout(userDetails?.about || '');
      setUserHobbies(userDetails?.hobbies || null);
      setUserOccupation(`${userDetails.occupationLabel || ''}`);
    }
  }, [dispatch, userDetails]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    init.basic = 'close';
    init.lifestyle = 'close';
    init.photo = 'close';

    if (key === '0') {
      init.personal = data;
    }
    if (key === '1') {
      init.basic = data;
    }
    if (key === '2') {
      init.lifestyle = data;
    }
    if (key === '3') {
      init.photo = data;
    }
    setAccStatus(init);
    if (data === 'close') {
      setInitOcc(null);
    } else {
      setInitOcc(key);
    }
  };

  // useEffect(() => {
  //   if (location?.state?.occordian) {
  //     console.log(location.state.occordian);
  //   }
  // }, [location.state]);

  const handleClicOpenAccordian = (val, scroll) => {
    callBack(val, 'open');
    setTimeout(() => {
      window.scrollTo(0, scroll);
    }, 500);
  };

  useEffect(() => {
    if (!userDetails?.profile_active_msg_read_dt && !userDetails?.phone) {
      setVerifyMsg(true);
    }
  }, []);

  const handleClosePrivateProfile = () => {
    setProvateProfileStatus(false);
  };

  const handleChange = async (nextChecked) => {
    const isProfilePrivate = nextChecked ? 'YES' : 'NO';
    webServices
      .updateUserDetails(userDetails.id, { isProfilePrivate })
      .then((response) => {
        if (response && response.success) {
          dispatch(setUser(response.data));
        }
        return response;
      });
  };

  const convertHeightToFeetAndInches = (decimalHeight) => {
    const feet = Math.floor(decimalHeight);
    const inches = Math.round((decimalHeight - feet) * 12);
    return `${feet}’ ${inches}”`;
  };

  return (
    <>
      <div className="inner-wrapper-newdesign profile-wrapper-outer">
        {/* {console.log('userDetailsuserDetails', userDetails)} */}
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="proile_private">
                <div className="profile-view">Private profile feature</div>
                <div className="custom_switch_private">
                  <Switch
                    // disabled
                    checked={userDetails?.is_profile_private === 'YES' || false}
                    onChange={handleChange}
                    handleDiameter={20}
                    offColor="#634a65"
                    onColor="#b800e0"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    height={40}
                    width={120}
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          color: '#fff',
                          fontSize: 18,
                          fontWeight: 'bold',
                          paddingRight: 20,
                          paddingTop: 2
                        }}
                      >
                        Off
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 18,
                          fontWeight: 'bold',
                          color: '#fff',
                          paddingRight: 5,
                          paddingTop: 2,
                          marginLeft: '35px'
                        }}
                      >
                        On
                      </div>
                    }
                    className="react-switch"
                  />
                </div>
                <div className="profile-view">
                  <span>(Blur my photo, hide my city):</span>
                </div>
                {userDetails?.is_profile_private === 'YES' && (
                  <div
                    className="preview_my_profile"
                    aria-hidden="true"
                    onClick={() => setProvateProfileStatus(true)}
                  >
                    <div className="proile_btn">
                      <img
                        src={eyePrivare}
                        className="img-fluid mr-2"
                        alt="arrow icon"
                        aria-hidden="true"
                      />{' '}
                      Preview my public profile
                    </div>
                  </div>
                )}
              </div>
              <div
                className="image-slider-box cursor-pointer"
                aria-hidden="true"
                onClick={() => handleClicOpenAccordian('3', 1450)}
              >
                <div
                  className="user-profile-image"
                  style={{
                    backgroundImage: `url("${
                      process.env.REACT_APP_PIC_BASE_URL + profilePic
                    }")`
                  }}
                />
              </div>
              <div className="user-detail-box profileSection">
                <div className="usr-info-box">
                  <h5>
                    <span className="profile_name" title={userName}>
                      {userName}
                    </span>
                    {/* <span className='dotAge'>&#8226;</span>{userAge}{' '} */}
                    {userDetails?.status === 'ACTIVE' &&
                    userDetails?.phone !== null ? (
                      <img
                        src={verfied}
                        className="img-fluid verifid_img"
                        alt="arrow icon"
                        aria-hidden="true"
                      />
                    ) : (
                      <img
                        src={notVerfied}
                        className="img-fluid verifid_img cursor-pointer"
                        alt="arrow icon"
                        aria-hidden="true"
                        onClick={() => {
                          setVerifyMsg(true);
                        }}
                      />
                    )}
                  </h5>
                  {userOccupation ? (
                    <p
                      className="occupation cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('1', 1450)}
                    >
                      {userOccupation} ({userDetails?.userDetails?.jobTitle})
                      {/* <br /> */}
                      {userDetails?.userDetails?.companyName ? ' at ' : ''}
                      {userDetails?.userDetails?.companyName}
                    </p>
                  ) : (
                    <p
                      className="occupation missting_section_match cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('1', 1450)}
                    >
                      Occupation to be added
                    </p>
                  )}

                  {userAdd && <p className="address">{userAdd}</p>}
                </div>

                <div>
                  <p className="prf_height age_icon">
                    <img src={ageIcon} alt="/" /> {userAge}
                    {/* {moment(userDetails?.dob).format('MMM D, YYYY')} */}
                  </p>
                  <p className="prf_height">
                    {convertHeightToFeetAndInches(userDetails?.height)}
                  </p>
                </div>
                <div className="about-me-box pt-3">
                  <p className="head">Education:</p>
                  <p className="about-dec cursor-pointer">
                    {userDetails?.userDetails?.educationLabel} (
                    {userDetails?.userDetails?.whatStudy})
                    <br />
                    from {userDetails?.userDetails?.schoolName}
                  </p>
                </div>

                <div className="about-me-box pt-3">
                  <p className="head">About Me</p>
                  {userAbout ? (
                    <p
                      className="about-dec cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('0', 1550)}
                    >
                      {userAbout}
                    </p>
                  ) : (
                    <p
                      className="about-dec missting_section_match cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('0', 1550)}
                    >
                      Intro summary to be added
                    </p>
                  )}
                </div>

                <div className="about-me-box pt-3">
                  <p className="head">My Roots:</p>
                  <p className="about-dec cursor-pointer">
                    {userDetails?.userDetails?.origin3?.label},{' '}
                    {userDetails?.userDetails?.origin2?.label},{' '}
                    {userDetails?.userDetails?.origin1?.label}
                  </p>
                </div>

                <div className="about-me-box ">
                  <p className="head">Looking for:</p>
                  <p className="about-dec cursor-pointer">
                    {userDetails?.userDetails?.lookingFor}
                  </p>
                </div>

                <div className="ul-li-box hobbies-wrapper pt-3">
                  <p className="head pb-2">My Hobbies</p>
                  {userHobbies.length > 0 ? (
                    <ul
                      className="color-list cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('0', 2938)}
                    >
                      {userHobbies.map((prop) => (
                        <li key={prop.label}>{prop.label}</li>
                      ))}
                    </ul>
                  ) : (
                    <ul
                      className="color-list missting_section_match cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClicOpenAccordian('0', 2938)}
                    >
                      <li>Hobbies to be added</li>
                    </ul>
                  )}
                </div>
              </div>
              <Accordion
                // defaultActiveKey={initOcc}
                activeKey={initOcc}
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="3"
                      title="My Photos"
                      status={accStatus.photo}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <EditProfilePhotosPage />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card ref={myRef}>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="0"
                      title="Personal Info"
                      status={accStatus.personal}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <PersonalInfo
                        userDetail={userDetails}
                        masterData={masterData}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="1"
                      title="Professional Info"
                      status={accStatus.basic}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <BasicDetails
                        userDetail={userDetails}
                        masterData={masterData}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="2"
                      title="Lifestyle"
                      status={accStatus.lifestyle}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <Lifestyle
                        userDetail={userDetails}
                        masterData={masterData}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={userDetails}
      />

      <PrivateProfileViewPopup
        modalStatus={provateProfileStatus}
        handleClose={handleClosePrivateProfile}
        userDetails={userDetails}
        userName={userName}
        userAge={userAge}
        userOccupation={userOccupation}
        userAdd={userPrivatedAdd}
        userAbout={userAbout}
        userHobbies={userHobbies}
        privateImage={userDetails.gender === 'MAN' ? manPrivate : womanPrivate}
      />
    </>
  );
};

export default PublicProfilePage;
