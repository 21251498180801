import { React } from 'react';
import { Modal } from 'react-bootstrap';

const HowLovricanWorkPopup = ({ modalStatus, handleClose }) => (
  <>
    <Modal
      className="filter-model lovrician-work-model home-wrapper-modal" 
      show={modalStatus}
      onHide={handleClose}
      centered
      // backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
          <h5 className="modal-title w-100 home-modal">Connecting<br/> Successful and <br/>Professional <br/>Nigerian Singles in <br/>America</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        At Lovrican, we understand that most dating sites are open to just about anyone, regardless of their background or aspirations. However, we believe in taking extra efforts to ensure that our platform is specifically designed to cater to the needs of successful and professional Nigerian singles in America. 
        </p>
        <p>Unlike other generic dating platforms, Lovrican goes beyond the surface to curate an exclusive community that is tailored to your specific desires and ambitions. That’s why our screening and approval process is carefully designed to select individuals who are accomplished and align with the high standards and aspirations of our members.</p>
        <p>By joining Lovrican, you gain access to a pool of exceptional individuals who are like-minded, driven and passionate about their personal and professional lives. At Lovrican, we are dedicated to creating an environment that fosters genuine love connections among successful and professional Nigerian singles in America.</p>
        {/* <p>Through our platform, you can embrace your heritage, celebrate your achievements and connect with individuals who truly understand and appreciate your unique life journey.</p> */}
        <p>We believe that borders should not limit choice when selecting a life partner. Through our platform, you can embrace your heritage, celebrate your achievements and connect with individuals who truly understand and appreciate your unique life journey.</p>
        <p>Don’t settle for just any dating site. Choose Lovrican, where we go the extra mile to bring successful and professional Nigerian singles together in a carefully curated and cozy community.</p>
        </Modal.Body>
    </Modal>
  </>
);

export default HowLovricanWorkPopup;
