import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import { setUser } from '../../../../redux/actions/user.actions';
import webServices from '../../../../services/webServices';
import RenderFormElement from '../../../editable-sections/RenderFormElement';
import WdyhauList from '../../../../appConstants/wdyhau';


const SellerInformations = ({
  userDetail,
  masterData,
  viewMode,
  forwardedRef
}) => {
  const dispatch = useDispatch();
  const [formControls, setFormControls] = useState(null);

  const handleSaveDetails = (values) =>
    webServices.updateUserDetails(userDetail.id, values).then((response) => {
      if (response && response.success) {
        dispatch(setUser(response.data));
      }
      return response;
    });

 

  const handleRangeChange = (props) => async (event) => {
    const formControlVal = { ...formControls };
    formControlVal[props].value = event;
    setFormControls(formControlVal);
  };

  const handleRangeChangeComplate = (props) => (val) => {
    handleSaveDetails({ [props]: val });
  };

  useEffect(() => {
    if (!userDetail) {
      return;
    }

    setFormControls({
      gender: {
        label: 'Gender',
        type: 'switch',
        options: [
          { value: 'MAN', label: 'MAN' },
          { value: 'WOMAN', label: 'WOMAN' }
        ],
        value: {
          label: userDetail?.gender || '',
          value: userDetail?.gender || ''
        }
      },
      dob: {
        label: 'Date of Birth',
        type: 'dob',
        value: moment(userDetail?.dob).format('MMMM . DD . YYYY'),
        isLock: !viewMode
      },
      residence: {
        label: 'Residence',
        type: 'residence',
        value1: userDetail?.countryLabel
          ? {
              label: userDetail?.countryLabel,
              value: userDetail?.countryValue
            }
          : '',
        value2: userDetail?.stateLabel
          ? {
              label: userDetail?.stateLabel,
              value: userDetail?.stateValue
            }
          : '',
        value3: userDetail?.cityLabel
          ? {
              label: userDetail?.cityLabel,
              value: userDetail?.cityLabel
            }
          : '',
        isDisabled:
          (userDetail?.countryLabel &&
            userDetail?.stateLabel &&
            userDetail?.cityLabel) ||
          false
      },
      values_col: {
        label: 'Values',
        type: !viewMode ? 'dropdown' : '',
        options: masterData.valuesDetails,
        value: userDetail?.values_col || '',
        isMulti: true,
        isLock: !viewMode
      },
      linkedin_URL: {
        label: 'LinkedIn URL',
        type: !viewMode ? 'text' : '',
        placeholder: 'Enter your LinkedIn URL',
        value: userDetail?.linkedin_URL,
        isTextArea: true,
        isLock: !viewMode
      },
      occupation: {
        label: 'Occupation',
        type: 'dropdown',
        options: masterData.occupations,
        value: userDetail?.occupationValue
          ? {
              label: userDetail?.occupationLabel,
              value: userDetail?.occupationValue
            }
          : ''
      },
      wdyhau: {
        label: 'How did you hear about us?',
        type: !viewMode ? 'dropdown' : '',
        options: WdyhauList,
        value: userDetail?.wdyhauLabel
          ? {
              label: userDetail?.wdyhauLabel,
              value: userDetail?.wdyhauValue
            }
          : '',
        isLock: !viewMode
      },
    });
  }, [userDetail, masterData]);

  return (
    <>
      {/* {console.log('userDetail', userDetail)} */}
      {formControls &&
        Object.keys(formControls).map((formControlName) => (
          <RenderFormElement
            ref={forwardedRef}
            isViewMode={viewMode}
            key={formControlName}
            formControlName={formControlName}
            {...formControls[formControlName]}
            saveDetails={handleSaveDetails}
            handleRangeChange={handleRangeChange(formControlName)}
            handleRangeChangeComplate={handleRangeChangeComplate(
              formControlName
            )}
          />
        ))}
    </>
  );
};

export default SellerInformations;
