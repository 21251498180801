import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import privatelock from '../../assets/images/private_lock.svg';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step8Component = ({ userDetails, masterData, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    culturalGroup: userDetails?.userDetails?.ethnicGroupValue
      ? [
          {
            label: userDetails?.userDetails?.ethnicGroupLabel,
            value: userDetails?.userDetails?.ethnicGroupValue
          }
        ]
      : [],
    languages: userDetails?.userDetails?.languages
      ? [userDetails?.userDetails?.languages]
      : [],
    culturGroupHelp: false,
    subCultureGroupHelp: false
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        ethnicGroup: values.culturalGroup,
        languages: JSON.stringify(values.languages[0])
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step8" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Share your cultural background. It helps us find you matches who
                suit your compatibility preferences
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join1 six_step">
            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Cultural Group"
                options={masterData.cultrual}
                autoComplete="off"
                className="custom_input"
                values={values.culturalGroup}
                onChange={(value) =>
                  setValues({ ...values, culturalGroup: value[0] })
                }
              />

              {validator.message(
                'culturalGroup',
                values.culturalGroup,
                'required'
              )}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      culturGroupHelp: true,
                      subCultureGroupHelp: false
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.culturGroupHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({ ...values, culturGroupHelp: false })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Sub-culture (Ethnic Group/Tribe)"
                options={masterData.languages}
                className="custom_input"
                values={values.languages}
                onChange={(value) => setValues({ ...values, languages: value })}
              />
              {validator.message('Sub-culture', values.languages, 'required')}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      culturGroupHelp: false,
                      subCultureGroupHelp: true
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.subCultureGroupHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({ ...values, subCultureGroupHelp: false })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Step8Component;
