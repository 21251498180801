import toastr from 'toastr';
import authService from './authServices';

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: '3000',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};
const axios = require('axios').default;

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// axios.defaults.baseURL = 'https://dev.lovrican.com/apis/v1/';
// axios.defaults.baseURL = 'https://lovrican.com/apis/v1/';
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = authService.getToken();
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZG9iIjoiMTk5MS0wMy0xNlQxODozMDowMC4wMDBaIiwiaWF0IjoxNjc3OTQ1NTcyfQ.DhsXWM3QicOKrsCiMzvmYd0HC8q6KYgeZL-ZJFJt-JE';
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error

    toastr.error('ERROR! Not able to send any request due to an exception.');
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.data.success !== true && !response.data.id) {
      toastr.error('ERROR! Internal Server side exception has occoured.');
    }
    return response;
  },
  (err) => {
    // console.log(JSON.stringify(err.response));
    // console.log(JSON.stringify(err.response.headers));
    if (err.response) {
      if (err.response.data) {
        if (err.response.data.message) {
          toastr.remove();
          toastr.clear();
          toastr.error(`${err.response.data.message}`);
        } else {
          toastr.error('ERROR! Server side exception has occoured.');
        }
      } else {
        toastr.error('ERROR! An exception has occoured.');
      }

      // if (err.response.status === 401) {
      //   // authService.logout();
      //   window.location.href = '/login';
      //   authService.logout();
      // }
    } else if (err.request) {
      toastr.error(
        'ERROR! Server is not responding, please check your internet connection.'
      );
    } else {
      toastr.error(
        'ERROR! Not able to complete the request due to an exception.'
      );
    }
    return Promise.reject(err);
  }
);

export const authCall = async (url, body) => {
  let result;
  await axios
    .post(url, body)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => error);
  return result;
};

export const fetchData = async (url) => {
  let result;
  await axios
    .get(url)
    .then((response) => {
      result = response.data;
    })
    .catch((error) => error);
  return result;
};

export const postRequest = (url, data) =>
  axios
    .post(url, data)
    .then((response) => response.data)
    .catch((error) => error);

export const putRequest = (url, dataToSend) =>
  axios
    .put(url, dataToSend)
    .then((res) => res.data)
    .catch((error) => error);
