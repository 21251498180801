/* eslint-disable react/no-did-update-set-state */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import OtpInput from 'react-otp-input';
import authServices from '../../services/authServices';
// import OtpInputCustom from '../general/otpInput';
import { setUser } from '../../redux/actions/user.actions';
import AppConstants from '../../appConstants';
import { isEmpty, isValidOtp } from '../../helpers/validator';
import { authCall } from '../../services';
import logo from '../../assets/images/Logo.svg';

const EmailVerifyComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const { logout } = authServices;

  const [values, setValues] = useState({
    email: '',
    otp: '',
    otpAttamp: 0,
    isError: false,
    userDetails: '',
    resendCodes: false
  });

  useEffect(async () => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    setValues({
      ...values,
      email
    });
    const resendOtpUrl = 'auth/action/get-details-by-email';
    const resp = await authCall(resendOtpUrl, {
      email
    });
    if (resp && resp.success === true) {
      setValues({
        ...values,
        email,
        userDetails: resp.data
      });
    }
  }, []);

  // async componentDidUpdate(prevProps, prevState) {

  //   const { otp } = state;
  //   if (prevState.otp !== otp) {
  //     if (isValidOtp(otp)) submitForm();
  //   }

  //   console.log('prevState', prevState);
  //   console.log('prevProps', prevProps);
  //   // const { email } = state;
  //   // const resendOtpUrl = 'auth/action/get-details-by-email';
  //   //   const resp = await authCall(resendOtpUrl, {
  //   //     email
  //   //   });
  //   //   if (resp && resp.success === true) {
  //   //     setValues({
  //   //       userDetails: resp.data
  //   //     });
  //   //   }
  // }

  const resendCode = async () => {
    const { email } = values;
    const resendOtpUrl = 'auth/action/email-verification-otp';
    if (isEmpty(email)) {
      toast.error(
        "We're not getting any Email to send the code, please try again from starting."
      );
    } else {
      const resp = await authCall(resendOtpUrl, {
        email
      });

      if (resp && resp.success === true) {
        setValues({ ...values, resendCodes: true, otpAttamp: 0 });
      }
    }
  };

  const validateForm = async () => {
    const { otp } = values;
    let ret = false;
    if (isValidOtp(otp)) {
      setValues({
        ...values,
        isError: false
      });
      ret = true;
    } else {
      toast.error(
        'Please enter a valid 4-digit code that we just sent you via email.'
      );
      setValues({
        ...values,
        isError: true
      });
    }
    return ret;
  };

  const submitForm = async () => {
    const { email, otp, otpAttamp } = values;
    const verifyOtpUrl = 'auth/action/verify-email-otp-for-password';

    if (otpAttamp < AppConstants.OTP_ATTEMP_LIMIT) {
      if (validateForm()) {
        const resp = await authCall(verifyOtpUrl, {
          email,
          otp
        });

        if (resp && resp.success === true) {
          dispatch(setUser({ ...current, is_email_verified: "1" }));
          history.push({
            pathname: AppConstants.routes.afterLogin
          });
        } else {
          setValues({
            ...values,
            otpAttamp: otpAttamp + 1,
            isError: true
          });
        }
      }
    } else {
      toast.error(
        'You have reached your maximum limit for entering a valid code. Please generate a new code and try again.'
      );
      setValues({
        ...values,
        otpAttamp: otpAttamp + 1
      });
    }
  };

  useEffect(async () => {
    const { otp, resendCodes } = values;
    if (otp && otp?.length === 4) {
      if (isValidOtp(otp)) submitForm();
    }
    if (resendCodes === true) {
      setTimeout(() => {
        setValues({ ...values, resendCodes: false });
      }, 6000);
    }
  }, [values.otp, values.resendCodes]);

  const otpChange = (otpInner) => {
    setValues({ ...values, otp: otpInner });
    setTimeout(() => {
      const valuess = Array.from(
        document.querySelectorAll('.inputStyleNew')
      ).map((ele) => {
        if (ele.value) {
          ele.className = 'inputStyleNew focus-color'; // eslint-disable-line no-param-reassign
        }
        return ele.value;
      });
      /* eslint-disable no-console */
      console.log('Inputs Values : ', valuess);
      /* eslint-enable no-console */
    }, 100);
  };

  const hanldleSkip = () => {
    if (!current) {
      history.push(AppConstants.routes.login);
    } else {
      history.push(AppConstants.routes.afterLogin);
    }
  };

  return (
    <>
      <section className="otpWraper text-light position-relative forget_Password_OTP">
        <div className="container-fluid">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            limit={3}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="row">
            <div className="col-12 text-center">
              <div
                className="logo cursor-pointer"
                onClick={() => {
                  logout(history);
                  history.push('/');
                }}
                onKeyDown={(event) =>
                  event.key === 'Enter' && history.push('/')
                }
                role="button"
                tabIndex="0"
              >
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="reg-inner">
              <div className="col-12 mar-top-2 position-relative">
                <h1>
                  Please enter the <br />
                  4-digit code <br />
                  we just sent you.
                </h1>
                {/* <div className="email-verifection-dot ">
                  <span className="dot_wrapper ">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </span>
                </div> */}
              </div>
              <div className="col-12">
                <OtpInput
                  value={values.otp}
                  onChange={(otpInner) => otpChange(otpInner)}
                  containerStyle="userOtpBbox"
                  inputStyle="inputStyleNew"
                  shouldAutoFocus
                  isInputNum
                  hasErrored={values.isError}
                  isDisabled={values?.otpAttamp > AppConstants.OTP_ATTEMP_LIMIT}
                />
              </div>
              <div className="col-12 resend-code-text">
                <p>
                  Didn&#x2019;t get the code?{' '}
                  <span
                    onClick={resendCode}
                    onKeyDown={(event) => event.key === 'Enter' && resendCode()}
                    role="button"
                    tabIndex="0"
                  >
                    Resend code
                  </span>
                </p>
              </div>
              {values.resendCodes ? (
                <>
                  <div className="col-12 ">
                    <p className="msg">
                      Code has been sent to your registered Email.
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {values?.userDetails?.days > 0 && (
              <div className="email-verification-day-wrapper">
                <div
                  className="skipBtn"
                  role="button"
                  onClick={hanldleSkip}
                  aria-hidden="true"
                >
                  skip
                </div>
                <p>{values?.userDetails?.days} days remaining</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailVerifyComponent;
