import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { authCall, fetchData } from '../../../../services';
import AppConstants from '../../../../appConstants';

const MyReferals = () => {
  const history = useHistory();
  const [performance, setPerformance] = useState({});
  const [countryPerformance, setCountryPerformance] = useState([]);
  const [referalList, setReferalList] = useState([]);

  useEffect(async () => {
    const resp = await fetchData('affiliate/getCampaignPerformance');
    if (resp && resp?.success) {
      setPerformance(resp?.data);
    }
    const resp2 = await authCall('affiliate/getPaidReferals', { limit: 5 });
    if (resp2 && resp2?.success) {
      setReferalList(resp2?.data);
    }
    const resp3 = await authCall('affiliate/getCountryPerformance', {
      limit: 5
    });
    if (resp3 && resp3?.success) {
      setCountryPerformance(resp3?.data);
    }
  }, []);

  const handleClickViewAll = () => {
    history.push(AppConstants.routes.affiliate.referals);
  };

  return (
    <>
      <div className="aff_my_reff">
        <div className="heading">Referal Summary</div>
        <div className="row">
          <div className="col-12">
            <p>Visitors</p>
            <div className="progress">
              <div
                className="progress-bar skyblu_color"
                style={{ width: performance?.visitors > 0 ? '100%' : 0 }}
              />
            </div>
            <p className="caption_text">{performance?.visitors || 0}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Incomplete Registration</p>
            <div className="progress">
              <div
                className="progress-bar orange_color"
                style={{
                  width: `${
                    performance?.incompleteRegistration?.percentage || 0
                  }%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.incompleteRegistration?.incomplete || 0} •{' '}
              {performance?.incompleteRegistration?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Completed Registration</p>
            <div className="progress">
              <div
                className="progress-bar green_color"
                style={{
                  width: `${
                    performance?.complatedRegistration?.percentage || 0
                  }%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.complatedRegistration?.complete || 0} •{' '}
              {performance?.complatedRegistration?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Membership Purchased</p>
            <div className="progress">
              <div
                className="progress-bar purple_color"
                style={{
                  width: `${performance?.membershipPurchase?.percentage || 0}%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.membershipPurchase?.membership || 0} •{' '}
              {performance?.membershipPurchase?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="divider_aff" />
          </div>
        </div>

        <div className="row aff_table_wrapper">
          <div className="col-12">
            <div className="table_heading">Recent Paid Referals</div>
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Country</th>
                  <th>Earned</th>
                </tr>
              </thead>
              <tbody>
                {referalList.map((list) => (
                  <tr key={list.id}>
                    <td>{moment(list.createdAt).format('MMM, DD, YYYY')}</td>
                    <td>{list?.referals?.firstCharOfName}***</td>
                    <td>
                      <span>${list.amount}</span>
                    </td>
                    <td>{list?.referals?.countryLabel}</td>
                    <td>
                      <span>${list.earning}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              className="btn btn_aff_common mb-3"
              onClick={handleClickViewAll}
            >
              View All
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="divider_aff" />
          </div>
        </div>

        <div className="heading">Top 5 Paid Users Countries</div>

        {countryPerformance.map((list) => (
          <div className="row" key={list.id}>
            <div className="col-12">
              <p>{list.countryLabel}</p>
              <div className="progress">
                <div
                  className="progress-bar purple_color"
                  style={{ width: `${list.percentage}%` }}
                />
              </div>
              <p className="caption_text">
                {list.total} • {list.percentage}%
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyReferals;
