import React, { useMemo } from 'react';

const ProfileImages = ({
  images
}) => {
  if (!images || !images.length) {
    return null;
  }
  

  return (
    <>
      {images.map((image) => (
        <div key={image} className="col-md-6">
          <div className="photo-frame">
            <img src={image} alt="" />
          </div>
        </div>
      ))}
    </>
  );
};

const ViewProfilePhotosPage = ({userDetail}) => {
  
  const profileImages = useMemo(
    () =>
      (userDetail?.images || [])
        .map(
          (imageName) => `${process.env.REACT_APP_PIC_BASE_URL}${imageName}`
        ),
    [userDetail?.images]
  );

  return (
    <>
      <div className="photo-outer-wrapper">
        <div className="row">
          <ProfileImages
            images={profileImages}
            profileImg={userDetail?.profile_image}
          />
        </div>
      </div>
    </>
  );
};

export default ViewProfilePhotosPage;
