import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import AppConstants from '../appConstants';
import webServices from '../services/webServices';
import { pageTitle } from '../redux/actions/home.actions';
import { getUserPlansDetails } from '../redux/actions/user.actions';
import Spinner from '../components/general/spinner';
import { authCall } from '../services';
import Section2remainderPopup from './section2remainderPopup';
import NoActivePlanPopup from './noActivePlanPopup';

const AccordionCustomToggle = ({ title }) => {
  const decoratedOnClick = useAccordionToggle();

  return (
    <div
      className="card-link"
      onClick={decoratedOnClick}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      <span>
        <i className="fa fa-chevron-right" />
      </span>
    </div>
  );
};

const PlansViewPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { planDetails, current } = useSelector((state) => state.user);
  const [isOrder, setIsOrder] = useState(false);
  const [isRoseHistory, setIsRoseHistory] = useState(false);
  const [isSpinner, setIsSpinner] = useState(true);

  const [section2RemainderMsg, setSection2RemainderMsg] = useState(false);
  const handleCloseSection2Remainder = () => setSection2RemainderMsg(false);

  const [showActivePlan, setShowActivePlan] = useState(false);
  const handleCloseActivePlan = () => setShowActivePlan(false);

  useEffect(() => {
    dispatch(pageTitle('Plans & History'));
    dispatch(getUserPlansDetails());
  }, [dispatch]);

  useEffect(async () => {
    const resp = await webServices.getOrderDetails();
    if (resp && resp.success) {
      setIsSpinner(false);
      if (resp.data.length > 0) {
        setIsOrder(true);
      }
    }
  }, []);

  useEffect(async () => {
    const rhResp = await webServices.getRoseHistory();
    if (rhResp && rhResp.success) {
      setIsSpinner(false);
      if (rhResp.data.length > 0) {
        setIsRoseHistory(true);
      }
    }
  }, []);

  const handleClickRedirect = async (redirectTo, action = '') => {
    if (['buyLike', 'buyRose', 'subscriptionPlan'].includes(action)) {
      const resp = await authCall('user/userCheckPermission', { action });
      if (resp.data === 'profileNotApproved') {
        history.push(AppConstants.routes.congratulations);
      } else if (resp.data === 'noActivePlan') {
        setShowActivePlan(true);
      } else {
        history.push({
          pathname: redirectTo,
          state: { planDetails }
        });
      }
    } else {
      history.push({
        pathname: redirectTo,
        state: { planDetails }
      });
    }


    // const resp = await authCall('user/userCheckPermission', { action: 'plan' });
    // if(act === 'rosehistory' || act === 'likehistory'){
    //   history.push({
    //     pathname: redirectTo,
    //     state: { planDetails }
    //   });
    // }
    // else if (resp.data === 'profileNotComplete') {
    //   setSection2RemainderMsg(true);
    // } else if (resp.data === 'profileNotApproved') {
    //   history.push(AppConstants.routes.congratulations);
    // } else {
    //   history.push({
    //     pathname: redirectTo,
    //     state: { planDetails }
    //   });
    // }
  };

  return (
    <>
      {isSpinner ? (
        <Spinner active={isSpinner} />
      ) : (
        <div className="inner-wrapper-newdesign">
          <div className="planViewWrapper">
            {/* OLD PLAN VIEW PAGE DESIGN */}
            <div className="profile-wrapper d-none">
              <Accordion
                defaultActiveKey="0"
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card>
                  <Card.Header
                    className="pb-2"
                    onClick={() =>
                      handleClickRedirect(AppConstants.routes.plans)
                    }
                  >
                    <AccordionCustomToggle title="Subscription Plans" />
                  </Card.Header>
                </Card>
                <Card>
                  <Card.Header
                    className="pb-2"
                    onClick={() =>
                      handleClickRedirect(AppConstants.routes.rosePlans)
                    }
                  >
                    <AccordionCustomToggle title="Rose Plans" />
                  </Card.Header>
                </Card>
                {isOrder && (
                  <Card>
                    <Card.Header
                      className="pb-2"
                      onClick={() =>
                        handleClickRedirect(
                          isOrder
                            ? AppConstants.routes.billingDetails
                            : AppConstants.routes.plans
                        )
                      }
                    >
                      <AccordionCustomToggle title="Payment History" />
                    </Card.Header>
                  </Card>
                )}
                {isRoseHistory && (
                  <Card>
                    <Card.Header
                      className="pb-2"
                      onClick={() =>
                        handleClickRedirect(AppConstants.routes.roseHistory)
                      }
                    >
                      <AccordionCustomToggle title="Rose History" />
                    </Card.Header>
                  </Card>
                )}
              </Accordion>
            </div>
            {/* OLD PLAN VIEW PAGE DESIGN */}

            <div className="plan-wrapper">
              <ul className="nav nav-tabs " role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active plan"
                    data-toggle="tab"
                    href="#plan"
                  >
                    Plans
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link history"
                    data-toggle="tab"
                    href="#history"
                  >
                    History
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div id="plan" className="tab-pane tab-plan active">
                  <ul className="list-unstyled">
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(AppConstants.routes.plans, 'subscriptionPlan')
                        }
                      >
                        Subscription Plans
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(AppConstants.routes.rosePlans, 'buyRose')
                        }
                      >
                        Rose Plans
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(AppConstants.routes.likesPlans, 'buyLike')
                        }
                      >
                        Likes Plans
                      </button>
                    </li>
                  </ul>
                </div>
                <div id="history" className="tab-pane tab-history fade">
                  <ul className="list-unstyled">
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(
                            isOrder
                              ? AppConstants.routes.billingDetails
                              : AppConstants.routes.plans
                          )
                        }
                      >
                        Payment History
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(AppConstants.routes.roseHistory, 'rosehistory')
                        }
                      >
                        Rose History
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          handleClickRedirect(AppConstants.routes.likeHistory, 'likehistory')
                        }
                      >
                        Like History
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <NoActivePlanPopup
            modalStatus={showActivePlan}
            handleClose={handleCloseActivePlan}
            // currentPlan={planDetails}
            // userDetails={current}
          />
        </div>
      )}

      <Section2remainderPopup
        modalStatus={section2RemainderMsg}
        handleClose={handleCloseSection2Remainder}
        userDetails={current}
      />
    </>
  );
};

export default PlansViewPage;
