import React from 'react';
import { Helmet } from 'react-helmet';

function CommunityStandardsPage() {
  return (
    <>
      <Helmet>
        <title>Community Standards | Lovrican</title>
        <meta
          name="description"
          content="Explore our community standards to understand the values and principles that shape our platform. Discover how we foster a respectful and welcoming environment."
        />
        <meta name="keywords" content="Community Standards" />
      </Helmet>
      <div className="row">
        <div className="col col-md-12">
          <div>
            <div className="service-content">
              <div className="sub-inner">
                <div className="sub-heading">
                  <h5>Lovrican Community Standards</h5>
                </div>
                <div className="subhead text-left">
                  <p className="pb-4">
                    By posting on our site you agree to all of the following
                    Community Standards, our&nbsp;Community Moderation Policies
                    &amp; Practices&nbsp;and the
                    <a
                      href={process.env.REACT_APP_BASE_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="pl-1 pr-1 d-inline-block"
                    >
                      www.Lovrican.com
                    </a>
                    Terms of Service, which are designed to create a welcoming,
                    friendly and supportive atmosphere in our
                    Community.&nbsp;&nbsp;These documents are subject to change,
                    so please bookmark this page and check back occasionally.
                  </p>
                  <ol type="number">
                    <li>
                      <span>Be kind and respectful.</span>
                      &nbsp;Personal attacks or hate speech will not be
                      tolerated. &nbsp;When giving advice to other Members,
                      please remember that it is a real testament of who you are
                      when you are both honest and kind. Let all your
                      conversation and advice come from a place of love.
                    </li>
                    <li>
                      <span>Be welcoming, especially to newer members.</span>
                      &nbsp;We don’t tolerate anyone being rude to our new
                      members. We’ve all been the new person in an environment -
                      a new job, new neighborhood, new school, etc. You’ll
                      remember how much you appreciated the first person to say
                      hi, smile at you, show you around or simply say welcome.
                      Be like that person to the new people here in Lovrican and
                      they’ll truly appreciate that.{' '}
                    </li>
                    <li>
                      <span>Keep it clean.</span>
                      &nbsp;Profane or obscene language or photos, or attempts
                      to bypass our moderation filters will result in your
                      entire post being removed.
                    </li>
                    <li>
                      <span>
                        No posts containing or encouraging spam, advertising,
                        fraudulent/ illegal activity or personally identifiable
                        information (email address, etc.).
                      </span>
                      &nbsp;This includes potentially libelous or defamatory
                      postings, material posted in potential breach of
                      copyright, or any post encouraging violation of the
                      Lovrican Advice Community Standards or&nbsp;Lovrican Terms
                      of Service. &nbsp;Any such postings will be removed.
                    </li>
                    <li>
                      <span>Respect the Lovrican staff.</span>
                      While we appreciate legitimate feedback about our service
                      and website, any post containing derogatory comments about
                      Lovrican staff or members of our Member-Moderator team is
                      considered a personal attack will be removed.
                    </li>
                    <li>
                      <span>
                        Do your best to communicate as clearly as possible.
                      </span>
                      Text isn’t always a great medium for conversation: tone of
                      voice – sarcasm, humor, etc. do not always come across the
                      right way when using words on a screen. &nbsp;Be aware
                      that you may be misunderstood, so try to be very clear
                      about what you are saying. &nbsp;Also, don’t type in all
                      CAPS, as your readers may consider this shouting. Divide
                      your posts into paragraphs for easy reading, and go easy
                      on the abbreviations, (TTL, OMG, etc.) as this may limit
                      your effectiveness communicating with some Community
                      Members.
                    </li>
                    <li>
                      <span>
                        Members who seriously, persistently or willfully ignore
                        the Community Standards,&nbsp;Moderation Policies and
                        Practice or Lovrican.com Terms of Service&nbsp;will have
                        their posting privileges on Lovrican Advice revoked.
                      </span>
                      This is not an action that we take lightly or arbitrarily.
                      However, our mission is to create and maintain a positive
                      experience for our Members, and we reserve the right to
                      make decisions which we feel support that. Prior to any
                      banning, when possible the participant will be warned and
                      given a chance to adhere to our Community Standards.
                      &nbsp;However, depending on the circumstances and the
                      severity of the offense, we reserve the right to ban a
                      user without prior notification.
                    </li>
                    <li>
                      <span>
                        Our goal is to create and maintain a pleasant and
                        welcoming environment for everyone.
                      </span>{' '}
                      To that end,&nbsp;we reserve the right to remove any post,
                      thread or Community Member&nbsp;whom we deem to be in
                      violation of the Community Standards or of detriment to
                      the spirit of our Community.
                      <br />
                      Thank you in advance for your cooperation in following our
                      Community Standards – by doing so you help make the
                      Lovrican Advice Community a great place to be!
                    </li>
                    <li>
                      <span>Republishing Your Words.</span> One of the best ways
                      to encourage more participation in these discussions is by
                      sharing your very best quotes with a wider audience.
                      Therefore, we may take various quotes and use them in
                      emails, advertising, and promotional efforts. If we do so,
                      we’ll only use your screen name and we promise to use
                      great care in the quotes we choose. We will never use a
                      quote from you that is embarrassing, endangering or
                      inflammatory.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityStandardsPage;
