import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// import { useHistory } from 'react-router-dom';
// import AppConstants from '../../appConstants';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/icon5.png';
import arrow from '../../assets/images/arrow.svg';
import { authCall } from '../../services';
import arrowIcon from '../../assets/images/arrow-circle.svg';

const SectionsLandingComponent = ({ goToNextStep }) => {
  const handleSubmit = async () => {
    const resp = await authCall('auth/register-section', {
      profileLevel: 2
    });
    if (resp && resp.success === true) {
      goToNextStep({ step: 2 });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleSubmit();
    }, 5000);
  }, [setTimeout]);

  return (
    <>
      <div className="container">
        <div className="accountSucess congra_lov_account">
          <div className="row">
            <div className="col-12">
              <h3>
                {' '}
                Congratulations on creating your <br />
                Lovrican account!
              </h3>
            </div>
            <div className="col-md-6">
              <div className="row lov-work align-items-end pb-2 mb-2">
                <div className="col-md-3 text-center">
                  <img className="img-fluid" src={icon1} alt="" />
                </div>
                <div className="col-md-9 text-left">
                  <div className="d-flex mt-4">
                    <div className="sno text-center">
                      <div className="circle">1</div>
                    </div>
                    <div className="box">
                      <div className="head">Submit your basic profile.</div>
                      <div className="dec">
                        Express interest to join Lovrican
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-2 text-center">
                  <img className="img-fluid" src={icon2} alt="" />
                </div>
                <div className="col-md-9 text-left">
                  <div className="d-flex mt-4">
                    <div className="sno text-center">
                      <div className="circle">2</div>
                    </div>
                    <div className="box">
                      <div className="head">Lovrican screens applicants.</div>
                      <div className="dec">
                        A Lovrican specialist approves people best fit for the
                        Lovrican service
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-3 text-center">
                  <img className="img-fluid" src={icon3} alt="" />
                </div>
                <div className="col-md-9 text-left">
                  <div className="d-flex mt-4">
                    <div className="sno text-center">
                      <div className="circle">3</div>
                    </div>
                    <div className="box">
                      <div className="head">
                        Select matches who capture your interest.
                      </div>
                      <div className="dec">
                        Explore curated & pre-screened matches in your match
                        queue
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mt-3 text-center">
                  <img className="img-fluid" src={icon4} alt="" />
                </div>
                <div className="col-md-9 text-left">
                  <div className="d-flex mt-4">
                    <div className="sno text-center">
                      <div className="circle">4</div>
                    </div>
                    <div className="box">
                      <div className="head">
                        Connect with exceptional matches based on your
                        preferences and values.
                      </div>
                      <div className="dec">
                        Get matched with highly compatible matches
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-3 text-center">
                  <img className="img-fluid" src={icon5} alt="" />
                </div>
                <div className="col-md-9 text-left">
                  <div className="d-flex d-flex mt-4">
                    <div className="sno text-center">
                      <div className="circle">5</div>
                    </div>
                    <div className="box border-0">
                      <div className="head">
                        Discover your special African soulmate.
                      </div>
                      <div className="dec">
                        Spend a lifetime of love, happiness, and success with
                        your soulmate
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 pl-md-4">
              <div className="arrow-wrapper">
                <img src={arrow} className="img-fluid" alt="arrow" />
              </div>

              <h2>
                Next Step:
                <br />
                Complete Your
                <br />
                Basic Profile
              </h2>
              <p> The basic profile has two sections:</p>
              <p>• Section 1 enables us to complete our screening process.</p>
              <p>• Section 2 introduces you to your potential matches.</p>
              <div className="pt-2 pb-4 pb-md-0">
                <span
                  className="search-btn-common"
                  onClick={handleSubmit}
                  onKeyDown={(event) => event.key === 'Enter' && handleSubmit()}
                  role="button"
                  tabIndex="0"
                >
                  {/* <i className="fa fa-arrow-right" /> */}
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionsLandingComponent;
