/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import AppConstants from '../../appConstants';
import { isEmpty, isValidOtp } from '../../helpers/validator';
import { authCall } from '../../services';

class OtpInputCustom extends React.Component {
  constructor(props) {
    super(props);
    const { u_email: uEmail } = this.props;
    this.state = {
      u_email: uEmail,
      step: 2,
      otp: '',
      otpAttamp: 0,
      isError: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { u_email: uEmail } = this.props;
    if (prevProps.u_email !== uEmail) {
      this.setState({
        u_email: uEmail
      });
    }

    const { otp } = this.state;
    if (prevState.otp !== otp) {
      if (isValidOtp(otp)) this.submitForm();
    }
  }

  resendCode = async () => {
    const { u_email: uEmail } = this.state;
    const { resendOtpUrl } = this.props;
    const email = uEmail;
    if (isEmpty(email)) {
      toast.error(
        "We're not getting any Email to send the code, please try again from starting.",
        {
          onClose: () => {
            window.location = AppConstants.routes.register;
          }
        }
      );
    } else {
      const resp = await authCall(resendOtpUrl, {
        email: uEmail
      });

      if (resp && resp.success === true) {
        this.setState({
          resendCode: true,
          otpAttamp: 0
        });
      }
    }
  };

  validateForm = async () => {
    const { otp } = this.state;
    let ret = false;
    if (isValidOtp(otp)) {
      this.setState({
        isError: false
      });
      ret = true;
    } else {
      toast.error(
        'Please enter a valid 4-digit code that we just sent you via email.'
      );
      this.setState({
        isError: true
      });
    }
    return ret;
  };

  submitForm = async () => {
    const { u_email: uEmail, otp, otpAttamp } = this.state;
    const { verifyOtpUrl } = this.props;

    if (otpAttamp < AppConstants.OTP_ATTEMP_LIMIT) {
      if (this.validateForm()) {
        const resp = await authCall(verifyOtpUrl, {
          email: uEmail,
          otp
        });

        if (resp && resp.success === true) {
          this.goToNext();
        } else {
          this.setState({
            otpAttamp: otpAttamp + 1,
            isError: true
          });
        }
      }
    } else {
      toast.error(
        'You have reached your maximum limit for entering a valid code. Please generate a new code and try again.'
      );
      this.setState({
        otpAttamp: otpAttamp + 1
      });
    }
  };

  goToNext = () => {
    const { u_email: email, step, otp } = this.state;
    const { goToNextStep } = this.props;
    goToNextStep({
      email,
      step: step + 1,
      otp
    });
  };

  otpChange = (otpInner) => {
    this.setState({ otp: otpInner });
    setTimeout(() => {
      const values = Array.from(
        document.querySelectorAll('.inputStyleNew')
      ).map((ele) => {
        if (ele.value) {
          ele.className = 'inputStyleNew focus-color'; // eslint-disable-line no-param-reassign
        }
        return ele.value;
      });
      /* eslint-disable no-console */
      console.log('Inputs Values : ', values);
      /* eslint-enable no-console */
    }, 100);
  };

  render() {
    const { otpAttamp, otp, resendCode, isError } = this.state;
    return (
      <>
        <div className="reg-inner">
          <div className="col-12 mar-top-2 position-relative">
            <h1>
              Please enter the <br />
              4-digit code <br />
              we just sent you.
            </h1>
          </div>
          <div className="col-12">
            <OtpInput
              value={otp}
              onChange={(otpInner) => this.otpChange(otpInner)}
              containerStyle="userOtpBbox"
              inputStyle="inputStyleNew"
              shouldAutoFocus
              isInputNum
              hasErrored={isError}
              isDisabled={otpAttamp > AppConstants.OTP_ATTEMP_LIMIT}
            />
          </div>
          <div className="col-12 resend-code-text">
            <p>
              Didn&#x2019;t get the code?{' '}
              <span
                onClick={this.resendCode}
                onKeyDown={(event) =>
                  event.key === 'Enter' && this.resendCode()
                }
                role="button"
                tabIndex="0"
              >
                Resend code
              </span>
            </p>
          </div>
          {resendCode ? (
            <>
              <div className="col-12 ">
                <p className="msg">
                  Code has been sent to your registered Email.
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default OtpInputCustom;
