import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import '../../../assets/scss/style.scss';
import '../../../assets/scss/affiliate.scss';
import '../../../assets/scss/mobile.scss';
import Aos from 'aos';
import work1 from '../../../assets/images/affiliate/work1.svg';
import work2 from '../../../assets/images/affiliate/work2.svg';
import work3 from '../../../assets/images/affiliate/work3.svg';
import work4 from '../../../assets/images/affiliate/work4.svg';
import work5 from '../../../assets/images/affiliate/work5.svg';
import work6 from '../../../assets/images/affiliate/work6.svg';
import 'aos/dist/aos.css';
import HomeHeaderMobile from '../../../layouts/partial/homeHeaderMobile';
import 'react-slideshow-image/dist/styles.css';
import AppConstants from '../../../appConstants';

Aos.init({ duration: 1000 });

const AffiliateComponent = () => {
  const history = useHistory();

  const handleClickLogin = () => {
    history.push(AppConstants.routes.affiliate.login);
  };

  const handleClickWelcome = () => {
    history.push(AppConstants.routes.affiliate.accountType);
  };

  const handleDontAccount = () => {
    history.push({
      pathname: AppConstants.routes.affiliate.accountType,
      state: { dontAccount: true }
    });
  };

  return (
    <>
      <Helmet>
        <title>Affiliate Program | Lovrican</title>
        <meta
          name="description"
          content="Make extra money by joining Lovrican's Affiliate Program and getting paid to help us spread the joy of true love and happiness to Africans around the world. Learn more."
        />
        <meta name="keywords" content="Affiliate Program" />
      </Helmet>
      <div className="affiliate-wrapper" id="fullpage">
        <section className="top-banner one_section full-height ">
          <div className="homeTop_stick">
            <div
              className="gift-home"
              aria-hidden="true"
              onClick={handleClickLogin}
            >
              Member Login
            </div>
            <HomeHeaderMobile />
          </div>
          <div className="caption">
            <h1>
              Get Paid for Spreading <br />
              Love and Happiness!
            </h1>
            <p>
              With Lovrican’s simple, transparent, and generous affiliate <br />
              program, you can make money by helping African <br />
              Professionals find lasting love and happiness. Here is the <br />
              bonus: there is no limit to the amount of money you can make.
            </p>
            <div>
              <span
                className="round-button purple-theme"
                role="button"
                aria-hidden="true"
                onClick={handleClickWelcome}
              >
                Get Started
              </span>
            </div>
          </div>
        </section>
        <section className="second_section full-height">
          <div className="container">
            <div className="caption">
              <h1>
                People Will <br />
                Thank You For It!
              </h1>
              <p>
                Lovrican is the premier meeting place for young <br />
                African professionals worldwide. We specialize in <br />
                connecting the kind of people anyone would be <br />
                proud to introduce to their mama. That is why <br />
                people - whether it’s your friends, relatives, or <br />
                your social media audience, will all thank you for <br />
                helping them find high-quality life partners in <br />
                Lovrican, the dating platform approved by <br />
                African mothers.
              </p>
            </div>
          </div>
        </section>
        <section className="third_section desktop_view">
          <div className="container">
            <div className="caption">
              <h1>
                How the <br />
                Lovrican <br />
                Affiliate <br />
                Program <br />
                Works
              </h1>
            </div>
            <div className="easy_to_use_features">
              <div className="row">
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box1">
                    <div className="number">1</div>
                    <img src={work1} alt="" />
                    <h3>
                      Express Your Interest <br />
                      By Submitting Your <br />
                      Affiliate Profile.
                    </h3>
                    <p>
                      Earn money by letting people know about Lovrican, the
                      premier meeting place for young African professionals
                      worldwide who are looking for lasting love. Creating the
                      affiliate profile is quick and easy.
                    </p>
                  </div>
                </div>
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box2">
                    <div className="number">2</div>
                    <img src={work2} alt="" />
                    <h3>
                      Advanced <br />
                      Screening and <br />
                      Approval.
                    </h3>
                    <p>
                      At Lovrican, we take great pride in our exclusive
                      community. We review all profiles with care and
                      consideration, approving those that align with our high
                      standards of trust, cultural esteem, and shared values.
                    </p>
                  </div>
                </div>
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box3">
                    <div className="number">3</div>
                    <img src={work3} alt="" />
                    <h3>
                      Access Your Affiliate <br />
                      Management Console.
                    </h3>
                    <p>
                      You’ll receive a special page to manage all aspects of
                      your affiliate account. Here you’ll find your unique
                      affiliate link and QR code that you can share with the
                      people whom you’d like to refer to Lovrican. You’ll also
                      be able to see how many people you referred, how many
                      enrolled, and how much you earned.
                    </p>
                  </div>
                </div>
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box4">
                    <div className="number">4</div>
                    <img src={work4} alt="" />
                    <h3>
                      The More You <br />
                      Spread Love, the More <br />
                      You Earn.
                    </h3>
                    <p>
                      Share your affiliate link with your network and people who
                      you believe can benefit from Lovrican’s exceptional
                      service, and get paid for it! The more people you help to
                      find love, the more you earn. There is no limit to how
                      much you can earn.
                    </p>
                  </div>
                </div>
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box5">
                    <div className="number">5</div>
                    <img src={work5} alt="" />
                    <h3>
                      {' '}
                      Get <br />
                      Paid <br />
                      Regularly.
                    </h3>
                    <p>
                      At the end of each month, Lovrican will pay you all the
                      money you earned in that month. Lovrican has multiple ways
                      you can choose to get paid, such through CashApp and
                      PayPal. New payment methods are added regularly - let us
                      know if there is a preferred payment method you’d like us
                      to prioritize.
                    </p>
                  </div>
                </div>
                <div className="com-md-4 col-sm-6 col-lg-4 top-space">
                  <div className="box box6">
                    <div className="number">6</div>
                    <img src={work6} alt="" />
                    <h3>
                      Earn More <br />
                      From Your <br />
                      Network.
                    </h3>
                    <p>
                      When others in your network let their friends know about
                      the Lovrian to find their soulmate, we can achieve our
                      mission of ensuring that every African finds lasting love
                      and happiness. Ask them to use your affliate link, and as
                      new people sign up for the Lovrican service, you get paid
                      for those referals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center readyToStart">
                  <h1>Ready to start earning?</h1>
                  <div>
                    <span
                      className="round-button purple-theme"
                      role="button"
                      aria-hidden="true"
                      onClick={handleClickWelcome}
                    >
                      Let’s Start
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="third_section mobile_view">
          <div className="container">
            <div className="caption">
              <h1>
                How the <br />
                Lovrican <br />
                Affiliate <br />
                Program <br />
                Works
              </h1>
            </div>
            <div className="easy_to_use_features">
              <div
                id="differentSite"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <ul className="carousel-indicators">
                  <li
                    data-target="#differentSite"
                    data-slide-to="0"
                    className="active"
                  />
                  <li data-target="#differentSite" data-slide-to="1" />
                  <li data-target="#differentSite" data-slide-to="2" />
                  <li data-target="#differentSite" data-slide-to="3" />
                  <li data-target="#differentSite" data-slide-to="4" />
                  <li data-target="#differentSite" data-slide-to="5" />
                </ul>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="box box1">
                      <div className="number">1</div>
                      <img src={work1} alt="" />
                      <h3>
                        Express Your Interest <br />
                        By Submitting Your <br />
                        Affiliate Profile.
                      </h3>
                      <p>
                        Earn money by letting people know about Lovrican, the
                        premier meeting place for young African professionals
                        worldwide who are looking for lasting love. Creating the
                        affiliate profile is quick and easy.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box2">
                      <div className="number">2</div>
                      <img src={work2} alt="" />
                      <h3>
                        Advanced <br />
                        Screening and <br />
                        Approval.
                      </h3>
                      <p>
                        At Lovrican, we take great pride in our exclusive
                        community. We review all profiles with care and
                        consideration, approving those that align with our high
                        standards of trust, cultural esteem, and shared values.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box3">
                      <div className="number">3</div>
                      <img src={work3} alt="" />
                      <h3>
                        Access Your Affiliate <br />
                        Management Console.
                      </h3>
                      <p>
                        You’ll receive a special page to manage all aspects of
                        your affiliate account. Here you’ll find your unique
                        affiliate link and QR code that you can share with the
                        people whom you’d like to refer to Lovrican. You’ll also
                        be able to see how many people you referred, how many
                        enrolled, and how much you earned.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box4">
                      <div className="number">4</div>
                      <img src={work4} alt="" />
                      <h3>
                        The More You <br />
                        Spread Love, the More <br />
                        You Earn.
                      </h3>
                      <p>
                        Share your affiliate link with your network and people
                        who you believe can benefit from Lovrican’s exceptional
                        service, and get paid for it! The more people you help
                        to find love, the more you earn. There is no limit to
                        how much you can earn.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box5">
                      <div className="number">5</div>
                      <img src={work5} alt="" />
                      <h3>
                        {' '}
                        Get <br />
                        Paid <br />
                        Regularly.
                      </h3>
                      <p>
                        At the end of each month, Lovrican will pay you all the
                        money you earned in that month. Lovrican has multiple
                        ways you can choose to get paid, such through CashApp
                        and PayPal. New payment methods are added regularly -
                        let us know if there is a preferred payment method you’d
                        like us to prioritize.
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box6">
                      <div className="number">6</div>
                      <img src={work6} alt="" />
                      <h3>
                        Earn More <br />
                        From Your <br />
                        Network.
                      </h3>
                      <p>
                        When others in your network let their friends know about
                        the Lovrian to find their soulmate, we can achieve our
                        mission of ensuring that every African finds lasting
                        love and happiness. Ask them to use your affliate link,
                        and as new people sign up for the Lovrican service, you
                        get paid for those referals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-center readyToStart">
                  <h1>Ready to start earning?</h1>
                  <div>
                    <span
                      className="round-button purple-theme"
                      role="button"
                      aria-hidden="true"
                    >
                      Let’s Start
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="forth_section full-height">
          <div className="container">
            <div className="caption">
              <h1>
                Quality <br />
                partnerships
              </h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting{' '}
                <br />
                industry. Lorem Ipsum has been the industrys standard dummy{' '}
                <br />
                text ever since the 1500s, when an unknown printer took a galley
                of <br />
                type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
        </section>

        <section className="fifth_section full-height desktop_view">
          <div className="custom_container">
            <div className="box">
              <div className="caption">
                <h3>
                  Premium <br />
                  Product <br />
                  at a great <br />
                  value
                </h3>
                <p>Preffered by successful African professionals</p>
              </div>
            </div>
            <div className="box">
              <div className="caption">
                <h3>
                  Generous <br />
                  compensation
                </h3>
                <p>20% of Revenue directly paid to you regulary</p>
              </div>
            </div>
            <div className="box">
              <div className="caption">
                <h3>
                  Large and <br />
                  Growing <br />
                  Demand
                </h3>
                <p>Essential service, with 200 Million Potential Customers</p>
              </div>
            </div>
            <div className="box">
              <div className="caption">
                <h3>
                  Trust <br />
                  and <br />
                  Transparency
                </h3>
                <p>You have 100% control and traceablity of all events</p>
              </div>
            </div>
          </div>
        </section>
        <section className="fifth_section full-height mobile_view">
          <div className="">
            <div
              id="productQuality"
              className="carousel slide"
              data-ride="carousel"
              data-interval="false"
            >
              <ul className="carousel-indicators">
                <li
                  data-target="#productQuality"
                  data-slide-to="0"
                  className="active"
                />
                <li data-target="#productQuality" data-slide-to="1" />
                <li data-target="#productQuality" data-slide-to="2" />
                <li data-target="#productQuality" data-slide-to="3" />
              </ul>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="box first_box">
                    <div className="caption">
                      <h3>
                        Premium <br />
                        Product <br />
                        at a great <br />
                        value
                      </h3>
                      <p>Preffered by successful African professionals</p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="box second_box">
                    <div className="caption">
                      <h3>
                        Generous <br />
                        compensation
                      </h3>
                      <p>20% of Revenue directly paid to you regulary</p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="box third_box">
                    <div className="caption">
                      <h3>
                        Large and <br />
                        Growing <br />
                        Demand
                      </h3>
                      <p>
                        Essential service, with 200 Million Potential Customers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="box forth_box">
                    <div className="caption">
                      <h3>
                        Trust <br />
                        and <br />
                        Transparency
                      </h3>
                      <p>You have 100% control and traceablity of all events</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sixteen_section">
          <div className="container">
            <div className="caption">
              <h1>
                Let’s start <br />
                your program
              </h1>
              <p>
                You loged in & even your already the Lovrican member <br />
                Do you want to proceed with your existing details?
              </p>
              <div className="account-section">
                <div className="customeRadio_Inner">
                  <div>
                    <input
                      type="radio"
                      id="lovricain_account"
                      name="radio-group"
                      onClick={handleClickLogin}
                    />
                    <label htmlFor="lovricain_account">
                      I have a Lovrican account
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="lovricain_account_not"
                      name="radio-group"
                      onClick={handleDontAccount}
                    />
                    <label htmlFor="lovricain_account_not">
                      I don’t have a Lovrican account
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AffiliateComponent;
