import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../../redux/actions/user.actions';
import { pageTitle } from '../../../../redux/actions/home.actions';
import webServices from '../../../../services/webServices';
import MyAffiliateInfo from './MyAffiliateInfo';
import MyEarnings from './MyEarnings';
import MyReferals from './MyReferals';
import MakeMoneyPopup from './MakeMoneyPopup';
import TermsAndServicePopup from './TermsAndServicePopup';

const AccordionCustomToggle = ({
  title,
  eventKey,
  parentCallBack,
  status,
  arrowRight
}) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={() => decoratedOnClick(title, status)}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      {arrowRight ? (
        <span>
          <i className="fa fa-angle-left" />
        </span>
      ) : (
        <span>
          {status === 'open' ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      )}
    </div>
  );
};

const DashboardPage = () => {
  const myRef = useRef(null);
  const location = useLocation();
  const [profilePic, setProfilePic] = useState('no_Image.svg');
  const [userName, setUserName] = useState('');
  const [userAdd, setUserAdd] = useState('City, State, Country');
  const [userOccupation, setUserOccupation] = useState('');
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );

  const [initOcc, setInitOcc] = useState(location?.state?.occordian || '0');

  const [accStatus, setAccStatus] = useState({
    personal: 'open',
    basic: 'close',
    lifestyle: 'close'
  });

  const [verifyMsg, setVerifyMsg] = useState(
    !userDetails?.cash_app_id || !userDetails?.paypal_id || false
  );
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const [termsMsg, setTermsMsg] = useState(false);
  const handleCloseTermsMsg = () => setTermsMsg(false);
  const handleOpenTermsMsg = () => setTermsMsg(true);

  useEffect(async () => {
    dispatch(pageTitle('Dashboard'));
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails) {
      (async () => {
        const resp = await webServices.getUserDetail();
        if (resp && resp.success === true) {
          dispatch(setUser(resp.data));
        }
      })();
      return;
    }

    if (userDetails && userDetails.id) {
      setProfilePic(userDetails.profile_image || 'no_Image.svg');
      setUserName(
        `${userDetails.first_name || ''} ${userDetails.last_name || ''}`.trim()
      );
      setUserAdd(
        `${userDetails.cityLabel ? `${userDetails.cityLabel}, ` : ''} 
        ${userDetails.stateLabel ? `${userDetails.stateLabel}, ` : ''} 
        ${userDetails.countryLabel || ''}`
      );

      setUserOccupation(`${userDetails.occupationLabel || ''}`);
    }
  }, [dispatch, userDetails]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    init.basic = 'close';
    init.lifestyle = 'close';

    if (key === '0') {
      init.personal = data;
    }
    if (key === '1') {
      init.basic = data;
    }
    if (key === '2') {
      init.lifestyle = data;
    }
    setAccStatus(init);
    if (data === 'close') {
      setInitOcc(null);
    } else {
      setInitOcc(key);
    }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign profile-wrapper-outer">
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="image-slider-box cursor-pointer">
                <div
                  className="user-profile-image"
                  style={{
                    backgroundImage: `url("${
                      process.env.REACT_APP_PIC_BASE_URL + profilePic
                    }")`
                  }}
                />
              </div>
              <div className="user-detail-box profileSection">
                <div className="usr-info-box">
                  <h5>
                    <span className="profile_name" title={userName}>
                      {userName}
                    </span>
                  </h5>
                  {userOccupation ? (
                    <p className="occupation cursor-pointer">
                      {userOccupation} ({userDetails?.userDetails?.jobTitle})
                      {userDetails?.userDetails?.companyName ? ' at ' : ''}
                      {userDetails?.userDetails?.companyName}
                    </p>
                  ) : (
                    <p className="occupation missting_section_match cursor-pointer">
                      Occupation to be added
                    </p>
                  )}

                  {userAdd && <p className="address">{userAdd}</p>}
                </div>
              </div>
              <Accordion
                // defaultActiveKey={initOcc}
                activeKey={initOcc}
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card ref={myRef}>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="0"
                      title="My Affiliate Info"
                      status={accStatus.personal}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <MyAffiliateInfo userDetail={userDetails} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="1"
                      title="My Earnings"
                      status={accStatus.basic}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <MyEarnings />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="2"
                      title="My Referals"
                      status={accStatus.lifestyle}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <MyReferals />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <MakeMoneyPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={userDetails}
        handleOpenTerms={handleOpenTermsMsg}
      />
      <TermsAndServicePopup
        modalStatus={termsMsg}
        handleClose={handleCloseTermsMsg}
      />
    </>
  );
};

export default DashboardPage;
