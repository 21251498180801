import React, { useEffect, useState } from 'react';
// import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import loaderImg from '../../assets/images/loader.gif';

function Spinner({ active }) {
  const [isActive, setActive] = useState(active);

  useEffect(() => {
    setActive(active);
  }, [active]);

  if (!isActive) {
    return null;
  }

  return <div className='loaderWrapper'>
    {/* <Loader type="Hearts" color="#ff4748" height={200} width={200} /> */}
    <img src={loaderImg} className="newloader" alt="/" />
  </div>;
}

export default Spinner;
