import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../../../redux/actions/user.actions';
import btnIcon from '../../../../assets/images/arrow-circle.svg';
import webServices from '../../../../services/webServices';

const MakeMoneyPopup = ({
  modalStatus,
  handleClose,
  userDetails,
  handleOpenTerms
}) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    phoneNumber: userDetails?.phone || "",
    cashAppId: '',
    paypalId: '',
    terms: false
  });

  const forceUpdate = useState()[1].bind(null, {});

  const submitForm = async () => {
    if (validator.allValid()) {
      setLoading(true);
      const resp = await webServices.updateUserDetails(userDetails?.id, values);
      if (resp?.success === true) {
        dispatch(setUser(resp?.data));
        handleClose(false);
        handleOpenTerms();
      }
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel aff_login"
        show={modalStatus}
        onHide={handleClose}
        centered
        size=""
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <div className="row">
              <div className="col-md-12">
                <h5 className="modal-title w-100">
                  Make money <br />
                  with Lovrican
                </h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100  input_field">
            <div className="col-md-12 col-lg-12 mb-4  custom_field">
              <div className="custom_input p-0">
                <PhoneInput
                  containerClass="custom_flag"
                  placeholder="Phone Number"
                  country="us"
                  value={values.phoneNumber}
                  onChange={(phone) =>
                    setValues({ ...values, phoneNumber: phone })
                  }
                />
              </div>

              {/* <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Mobile Number"
                autoComplete="off"
                value={values.phoneNumber}
                onChange={(e) =>
                  setValues({ ...values, phoneNumber: e.target.value })
                }
              /> */}
              {validator.message(
                'Mobile Number',
                values.phoneNumber,
                'phoneNumber|required'
              )}
            </div>
            <div className="col-md-12 col-lg-12 mb-4  custom_field">
              <input
                type="text"
                name="cashAppId"
                id="cashAppId"
                placeholder="Cash App ID"
                autoComplete="off"
                value={values.cashAppId}
                onChange={(e) =>
                  setValues({ ...values, cashAppId: e.target.value })
                }
              />
              {validator.message('cashAppId', values.cashAppId, 'required')}
            </div>
            <div className="col-md-12 col-lg-12 mb-4 custom_field">
              <input
                type="text"
                name="paypalId"
                id="paypalId"
                placeholder="Paypal ID"
                autoComplete="off"
                value={values.paypalId}
                onChange={(e) =>
                  setValues({ ...values, paypalId: e.target.value })
                }
              />
              {validator.message('paypalId', values.paypalId, 'required')}
            </div>
            <div className="col-md-12 col-lg-12 mb-4 form-group">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={values.terms || false}
                onChange={(e) =>
                  setValues({ ...values, terms: e.target.checked })
                }
              />{' '}
              <label className="pr-1" htmlFor="terms">
                {' '}
                I have read & agree to{' '}
              </label>
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                aria-hidden="true"
                onClick={handleOpenTerms}
              >
                terms of service
              </span>
              {validator.message('terms', values.terms, 'accepted', {
                messages: { accepted: 'You must accept the terms to proceed' }
              })}
            </div>
          </div>
          <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
            <button
              className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
              onClick={submitForm}
              onKeyDown={(event) => event.key === 'Enter' && submitForm()}
              type="button"
              tabIndex="0"
              disabled={loading || false}
            >
              <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MakeMoneyPopup;
