import React from 'react';
import Footer from './partial/footer';
import Header from './partial/header';

function StaticLayout(props) {
  const { children } = props;
  return (
    <>
      <div className="muli-fonts">
        <section className="section header-static-layout">
          <div className="row">
            <Header {...props} />
          </div>
        </section>
        <section className="section inner-static-layout">
           <div className='inner_staticbgImage'>
            <div className="container">{children}</div>
          </div>
        </section>
        <Footer {...props} />
      </div>
    </>
  );
}

export default StaticLayout;
