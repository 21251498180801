import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import EditIcon from '../../assets/images/edit.svg';
import lock from '../../assets/images/lock.svg';

const InputBox = ({ isTextArea, ...restProps }) => {
  if (isTextArea) {
    return (
      <textarea
        className="form-control"
        rows="3"
        {...restProps}
        maxLength="300"
      />
    );
  }
  return <input type="text" {...restProps} className="form-control" />;
};

const TextSection = ({
  label,
  value,
  isTextArea,
  formControlName,
  saveDetails,
  placeholder,
  isViewMode,
  isLock
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const goToEditMode = () => {
    if (!isViewMode) {
      setIsEditMode(true);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (
      formControlName === 'linkedin_URL' &&
      !localValue.toLowerCase().includes('linkedin.com')
    ) {
      toast.error('Invalid LinkedIn URL.', localValue);
    } else {
      (async () => {
        const response = await saveDetails({ [formControlName]: localValue });
        if (response && response.success) {
          // toast.dismiss();
          toast.success('Updated successfully');
          setIsEditMode(false);
        }
      })();
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (window.innerWidth <= 800 && window.innerHeight <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window]);

  return (
    <div className="card-box position-relative">
      {isLock && (
        <div className="text-right lockSection">
          <img src={lock} alt="lock" />
        </div>
      )}
      <form onSubmit={handleFormSubmit}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={!isMobile && goToEditMode}
          aria-hidden="true"
        >
          <div className="card-header d-flex justify-content-between align-items-center">
            <span>{label}</span>
            {!isEditMode && !isViewMode && (
              <div
                className={`edit ${isMobile ? '' : 'd-none'}`}
                onClick={goToEditMode}
                onKeyDown={(event) => event.key === 'Enter' && goToEditMode()}
                role="button"
                tabIndex="0"
              >
                <img src={EditIcon} alt="Edit icon" />
              </div>
            )}
          </div>
          <div className="card-container mt-2 mb-2">
            {!isEditMode &&
              (value ? (
                <>
                  {!isTextArea && (
                    <ul className="list-unstyled list1 mb-0">
                      <li key={localValue}>{localValue}</li>
                    </ul>
                  )}
                  {isTextArea && localValue}
                </>
              ) : (
                <span className="text-danger-not">Not answered yet</span>
              ))}
            {isEditMode && (
              <InputBox
                autoFocus
                isTextArea={isTextArea}
                name={formControlName}
                id={formControlName}
                placeholder={placeholder}
                value={localValue}
                onChange={(event) => setLocalValue(event.target.value)}
                onBlur={!isMobile && handleFormSubmit}
              />
            )}
          </div>
        </div>
        {isEditMode && (
          <div className={`${isMobile ? '' : 'd-none'}`}>
            <button
              className="btn cancel-btn mr-2"
              onClick={() => setIsEditMode(false)}
              type="button"
            >
              Cancel
            </button>
            <button className="btn theme-btn" type="submit">
              Save
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default TextSection;
