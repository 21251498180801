import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const ProfileImage = (props) => {
  const { fullName, profileImage, redirectToProfile, redirectToPhoto, imagesDetails } = props;
  const history = useHistory();
  const handleRedirect = () => {
    history.push({
      pathname: redirectToPhoto,
      state: { imagesDetail: imagesDetails },
    });
  }
  
  return (
    <figure className="figure position-relative">
      <img
        src={`${process.env.REACT_APP_PIC_BASE_URL}${profileImage}`}
        alt="profile"
      />
      <figcaption className="figure-caption-header position-absolute w-100 p-3">
        <div className="d-flex justify-content-between">
          <Link to={redirectToProfile}>
            <div className="back">
              <i className="fa fa-angle-left" />
            </div>
          </Link>
          <div className="text-decoration-none" onClick={handleRedirect} aria-hidden="true">
            <div className="gallery">
              <i className="fa fa-file-image-o" aria-hidden="true" />
              My Photos
            </div>
          </div>
        </div>
      </figcaption>
      <figcaption className="figure-caption-name position-absolute w-100 p-3 text-left">
        {fullName}
      </figcaption>
    </figure>
  );
};

export default ProfileImage;
