import { React } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import authService from '../../services/authServices';

const RelationshipPopup = ({ modalStatus, handleClose }) => {
  const history = useHistory();
  const { logout } = authService;

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-left w-100 text-danger pl-3">
              Singles Service Not For<br/> Married People
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='text-danger'>
            Lovrican Singles Service is only for people who are single or (if
            legally married) separated. If you are married and not separated,
            you may not register to use or use the Singles Service.
          </p>
          <p className='text-danger'>
            You can still gift a Singles Service Plan to someone who is single
            through our matchmaker service - see below. To learn more about
            Lovrican please see our homepage.
          </p>
          <div className="text-center d-sm-flex">
            <button
              type="button"
              className="btn theme-btn themebtn1 m-1 d-none"
              onClick={() => {
                logout(history);
                history.push('/gift');
              }}
            >
              Be a Matchmaker
            </button>
            <button
              type="button"
              className="btn theme-btn themebtn2 m-1 d-none"
              onClick={() => {
                logout(history);
                history.push('/');
              }}
            >
              Go to Homepage
            </button>
          </div>
       
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RelationshipPopup;
