import React, { useEffect } from 'react';
import * as moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import { authCall } from '../services';
import verfied from '../assets/images/verified.svg';

const VerifiedNotificationPopup = ({
  modalStatus,
  handleClose,
  userDetails
}) => {
  const history = useHistory();

  const handleClick = () => {
    // if (userDetails?.profileLevel === 0) {
    //   history.push(AppConstants.routes.congratulations);
    // } else {
    //   history.push(AppConstants.routes.registerSections);
    // }
    history.push(AppConstants.routes.registerSections);
  };

  const handleCancel = async () => {
    await authCall('auth/register-section', {
      profileActiveMsgReadDt: moment()
    });
    handleClose(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (userDetails?.phone !== null && modalStatus) {
        handleCancel();
      }
    }, 10000);
  }, [modalStatus]);

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel complete_your_reg "
        show={modalStatus}
        onHide={modalStatus}
        centered
        size={userDetails?.phone === null ? "lg" : ""}
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            <img
              src={verfied}
              className="img-fluid cursor-pointer verifid_img"
              alt="arrow icon"
              aria-hidden="true"
            />
            <h5 className="modal-title text-center w-100">
              {userDetails?.phone !== null
                ? 'Verification in Progress'
                : 'Get Verified'}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {userDetails?.phone !== null ? (
              <>
                Hi {userDetails?.first_name.trim()}. We have received your
                verification request. Our team is reviewing your request.
                Verifications usually take 1-3 days. You’ll hear from us soon.
              </>
            ) : (
              <>
                Hello {userDetails?.first_name.trim()}! We encourage you to
                verify your Lovrican account to enhance trust and credibility
                within our community. Completing the verification process is
                simple; just fill out the verification form below.
                <p className="text-theme pb-3 mt-2">
                  We’re pleased to inform you that Lovrican verification is
                  offered completely free of charge. Additionally, to show our
                  appreciation, you’ll 🎁 receive a delightful gift of 10
                  Free Roses upon successful verification.
                </p>
              </>
            )}
          </p>

          {userDetails?.phone !== null ? (
            <div className="text-center">
              <button
                type="button"
                className="btn theme-btn themebtn2"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          ) : (
            <>
              <div className="text-center">
                <button
                  type="button"
                  className="btn theme-btn themebtn1 mr-0"
                  onClick={() => handleClick()}
                >
                  Get Verified
                </button>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn theme-btn themebtn2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VerifiedNotificationPopup;
