import PropTypes from 'prop-types';
import React from 'react';
import AppConstants from '../appConstants';
// import '../assets/css/site.css';
import authService from '../services/authServices';
// import HomeHeader from './partial/homeHeader';
import Spinner from '../components/general/spinner';

class AffiliateLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpinner: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isSpinner: false });
    }, 1000);

    const { match } = this.props;
    if (
      match.path === AppConstants.routes.plans ||
      match.path === AppConstants.routes.rosePlans ||
      match.path.startsWith(AppConstants.routes.order)
    ) {
      return;
    }
    const { history } = this.props;
    authService.throwBackRev(history);
  }

  render() {
    const { children } = this.props;
    const { isSpinner } = this.state;
    return (
      <>
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <div className="main-wrapper muli-fonts affiliateReg">
            {/* <HomeHeader {...this.props} callBack={this.callbackfunction} /> */}
            {children}
          </div>
        )}
      </>
    );
  }
}

AffiliateLayout.propType = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};

export default AffiliateLayout;
