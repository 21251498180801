import React from 'react';
// import '../assets/css/site.css';
// import Footer from './partial/footer';
// import Header from './partial/header';

const ErrorLayout = (props) => {
  const { children } = props;
  return (
    <>
      {/* <Header {...props} /> */}
      {children}
      {/* <Footer {...props} /> */}
    </>
  );
};

export default ErrorLayout;
