import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import { authCall } from '../services';

const RoseExceedPopup = ({
  modalStatus,
  handleClose,
  userData,
  roseCountSend,
  myTotalRoses,
  fromMatched
}) => {
  const history = useHistory();

  const handleClickPlan = async () => {
    const resp = await authCall('user/userCheckPermission', {
      action: 'buyRose'
    });
    if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else {
      history.push({
        pathname: AppConstants.routes.rosePlans
      });
    }
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Rose Limit Exceeded
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="expireAlert d-none1">
            {myTotalRoses} <br />
            <span>{myTotalRoses > 1 ? 'ROSES' : 'ROSE'}</span>
          </div>
          <p>
            Unfortunately you have {myTotalRoses}{' '}
            {myTotalRoses !== 1 ? 'roses' : 'rose'} available vs. the{' '}
            {roseCountSend} {roseCountSend > 1 ? 'roses' : 'rose'} you’d like to send to{' '}
            {fromMatched
              ? userData?.first_name
              : userData?.userName?.toUpperCase()}
            . You can buy more roses right away!
            {/* {`Available vs. ${roseCountSend} Roses you'd like to give to ${
              fromMatched
                ? userData?.user_name
                : userData?.userName?.toUpperCase()
            }`} */}
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleClickPlan()}
            >
              Buy Roses
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleClose(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RoseExceedPopup;
