import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import InputRange from 'react-input-range';
import rose from '../assets/images/rose.svg';

const RoseSendPopup = ({
  modalStatus,
  handleClose,
  handleConfirmRoseSend,
  userData,
  roseSent,
  fromMatched,
  handleCloseCancel
}) => {
  const [values, setValues] = useState(0);

  const handleFinalConfirm = async () => {
    if (values > 0) {
      handleConfirmRoseSend({ status: true, roses: values });
    }
  };

  const roseSentFun = () => (
    <>
      You’ve just sent {values} {values > 1 ? 'roses' : 'rose'} to{' '}
      {fromMatched ? userData?.first_name : userData?.userName?.toUpperCase()}.
      We hope this gets the flame burning!
    </>
  );

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            {roseSent && (
              <h5 className="modal-title text-center w-100">
                Congratulations!
              </h5>
            )}
            {!roseSent && (
              <h5 className="modal-title text-center w-100">
                Give Roses to{' '}
                {!fromMatched ? userData?.userName?.toUpperCase() : ''}
              </h5>
            )}
            {fromMatched && !roseSent && (
              <h4 className="modal-title text-center w-100">
                {userData?.first_name}
              </h4>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='rose_popup'>
          {roseSent && (
            <>
              <div className="roseAlert d-none1">
                <img src={rose} alt="Lovrican rose" />
              </div>
              <div className="countRose">
                {values} {values > 1 ? 'Roses' : 'Rose'}
              </div>
            </>
          )}
          <p>
            {roseSent ? (
              roseSentFun()
            ) : (
              <div className="card-container mt-4 mb-4 pb-2 innerRange roseRanger">
                <InputRange
                  allowSameValues
                  formatLabel={(v1) => v1}
                  maxValue={12}
                  minValue={0}
                  value={values}
                  step={1}
                  onChange={(val) => setValues(val)}
                />
              </div>
            )}
          </p>
          <div className="text-center">
            {!roseSent && (
              <button
                type="button"
                className="btn theme-btn themebtn1 mr-0"
                onClick={() => handleFinalConfirm()}
                disabled={values === 0}
              >
                Send Roses
              </button>
            )}
          </div>
          <div className="text-center">
            {roseSent && (
              <button
                type="button"
                className="btn theme-btn themebtn2"
                onClick={() => {
                  handleClose(false);
                  setValues(0);
                }}
              >
                Ok
              </button>
            )}
            {!roseSent && (
              <button
                type="button"
                className="btn theme-btn themebtn2"
                onClick={() => {
                  handleCloseCancel(false);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default RoseSendPopup;
