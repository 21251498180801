const LoveGoals = [
  {
    value: 1,
    label: 'Meet the love of my life within 6-12 months'
  },
  {
    value: 2,
    label: 'Get married within 18 months'
  },
  {
    value: 3,
    label: 'Have kids within 2 years'
  },
  {
    value: 4,
    label: 'Get married at some point, not anytime soon'
  },
  {
    value: 5,
    label: 'Have kids at some point, not anytime soon'
  },
  {
    value: 6,
    label: 'Just date, not thinking of marriage'
  },
  {
    value: 7,
    label: 'Other'
  }
];

export default LoveGoals;
