import { React } from 'react';
import { Modal } from 'react-bootstrap';
import * as moment from 'moment';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import webServices from '../services/webServices';

const PlanExpireRemainderPopup = ({
  modalStatus,
  handleClose,
  currentPlan,
  userDetails
}) => {
  const history = useHistory();

  const handleClickPlan = () => {
    history.push({
      pathname: AppConstants.routes.plans,
      state: {
        daysRemaining: currentPlan.daysRemaining,
        currentPlan: currentPlan.currentPlan
      }
    });
  };

  const handleCancel = async () => {
    await webServices.updateUserSettings({
      readPlanRemainderMsg: moment().format('YYYY-MM-DD')
    });
    handleClose(false);
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Your Plan is About to Expire
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="expireAlert d-none1">
            {currentPlan?.daysRemaining} <br />
            <span>DAYS</span>
          </div>
          <p>
            Hey {`${userDetails?.first_name || ''}`.trim()}! Your current subscription plan will expire
            in {currentPlan?.daysRemaining} days. Renew your plan today to continue enjoying the full functionality of Lovrican.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleClickPlan()}
            >
              Update Plan
            </button>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleCancel()}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PlanExpireRemainderPopup;
