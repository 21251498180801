import { combineReducers } from 'redux';
import homeReducer, { initialState as homeInitialState } from './home.reducer';
import userReducer, { initialState as userInitialState } from './user.reducer';

const rootReducer = combineReducers({
  home: homeReducer,
  user: userReducer
});

export const initialState = {
  home: homeInitialState,
  user: userInitialState
};

export default rootReducer;
