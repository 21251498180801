import { HomeConstants } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const loginPopupAction = (payload) => ({
  type: HomeConstants.LOGIN_POPUP,
  payload
});

export const pageTitle = (payload) => ({
  type: HomeConstants.PAGE_TITLE,
  payload
});
