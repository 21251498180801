import React, { useEffect, useState, useRef } from 'react';
import toast from 'toastr';
import Resizer from 'react-image-file-resizer';
import { useLocation } from 'react-router-dom';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../../redux/actions/user.actions';
import { pageTitle } from '../../../../redux/actions/home.actions';
import webServices from '../../../../services/webServices';
import SellerInformations from './SellerInformations';
import upload from '../../../../assets/images/affiliate/download.svg';
import CropperPopup from '../../../../pages/cropperPopup';

const AccordionCustomToggle = ({
  title,
  eventKey,
  parentCallBack,
  status,
  arrowRight
}) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={() => decoratedOnClick(title, status)}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      {arrowRight ? (
        <span>
          <i className="fa fa-angle-left" />
        </span>
      ) : (
        <span>
          {status === 'open' ? (
            <i className="fa fa-minus" />
          ) : (
            <i className="fa fa-plus" />
          )}
        </span>
      )}
    </div>
  );
};

const PublicProfilePageAffilaite = () => {
  const myRef = useRef(null);
  const fileInputRef = useRef(null);
  const location = useLocation();
  const [profilePic, setProfilePic] = useState('no_Image.svg');
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );

  const [masterData, setMasterData] = useState({});
  const [initOcc, setInitOcc] = useState(location?.state?.occordian || '0');

  const [accStatus, setAccStatus] = useState({
    personal: 'open',
    basic: 'close',
    lifestyle: 'close',
    photo: 'close'
  });

  const [imageSRC, setImageSRC] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(async () => {
    dispatch(pageTitle('Profile'));
    webServices.getMasterData().then((response) => {
      if (response && response.success) {
        setMasterData(response.data);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails) {
      (async () => {
        const resp = await webServices.getUserDetail();
        if (resp && resp.success === true) {
          dispatch(setUser(resp.data));
        }
      })();
      return;
    }

    if (userDetails && userDetails.id) {
      setProfilePic(userDetails.profile_image || 'no_Image.svg');
    }
  }, [dispatch, userDetails]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    if (key === '0') {
      init.personal = data;
    }

    setAccStatus(init);
    if (data === 'close') {
      setInitOcc(null);
    } else {
      setInitOcc(key);
    }
  };

  const handleFileChange = (event) => {
    const [firstFile] = event.target.files;
    if (!firstFile) {
      return;
    }
    Resizer.imageFileResizer(
      firstFile, // the file from input
      800, // width
      850, // height
      'JPEG', // compress format WEBP, JPEG, PNG
      90, // quality
      0, // rotation
      async (uri) => {
        setImageSRC(uri);
        setShow(true);
      },
      'base64' // blob or base64 default base64
    );

    /* eslint no-param-reassign: "error" */
    event.target.value = null;
  };

  const close = (val) => {
    setShow(val);
  };

  const callbak = async (action, data) => {
    if (action === 'click') {
      const formData = new FormData();
      formData.append('profileImage', data.blobFile);
      formData.append('affiliateProfile', true);
      const response = await webServices.updateProfilePhotos(formData);
      if (response && response.success) {
        dispatch(setUser(response.data));
        setShow(false);
      } else {
        toast.error('Error occurred while adding profile photo');
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  
  return (
    <>
      <div className="inner-wrapper-newdesign profile-wrapper-outer aff_profile">
        <div className="largeScreen">
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="image-slider-box cursor-pointer">
                <div
                  className="user-profile-image"
                  style={{
                    backgroundImage: `url("${
                      process.env.REACT_APP_PIC_BASE_URL + profilePic
                    }")`
                  }}
                />

                {/* <div className='total_earning'>
                  <div className='top'>Total Earnings:</div>
                  <div className='bottom'>$2600.00</div>
                </div> */}

                <div className="change_profoile">
                  <button
                    type="button"
                    className="upload btn"
                    onClick={handleButtonClick}
                  >
                    <img src={upload} className="img-fluid" alt="arrow icon" />
                  </button>
                  <div aria-hidden="true" onClick={handleButtonClick}>
                    Change Profile Photo
                  </div>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="d-none"
                  id="fileSelector"
                  onChange={handleFileChange}
                />
              </div>

              <Accordion
                // defaultActiveKey={initOcc}
                activeKey={initOcc}
                className="profile-accordion new_profile_accordion mt-4"
              >
                <Card ref={myRef}>
                  <Card.Header className="pb-2">
                    <AccordionCustomToggle
                      eventKey="0"
                      title="Seller Account Information"
                      status={accStatus.personal}
                      parentCallBack={callBack}
                    />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <SellerInformations
                        userDetail={userDetails}
                        masterData={masterData}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <CropperPopup
        modalStatus={show}
        handleClose={close}
        parentCallback={callbak}
        imageSRC={imageSRC}
      />
    </>
  );
};

export default PublicProfilePageAffilaite;
