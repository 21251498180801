import React from 'react';
import { useHistory } from "react-router-dom";
import AppConstants from '../../appConstants';

const QueueCard = (props) => {
  const { data } = props;
  const history = useHistory();

  const handleAction = (details, action) => {    
    if(action === 'chat'){
      history.push({
        pathname: AppConstants.routes.chat,
        state: { userDetail: details },
      });
    }
    else if(action === 'view'){
      history.push({
        pathname: AppConstants.routes.viewProfile,
        state: { userId: details.user_id },
      });
    }
  };
  
  return (
    <div className="matchqu-box">
      <div className="w-100 d-md-flex align-items-center">
        <div className="img-box cursor-pointer" onClick={() => handleAction(data, 'view')} aria-hidden="true">
          <img
            src={process.env.REACT_APP_PIC_BASE_URL + data.profile_image}
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="content-box">
          <h3 className="gender">
            <span onClick={() => handleAction(data, 'view')} aria-hidden="true" className='cursor-pointer'>
              {data.user_name}, {data.user_age}{' '}
            </span>
            <i
              className="fa fa-check-circle lovrican-color"
              aria-hidden="true"
            />
          </h3>
          <p>{data.user_occupation}</p>
          <p className="address">
            <i className="fa fa-map-marker lovrican-color" aria-hidden="true" />{' '}
            {data.user_add}
          </p>

          <button
            className="btn btn-info theme-btn mt-3"
            type="button"
            onClick={() => handleAction(data, 'chat')}
          >
            Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default QueueCard;
