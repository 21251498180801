import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from 'simple-react-validator';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppConstants from '../../../appConstants';
import { setUser } from '../../../redux/actions/user.actions';
import { authCall } from '../../../services';
import authService from '../../../services/authServices';
import arrowIcon from '../../../assets/images/arrow-circle.svg';
import HomeHeaderMobile from '../../../layouts/partial/homeHeaderMobile';
import Spinner from '../../general/spinner';

function AffiliateLoginComponent() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());

  const [isSpinner, setIsSpinner] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: '',
    email: ''
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const submitForm = async () => {
    if (validator.allValid()) {
      setLoading(true);
      const request = {
        password: values.password,
        username: values.email,
        usertype: 'AFFILIATE'
      };
      const resp = await authCall('auth/login', request);
      if (resp && resp?.success === true) {
        setLoading(false);
        authService.setToken(resp.data);
        dispatch(setUser(resp.data.user));
        // set selected county in localstorage
        window.localStorage.setItem(
          'selectedCountry',
          JSON.stringify([
            {
              value: resp?.data?.user?.countryValue,
              label: resp?.data?.user?.countryLabel
            }
          ])
        );

        const { is_profile_completed: isProfileComplete } = resp?.data?.user;
        if (isProfileComplete === 'YES') {
          history.push(AppConstants.routes.afterLogin);
        } else {
          history.push(AppConstants.routes.registerSections);
        }
      } else {
        setLoading(false);
      }
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  useEffect(async () => {
    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);
  }, []);

  return (
    <>
      {isSpinner ? (
        <>
          <Spinner active={isSpinner} />
        </>
      ) : (
        <>
          <Helmet>
            <title>Login to Lovrican | Find Your African Soulmate</title>
            <meta
              name="description"
              content="Login to your Lovrican account and embark on your journey to find genuine love and happiness with a high-qualityl African professional in America. Embark on your journey to find love today!"
            />
            <meta name="keywords" content="Login to Lovrican" />
          </Helmet>

          <section className="loginWrapper text-light position-relative">
            <div className="container-fluid">
              <div className="row">
                {/* Mobile Header */}
                <HomeHeaderMobile />
                {/* End Mobile Header */}

                <div className="reg-inner">
                  <div className="col-12">
                    <h1>
                      Welcome back to
                      <br />
                      Lovrican!
                    </h1>
                  </div>

                  <div
                    className="col-12 pr-0"
                    style={{ margin: '0 0 2.8rem 0' }}
                  >
                    <div className="reg-login ml-0 mb-0">
                      <input
                        className="login"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        autoComplete="off"
                        onChange={handleChange('email')}
                      />
                    </div>
                    {validator.message('email', values.email, 'required|email')}
                  </div>
                  <div
                    className="col-12 pr-0"
                    style={{ margin: '0 0 2.8rem 0' }}
                  >
                    <div className="reg-login ml-0 mb-0">
                      <input
                        className="login"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter your password"
                        autoComplete="off"
                        onChange={handleChange('password')}
                        onKeyDown={(event) =>
                          event.key === 'Enter' && submitForm()
                        }
                      />
                    </div>
                    {validator.message('password', values.password, 'required')}
                  </div>

                  <div className="col-12 d-flex reg-login justify-content-end pr-0 border-0">
                    <span
                      className={`search-btn ${
                        loading ? 'hks-btn-disable' : ''
                      }`}
                      role="button"
                      tabIndex="0"
                      onClick={submitForm}
                      aria-hidden="true"
                    >
                      <img
                        src={arrowIcon}
                        className="img-fluid"
                        alt="arrow icon"
                      />
                    </span>
                  </div>
                  <div className="col-12 text-right link-btn">
                    <p>
                      <span>Forgot Password?</span>
                      <NavLink to={AppConstants.routes.forgotPassword}>
                        <span className="link-color" role="button">
                          Reset
                        </span>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default AffiliateLoginComponent;
