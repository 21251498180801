import React from 'react';
import { Modal } from 'react-bootstrap';

const TermsAndServicePopup = ({ modalStatus, handleClose }) => (
  <>
    <Modal
      className="filter-model PlanStatusModel aff_login"
      show={modalStatus}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
        <div className="row">
        <div className="col-md-12">
          <h5 className="modal-title text-center w-100"> </h5>
          </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='term_contion'>
        <p>
          This is a legal agreement (Agreement) between you and Lovrican LLC., a
          New Jersey Limited Liability Company (Lovrican), which may be
          contacted at P.O. Box 437, Raritan, New Jersey, 08869, USA. By
          accessing the Lovrican web site, currently located at www.Lovrican.com
          (the Site), and using any of the Services (as defined below)
          accessible through the Site, you become a user and agree to, and are
          bound by, the terms and conditions of this Agreement for as long as
          you continue to use the Site or Services. IF YOU DO NOT AGREE TO BE
          BOUND BY THIS AGREEMENT, DO NOT USE THE SITE OR THE SERVICES . Your
          use of, or participation in, certain Services may be subject to
          additional terms, and such terms will be either listed in this
          Agreement or will be presented to you for your acceptance when you
          sign up to use such Services.

          This is a legal agreement (Agreement) between you and Lovrican LLC., a
          New Jersey Limited Liability Company (Lovrican), which may be
          contacted at P.O. Box 437, Raritan, New Jersey, 08869, USA. By
          accessing the Lovrican web site, currently located at www.Lovrican.com
          (the Site), and using any of the Services (as defined below)
          accessible through the Site, you become a user and agree to, and are
          bound by, the terms and conditions of this Agreement for as long as
          you continue to use the Site or Services. IF YOU DO NOT AGREE TO BE
          BOUND BY THIS AGREEMENT, DO NOT USE THE SITE OR THE SERVICES . Your
          use of, or participation in, certain Services may be subject to
          additional terms, and such terms will be either listed in this
          Agreement or will be presented to you for your acceptance when you
          sign up to use such Services.
        </p>
        </div>
      </Modal.Body>
    </Modal>
  </>
);

export default TermsAndServicePopup;