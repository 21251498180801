import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import themeRose from '../assets/images/rose-theme.svg';
import superExcited from '../assets/images/super-excited.svg';
import satisfied from '../assets/images/Satisfied.svg';
import smile from '../assets/images/Smile.svg';
import dissatisfied from '../assets/images/Dissatisfied.svg';
import angry from '../assets/images/angry.svg';

const RewardsFeedbacksPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    reason: '',
    otherReason: '',
    improvement: '',
    feedbackType: 'REWARD'
  });

  const [errors, setErrors] = useState({
    reasonError: '',
    otherReasonError: '',
    improvementError: ''
  });

  useEffect(async () => {
    dispatch(pageTitle('Feedbacks'));
  }, []);

  const validations = () => {
    let isError = false;
    const errorsMsg = { ...errors };
    if (values.reason === '') {
      isError = true;
      errorsMsg.reasonError = 'Please choose any overall satisfaction';
    }

    if (values.otherReason === '') {
      isError = true;
      errorsMsg.otherReasonError = 'Please enter your likes';
    }

    if (values.improvement === '') {
      isError = true;
      errorsMsg.improvementError = 'Please enter need improvement';
    }

    setErrors(errorsMsg);

    return isError;
  };

  const handleSubmit = async () => {
    if (!validations()) {
      setLoading(true);
      const res = await webServices.createFeedbacks(values);
      if (res.success) {
        toast.success('Your feedback submitted successfully!');
        setLoading(false);
        setValues({ ...values, reason: '', otherReason: '', improvement: '' });
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="inner-wrapper-newdesign">
        <div className="reward">
          <h2 className="text-center">Give us feedback & Earn Free Roses</h2>
          <p className="text-center">
            We appreciate your feedback in helping us enhance our service.
          </p>
          <div className="reward_container">
            <div className="head">
              <p className="text-theme m-0">Overall satisfaction</p>
              <div className="roseBox">
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <img
                    src={themeRose}
                    className="img-rose-fluid"
                    alt="Lovrican rose"
                  />
                  <span className="text-theme">
                    <em>02 Roses</em>
                  </span>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="customeRadio_Inner ">
                <div>
                  <input
                    type="radio"
                    id="reward1"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Extremely Satisfied!'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                    checked={values.reason === 'Extremely Satisfied!' || false}
                  />
                  <label htmlFor="reward1">
                    <img
                      src={superExcited}
                      className="face"
                      alt="face action"
                    />
                    Extremely Satisfied!
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward2"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Satisfied'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                    checked={values.reason === 'Satisfied' || false}
                  />
                  <label htmlFor="reward2">
                    <img src={satisfied} className="face" alt="face action" />
                    Satisfied
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward3"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Neutral'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                    checked={values.reason === 'Neutral' || false}
                  />
                  <label htmlFor="reward3">
                    <img src={smile} className="face" alt="face action" />
                    Neutral
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward4"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Dissatisfied'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                    checked={values.reason === 'Dissatisfied' || false}
                  />
                  <label htmlFor="reward4">
                    <img
                      src={dissatisfied}
                      className="face"
                      alt="face action"
                    />
                    Dissatisfied
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="reward5"
                    name="radio-group"
                    onChange={() => {
                      setValues({
                        ...values,
                        reason: 'Extremely Unhappy'
                      });
                      setErrors({
                        ...errors,
                        reasonError: '',
                        otherReasonError: ''
                      });
                    }}
                    checked={values.reason === 'Extremely Unhappy' || false}
                  />
                  <label htmlFor="reward5">
                    <img src={angry} className="face" alt="face action" />
                    Extremely Unhappy
                  </label>
                </div>
              </div>
              <div className="mt-3" style={{ color: 'red' }}>
                {errors.reasonError}
              </div>
            </div>

            <div className="head">
              <p className="text-theme m-0">
                What do you enjoy <br />
                most about Lovrican?
              </p>
              <div className="roseBox">
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <img
                    src={themeRose}
                    className="img-rose-fluid"
                    alt="Lovrican rose"
                  />
                  <span className="text-theme">
                    <em>02 Roses</em>
                  </span>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="feedback_field">
                <div className="label">I love that Lovrican ...</div>
                <textarea
                  className="form-control"
                  name="review"
                  rows="5"
                  placeholder="Tell us what you like about Lovrican"
                  value={values.otherReason}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      otherReason: e.target.value
                    });
                    setErrors({ ...errors, otherReasonError: '' });
                  }}
                />
                <div className="mt-3" style={{ color: 'red' }}>
                  {errors.otherReasonError}
                </div>
              </div>
            </div>

            <div className="head">
              <p className="text-theme m-0">
                What new feature or improvement would <br />
                enhance your Lovrican experience?
              </p>
              <div className="roseBox">
                <div className="w-100 d-flex justify-content-around align-items-center">
                  <img
                    src={themeRose}
                    className="img-rose-fluid"
                    alt="Lovrican rose"
                  />
                  <span className="text-theme">
                    <em>02 Roses</em>
                  </span>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="feedback_field">
                <div className="label">I wish Lovrican ...</div>
                <textarea
                  className="form-control second"
                  name="review"
                  rows="5"
                  placeholder="Tell us what you like about Lovrican"
                  value={values.improvement}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      improvement: e.target.value
                    });
                    setErrors({ ...errors, improvementError: '' });
                  }}
                />
                <div className="mt-3" style={{ color: 'red' }}>
                  {errors.improvementError}
                </div>
              </div>
            </div>
            <button
              className="submit-btn"
              type="button"
              aria-hidden="true"
              onClick={handleSubmit}
              disabled={loading || false}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsFeedbacksPage;
