import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import indexRoutes from './routes';
import ScrollToTop from './scrollToTop';

const hist = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
      <Provider store={configureStore()}>
        <BrowserRouter history={hist}>
          <ScrollToTop>
            <Switch>
              {indexRoutes.map((prop) => (
                <Route
                  exact={prop.exact}
                  path={prop.path}
                  key={prop.path}
                  component={prop.component}
                />
              ))}
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
