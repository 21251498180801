import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
// import logo from '../../assets/images/Logo.svg';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import privatelock from '../../assets/images/private_lock.svg';
import { authCall } from '../../services';
import AppConstants from '../../appConstants';

const Step15Component = ({ userDetails }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [validator] = useState(
    new SimpleReactValidator({
      validators: {
        linkedInURL: {
          message: 'Invalid linkedIn URL.',
          rule: val => {
            const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9-]+\/?$/;
            return linkedInRegex.test(val);
          },
          required: true // optional
        },
        phoneNumber: {
          message: 'Invalid mobile number.',
          rule: (val) => val.length < 12,
          required: true
        }
      }
    })
  );
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    phoneNumber: userDetails?.phone ? userDetails?.phone : '',
    phoneHelp: false,
    linkedInURL: userDetails?.linkedin_URL ? userDetails?.linkedin_URL : '',
    linkedInHelp: false
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        linkedin_URL: values.linkedInURL,
        phone: values.phoneNumber,
        isProfileComplted: 'YES'
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        // handleNext(step);
        setLoading(false);
        history.push(AppConstants.routes.afterLogin);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step15" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Complete the final step to help verify authenticity on Lovrican.
                Your information remains confidential
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3 private_wrapper">
              <div className="custom_input p-0">
                <PhoneInput
                  containerClass="custom_flag"
                  placeholder="Phone Number"
                  country="us"
                  value={values.phoneNumber}
                  onChange={(phone) =>
                    setValues({ ...values, phoneNumber: phone })
                  }
                />
                <input
                  type="text"
                  name="user-phone_number"
                  id="user-phone_number"
                  placeholder="Phone Number"
                  autoComplete="off"
                  className="custom_input d-none"
                  value={values.phoneNumber}
                  onChange={(e) =>
                    setValues({ ...values, phoneNumber: e.target.value })
                  }
                />
              </div>
              {validator.message(
                'Phone Number',
                values.phoneNumber,
                'phoneNumber|required'
              )}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      phoneHelp: true,
                      linkedInHelp: false
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.phoneHelp && (
                <div className="toolTipHelp d-flex left">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({
                          ...values,
                          phoneHelp: false
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3 private_wrapper">
              <input
                type="text"
                name="user-linkedIn_URL"
                id="user-linkedIn_URL"
                placeholder="Your LinkedIn Profile Link"
                autoComplete="off"
                className="custom_input"
                value={values.linkedInURL}
                onChange={(e) =>
                  setValues({ ...values, linkedInURL: e.target.value })
                }
              />
              {validator.message(
                'linkedIn url',
                values.linkedInURL,
                'linkedInURL|required'
              )}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      linkedInHelp: true,
                      phoneHelp: false
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.linkedInHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({
                          ...values,
                          linkedInHelp: false
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 mb-3  marb7 text-right">
              <button
                className={`new_next_btn metMaches_btn ${
                  loading ? 'hks-btn-disable' : ''
                }`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                {userDetails?.role === 'AFFILIATE' ? "Complete" : "Meet Matches"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step15Component;
