import React from 'react';
import { Helmet } from 'react-helmet';

function ServiceTermsPage() {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Lovrican</title>
        <meta
          name="description"
          content="Explore Lovrican's Terms of Service to understand the guidelines that govern our platform. Discover how we create a safe and cultivated space for your love journey."
        />
        <meta name="keywords" content="Terms of Service" />
      </Helmet>
      <div className="row privacy_policy">
        <div className="col col-md-12">
          <div className="">
            <div className="service-content">
              <div className="sub-inner">
                <div className="sub-heading">
                  <h5 className="text-center">Lovrican’s Terms of Service</h5>
                  <div className="subhead">
                    <p>
                      This is a legal agreement (&quot;Agreement&quot;) between
                      you and Lovrican LLC., a New Jersey Limited Liability
                      Company (&quot;Lovrican&quot;), which may be contacted at
                      P.O. Box 437, Raritan, New Jersey, 08869, USA. By
                      accessing the Lovrican web site, currently located
                      at&nbsp;
                      <a
                        href={process.env.REACT_APP_BASE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.Lovrican.com
                      </a>
                      &nbsp;(the &quot;Site&quot;), and using any of the
                      Services (as defined below) accessible through the Site,
                      you become a user and agree to, and are bound by, the
                      terms and conditions of this Agreement for as long as you
                      continue to use the Site or Services.&nbsp; IF YOU DO NOT
                      AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT USE THE SITE
                      OR THE SERVICES .&nbsp;Your use of, or participation in,
                      certain Services may be subject to additional terms, and
                      such terms will be either listed in this Agreement or will
                      be presented to you for your acceptance when you sign up
                      to use such Services.
                    </p>
                    <p>
                      The Lovrican services consist of the following, without
                      limitation: a service for single people (&quot;Singles
                      Service&quot;), interactive content and community services
                      such as&nbsp;Lovrican Advice&nbsp;(&quot;Content
                      Services&quot;), any premium services offered by or on
                      behalf of Lovrican (&quot;Premium Services&quot;)
                      (collectively, the &quot;Services&quot;). Lovrican may
                      offer additional services or revise any of the Services,
                      at its discretion, and this Agreement will apply to all
                      additional services or revised Services. Lovrican also
                      reserves the right to cease offering any of the Services.
                    </p>
                    <p>
                      This Agreement is subject to change by Lovrican in its
                      sole discretion at any time, and any such changes will be
                      posted on the Site. Your continued use of this Site or the
                      Services after the posting of revisions to this Agreement
                      will constitute your acceptance of such revisions. Please
                      consult the end of this Agreement to determine when the
                      Agreement was last revised.
                    </p>
                  </div>
                </div>

                <h3 className="subhead pt-4">1. Eligibility</h3>
                <div className="subhead pl-4">
                  <ul>
                    <li>
                      <span>Minimum Age.</span>
                      &nbsp;You must be at least 18 years old to use the Site or
                      to register for the Services. By using the Singles
                      Service, you represent and warrant that you are at least
                      18 years old. Other Services may have other age
                      requirements for all or portion of such Services, and such
                      other age requirements are stated on such Services or
                      portions thereof.
                    </li>
                    <li>
                      <span>Marital Status.</span>
                      &nbsp;By registering to use or using the Singles Service,
                      you represent and warrant that you are single or (if
                      legally married) separated. If you are married and not
                      separated, you may not register to use or use the Singles
                      Service.
                    </li>
                    <li>
                      <span>Criminal History.</span>
                      &nbsp;By requesting to use, registering to use, and/or
                      using the Singles Service, you represent and warrant that
                      you have never been convicted of a felony and/or are not
                      required to register as a sex offender with any government
                      entity.&nbsp;
                      <strong>
                        LOVRICAN DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND
                        SCREENINGS ON ITS MEMBERS
                      </strong>
                      .&nbsp;However, Lovrican reserves the right to conduct any
                      criminal background check, at any time and using available
                      public records, to confirm your compliance with this
                      subsection. By agreeing to these terms and conditions, you
                      hereby authorize any such check.
                    </li>
                    <li>
                      <span>Meeting Matching System Criteria.</span>
                      &nbsp;The Service requires your completion of the Lovrican
                      Profile for Lovrican to find highly compatible matches for
                      you. However, Lovrican may still deliver matches to you if
                      you have not completed the Lovrican Profile, provided that
                      the self-select portion (e.g. match distance, age
                      preferences, etc.) has been submitted. These pre-Lovrican
                      Profile matches will not be confirmed as compatible.
                    </li>
                  </ul>
                </div>

                <h3 className="subhead pt-4">2. Use of Site and Service</h3>
                <div className="subhead pl-4">
                  <p>
                    As a user of the Site or a user registered to use any of the
                    Services (a &quot;Registered User&quot;), you agree to the
                    following:
                  </p>
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Exclusive Use.</span>
                      &nbsp;Your account is for your personal use only. You may
                      not authorize others to use your account, and you may not
                      assign or otherwise transfer your account to any other
                      person or entity. You acknowledge that Lovrican is not
                      responsible for third party access to your account that
                      results from theft or misappropriation of your user names
                      and passwords.&nbsp;
                    </li>
                    <li>
                      <span>Geographic Limitations.</span>
                      &nbsp;The Site and Services are intended for use in the
                      United States. You will only use the Services in a manner
                      consistent with this Agreement and any and all applicable
                      local, state, national and international laws and
                      regulations, including, but not limited to, United States
                      export control laws. By using the Services, you represent
                      that you have not been designated by the United States
                      government as a &quot;Specially Designated National&quot;
                      or other person to whom the provisions of the Services are
                      prohibited. Registration for, and use of, the Services are
                      void where prohibited. You are responsible for determining
                      whether the user of the Services is legal in your
                      jurisdiction.
                    </li>
                    <li>
                      <span>Information Submitted.</span>
                      &nbsp;You are solely responsible for, and assume all
                      liability regarding, (i) the information and content you
                      contribute to the Service; (ii) the information and
                      content you post, transmit, publish, or otherwise make
                      available (hereinafter &quot;post&quot;) through the
                      Services; and (iii) your interactions with other
                      Registered Users through the Services. In addition to
                      sharing your information with your matches, we may allow
                      your matches to share your profile information with
                      members of their social network(s).&nbsp;
                    </li>
                    <li>
                      <span>Risk Assumption and Precautions.</span>
                      &nbsp;You assume all risk when using the Services,
                      including but not limited to all of the risks associated
                      with any online or offline interactions with others,
                      including dating. You agree to take all necessary
                      precautions when meeting individuals through the Singles
                      Service. In addition, you agree to review and follow the
                      recommendations set forth in Lovrican’s&nbsp;Safety Tips,
                      which will be provided to you prior to entering the
                      &quot;Lovrican Mail&quot; phase with your matches in the
                      Singles Service and is available at the bottom of all
                      pages of the Singles Service.&nbsp;
                    </li>
                    <li>
                      <span>No Guarantees.</span>
                      &nbsp;Lovrican may not be able to provide matches for
                      everyone seeking to use its services. Further, Lovrican
                      makes no guarantees as to the number or frequency of
                      matches through the Singles Service, or to such matches’
                      ability, desire or criteria to communicate with any user.
                      You understand that Lovrican makes no guarantees, either
                      express or implied, regarding your ultimate compatibility
                      with individuals you meet through the Singles Service or
                      as to the conduct of such individuals.
                    </li>
                    <li>
                      <span>Reporting of Violations.</span>
                      &nbsp;You will promptly report to Lovrican any violation
                      of the Agreement by others, including but not limited to,
                      Registered Users.
                    </li>
                    <li>
                      <span>Content Removal.</span>
                      &nbsp;Lovrican reserves the right, but has no obligation,
                      to monitor the information or material you submit to the
                      Services or post in the public areas of the Services.
                      Lovrican will have the right to remove any such
                      information or material that in its sole opinion violates,
                      or may violate, any applicable law or either the letter or
                      spirit of this Agreement or upon the request of any third
                      party. Lovrican further reserves the right to remove
                      matches previously delivered to you, in its reasonable
                      discretion, in order to assure that you have a quality
                      experience on the Site.
                    </li>
                    <li>
                      <span>Posting and Communication Restrictions.</span>
                      &nbsp;You will not post on the Services, transmit to other
                      users, communicate any content (or links thereto), or
                      otherwise engage in any activity on the Site or through
                      the Services, that:
                    </li>
                    <ol type="i">
                      <li>
                        promotes racism, bigotry, hatred or physical harm of any
                        kind against any group or individual;
                      </li>
                      <li>
                        is intended to or tends to harass, annoy, threaten or
                        intimidate any other users of the Site or Services;
                      </li>
                      <li>
                        is defamatory, inaccurate, abusive, obscene, profane,
                        offensive, sexually oriented, obscene or otherwise
                        objectionable;
                      </li>
                      <li>
                        contains others’ copyrighted content (e.g., music,
                        movies, videos, photographs, images, software, etc.)
                        without obtaining permission first;
                      </li>
                      <li>
                        contains video, audio photographs, or images of another
                        person without his or her permission (or in the case of
                        a minor, the minor’s legal guardian);
                      </li>
                      <li>
                        promotes or enables illegal or unlawful activities, such
                        as instructions on how to make or buy illegal weapons or
                        drugs, violate someone’s privacy, harm or harass another
                        person, obtain others’ identity information, create or
                        disseminate computer viruses, or circumvent copy-protect
                        devices;
                      </li>
                      <li>
                        intended to defraud, swindle or deceive other users of
                        the Services;
                      </li>
                      <li>
                        contains viruses, time bombs, trojan horses, cancelbots,
                        worms or other harmful, or disruptive codes, components
                        or devices;
                      </li>
                      <li>
                        promotes or solicits involvement in or support of a
                        political platform, religion, cult, or sect;
                      </li>
                      <li>
                        disseminates another person’s personal information
                        without his or her permission, or collects or solicits
                        another person’s personal information for commercial or
                        unlawful purposes;
                      </li>
                      <li>
                        is off-topic, meaningless, or otherwise intended to
                        annoy or interfere with others’ enjoyment of the Site;
                      </li>
                      <li>
                        impersonates, or otherwise misrepresents affiliation,
                        connection or association with, any person or entity;
                      </li>
                      <li>
                        solicits gambling or engages in any gambling or similar
                        activity;
                      </li>
                      <li>
                        uses scripts, bots or other automated technology to
                        access the Site or Services;
                      </li>
                      <li>
                        uses the Site or Services for chain letter, junk mail or
                        spam e-mails;
                      </li>
                      <li>
                        collects or solicits personal information about anyone
                        under 18; or
                      </li>
                      <li>
                        is in any way used for or in connection with spamming,
                        spimming, phishing, trolling, or similar activities.
                      </li>
                      <p>
                        <span className="italic">
                          {' '}
                          In addition to the above, postings on the Content
                          Services currently known as Lovrican Advice
                          (&quot;Advice&quot;) will follow the
                          Advice&nbsp;Community Standards&nbsp;, in addition to
                          the terms of this Agreement.
                        </span>
                      </p>
                    </ol>
                    <li>
                      <span>No False Information.</span>
                      &nbsp;You will not provide inaccurate, misleading or false
                      information to Lovrican or to any other user. If
                      information provided to Lovrican or another user
                      subsequently becomes inaccurate, misleading or false, you
                      will promptly notify Lovrican of such change.
                    </li>
                    <li>
                      <span>No Advertising or Commercial Solicitation.</span>
                      &nbsp;You will not advertise or solicit any user to buy or
                      sell any products or services through the Site or
                      Services. You may not transmit any chain letters, junk or
                      spam e-mail to other users. Further, you will not use any
                      information obtained from the Services in order to
                      contact, advertise to, solicit, or sell to any user
                      without their prior explicit consent. If you breach the
                      terms of this subsection and send or post unsolicited bulk
                      email, &quot;spam&quot; or other unsolicited
                      communications of any kind through the Services, you
                      acknowledge that you will have caused substantial harm to
                      Lovrican, but that the amount of such harm would be
                      extremely difficult to ascertain. As a reasonable
                      estimation of such harm, you agree to pay Lovrican $60 for
                      each such unsolicited communication you send through the
                      Services.
                    </li>
                    <li>
                      <span>Unique and Bona Fide Profile.</span>
                      &nbsp;As a Registered User of the Singles Service, you
                      will create only one unique profile. In addition, your use
                      of the Singles Service must be for bona fide
                      relationship-seeking purposes in order to maintain the
                      integrity of the Singles Service (for example, you may not
                      become a Registered User solely to compile a report of
                      compatible singles in your area, or to write a school
                      research paper). Not all registered users are available
                      for matching. From time to time, Lovrican may create test
                      profiles in order to monitor the operation of the
                      Services.
                    </li>
                    <li>
                      <span>Selection of Profiles.</span>
                      &nbsp;From time to time, your profile may be selected to
                      introduce you to specific compatible matches, in which
                      case we may offer you a limited complimentary subscription
                      (if you are not already a subscriber) in order to enable
                      you to meet such specific match. You will be under no
                      obligation to accept such complimentary subscription or to
                      meet with or communicate with the match(es) we may
                      introduce to you.
                    </li>
                    <li>
                      <span>
                        No Harassment of Lovrican Employees or Agents.
                      </span>
                      &nbsp;You will not harass, annoy, intimidate or threaten
                      any Lovrican employees or agents engaged in providing any
                      portion of the Services to you.
                    </li>
                    <li>
                      <span>Social Media.</span>
                      &nbsp;We may provide you the option to connect your
                      Lovrican account to your account on some social networking
                      sites (such as via Facebook Connect) for the purpose of
                      logging in, uploading information or enabling certain
                      features on the Service. When enabling this feature, we
                      will disclose to you the information we collect from the
                      connected social networking site, and will use such
                      information in compliance with our Privacy Policy. Once
                      connected, some of your matches may also be able to see
                      information about your social network, such as the size of
                      your network and your friends, including common friends.
                      By connecting your Lovrican account to your account on any
                      social networking site, you hereby consent to the
                      continuous release of information about you to Lovrican.
                      We will not send any of your Lovrican account information
                      to the connected social networking site without first
                      disclosing that to you. You can always disconnect the
                      accounts via your Lovrican Account Settings page. Each
                      social network may further allow you to set privacy
                      controls around your information on their system, and
                      Lovrican’s collection of information will always follow
                      such controls and permissions. This feature is subject to
                      continuous change and improvement by us and each social
                      networking site involved, and therefore the available
                      features and shared information are subject to change
                      without notice to you.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">3. Proprietary Rights</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Ownership of Proprietary Information.</span>
                      &nbsp;You hereby acknowledge and agree that Lovrican is
                      the owner of highly valuable proprietary information,
                      including without limitation, the compatibility matching
                      system, compatibility profiles, and relationship
                      questionnaires (collectively, &quot;Confidential
                      Information&quot;). Lovrican owns and hereby retains all
                      proprietary rights in the Services and the Site, including
                      but not limited to, all Confidential Information.
                    </li>
                    <li>
                      <span>No Use of Confidential Information.</span>
                      You will not post, copy, modify, transmit, disclose, show
                      in public, create any derivative works from, distribute,
                      make commercial use of, or reproduce in any way any (i)
                      Confidential Information or (ii) other copyrighted
                      material, trademarks, or other proprietary information
                      accessible via the Services, without first obtaining the
                      prior written consent of the owner of such proprietary
                      rights.
                    </li>
                    <li>
                      <span>Other Users’ Information.</span>
                      Other Registered Users may post copyrighted information,
                      which has copyright protection whether or not it is
                      identified as copyrighted. You agree that you will not
                      copy, modify, publish, transmit, distribute, perform,
                      display, commercially use, or sell any Lovrican or third
                      party proprietary information available via the Services
                      or the Site.
                    </li>
                    <li>
                      <span>License to Posted or Accessed Content.</span>
                      By posting information or content to any profile pages or
                      public area of the Services, or making it accessible to us
                      by linking your Lovrican account to any of your social
                      network accounts (e.g. via Facebook Connect), you
                      automatically grant, and you represent and warrant that
                      you have the right to grant, to Lovrican and its users, an
                      irrevocable, perpetual, non-exclusive, fully-paid,
                      worldwide license to use, reproduce, publicly perform,
                      publicly display and distribute such information and
                      content, and to prepare derivative works of, or
                      incorporate into other works, such information and
                      content, and to grant and authorize sub-licenses of the
                      foregoing. From time to time, we may create, test or
                      implement new features or programs on the Site in which
                      you may voluntarily choose to participate or may be a part
                      of a test group with special access, in accordance with
                      the additional terms and conditions of such features or
                      programs. By your participation in such features or
                      programs, you grant us the rights stated in this
                      subsection in connection with the additional terms and
                      conditions (if any) of such features or programs.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">4. User Information</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Privacy Statement.</span> For information about the
                      collection and possible use of information and material
                      provided by you, please click on Lovrican’s&nbsp;Privacy
                      Statement&nbsp;located on the Site. By using the Site or
                      the Services, you are consenting to the terms of
                      Lovrican’s&nbsp;Privacy Statement.
                    </li>
                    <li>
                      <span>No Contact Information.</span>
                      &nbsp;You agree that you will not post any e-mail
                      addresses, personal website address or profile page you
                      may have on a third-party website, or other contact
                      information in the &quot;About Me&quot; section of the
                      Singles Service or in any other communications you may
                      have with other users. You may, at your discretion,
                      exchange such information after you reach the
                      &quot;Lovrican Mail&quot; phase with your match.
                    </li>
                    <li>
                      <span>Disclosure By Law.</span>
                      &nbsp;You acknowledge and agree that Lovrican may disclose
                      information you provide if required to do so by law, at
                      the request of a third party, or if we, in our sole
                      discretion, believe that disclosure is reasonable to (1)
                      comply with the law, requests or orders from law
                      enforcement, or any legal process (whether or not such
                      disclosure is required by applicable law); (2) protect or
                      defend Lovrican’s, or a third party’s, rights or property;
                      or (3) protect someone’s health or safety, such as when
                      harm or violence against any person (including the user)
                      is threatened.
                    </li>
                    <li>
                      <span>Disclosure to Protect Abuse Victims.</span>
                      &nbsp;Notwithstanding any other provision of this
                      Agreement or the&nbsp;Privacy Statement, Lovrican reserves
                      the right, but has no obligation, to disclose any
                      information that you submit to the Services, if in its
                      sole opinion, Lovrican suspects or has reason to suspect,
                      that the information involves a party who may be the
                      victim of abuse in any form. Abuse may include, without
                      limitation, elder abuse, child abuse, spousal abuse,
                      neglect, or domestic violence. Information may be
                      disclosed to authorities that Lovrican, in its sole
                      discretion, deems appropriate to handle such disclosure.
                      Appropriate authorities may include, without limitation,
                      law enforcement agencies, child protection agencies, or
                      court officials. You hereby acknowledge and agree that
                      Lovrican is permitted to make such disclosure.
                    </li>
                    <li>
                      <span>Use of Anonymous Information for Research.</span>
                      &nbsp;By using the Services, you agree to allow Lovrican
                      to anonymously use the information from you and your
                      experiences through the Services to continue Lovrican’s
                      research into successful relationships and to improve the
                      Services. This research, conducted by psychologists and
                      behavior research scientists, may be published in academic
                      journals. However, all of your responses will be
                      anonymous, and we will not publish research containing
                      your personal identifying information.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">
                  5. Links to Third-Party Web Sites / Dealings with Advertisers
                  and Sponsors
                </h3>
                <div className="subhead pl-4">
                  <p>
                    The Services may contain links to web sites of
                    third-parties, including without limitation, advertisers,
                    which are not under the control of Lovrican, and Lovrican is
                    not responsible for the content of any linked site or any
                    link contained in a linked site, or any changes or updates
                    to such web sites. Lovrican provides these links to you only
                    as a convenience, and the inclusion of any link does not
                    imply that Lovrican endorses or accepts any responsibility
                    for the content on such third-party web site. Your
                    correspondence or business dealings with, or participation
                    in promotions of, advertisers or sweepstakes sponsors found
                    on or through the Services (including payment and delivery
                    of related goods or services, any personal information or
                    opt-in contact information voluntarily given to advertisers
                    and sweepstake sponsors, and any other terms, conditions,
                    warranties or representations associated with such dealings)
                    are solely between you and such advertiser or sweepstakes
                    sponsor. You agree that Lovrican will not be responsible or
                    liable for any loss or damage of any sort incurred as the
                    result of any such dealings, including the sharing of the
                    information you supply to Lovrican with advertisers or
                    sweepstakes sponsors, or as the result of the presence of
                    such advertisers on the Services. Please visit
                    our&nbsp;Privacy Statement&nbsp;to learn more about how we
                    use your information.
                  </p>
                </div>
                <h3 className="subhead pt-4">6. Disclaimer of Warranty</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>No Warranties.</span>
                      &nbsp;THIS SECTION WILL APPLY TO THE MAXIMUM EXTENT
                      PERMITTED BY APPLICABLE LAW. LOVRICAN PROVIDES THE
                      SERVICES ON AN &quot;AS IS&quot; AND &quot;AS
                      AVAILABLE&quot; BASIS AND GRANTS NO WARRANTIES OF ANY
                      KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE
                      WITH RESPECT TO THE SERVICES OR THE SITE (INCLUDING ALL
                      INFORMATION CONTAINED THEREIN), INCLUDING ANY IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE OR NON-INFRINGEMENT. LOVRICAN DOES NOT WARRANT
                      THAT YOUR USE OF THE SERVICES WILL BE SECURE,
                      UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET
                      YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICES
                      WILL BE CORRECTED. LOVRICAN DISCLAIMS LIABILITY FOR, AND
                      NO WARRANTY IS MADE WITH RESPECT TO, THE CONNECTIVITY AND
                      AVAILABILITY OF THE SERVICES.
                    </li>
                    <li>
                      <span>Third Party Content.</span>
                      &nbsp;Opinions, advice, statements, offers, or other
                      information or content made available through the
                      Services, but not directly by Lovrican, are those of their
                      respective authors, and should not necessarily be relied
                      upon. Such authors are solely responsible for such
                      content. LOVRICAN DOES NOT: (i) GUARANTEE THE ACCURACY,
                      COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON
                      THE SERVICES, OR (ii) ADOPT, ENDORSE OR ACCEPT
                      RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
                      OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN
                      LOVRICAN. UNDER NO CIRCUMSTANCES WILL LOVRICAN BE
                      RESPONSIBLE FOR ANY LOSS OR DAMAGE RESULTING FROM ANYONE’S
                      RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE
                      SERVICES, OR TRANSMITTED TO OR BY ANY USERS.
                    </li>
                    <li>
                      <span>Beta Features.</span>
                      &nbsp;From time to time, Lovrican may offer new
                      &quot;beta&quot; features or tools with which its users
                      may experiment on the Services. Such features or tools are
                      offered solely for experimental purposes and without any
                      warranty of any kind, and may be modified or discontinued
                      at Lovrican’s sole discretion. The provisions of this
                      Disclaimer of Warranty section apply with full force to
                      such features or tools.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">7. Limitation of Liability</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Incidental Damages and Aggregate Liability.</span>
                      &nbsp;TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                      IN NO EVENT WILL LOVRICAN BE LIABLE FOR ANY INCIDENTAL,
                      SPECIAL, CONSEQUENTIAL OR INDIRECT DAMAGES ARISING OUT OF
                      OR RELATING TO THE USE OR INABILITY TO USE THE SERVICES,
                      INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OR
                      CORRUPTION OF DATA OR PROGRAMS, SERVICE INTERRUPTIONS AND
                      PROCUREMENT OF SUBSTITUTE SERVICES, EVEN IF LOVRICAN KNOWS
                      OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO
                      THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO
                      CIRCUMSTANCES WILL LOVRICAN’S AGGREGATE LIABILITY, IN ANY
                      FORM OF ACTION WHATSOEVER IN CONNECTION WITH THIS
                      AGREEMENT OR THE USE OF THE SERVICES OR THE SITE, EXCEED
                      THE PRICE PAID BY YOU FOR YOUR ACCOUNT, OR, IF YOU HAVE
                      NOT PAID LOVRICAN FOR THE USE OF ANY SERVICES, THE AMOUNT
                      OF US$10 OR ITS EQUIVALENT.
                    </li>
                    <li>
                      <span>No Liability for non-Lovrican Actions.</span>
                      &nbsp;TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                      IN NO EVENT WILL LOVRICAN BE LIABLE FOR ANY DAMAGES
                      WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL,
                      COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING
                      OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN
                      CONNECTION WITH THE USE OF THE SERVICES, INCLUDING WITHOUT
                      LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY
                      OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS
                      WITH OTHER REGISTERED USERS OF THE SERVICES. THIS INCLUDES
                      ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF
                      USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO
                      ATTEMPT TO DEFRAUD OR HARM YOU.
                    </li>
                    <li>
                      <span>Information Verification.</span>
                      &nbsp;Lovrican and its contractors may use various ways of
                      verifying information that users have provided. However,
                      none of those ways are perfect, and you agree that
                      Lovrican and its contractors will have no liability to you
                      arising from any incorrectly verified information.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">8. Indemnification</h3>
                <div className="subhead pl-4">
                  <p>
                    You agree to indemnify, defend and hold harmless Lovrican,
                    its subsidiary and parent companies, and each of their
                    officers, directors, employees, agents and related third
                    parties, for any losses, costs, liabilities and expenses
                    (including reasonable attorneys’ fees) relating to or
                    arising out of any third party claim that (a) your use of or
                    inability to use the Site or Services, (b) any user postings
                    made by you, (c) your violation of any terms of this
                    Agreement or your violation of any rights of a third party,
                    or (d) your violation of any applicable laws, rules or
                    regulations. Lovrican reserves the right, at its own cost,
                    to assume the exclusive defense and control of any matter
                    otherwise subject to indemnification by you, in which event
                    you will fully cooperate with Lovrican in asserting any
                    available defenses.
                  </p>
                </div>
                <h3 className="subhead pt-4">
                  9. Complaints / Law Enforcement Contact
                </h3>
                <div className="subhead pl-4">
                  <p>
                    To resolve a complaint regarding the Service, you should
                    review our Frequently Asked Questions (FAQ) by clicking on
                    the&nbsp;Help&nbsp;link located at the bottom of any page of
                    the Lovrican website, or e-mail us by
                    clicking&nbsp;here&nbsp;. Law enforcement officials may send
                    correspondence (such as subpoenas, court orders and
                    warrants) to Lovrican via Postal Mail at: Lovrican, P.O. Box
                    437, Raritan, NJ, 08869. All other communication or
                    inquiries sent to this facsimile number will be discarded.
                  </p>
                </div>
                <h3 className="subhead pt-4">10. Communication and Privacy</h3>
                <div className="subhead pl-4">
                  <p>
                    We may use your email address to send you messages notifying
                    you of important changes to the Services or special offers.
                    Further, we may contact you by telephone if you voluntarily
                    provide us with your telephone number, in order to
                    communicate with you regarding the Services. If you do not
                    want to receive such email messages or telephone calls
                    (including at any wireless number you may have voluntarily
                    provided us), please refer to our&nbsp;Privacy
                    Statement&nbsp;to review your options.
                  </p>
                </div>
                <h3 className="subhead pt-4">11. Term and Termination</h3>
                <div className="subhead pl-4">
                  <p>
                    This Agreement will become effective upon your acceptance of the Agreement by your use of the Site or the Services and will remain in effect in perpetuity unless terminated hereunder. Either you or Lovrican may terminate your account at any time, for any reason or no reason, without explanation, effective upon sending written notice to the other party. Lovrican reserves the right to immediately suspend or terminate your access to any of the Services, without notice, for any reason or no reason. We also reserve the right to remove your account information or data from our Services and any other records at any time at our sole discretion. In the event your access to any of the Services is suspended due to the breach of this Agreement, you agree that all fees then paid to Lovrican by you will be nonrefundable. You may terminate your account by following the steps in the applicable section under &quot;Cancellations&quot; below, or by sending a notice of cancellation to: Lovrican, LLC., Attn: Account Cancellations, P.O. Box 437, Raritan, NJ USA 08869. Following any termination of any Registered User’s use of the Singles Service, Lovrican reserves the right to send a notice thereof to other Registered Users which whom you have corresponded.
                  </p>
                </div>
                <h3 className="subhead pt-4">12. Cancellations</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Cancellation At Any Time With No Refund.</span>
                      &nbsp;Except as otherwise stated in this section, you may
                      cancel your registration or subscription to any Services
                      at any time during the term of such registration or
                      subscription or any renewal period by accessing the
                      &quot;Account Settings&quot; page under the &quot;My
                      Settings&quot; option on your membership home page,
                      clicking on &quot;Cancel My Subscription&quot; or
                      &quot;Close Account&quot; link, and providing the
                      information requested. In such case, your subscription
                      will terminate at the end of the subscription term for
                      which you have paid, and you will not receive any refund
                      for any unused days of such subscription term. If you
                      purchase a subscription on an installation payment basis
                      (such as three-part pay), cancellation will not affect
                      your obligation to pay the total contractual amount due on
                      your subscription and your credit card will continue to be
                      charged the scheduled payments until the remaining balance
                      is paid in full.&nbsp;
                    </li>
                    <li>
                      <span>3-Day Cancellation.</span>
                      &nbsp;Regarding the Singles Service, you, the buyer, may
                      cancel the Agreement, without any penalty or obligation,
                      at any time prior to midnight of the third business day
                      following the date of this Agreement, excluding Sundays
                      and holidays. To cancel this agreement, you can
                      email&nbsp;subscriptions@Lovrican.com&nbsp;or mail a
                      signed and dated notice, which states you, the buyer, are
                      canceling this agreement, or words of similar effect. This
                      mailed notice shall be sent to Lovrican, LLC., Attn:
                      Account Cancellations, P.O. Box 437, Raritan, NJ USA
                      08869. Please include the e-mail address associated with
                      your Lovrican account in this notice. For Singles
                      Services, the day that you submit a completed subscription
                      form will be the date of this Agreement. Any refunds under
                      this 3-day cancellation policy will be made within 10 days
                      after Lovrican’s receipt of your cancellation notice.
                    </li>
                    <li>
                      <span>
                        Cancellation As a Result of Death or Disability.
                      </span>
                      &nbsp;If by reason of death or disability you are unable
                      to receive all Singles Services for which you contracted,
                      you or your estate may elect to be relieved of the
                      obligation to make payments for services other than those
                      received before death or the onset of disability, except
                      as provided in subsection (iii).
                    </li>
                    <ol type="i">
                      <li>
                        If you have prepaid any amount for the Singles Service,
                        so much of the amount prepaid that is allocable to
                        services that you have not received will be promptly
                        refunded to you or your representative.
                      </li>
                      <li>
                        &quot;Disability&quot; means a condition which precludes
                        you from physically using the Singles Service during the
                        term of disability and the condition is verified in
                        writing by a physician designated and remunerated by
                        you. Written verification from the physician must be
                        presented to Lovrican.
                      </li>
                      <li>
                        If the physician determines that the duration of the
                        disability will be less than six (6) months, Lovrican
                        may extend the term of the Single Services contract for
                        a period of six (6) months at no additional charge to
                        you in lieu of cancellation.
                      </li>
                    </ol>
                  </ol>
                </div>
                <h3 className="danger subhead pt-4">13. *RENEWALS*</h3>
                <div className="subhead pl-4">
                  <p className="danger">
                    In order to provide continuous service, Lovrican
                    automatically renews all paid subscriptions for the Services
                    on the date such subscriptions expire. We always communicate
                    renewal periods to you in the subscription plan page, before
                    you finalize the purchase of your subscription, upon
                    confirmation of purchase, and in the body of any special
                    promotions sent to our users. By entering into this
                    Agreement, you acknowledge that your account will be subject
                    to the above-described automatic renewals. In all cases, if
                    you do not wish your account to renew automatically, please
                    follow the directions set out under &quot;Cancellation At
                    Any Time With No Refund&quot; above.
                  </p>
                </div>
                <h3 className="subhead pt-4">
                  14. Specific Terms Controlling the Premium Services.
                </h3>
                <div className="subhead pl-4">
                  <p>
                    Use of the Premium Services shall be subject to the
                    additional terms and conditions.
                  </p>
                </div>
                <h3 className="subhead pt-4">
                  15. Arbitration Agreement &amp; Class Action Waiver
                </h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Purpose:</span>
                      &nbsp;This Arbitration Agreement facilitates the prompt
                      and efficient resolution of any disputes that may arise
                      between you and Lovrican. Arbitration is a form of private
                      dispute resolution in which parties to a contract agree to
                      submit their disputes and potential disputes to a neutral
                      third person (called an arbitrator) for a binding
                      decision, instead of having such dispute(s) decided in a
                      lawsuit, in court, by a judge or jury trial.
                      <br />
                      <br />
                      Please read this Arbitration Agreement carefully. It
                      provides that all disputes between you and Lovrican shall
                      be resolved by binding arbitration. Arbitration replaces
                      the right to go to court. In the absence of this
                      Arbitration Agreement, you may otherwise have a right or
                      opportunity to bring claims in a court, before a judge or
                      jury, and/or to participate in or be represented in a case
                      filed in court by others (including, but not limited to,
                      class actions). Entering into this Arbitration Agreement
                      constitutes a waiver of your right to litigate claims in
                      court and all opportunity to be heard by a judge or jury.
                      There is no judge or jury in arbitration, and court review
                      of an arbitration award is limited. The arbitrator must
                      follow this Arbitration Agreement and can award the same
                      damages and relief as a court (including attorney’s fees,
                      if otherwise authorized by applicable law)&nbsp;
                      <br />
                      <br />
                      For the purpose of this Arbitration Agreement, “Lovrican”
                      means Lovrican, LLC. and its parents, subsidiaries, and
                      affiliated companies, and each of their respective
                      officers, directors, employees, and agents. The term
                      “Dispute” means any dispute, claim, or controversy between
                      you and Lovrican regarding any aspect of your relationship
                      with Lovrican, whether based in contract, statute,
                      regulation, ordinance, tort (including, but not limited
                      to, fraud, misrepresentation, fraudulent inducement,
                      negligence, gross negligence or reckless behavior), or any
                      other legal or equitable theory, and includes the
                      validity, enforceability or scope of this Arbitration
                      Agreement (with the exception of the enforceability of the
                      Class Action Waiver clause below). “Dispute” is to be
                      given the broadest possible meaning that will be
                      enforced.&nbsp;
                      <br />
                      <br />
                      WE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL
                      DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE
                      OR BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE
                      FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY
                      BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE
                      WITH THIS ARBITRATION AGREEMENT.&nbsp;
                    </li>
                    <li>
                      <span>Pre-Arbitration Dispute Resolution:</span>
                      &nbsp;For all Disputes, you must first give Lovrican an
                      opportunity to resolve the Dispute. You must commence this
                      process by mailing written notification to P.O. Box 437,
                      Raritan, NJ USA 08869. That written notification must
                      include (1) your name, (2) your address, (3) a written
                      description of the Dispute, and (4) a description of the
                      specific relief you seek. If Lovrican does not resolve the
                      Dispute to your satisfaction within 45 days after it
                      receives your written notification, you may pursue your
                      Dispute in arbitration.
                    </li>
                    <li>
                      <p>
                        <span>Arbitration Procedures:</span>
                        &nbsp;If this Arbitration Agreement applies and the
                        Dispute is not resolved as provided above
                        (“Pre-Arbitration Claim Resolution”) either you or
                        Lovrican may initiate arbitration proceedings. The
                        American Arbitration Association
                        (“AAA”),&nbsp;www.adr.org, or
                        JAMS,&nbsp;www.jamsadr.com, will arbitrate all Disputes,
                        and the arbitration will be conducted before a single
                        arbitrator. The arbitration shall be commenced as an
                        individual arbitration, and shall in no event be
                        commenced as a representative or class arbitration. All
                        issues shall be for the arbitrator to decide, including
                        the scope of this Arbitration Agreement.
                      </p>
                      <p>
                        This Arbitration Agreement governs in the event it
                        conflicts with the applicable arbitration rules. Under
                        no circumstances will class action procedures or rules
                        apply to the arbitration.&nbsp;
                      </p>
                      <p>
                        Because your contract with Lovrican, the Terms of
                        Service, and this this Arbitration Agreement concern
                        interstate commerce, the Federal Arbitration Act (“FAA”)
                        governs the arbitrability of all Disputes. However, the
                        arbitrator will apply applicable substantive law
                        consistent with the FAA and the applicable statute of
                        limitations or condition precedent to suit.&nbsp;
                      </p>
                    </li>
                    <li>
                      <span>Arbitration Award:</span> The arbitrator may award
                      on an individual basis any relief that would be available
                      pursuant to applicable law, and will not have the power to
                      award relief to, against or for the benefit of any person
                      who is not a party to the proceeding. The arbitrator shall
                      make any award in writing but need not provide a statement
                      of reasons unless requested by a party. Such award by the
                      arbitrator will be final and binding on the parties,
                      except for any right of appeal provided by the FAA, and
                      may be entered in any court having jurisdiction over the
                      parties for purposes of enforcement.&nbsp;
                    </li>
                    <li>
                      <span>Location of Arbitration:</span>
                      &nbsp;You may initiate arbitration in Raritan or in the
                      federal judicial district in the state of New Jersey.
                    </li>
                    <li>
                      <span>Payment of Arbitration Fees and Costs:</span>
                      &nbsp;Lovrican will pay all arbitration filing fees and
                      arbitrator’s costs and expenses upon your written request
                      given prior to the commencement of the arbitration. You
                      are responsible for all additional fees and costs that you
                      incur in the arbitration, including, but not limited to,
                      attorneys or expert witnesses. Fees and costs may be
                      awarded as provided pursuant to applicable law. In
                      addition to any rights to recover fees and costs under
                      applicable law, if you provide notice and negotiate in
                      good faith with Lovrican as provided in the section above
                      titled “Pre-Arbitration Dispute Resolution” and the
                      arbitrator concludes that you are the prevailing party in
                      the arbitration, you will be entitled to recover from
                      Lovrican your actual and reasonable attorney’s fees and
                      costs as determined by the arbitrator.&nbsp;
                    </li>
                    <li>
                      <span>Class Action Waiver:</span>
                      &nbsp;The parties agree that the arbitrator may not
                      consolidate more than one person’s claims, and may not
                      otherwise preside over any form of a class or
                      representative proceeding or claims (such as a class
                      action, representative action, consolidated action or
                      private attorney general action) unless both you and
                      Lovrican specifically agree in writing to do so following
                      initiation of the arbitration. Neither you, nor any other
                      Member of Lovrican and/or user of Lovrican services, can
                      be a class representative, class member, or otherwise
                      participate in a class, representative, consolidated or
                      private attorney general proceeding.&nbsp;
                    </li>
                    <li>
                      <span>Limitation of Procedural Rights:</span>
                      &nbsp;You understand and agree that, by entering into this
                      Arbitration Agreement, you and Lovrican are each agreeing
                      to arbitration instead of the right to a trial before a
                      judge or jury in a public court. In the absence of this
                      Arbitration Agreement, you and Lovrican might otherwise
                      have had a right or opportunity to bring Disputes in a
                      court, before a judge or jury, and/or to participate or be
                      represented in a case filed in court by others (including
                      class actions). You give up those rights. Other rights
                      that you would have if you went to court, such as the
                      right to appeal and to certain types of discovery, may be
                      more limited in arbitration. The right to appellate review
                      of an arbitrator’s decision is much more limited than in
                      court, and in general an arbitrator’s decision may not be
                      appealed for errors of fact or law.&nbsp;
                    </li>
                    <li>
                      <span>Severability:</span>
                      &nbsp;If any clause within this Arbitration Agreement
                      (other than the Class Action Waiver clause above) is found
                      to be illegal or unenforceable, that clause will be
                      severed from this Arbitration Agreement, and the remainder
                      of this Arbitration Agreement will be given full force and
                      effect. If the Class Action Waiver clause is found to be
                      illegal or unenforceable, then this entire Arbitration
                      Agreement will be unenforceable, and the Dispute will be
                      decided by a court.&nbsp;
                    </li>
                    <li>
                      <span>Continuation:</span>
                      &nbsp;This Arbitration Agreement shall survive the
                      termination of your contract with Lovrican and your use of
                      Lovrican services.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">16. General Provisions</h3>
                <div className="subhead pl-4">
                  <ol type="lower-greek" className="list-greek">
                    <li>
                      <span>Right to Seek Injunction.</span>
                      &nbsp;Violation of this Agreement may cause Lovrican
                      irreparable harm, and therefore agree that Lovrican will
                      be entitled to seek extraordinary relief in court,
                      including but not limited to temporary restraining orders,
                      preliminary injunctions and permanent injunctions without
                      the necessity of posting a bond or other security, in
                      addition to and without prejudice to any other rights or
                      remedies that Lovrican may have for a breach of this
                      Agreement.
                    </li>
                    <li>
                      <span>Miscellaneous.</span>
                      &nbsp;This Agreement, which you accept upon registration
                      for the Services, the&nbsp;Privacy Statement&nbsp;located
                      on the Site, and any applicable payment, renewal,
                      additional Services terms, comprise the entire agreement
                      between you and Lovrican regarding the use of this
                      Service, superseding any prior agreements between you and
                      Lovrican related to your use of this Site or Services
                      (including, but not limited to, any prior versions of this
                      Agreement). The FAQ’s found on the Services are for
                      informational purposes only and are not deemed to be part
                      of this Agreement. Unless otherwise explicitly stated, the
                      Agreement will survive termination of your registration to
                      the Service. The failure of Lovrican to exercise or
                      enforce any right or provision of this Agreement does not
                      constitute a waiver of such right or provision. If any
                      provision of this Agreement is held invalid, the remainder
                      of this Agreement will continue in full force and effect.
                      The section titles in this Agreement are for convenience
                      only and have no legal or contractual effect.
                    </li>
                  </ol>
                </div>
                <h3 className="subhead pt-4">
                  17. Digital Millennium Copyright Act Notice
                </h3>
                <div className="subhead pl-4">
                  <p>
                    If you believe that your copyrighted work has been copied in
                    a way that constitutes copyright infringement and is
                    accessible on this Site, please notify Lovrican’s copyright
                    agent, as set forth in the Digital Millennium Copyright Act
                    of 1998 (DMCA). For your complaint to be valid under the
                    DMCA, you must provide the following information in writing:
                  </p>
                  <ol type="number">
                    <li>
                      An electronic or physical signature of a person authorized
                      to act on behalf of the copyright owner;
                    </li>
                    <li>
                      Identification of the copyrighted work that you claim is
                      being infringed;
                    </li>
                    <li>
                      Identification of the material that is claimed to be
                      infringing and where it is located on the Site;
                    </li>
                    <li>
                      Information reasonably sufficient to permit Lovrican to
                      contact you, such as your address, telephone number, and
                      e-mail address;
                    </li>
                    <li>
                      A statement that you have a good faith belief that use of
                      the material in the manner complained of is not authorized
                      by the copyright owner, its agent, or law; and
                    </li>
                    <li>
                      A statement, made under penalty of perjury, that the above
                      information is accurate, and that you are the copyright
                      owner or are authorized to act on behalf of the owner.
                    </li>
                  </ol>
                  <div className="copyright-ul">
                    <div className="center-small">
                      <p className="m-0">
                        The above information must be submitted to the following
                        DMCA Agent:
                      </p>
                      <p>Copyright Agent</p>
                      <p>Lovrican, LLC.</p>
                      <p>P.O. Box 437, Raritan, NJ USA 08869</p>
                      <p>
                        <a href="mailto:copyright@lovrican.com">
                          copyright@Lovrican.com
                        </a>
                        &nbsp;(only DMCA notices will be accepted at this email
                        address. All other inquiries or requests will be
                        discarded)
                      </p>
                    </div>
                  </div>
                </div>
                <h3 className="subhead pt-4">18. Revision Date</h3>
                <div className="subhead pb-4 mb-4">
                  <p className="m-0">
                    This Agreement was last revised on March 1, 2022.
                  </p>
                  <p className="pb-4">
                    Copyright © {new Date().getFullYear()} Lovrican, LLC. All
                    Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceTermsPage;
