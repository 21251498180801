import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import { pageTitle } from '../redux/actions/home.actions';
import Spinner from '../components/general/spinner';
import rose from '../assets/images/rose.svg';
import webServices from '../services/webServices';

const RoseHistoryPage = () => {
  const dispatch = useDispatch();
  const [isSpinner, setIsSpinner] = useState(true);
  const [list, setList] = useState([]);

  const roseHistoryList = async () => {
    const resp = await webServices.getRoseHistory();
    if (resp && resp.success === true) {
      setList(resp.data);
      setTimeout(() => {
        setIsSpinner(false);
      }, 400);
    }
  };

  useEffect(async () => {
    roseHistoryList();
    dispatch(pageTitle('Rose History'));
  }, []);

  return (
    <>
      <div className="inner-wrapper-newdesign">
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="largeScreen">
                <div className="d-flex justify-content-center">
                  <div className="listing">
                    {!!list.length &&
                      list.map((val) => (
                        <div key={val?.id} className="roseHistoryWrapper">
                          <div className="roseHistory">
                            <div className="roseLeft">
                             
                              <div className="imageBox">
                                <img
                                  src={`${process.env.REACT_APP_PIC_BASE_URL}${val?.toUser?.profile_image}`}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="roseRight">
                            <h2>{val?.toUser?.first_name}</h2>
                              <p className=" pb-0 pb-md-2 mb-1">
                                {moment(val?.createdAt).format('DD . MM . YYYY')}
                              </p>
                              <div className="roseBox">
                                <div className="rose-circle">
                                  <img src={rose} alt="" />
                                </div>
                                <div className="counter">
                                  {val?.roses} {val?.roses > 1 ? 'Roses' : 'Rose'}
                                </div>
                              </div>
                              <p className="pl-0  mb-0 pt-3">
                                You sent {val?.roses} {val?.roses > 1 ? 'roses' : 'rose'} to{' '}
                                {val?.toUser?.first_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      {list.length === 0 && <h3>No record found!</h3>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoseHistoryPage;
