import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import privatelock from '../../assets/images/private_lock.svg';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step6Component = ({ userDetails, handleNext, step }) => {
  const SALARY_MAX_VALUE = 500;
  const SALARY_RANGE_STEP_VALUE = 10;

  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator({
    validators: {
        salary_check: {
            message: "The Annual Income field is required.",
            rule: val => val > 0,
            required: true
        }
    }
  }));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    anualIncome: userDetails?.anualIncome?.label2 ? userDetails?.anualIncome?.label2 : 0,
    companyName: userDetails?.userDetails?.companyName || '',
    incomeHelp: false
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        annualIncome: JSON.stringify(values.anualIncome),
        companyName: values.companyName
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step6" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className="subtitle">
                Let us know where you work and your income tier. It aids us in
                finding you ideal matches tailored to your lifestyle
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join1 six_step">
            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3">
              <input
                type="text"
                name="company_name"
                id="company_name"
                placeholder="Company Name"
                className="custom_input"
                autoComplete="off"
                value={values.companyName}
                onChange={(e) =>
                  setValues({ ...values, companyName: e.target.value })
                }
              />
              {validator.message(
                'company_name',
                values.companyName,
                'required'
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-2 custome_select mb-3 private_wrapper">
                <label htmlFor="salary_input" className='salary_input'>Annual Income</label>
              <InputRange
                  id="salary_input mt-4"
                  className="mt-4"
                  allowSameValues
                  maxValue={SALARY_MAX_VALUE}
                  minValue={0}
                  step={SALARY_RANGE_STEP_VALUE}
                  value={values.anualIncome}
                  formatLabel={value => `$${value}K`}
                  onChange={val => setValues(prevValues => ({...prevValues, anualIncome: val}))}
                  onChangeComplete={val => setValues(prevValues => ({...prevValues, anualIncome: val}))}
              />
              <br />

              {validator.message('anualIncome', values.anualIncome, 'salary_check')}
              <div className="text-right">
                <div
                  className="private_btn"
                  aria-hidden="true"
                  onClick={() => {
                    setValues({
                      ...values,
                      incomeHelp: true
                    });
                  }}
                >
                  Private
                  <img
                    src={privatelock}
                    className="lock_private"
                    alt="Lock"
                    aria-hidden="true"
                  />
                </div>
              </div>
              {values.incomeHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() =>
                        setValues({ ...values, incomeHelp: false })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Step6Component;
