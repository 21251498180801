import React from 'react';
import { ToastContainer } from 'react-toastify';
import logo from '../../assets/images/Logo.svg';
import OtpInputCustom from '../general/otpInput';

class RegisterOtpComponent extends React.Component {
  goToNextStep = (data) => {
    const { goToNextStep } = this.props;
    goToNextStep(data);
  };

  render() {
    const { history, verifyOtpUrl, isReset } = this.props;
    const verifyotp = verifyOtpUrl || 'auth/action/verify-otp-to-email';
    return (
      <>
        <section
          className={`otpWraper text-light position-relative ${
            isReset ? 'forget_Password_OTP' : ''
          }`}
        >
          <div className="container-fluid">
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              limit={3}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              
            />
            <div className="row">
              <div className="col-12 text-center">
                <div
                  className="logo cursor-pointer"
                  onClick={() => {
                    history.push('/');
                  }}
                  onKeyDown={(event) =>
                    event.key === 'Enter' && history.push('/')
                  }
                  role="button"
                  tabIndex="0"
                >
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </div>
              </div>
              <OtpInputCustom
                {...this.props}
                resendOtpUrl="auth/action/resend-otp-to-email"
                verifyOtpUrl={verifyotp}
                goToNextStep={this.goToNextStep}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default RegisterOtpComponent;
