const SalaryRange = [
  {
    value: 1,
    label: '$ 0k',
    label2: 0
  },
  {
    value: 2,
    label: '$ 50k',
    label2: 50
  },
  {
    value: 3,
    label: '$ 100k',
    label2: 100
  },
  {
    value: 4,
    label: '$ 150k',
    label2: 150
  },
  {
    value: 5,
    label: '$ 200k',
    label2: 200
  },
  {
    value: 6,
    label: '$ 250k',
    label2: 250
  }
];

export default SalaryRange;
