export const parseDate = (str) => {
  const mdy = `${str}`.split('/');
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
};

export const datediff = (first, second) =>
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  Math.round((second - first) / (1000 * 60 * 60 * 24));

export const isEmpty = (myVar) => {
  if (typeof myVar === 'string') {
    myVar.trim();
  }
  return !myVar || myVar === 'null' || myVar === 'undefined';
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidPhone = (phone) => {
  const phoneLength = phone.toString().length;
  const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  return phoneLength > 9 && phoneLength < 16 && re.test(phone);
};

export const isValidOtp = (otp) => otp.toString().length === 4;
