import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../redux/actions/home.actions';
import webServices from '../../../../services/webServices';
import PasswordAndEmailSetting from './PasswordAndEmailSetting';
import AppConstants from '../../../../appConstants';

const AccordionCustomToggle = ({ title, eventKey, parentCallBack, status }) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });
  return (
    <div
      className="card-link"
      onClick={decoratedOnClick}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      <span>
        {status === 'open' ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    </div>
  );
};

const SettingsPageAffiliate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [userSettings, setUserSettings] = useState(null);
  const [accStatus, setAccStatus] = useState({
    advanced: 'close',
    account: 'close',
    billing: 'open'
  });
  // const [masterData, setMasterData] = useState({});

  useEffect(async () => {
    dispatch(pageTitle('Setting'));
    const response = await webServices.getUserSettings();
    if (response && response.success) {
      setUserSettings(response.data);
    }
  }, []);

  const parentCallBackWhenUpdate = async (val) => {
    if (val === 'updated') {
      const response = await webServices.getUserSettings();
      if (response && response.success) {
        setUserSettings(response.data);
      }
    }
  };

  useEffect(() => {
    webServices.getMasterData().then((response) => {
      if (response && response.success) {
        // setMasterData(response.data);
      }
    });
  }, []);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.advanced = 'close';
    init.account = 'close';
    init.billing = 'close';

    if (key === '0') {
      init.advanced = data;
    }
    if (key === '1') {
      init.account = data;
    }
    if (key === '2') {
      init.billing = data;
    }
    setAccStatus(init);
  };

  const handleClickToSupport = () => {
    history.push(AppConstants.routes.affiliate.contactsUs);
  };

  return (
    <div className="inner-wrapper-newdesign profile-wrapper-outer">
      <div className="largeScreen">
        <div className="row">
          <div className="col-md-12 profile-box profile-wrapper">
            <Accordion
              defaultActiveKey="2"
              className="profile-accordion new_profile_accordion mt-4 accordion"
            >
              <Card>
                <Card.Header className="pb-2">
                  <AccordionCustomToggle
                    eventKey="2"
                    title="Password and Email"
                    status={accStatus.billing}
                    parentCallBack={callBack}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <PasswordAndEmailSetting
                      userSettings={userSettings}
                      parentCallBackWhenUpdate={parentCallBackWhenUpdate}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <div className='row'>
            <div className="col-md-12">
              <div className='setting_customer_support'>
                <div className='heading'>Get Customer Support</div>
              <span
                className="submit-btn"
                aria-hidden="true"
                onClick={handleClickToSupport}
                role="button"
                tabIndex="0"
              >
                Raise Ticket
              </span>
              </div>
             
            </div>
            </div>
          </div>
        
        </div>
        
      </div>
    </div>
  );
};

export default SettingsPageAffiliate;
