import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import avtar from '../../assets/images/avtar-photo.jpg';
import avtar from '../../assets/images/photo-placeholder.svg';

const DropInput = (props) => {
  const { handleFileChange, imagePreview, changeImage } = props;
  const [files, setFiles] = useState([]);
  // const [showFileInput, setShowFileInput] = useState(true);
  const onDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
    handleFileChange(acceptedFiles);
    // setShowFileInput(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    // maxSize: 2000000
  });

  // const thumbs = files.map((file) => (
  //   <div key={file.name} className="profile-pic-preview">
  //     <img src={file.preview} alt={file.name} />
  //   </div>
  // ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // const acceptedFileItems = acceptedFiles.map(file => (
  //   props.onChange(file, null)
  //   // <li key={file.path}>
  //   //   {file.path} - {file.size} bytes
  //   // </li>
  // ));

  // const fileRejectionItems = fileRejections.map(({ file, errors }) => (
  //   props.onChange(file, errors)
  //   // <li key={file.path}>
  //   //   {file.path} - {file.size} bytes
  //   //   <ul>
  //   //     {errors.map(e => (
  //   //       <li key={e.code}>{e.message}</li>
  //   //     ))}
  //   //   </ul>
  //   // </li>
  // ));

  return (
    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-center">
      {!imagePreview ? (
        <>
          <div {...getRootProps({ className: 'imgUploader' })}>
            <input {...getInputProps()} />
            <img src={avtar} alt="" />
            <p className="mb-0">
            Upload your photo
            </p>
            {/* <em>(Only *.jpeg and *.png images will be accepted)</em> */}
          </div>
        </>
      ) : (
        <>
          <div
            className="change-pic-btn round-button"
            onClick={() => changeImage(null)}
            onKeyDown={(event) =>
              event.key === 'Enter' && changeImage(null)
            }
            role="button"
            tabIndex="0"
          >
            <span>Change Photo</span>
          </div>
        </>
      )}
       <aside>
        {imagePreview && (
          <div className="preview">
            <img src={imagePreview} alt={imagePreview} />
          </div>
        )}
      </aside>

      <span className='formate'>Note: You can choose who sees your photo using our VIP Privacy feature in your profile</span>
      {/* <aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside> */}
      {/* <aside>{thumbs}</aside> */}

    </div>
  );
};

export default DropInput;
