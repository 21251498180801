import React from 'react';
import arrowIcon from '../../assets/images/arrow-circle.svg';
import start from '../../assets/images/Star.svg';

const NoticeComponent = props => {
  const { onButtonClickHandler } = props;

  return (
    <div className='notification_bg'>
      <div className='container-fluid'>
        <div className='innerContent'>
          <h1>Important</h1>
          <p>
            Welcome to Lovrican! <img className='ml-3' src={start} alt="start" /> <br /> <br />
            To help us verify and approve your account quickly, please provide complete and accurate information.
          </p>

          <ul>
            <li>Make a positive first impression with potential partners.</li>
            <li>Meet people who suit your preferences and your vision for the future.</li>
            <li>Your privacy is our priority; rest assured, your information is handled with care.</li>
          </ul>
          
          <p className=''><span>Here’s what we need from you:</span></p>
          <ul>
            <li><span>Honesty:</span> Your details should reflect the truth.</li>
            <li><span>Completeness:</span> Fill in all required fields.</li>
            <li><span>Accuracy:</span> Ensure all information is correct.</li>
          </ul>

          <p>If the information does not meet these guidelines, it may lead to delays or even rejection of your application.</p>

          <p>
            We’re excited about the possibility of having you join our distinguished community and 
            <span> we look forward to welcoming you soon!</span>
          </p>
          <div className="col-12 text-left pr-lg-4 pt-2">
            <span onClick={onButtonClickHandler} className="search-btn" role="button" aria-hidden="true">
              <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
            </span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default NoticeComponent;