import React from 'react';
import dragDropIcon from '../../adminAssets/images/DragDrop.svg';

const UserProfileListCardComponent = props => {
    const { user, itemIndex, handleDragStartHandler, handleDragOverHandler, handleDragEndHandler } = props;

    const linkedInBtnClickHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        window.open(user?.linkedin_URL, '_blank', 'location=yes,height=570,width=600,top=200,left=400,scrollbars=yes,status=yes');
    };

    return <>
        <li
            draggable
            onDragStart={() => handleDragStartHandler(itemIndex)}
            onDragOver={() => handleDragOverHandler(itemIndex)}
            onDragEnd={handleDragEndHandler}
        >
            <div className="list_wrapper">
                <div className="serialno">
                    <div className="box">{itemIndex + 1}</div>
                </div>

                <div className="draggable_icon d-inline-block">
                    <img
                        src={dragDropIcon}
                        alt=""
                    />
                </div>

                <div className="profile_photo d-inline-block">
                    <img src={user.profile_image === null ? `${process.env.REACT_APP_PIC_BASE_URL}no_Image.svg` : `${process.env.REACT_APP_PIC_BASE_URL}${user.profile_image}`} alt="" />
                </div>

                <div className="title_name d-inline-block">
                    <h3>{`${user.first_name} ${user.last_name}`}</h3>
                    <div className="city">{`${user?.cityLabel ? `${user?.cityLabel},` : ''} ${user?.stateLabel ? `${user?.stateLabel},` : ''} ${user?.countryLabel}`}</div>
                </div>

                <div className="destination d-inline-block">
                    {
                        user.occupationLabel ? (
                            <>
                                {user.occupationLabel}
                                {user?.userDetails?.jobTitle ? ` (${user.userDetails.jobTitle})` : ''} <br />
                                {user?.userDetails?.companyName ? ` at ${user.userDetails.companyName}` : ''}
                            </>
                        ) : 'Occupation to be added'
                    }
                </div>

                <div className="degree d-inline-block">
                    {
                        user?.userDetails?.educationLabel ? (
                            <>
                                {user.userDetails.educationLabel}
                                {user?.userDetails?.whatStudy && ` (${user.userDetails.whatStudy})`}
                                {user?.userDetails?.schoolName && ` from ${user.userDetails.schoolName}`}
                            </>
                        ) : ''
                    }
                </div>

                <div className="age d-inline-block">
                    <div className="head">Age</div>
                    <div className="ans">{user.age}</div>
                    <div className="head">My Roots:</div>
                    <div className="ans">{user.userDetails.origin3}, {user.userDetails.origin2}, {user.userDetails.origin1}</div>
                </div>

                <div className="income d-inline-block">
                    <div className="head">Income</div>
                    <div className="ans">{user?.anual_income !== null && `$${user?.anual_income}K`}</div>
                    {
                        user?.linkedin_URL !== null && (
                            <button type='button' className="btn mt-2" onClick={linkedInBtnClickHandler}>LinkedIn <i className="fa fa-arrow-right ml-1" aria-hidden="true" /></button>
                        )
                    }
                </div>

                <div className="about d-inline-block">
                    <div className="head">About me:</div>
                    <div className="ans">{user.about}</div>
                </div>
            </div>
        </li>
    </>
};

export default UserProfileListCardComponent;
