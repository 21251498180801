import React from 'react';
import { NavLink } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import RegisterOtpComponent from '../components/home/RegisterOtpComponent';
import logo from '../assets/images/Logo.svg';
import eye from '../assets/images/eye_white.svg';
import AppConstants from '../appConstants';
import { authCall } from '../services';
import arrowIcon from '../assets/images/arrow-circle.svg';
import HomeHeaderMobile from '../layouts/partial/homeHeaderMobile';

const PASSWORDSTRENGHT = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      u_email: '',
      password: '',
      confirm_password: '',
      step: 1,
      otp: null,
      otpAttamp: 1,
      request: true,
      showModel: false,
      forgot_password: false,
      showPassword: false,
      showPasswordConfirm: false,
      strenght: 0
    };

    this.validator = new SimpleReactValidator({
      validators: {
        confirm: {
          message: 'The :attribute must be same as Password.',
          // eslint-disable-next-line react/destructuring-assignment
          rule: (val) => val === this.state.password,
          required: true // optional
        },
        password: {
          message:
            'The :attribute must have min 8 characters, with at least a symbol, number, upper and lower case.',
          rule: (val, params, validator) =>
            validator.helpers.testRegex(
              val,
              /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/i
            ) && params.indexOf(val) === -1,
          required: true // optional
        }
      }
    });
  }

  goToLogin = () => {
    this.setState({
      step: 1,
      forgot_password: false
    });
  };

  resetUserPassword = async () => {
    if (
      this.validator.fieldValid('password') &&
      this.validator.fieldValid('confirm_password')
    ) {
      const { request } = this.state;
      if (request) {
        this.setState({ request: false });

        const { u_email: email, password, otp } = this.state;
        const resp = await authCall('auth/action/reset-password-by-email', {
          email,
          password,
          otp
        });
        if (resp && resp.success === true) {
          toast.success('Password has been successfuly changed.');
          const { history } = this.props;
          history.push(AppConstants.routes.login);
        }

        this.setState({ request: true });
      }
    } else {
      this.validator.showMessageFor('confirm_password');
      this.validator.showMessageFor('password');
      this.forceUpdate();
    }
  };

  sendCode = async () => {
    const { request, u_email: email } = this.state;
    if (this.validator.fieldValid('email')) {
      if (request) {
        this.setState({ request: false });
        const resp = await authCall('auth/action/send-email-otp-to-user', {
          email
        });
        if (resp && resp.success === true) {
          this.setState({
            step: 2
          });
        }

        this.setState({ request: true });
      }
    } else {
      this.validator.showMessageFor('email');
      this.forceUpdate();
    }
  };

  goToNextStep = (data) => {
    // console.log('Next step data =>', data);
    this.setState({
      step: data.step,
      u_email: data.email,
      otp: data.otp
    });
  };

  closePopup = () => {
    this.goToLogin();
    const { closePopup } = this.props;
    closePopup();
  };

  handleClickShowPassword = (status) => {
    this.setState({ showPassword: status });
  };

  handleClickShowPasswordConfirm = (status) => {
    this.setState({ showPasswordConfirm: status });
  };

  handleChangePassword = (password) => {
    const { score } = zxcvbn(password);
    this.setState({ password, strenght: score });
  };

  render() {
    const {
      // forgot_password: forgotPassword,
      confirm_password: confirmPassword,
      step,
      u_email: uEmail,
      request,
      password,
      showPassword,
      showPasswordConfirm,
      strenght
    } = this.state;
    return (
      <>
        {step === 3 ? (
          <>
            <section className="loginWrapper text-light position-relative forget_password_final">
              <div className="container-fluid">
                <div className="row common-top-logo">
                  <div className="col-12 text-center">
                    <div
                      className="logo cursor-pointer"
                      role="button"
                      tabIndex="0"
                    >
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="Lovrican Logo"
                      />
                    </div>
                  </div>

                  <div className="reg-inner">
                    <div className="col-12">
                      <h1>
                        Reset <br />
                        Password
                      </h1>
                    </div>

                    <div className="col-12 d-flex reg-login justify-content-between pr-0 mb-1">
                      <div className="d-flex align-items-end password">
                        <div
                          className="icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.handleClickShowPassword(!showPassword)
                          }
                          aria-hidden="true"
                        >
                          <img src={eye} className="img-fluid mr-2" alt="" />
                        </div>
                        <div className="field">
                          <input
                            className="login"
                            type={showPassword ? 'text' : 'password'}
                            name="user-email"
                            id="user-email"
                            placeholder="Enter Password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) =>
                              this.handleChangePassword(e.target.value)
                            }
                          />
                        </div>
                        <div className="hint">
                          <ul className="list-unstyled">
                            <li>Atleast:</li>
                            <li>• 8 characters</li>
                            <li>• 1 special character</li>
                            <li>• 1 capital letter</li>
                            <li>• 1 number</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <span className="srv-validation-message">
                        {this.validator.message(
                          'password',
                          password,
                          'required|password'
                        )}
                      </span>
                    </div>
                    <div
                      className={`col-12 ${password ? 'visible' : 'invisible'}`}
                    >
                      <div className="d-flex w-100 mt-1 align-items-center pb-4">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{ width: `${(strenght + 1) * 20}%` }}
                          />
                        </div>
                        <div className="password_status">
                          Password is {PASSWORDSTRENGHT[strenght]}
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-12  pr-0"
                      style={{ margin: '0 0 2.5rem 0' }}
                    >
                      <div className="d-flex reg-login mb-1 justify-content-between flex-column ml-0">
                        <div className="d-flex align-items-end password">
                          <div
                            className="icon"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.handleClickShowPasswordConfirm(
                                !showPasswordConfirm
                              )
                            }
                            aria-hidden="true"
                          >
                            <img src={eye} className="img-fluid mr-2" alt="" />
                          </div>
                          <div className="field">
                            <input
                              className="login"
                              type={showPasswordConfirm ? 'text' : 'password'}
                              name="user-email"
                              id="user-email"
                              placeholder="Confirm Password"
                              autoComplete="off"
                              value={confirmPassword}
                              onChange={(e) =>
                                this.setState({
                                  confirm_password: e.target.value
                                })
                              }
                              onKeyDown={(event) =>
                                event.key === 'Enter' && this.resetUserPassword()
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <span className="srv-validation-message">
                        {this.validator.message(
                          'confirm_password',
                          confirmPassword,
                          'confirm'
                        )}
                      </span>
                    </div>
                    <div className="col-12 d-flex reg-login justify-content-end pr-0 border-0">
                      <span
                        className={`search-btn ${
                          !request ? 'hks-btn-disable' : ''
                        }`}
                        role="button"
                        tabIndex="0"
                        onClick={this.resetUserPassword}
                        aria-hidden="true"
                      >
                        <img
                          src={arrowIcon}
                          className="img-fluid"
                          alt="arrow icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {step === 2 ? (
              <>
                <RegisterOtpComponent
                  {...this.props}
                  {...this.state}
                  verifyOtpUrl="auth/action/verify-email-otp-for-password"
                  isReset
                  goToNextStep={this.goToNextStep}
                />
              </>
            ) : (
              <>
                <section className="loginWrapper text-light position-relative forgetpassword">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-center">
                        {/* Mobile Header */}
                        <HomeHeaderMobile />
                        {/* End Mobile Header */}
                        {/* <div
                          className="logo cursor-pointer d-none"
                          role="button"
                          tabIndex="0"
                        >
                          <NavLink to={AppConstants.routes.home}>
                            <img
                              src={logo}
                              className="img-fluid"
                              alt="Lovrican Logo"
                            />
                          </NavLink>
                        </div> */}
                      </div>

                      <div className="reg-inner">
                        <div className="col-12">
                          <h1>
                            Reset <br />
                            Password
                          </h1>
                        </div>
                        <div style={{ margin: '0 0 1.2rem 0' }}>
                          <div className="col-12 d-flex reg-login justify-content-between pr-0 mb-1">
                            <input
                              className="login"
                              type="email"
                              name="user-email"
                              id="user-email"
                              placeholder="Enter your email"
                              autoComplete="off"
                              value={uEmail}
                              onChange={(e) =>
                                this.setState({ u_email: e.target.value })
                              }
                              onKeyDown={(event) =>
                                event.key === 'Enter' && this.sendCode(event)
                              }
                            />
                          </div>
                          <div className="col-12">
                            <span>
                              {this.validator.message(
                                'email',
                                uEmail,
                                'required|email'
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 declaration-box reg-login border-0">
                          <p className="mb-0">
                            We’ll send a code to your registered email to reset
                            your password.
                          </p>
                        </div>
                        <div className="col-12 d-flex reg-login justify-content-end pr-0 border-0">
                          <span
                            className={`search-btn ${
                              !request ? 'hks-btn-disable' : ''
                            }`}
                            role="button"
                            tabIndex="0"
                            onClick={this.sendCode}
                            aria-hidden="true"
                          >
                            {/* <i className="fa fa-arrow-right" /> */}
                            <img
                              src={arrowIcon}
                              className="img-fluid"
                              alt="arrow icon"
                            />
                          </span>
                        </div>
                        <div className="col-12 text-right link-btn">
                          <p>
                            <span> I now remember my</span>
                            <NavLink to={AppConstants.routes.login}>
                              <span className="link-color" role="button">
                                Login
                              </span>
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default ForgotPasswordComponent;
