import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import HomeHeaderMobile from '../../../layouts/partial/homeHeaderMobile';
import ConfirmEmailPopup from '../ConfirmEmailPopup';
import AppConstants from '../../../appConstants';
import { authCall } from '../../../services';

const AffiliateAccountTypeComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referralCode');

  const dontAccount = location?.state?.dontAccount || '';

  const [validator] = useState(new SimpleReactValidator());

  const [noAccount, setNoAccount] = useState(dontAccount || referralCode || false);
  const [email, setEmail] = useState('');
  const [confirmEmailMsg, setConfirmEmailMsg] = useState(false);
  const [otp, setOtp] = useState('');
  const [noAccountChecked, setNoAccountChecked] = useState(
    dontAccount || referralCode || false
  );

  const handleClickHaveAccount = () => {
    setNoAccount(false);
    history.push(AppConstants.routes.affiliate.login);
  };

  const handleClickNoAccount = () => {
    setNoAccount(true);
    setNoAccountChecked(true);
  };

  const submitForm = () => {
    if (confirmEmailMsg) {
      setConfirmEmailMsg(false);
      history.push({
        pathname: AppConstants.routes.affiliate.register,
        search: referralCode
          ? `?email=${email}&referralCode=${referralCode}`
          : `?email=${email}`,
        state: { otp }
      });
    }
  };

  const handleClickJoin = async () => {
    if (validator.allValid()) {
      const resp = await authCall('auth/action/send-otp-to-email', {
        email
      });
      if (resp && resp.success === true) {
        setOtp(resp?.otp);
        setConfirmEmailMsg(true);
      }
    } else {
      validator.showMessages();
      setEmail({ ...email });
    }
  };

  return (
    <>
      <section className="affiliateReg">
        <div className="container-fluid">
          <HomeHeaderMobile />
          <div className="reg">
            <h1>
              Let’s start
              <br /> your program
            </h1>
            <p>
              You loged in & even your already the Lovrican
              <br /> member Do you want to proceed with your existing details?
            </p>

            <div className="customeRadio_Inner">
              <div className="d-block">
                <input
                  type="radio"
                  id="lovricain_account"
                  name="radio-group"
                  onClick={handleClickHaveAccount}
                />
                <label htmlFor="lovricain_account">
                  I have a Lovrican account
                </label>
              </div>
              <div className="d-block">
                <input
                  type="radio"
                  id="lovricain_account_not"
                  name="radio-group"
                  checked={noAccountChecked}
                  onClick={handleClickNoAccount}
                />
                <label htmlFor="lovricain_account_not">
                  I don’t have a Lovrican account
                </label>
              </div>
            </div>

            {noAccount && (
              <>
                <div className="affiliate-login ">
                  <input
                    className="inputField"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {validator.message('email', email, 'required|email')}
                <p className="help mb-0">
                  By continuing, you’re confirming that you’ve read and agree
                  <br /> to our Terms and Conditions and Privacy Policy
                </p>

                <div
                  className="affiliate_btn1 marginTop1 marginBottom1"
                  role="button"
                  onClick={handleClickJoin}
                  aria-hidden="true"
                >
                  Join
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {confirmEmailMsg && (
        <ConfirmEmailPopup
          modalStatus={confirmEmailMsg}
          handleConfirm={submitForm}
          affiliate
        />
      )}
    </>
  );
};

export default AffiliateAccountTypeComponent;
