import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import close from '../../adminAssets/images/dashbaord_X.svg';
import UserStatusButtonsComponent from './UserStatusButtonsComponent';
import UserFooterButtonComponent from './UserFooterButtonComponent';
import webServices from '../../services/webServices';

const UserProfileGridCardComponent = props => {
    const { user: initialUserData, removeUserFromUsersListHandler } = props;
    const [user, setUser] = useState(initialUserData);
    const [isViewMode, setIsviewMode] = useState(false);
    const [isPremiumProfile, setIsPremiumProfile] = useState(user?.premium_profile_priority !== null);

    const detailsClickHandler = (event, visibleMode = true) => {
        if (event.target.dataset?.isCheckbox === undefined) {
            event.preventDefault();
            event.stopPropagation();

            setIsviewMode(() => visibleMode);
        }
    };

    const updateUserStatusHandler = (userStatus) => {
        webServices.changeUserStatus(user.id, { status: userStatus })
            .then((response) => {
                if (response && response.success) {
                    if (['ACTIVE', 'INACTIVE', 'DELETE'].includes(userStatus)) {
                        setUser(prevUser => ({ ...prevUser, status: userStatus }));
                    } else {
                        setUser(prevUser => ({ ...prevUser, activityStatus: userStatus }));
                    }
                    toast.success('Updated successfully');
                }
                return response;
            });
    }

    const premiumProfileUpdateHandler = () => {
        const status = !isPremiumProfile ? 'ADDED' : 'REMOVED';

        setIsPremiumProfile(!isPremiumProfile);

        webServices.updateUserPremiumProfileStatus(user.id, { status })
            .then((response) => {
                if (response && response.success) {
                    setUser(prevUser => ({
                        ...prevUser,
                        premium_profile_priority: response.premium_profile_priority
                    }));

                    if (status === 'REMOVED') {
                        removeUserFromUsersListHandler(user);
                    }

                    toast.success('Updated successfully');
                }
                return response;
            });
    }

    const approveButtonHandler = () => updateUserStatusHandler('ACTIVE');
    const rejectButtonHandler = () => updateUserStatusHandler('INACTIVE');
    const visibleButtonHandler = () => updateUserStatusHandler('VISIBLE');
    const hideButtonHandler = () => updateUserStatusHandler('HIDDEN');
    const suspendButtonHandler = () => updateUserStatusHandler('SUSPENDED');
    const deleteButtonHandler = () => {
        if (window.confirm(`You're about to delete a user account. This can't be undone. Are you sure?`)) {  // eslint-disable-line no-alert
            updateUserStatusHandler('DELETED');
        }
    };

    return (
        <>
            <div
                role="button"
                className={`list_box ${isViewMode ? 'expend_box' : 'no_expand'}`}
                onClick={detailsClickHandler}
                onKeyPress={() => { }}
                tabIndex={0}
            >
                <div className="close_icon">
                    <button
                        type="button"
                        className="close"
                        aria-hidden="true"
                        onClick={event => detailsClickHandler(event, false)}
                    >
                        <span aria-hidden="true">
                            <img title="close" src={close} alt="close" />
                        </span>
                        <span className="sr-only">Close</span>
                    </button>
                </div>

                <div className="scroll_Box">
                    <div className="figure">
                        <img className='prfl_img' src={user.profile_image === null ? `${process.env.REACT_APP_PIC_BASE_URL}no_Image.svg` : `${process.env.REACT_APP_PIC_BASE_URL}${user.profile_image}`} alt="" />
                        <div className="figure_content">
                            <div className="top">
                                <div className="profile">
                                    {user?.status === 'ACTIVE' && (
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id={`premium_id_${user?.id}`}
                                                data-is-checkbox="1"
                                                checked={isPremiumProfile}
                                                onChange={premiumProfileUpdateHandler}
                                            />
                                            <label data-is-checkbox="1" htmlFor={`premium_id_${user?.id}`}>Premium Profile</label>
                                        </div>
                                    )}
                                </div>

                                <div className='right'>
                                    <div className='time'><i className="fa fa-clock-o" aria-hidden="true" /> {moment(user.registration_date).format('DD.MM.YYYY')}</div>
                                    <div className='user'><i className="fa fa-user-o" aria-hidden="true" /> {user.id}</div>
                                </div>
                            </div>

                            <UserStatusButtonsComponent user={user} approveButtonHandler={approveButtonHandler} rejectButtonHandler={rejectButtonHandler} />
                        </div>
                    </div>
                    <div className="list_details">
                        <div className='gender'>
                            <span className={`${user?.gender.toLowerCase() === 'man' ? 'male' : 'female'}`}>{user?.gender.toUpperCase()}</span>
                        </div>
                        <h1>{`${user.first_name} ${user.last_name}`} • {user.age}</h1>
                        <div className="profile">
                            {
                                user.occupationLabel ? (
                                    <>
                                        {user.occupationLabel}
                                        {user?.userDetails?.jobTitle ? ` (${user.userDetails.jobTitle})` : ''} <br />
                                        {user?.userDetails?.companyName ? ` at ${user.userDetails.companyName}` : ''}
                                    </>
                                ) : 'Occupation to be added'
                            }
                        </div>

                        {
                            user?.userDetails?.educationLabel ? (
                                <p>
                                    {user.userDetails.educationLabel}
                                    {user?.userDetails?.whatStudy && ` (${user.userDetails.whatStudy})`}
                                    {user?.userDetails?.schoolName && ` from ${user.userDetails.schoolName}`}
                                </p>
                            ) : ''
                        }

                        <p>{`${user?.cityLabel ? `${user?.cityLabel},` : ''} ${user?.stateLabel ? `${user?.stateLabel},` : ''} ${user?.countryLabel}`}</p>

                        {
                            user?.userDetails?.origin1 && user?.userDetails?.origin2 && user?.userDetails?.origin3 ? (
                                <>
                                    <div className="sub_head">My Roots:</div>
                                    <p>{user.userDetails.origin3}, {user.userDetails.origin2}, {user.userDetails.origin1}</p>
                                </>
                            ) : ''
                        }

                        <div className="sub_head">Salary:</div>
                        <p>{user?.anual_income !== null && `$${user?.anual_income}K`}</p>

                        <div className="sub_head">LinkedIn:</div>
                        <p>{user?.linkedin_URL !== null && user?.linkedin_URL}</p>

                        <div className="sub_head">Hobbies:</div>
                        <p>{user?.hobbies.join(', ')}</p>

                        <div className="sub_head">About me:</div>
                        <p>{user.about}</p>

                        <div className="sub_head">Looking For:</div>
                        <p>{user?.userDetails?.lookingFor}</p>

                        <div className="sub_head">Email:</div>
                        <p>{user?.email}</p>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="sub_head">Phone:</div>
                                <p>{user?.phone}</p>
                            </div>
                            <div className="col-md-6">
                                <div className="sub_head">Hear about us:</div>
                                <p>{user?.wdyhauLabel}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <UserFooterButtonComponent
                        user={user}
                        visibleButtonHandler={visibleButtonHandler}
                        hideButtonHandler={hideButtonHandler}
                        suspendButtonHandler={suspendButtonHandler}
                        deleteButtonHandler={deleteButtonHandler}
                    />
                </div>
            </div>
        </>
    );
};

export default UserProfileGridCardComponent;
