import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import csc from 'country-state-city';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
// import AppConstants from '../../appConstants';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step2Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    u_country: '',
    u_state:
      userDetails?.stateValue && userDetails?.stateLabel
        ? [
            {
              value: userDetails.stateValue,
              label: userDetails.stateLabel
            }
          ]
        : [],
    u_city:
      userDetails?.cityValue && userDetails?.cityLabel
        ? [
            {
              value: userDetails.cityValue,
              label: userDetails.cityLabel
            }
          ]
        : [],
    states: [],
    cities: [],
    selectedCountry: JSON.parse(localStorage.getItem('selectedCountry'))
  });

  useEffect(async () => {
    const { selectedCountry } = values;
    if (selectedCountry) {
      const data = await csc.getStatesOfCountry(selectedCountry[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      setValues({
        ...values,
        u_country: selectedCountry[0],
        // u_state: '',
        // u_city: '',
        states: options,
        cities: []
      });
    }
  }, []);

  const handelStateChange = async (value) => {
    if (value[0]) {
      const { u_country: uCountry } = values;
      const data = await csc.getCitiesOfState(uCountry.value, value[0].value);
      const options = data.map((d) => ({
        value: d.name,
        label: d.name
      }));
      setValues({
        ...values,
        u_state: value[0],
        u_city: [],
        cities: options
      });
    } else {
      setValues({
        ...values,
        u_state: [],
        u_city: [],
        cities: []
      });
    }
  };

  const handelCityChange = async (value) => {
    if (value[0]) {
      setValues({ ...values, u_city: value[0] });
    } else {
      setValues({
        ...values,
        u_city: []
      });
    }
  };

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        state: JSON.stringify(values.u_state),
        city: JSON.stringify(values.u_city)
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
       <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step2" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
            <div className='subtitle'>Share your location to discover amazing
            Nigerian singles nearby, or afar</div>
            </div>
            </div>
            <div className="row join-reg bottom-join inner_bottom">
              <div className="col-md-12 col-lg-12 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Residence State"
                  options={values.states}
                  autoComplete="off"
                  className="custom_input"
                  values={values.u_state}
                  onChange={(value) => handelStateChange(value)}
                />
                {validator.message('state', values.u_state, 'required')}
              </div>
              <div className="col-md-12 col-lg-12 pb-4 mb-1 mt-lg-2 custome_select">
                <Select
                  placeholder="Residence City"
                  autoComplete="off"
                  options={values.cities}
                  className="custom_input"
                  values={values.u_city}
                  onChange={(value) => handelCityChange(value)}
                />
                {validator.message('city', values.u_city, 'required')}
              </div>

              <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-right">
                <button
                  className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                  onClick={submitForm}
                  onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                  type="button"
                  tabIndex="0"
                  disabled={loading || false}
                >
                   <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
                </button>
              </div>
            </div>
          
        </div>
      </section>
    </>
  );
};

export default Step2Component;
