const CareerLevels = [
  {
    value: 1,
    label: 'Intern'
  },
  {
    value: 2,
    label: 'Analyst'
  },
  {
    value: 3,
    label: 'Lead'
  },
  {
    value: 4,
    label: 'Manager'
  },
  {
    value: 5,
    label: 'Director'
  },
  {
    value: 6,
    label: 'Vice President'
  },
  {
    value: 7,
    label: 'President / CEO'
  }
];

export default CareerLevels;
