/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/no-did-update-set-state */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
// import AppConstants from '../../appConstants';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import { authCall } from '../../services';
import { isEmpty, isValidOtp } from '../../helpers/validator';
import NoticeComponent from './NoticeComponent';

const Step1Component = (props) => {
  const [showFirstComponent, setShowFirstComponent] = useState(true); // State to toggle between components
  
  const handleToggle = () => {
    setShowFirstComponent(prevValue => !prevValue); // Toggle state on button click
  };

  return (
    <div>
        {showFirstComponent ? (
            <NoticeComponent onButtonClickHandler={handleToggle} />
        ) : (
            <OtpFormComponent {...props} />
        )}
    </div>
  );
}

const OtpFormComponent = ({ userDetails, handleNext, step }) => {
  const otpLength = 4;
  const [values, setValues] = useState({
    email: userDetails?.email,
    otp: '',
    otpHelp: false,
    resendCode: false,
    isError: false,
    otpVerified: userDetails?.is_otp_valid === '1' || false
  });

  const resendCode = async () => {
    const { email } = values;
    if (isEmpty(email)) {
      toast.error(
        "We're not getting any Email to send the code, please try again from starting."
      );
    } else {
      const resp = await authCall('auth/action/resend-otp-to-email', {
        email
      });

      if (resp && resp.success === true) {
        setValues({
          ...values,
          resendCode: true
        });
      }

      setTimeout(() => {
        setValues({
          ...values,
          resendCode: false
        });
      }, 5000);
    }
  };

  const otpValidateForm = async () => {
    const { otp } = values;
    let ret = false;
    if (isValidOtp(otp)) {
      setValues({ ...values, isError: false });
      ret = true;
    } else {
      setValues({ ...values, isError: true });
    }
    return ret;
  };

  const otpsubmitForm = async (otp) => {
    if (otpValidateForm()) {
      const resp = await authCall('auth/action/verify-otp-to-email', {
        email: userDetails?.email,
        otp
      });

      if (resp && resp.success === true) {
        setValues({ ...values, otpVerified: true, resendCode: false });
        // toast.success('OTP verified successfully!');
        handleNext(step);
      } else {
        setValues({ ...values, isError: true });
      }
    }
    setValues({ ...values, otp: '' });
  };

  const otpChange = (otpInner) => {
    setValues({ ...values, otp: otpInner });
    if (otpInner.length === otpLength) {
      otpsubmitForm(otpInner);
    }

    setTimeout(() => {
      Array.from(document.querySelectorAll('.inputStyleNew')).map((ele) => {
        if (ele.value) {
          ele.className = 'inputStyleNew focus-color'; // eslint-disable-line no-param-reassign
        }
        return ele.value;
      });
    }, 100);
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step1" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
            <div className="subtitle">Please enter the one time password we sent to your email</div>
              <div className="row w-100 settingOtp">
                {!values.otpVerified && (
                  <>
                    <div className="col-12 d-flex align-items-end justify-content-center">
                      <OtpInput
                        value={values.otp}
                        numInputs={otpLength}
                        onChange={(otpInner) => otpChange(otpInner)}
                        containerStyle="userOtpBbox"
                        inputStyle="inputStyleNew"
                        shouldAutoFocus
                        className="inputStyleNew focus-color otpInput"
                        isInputNum
                        hasErrored={values.isError}
                      />
                      <div className="position-relative">
                        <div
                          className="helpTrip"
                          aria-hidden="true"
                          onClick={() =>
                            setValues({ ...values, otpHelp: true })
                          }
                        >
                          <i className="fa fa-question" aria-hidden="true" />
                        </div>
                        {values.otpHelp && (
                          <div className="toolTipHelp d-flex">
                            <p>
                              Check your email for the One Time Passcode. Use
                              this code to verify you email
                            </p>
                            <div className="ml-3">
                              <i
                                className="fa fa-times cursor-pointer"
                                aria-hidden="true"
                                onClick={() =>
                                  setValues({ ...values, otpHelp: false })
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-12 notification_otp">
                        <span>Enter the OTP we just sent to your email.</span>
                        {this.validator.message('otp', otp, 'required')}
                        {otp && !otpVerified && (
                          <div className="srv-validation-message">
                            Invalid otp
                          </div>
                        )}
                      </div> */}
                    {/* <div className='col-12 notification_otp'><span>Enter the OTP we just sent to your email.</span></div> */}
                    {!values.resendCode && (
                      <div className="col-12 resend-code-text">
                        <p>
                          Didn’t get the code?{' '}
                          <span
                            onClick={resendCode}
                            onKeyDown={(event) =>
                              event.key === 'Enter' && resendCode()
                            }
                            role="button"
                            tabIndex="0"
                          >
                            Resend code
                          </span>
                        </p>
                      </div>
                    )}
                  </>
                )}
                {values.resendCode && (
                  <div className="col-12 resend-code-text">
                    <p className="msg">
                      <span> Code has been sent to your registered Email.</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step1Component;
