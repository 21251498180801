import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/Logo.svg';
// import authService from '../../services/authServices';
import AppConstants from '../../appConstants';

class Section2SuccessComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false
    };
  }

  render() {
    const { history } = this.props;
    // const { logout } = authService;
    const { modalStatus } = this.state;
    return (
      <>
        <section className="registerWrapper succuss_reg2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center d-none">
                <div className="logo cursor-pointer" role="button" tabIndex="0">
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </div>
              </div>
              <div className="reg-inner second_step">
                <div className="col-12">
                  <h1>
                    {/* Congratulations
                    <br /> on completing
                    <br /> your Basic Profile
                    <br /> Section 2! */}
                    Thank You for <br />Your Patience as <br />We Review Your <br />Profile!
                  </h1>
                </div>
                <div className="col-12">
                  <p className="read-more">
                    Our experts are still reviewing your profile to determine if{' '}
                    <br />
                    our service and the Lovrican community are a good fit for
                    <br />
                    you at this time based on the information you have provided.
                    <br />
                    The approval process usually takes 1-2 days. You’ll either
                    <br />
                    get an ‘approved to join’ the community email, or ‘not
                    <br />
                    approved to join the community at this time’ email.
                  </p>
                  <p className="mt-2">
                    <span
                      className="d-inline-block cursor-pointer"
                      onClick={() => this.setState({ modalStatus: true })}
                      aria-hidden="true"
                    >
                      <span className="dot_wrapper">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                      </span>
                      <span className="read-more-text">READ MORE</span>
                    </span>
                  </p>
                  <p className="d-none">
                    You should hear from our team shortly. We can’t wait for you
                    <br />
                    to join us!
                  </p>
                </div>
                <div className="col-12 join-links">
                  <span
                    className="common-button gotohome w-100"
                    onClick={() => history.push(AppConstants.routes.afterLogin)}
                    onKeyDown={(event) =>
                      event.key === 'Enter' &&
                      history.push(AppConstants.routes.afterLogin)
                    }
                    role="button"
                    tabIndex="0"
                  >
                    Go to Matches
                  </span>
                  {/* <span
                    className="common-button matchmaker"
                    onClick={() => history.push(AppConstants.routes.gift)}
                    onKeyDown={(event) =>
                      event.key === 'Enter' &&
                      history.push(AppConstants.routes.gift)
                    }
                    role="button"
                    tabIndex="0"
                  >
                    Be a Matchmaker
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          className="filter-model lovrician-work-model gift-readmore"
          show={modalStatus}
          onClick={() => this.setState({ modalStatus: false })}
          centered
          // backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100">
              <h5 className="modal-title w-100">
              Thank You for <br />Your Patience as <br />We Review Your <br />Profile!
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Our experts are still reviewing your profile to determine if our
              service and the Lovrican community are a good fit for you at this
              time based on the information you have provided. The approval
              process usually takes 1-2 days. You’ll either get an ‘approved to
              join’ the community email, or ‘not approved to join the community
              at this time’ email.
            </p>
            <p>
              You should hear from our team shortly. We can’t wait for you to
              join us!
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Section2SuccessComponent;
