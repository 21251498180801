import { useState, React, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
// Cropper
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';

const CropperPopup = ({
  modalStatus,
  handleClose,
  parentCallback,
  imageSRC
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isDisabled, setisDisabled] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels1) => {
    setisDisabled(false);
    setCroppedAreaPixels(croppedAreaPixels1);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      setisDisabled(true);
      const croppedImage1 = await getCroppedImg(
        imageSRC,
        croppedAreaPixels,
        rotation
      );
      parentCallback('click', croppedImage1);
    } catch (e) {
      parentCallback('error', e);
    }
  }, [imageSRC, croppedAreaPixels, rotation]);

  const closeModal = () => {
    setRotation(0);
    setZoom(1);
    handleClose(false);
  };

  const handleZoom = (type) => () => {
    let zoomVal;
    if (zoom > 3) zoomVal = 3;
    else if (zoom < 1) zoomVal = 1;
    else {
      zoomVal = type === 'increase' ? zoom + 0.1 : zoom - 0.1;
    }
    setZoom(zoomVal);
  };

  const handleRotation = (type) => () => {
    let rotationVal;
    if (rotation > 360) rotationVal = 360;
    else if (rotation < 0) rotationVal = 0;
    else {
      rotationVal = type === 'increase' ? rotation + 1 : rotation - 1;
    }
    setRotation(rotationVal);
  };
  return (
    <>
      <Modal
        size="lg"
        className="filter-model cropper_wrapper "
        show={modalStatus}
        onHide={closeModal}
        centered={false}
        // backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title">Crop Image</h5>
          </Modal.Title>
        </Modal.Header>
        <div className='main-body rotateBody'>
        <Modal.Body>
          <div className='zoomReflect'>
            <div className="row">
              {imageSRC && (
                <Cropper
                  image={imageSRC}
                  crop={crop}
                 
                  rotation={rotation}
                  zoom={zoom}
                  aspect={2 / 3}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropSize={{ width: 376, height: 410 }}
                  showGrid={false}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Body>
          {/* <div className="row" style={{ margin: '0 10px 0 10px' }}>
            ZOOM
            <InputRange
              value={zoom}
              minValue={1}
              maxValue={3}
              step={0.1}
              onChange={(val) => setZoom(val)}
              formatLabel={() => ``}
            />
          </div>
            ROTATION
            <InputRange
              value={rotation}
              minValue={0}
              maxValue={360}
              step={1}
              onChange={(val) => setRotation(val)}
              formatLabel={() => ``}
            />
          </div> */}
          <div
            className="row roteteSection"
            style={{
              margin: '0 10px 0 10px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <button
                type="button"
                className="btn theme-btn"
                style={{ width: '75px', padding: 0 }}
                onClick={handleZoom('decrease')}
              >
                <span>zoom</span> <i className="fa fa-minus ml-1" />
              </button>
              <span style={{ margin: '0 15px' }}><div style={{ minWidth: '40px', display: 'inline-block',  }}>{zoom.toFixed(1)}</div></span>
              <button
                type="button"
                className="btn theme-btn"
                style={{ width: '75px', padding: 0 }}
                onClick={handleZoom('increase')}
              >
                 <span>zoom</span> <i className="fa fa-plus ml-1" />
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn theme-btn"
                style={{ width: '90px', padding: 0 }}
                onClick={handleRotation('decrease')}
              >
                <span>rotation</span> <i className="fa fa-undo ml-1" />
              </button>
              <span style={{ margin: '0 15px',  }}><div style={{ minWidth: '40px', display: 'inline-block',  }}>{rotation}&#176;</div></span>
              <button
                type="button"
                className="btn theme-btn"
                style={{ width: '90px', padding: 0 }}
                onClick={handleRotation('increase')}
              >
                <span>rotation</span> <i className="fa fa-repeat ml-1" />
              </button>
            </div>
          </div>
        </Modal.Body>
        </div>
        <Modal.Footer className="modal-footer border-0">
          {/* <button type="button" className="btn btn-link" onClick={closeModal}>
            Cancel
          </button> */}
          <button
            type="button"
            className="btn theme-btn pt-2 pb-2"
            onClick={showCroppedImage}
            disabled={isDisabled}
          >
            Upload Photo
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CropperPopup;
