import React from 'react';
import { Slide } from 'react-slideshow-image';
import rose from '../../assets/images/rose.svg';
import 'react-slideshow-image/dist/styles.css';

class ImageSlider2 extends React.Component {
  constructor(props) {
    super(props);
    this.slideRef = React.createRef();
    const { images, isSentRose, rosesSentToMe } = this.props;
    this.state = {
      images,
      isSentRose,
      rosesSentToMe
    };
  }

  componentDidUpdate({ images: prevImages }) {
    const { images } = this.props;
    if (prevImages !== images) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        images
      });
      this.slideRef.current.goTo(0);
    }
  }

  render() {
    const properties = {
      duration: 5000,
      autoplay: true,
      transitionDuration: 500,
      arrows: false,
      infinite: false,
      dots: true,
      prevArrow: (
        <div style={{ width: '50px', height: '50px', marginRight: '-50px' }}>
          <i className="fa fa-angle-left" aria-hidden="true" />
        </div>
      ),
      nextArrow: (
        <div style={{ width: '50px', height: '50px', marginLeft: '-50px' }}>
          <i className="fa fa-angle-right" aria-hidden="true" />
        </div>
      ),
      easing: 'ease'
    };
    const {
      images = [],
      isSentRose,
      rosesSentToMe,
    } = this.state;
    const {isProfilePrivate, privateImage} = this.props
    return (
      <div className="App">
        <div className="slide-container">
          {rosesSentToMe > 0 && (
            <div className="rose-container">
              <div className="rose-inner row">
                {(() => {
                  const roseData = [];
                  for (
                    let i = 1;
                    i <= (rosesSentToMe > 12 ? 11 : rosesSentToMe);
                    i++
                  ) {
                    roseData.push(
                      <div className="roseBox col-6">
                        <div className="rose-circle">
                          <img src={rose} alt="Lovrican rose" />
                        </div>
                      </div>
                    );
                  }
                  return roseData;
                })()}
                {rosesSentToMe > 12 && (
                  <div className="roseBox col-6 ">
                    <div className="rose-circle position-relative">
                      <img src={rose} alt="Lovrican rose" />
                      <div className="moreOption">+{rosesSentToMe - 12}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <Slide
            indicators
            ref={this.slideRef}
            {...properties}
            infinite={images && images.length > 1}
            // arrows={images.length > 1}
          >
            {images &&
              images.length > 0 &&
              images.map((each) => (
                <div key={each} className="each-slide">
                  <div
                    className="slider-bck-img"
                    style={{
                      backgroundImage: `url("${
                        isProfilePrivate === 'NO'
                          ? process.env.REACT_APP_PIC_BASE_URL + each
                          : privateImage
                      }")`,
                      border: isSentRose ? '2px solid #B800E0' : null
                    }}
                  />
                </div>
              ))}
          </Slide>
        </div>
      </div>
    );
  }
}

export default ImageSlider2;
