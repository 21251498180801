import React, { useState, useEffect } from 'react';
import { authCall, fetchData } from '../../../../services';

const CampaignPerformance = () => {
  const [performance, setPerformance] = useState({});
  const [countryPerformance, setCountryPerformance] = useState([]);
  const [countryFilterList, setCountryFilterList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const limit = 5;

  const getCountryPerformance = async (condi) => {
    const resp3 = await authCall('affiliate/getCountryPerformance', condi);
    if (resp3 && resp3?.success) {
      setCountryPerformance(resp3?.data);
    }
  };

  useEffect(async () => {
    const resp = await fetchData('affiliate/getCampaignPerformance');
    if (resp && resp?.success) {
      setPerformance(resp?.data);
    }

    const resp2 = await authCall('affiliate/getCountryPerformance', {});
    if (resp2 && resp2?.success) {
      setCountryFilterList(resp2?.data);
    }

    getCountryPerformance({
      limit
    });
  }, []);

  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterCountry = (country) => {
    setSelectedCountry(country);
    getCountryPerformance({
      country,
      limit
    });
    setIsOpen(false);
  };

  return (
    <>
      <div className="cap_performance">
        {/* <div className='heading'>Referal Summary</div> */}
        <div className="row">
          <div className="col-12">
            <p>Visitors</p>
            <div className="progress">
              <div
                className="progress-bar skyblu_color"
                style={{ width: performance?.visitors > 0 ? '100%' : 0 }}
              />
            </div>
            <p className="caption_text">{performance?.visitors || 0}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Incomplete Registration</p>
            <div className="progress">
              <div
                className="progress-bar orange_color"
                style={{
                  width: `${
                    performance?.incompleteRegistration?.percentage || 0
                  }%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.incompleteRegistration?.incomplete || 0} •{' '}
              {performance?.incompleteRegistration?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Completed Registration</p>
            <div className="progress">
              <div
                className="progress-bar green_color"
                style={{
                  width: `${
                    performance?.complatedRegistration?.percentage || 0
                  }%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.complatedRegistration?.complete || 0} •{' '}
              {performance?.complatedRegistration?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>Membership Purchased</p>
            <div className="progress">
              <div
                className="progress-bar purple_color"
                style={{
                  width: `${performance?.membershipPurchase?.percentage || 0}%`
                }}
              />
            </div>
            <p className="caption_text">
              {performance?.membershipPurchase?.membership || 0} •{' '}
              {performance?.membershipPurchase?.percentage || 0}%
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="divider_aff" />
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="heading">Performace in Countries</div>
          <div className="filter_aff">
            <div
              role="button"
              className="box"
              aria-hidden="true"
              onClick={toggleDiv}
            >
              Filter{' '}
              <i
                className={`fa ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`}
                aria-hidden="true"
              />
            </div>
            {isOpen && (
              <div className="filter_menu">
                <ul className="list-unstyled">
                  {countryFilterList.map((list) => (
                    <li
                      className={
                        selectedCountry === list.countryLabel ? 'active' : ''
                      }
                      key={list.id}
                    >
                      <span
                        aria-hidden="true"
                        className="cursor-pointer"
                        onClick={() => handleFilterCountry(list.countryLabel)}
                      >
                        {list.countryLabel}
                      </span>
                    </li>
                  ))}

                  {/* <li className="active">
                    <a href="/">South Africa</a>
                  </li>
                   */}
                </ul>
              </div>
            )}
          </div>
        </div>

        {countryPerformance.map((list) => (
          <div className="row" key={list.id}>
            <div className="col-12">
              <p>{list.countryLabel}</p>
              <div className="progress">
                <div
                  className="progress-bar purple_color"
                  style={{ width: `${list.percentage}%` }}
                />
              </div>
              <p className="caption_text">
                {list.total} • {list.percentage}%
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CampaignPerformance;
