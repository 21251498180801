import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import logoWhite from '../../../assets/images/Logo.svg';
import arrowIcon from '../../../assets/images/arrow-circle.svg';
import start from '../../../assets/images/Star.svg';

const GiftLandingComponent = ({ goToNextStep }) => {
  const history = useHistory();
  const [modalStatus, setModalStatus] = useState(false);

  const goToNext = () => {
    goToNextStep(2);
  };

  return (
    <>
      <div className="giftWrapper laddingPage">
        <div
          className="gift-logo"
          onClick={() => {
            history.push('/');
          }}
          onKeyDown={(event) => event.key === 'Enter' && history.push('/')}
          role="button"
          tabIndex="0"
        >
          <img src={logoWhite} className="img-fluid" alt="Lovrican Logo" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-right">
              {/* <div className="gift">Give a gift of Love</div> */}
            </div>
            <div className="innerWrapper">
              <div className="love_happiend_wrapper">
                <h1>
                  Become a Matchmaker
                  <br /> and Spread the Magic
                  <br className='mibile-hide' /> of Love!
                </h1>
                <div className="main-wrapper">
                  <p>
                    Wouldn’t it be wonderful if everyone could find true love?
                    We certainly think it would be nothing short of magical! At
                    Lovrican, we’ve made it easy for you to be the matchmaker for
                    your friends and relatives. Simply become a matchmaker and
                    gift them a Lovrican plan to kickstart their love journey.
                  </p>
                  <p className="read_more">
                    Here’s how it works: When you play matchmaker and purchase a
                    Lovrican plan for someone special, we will take it from there!
                    We’ll reach out to the lucky recipient, letting them know
                    about your thoughtful gift of love. With their enrollment
                    into the Lovrican community, we’ll connect them with some
                    amazing matches tailored to their preferences.
                  </p>
                  <p className="d-none">
                    Who knows, your small gesture may just lead to a love story
                    that lasts a lifetime!
                  </p>
                  <p className="d-none">
                    And here’s the best part – the more people you gift this
                    gesture of love to, the greater your impact becomes. Become a
                    matchmaker today and join us in spreading the magic of love!
                    Let’s make the world a brighter and more love-filled place,
                    one gift at a time.
                  </p>
                  <p className="mt-1">
                    <span
                      className="d-inline-block cursor-pointer"
                      onClick={() => setModalStatus(true)}
                      aria-hidden="true"
                    >
                      <span className="dot_wrapper">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                      </span>
                      <span className="read-more-text">READ MORE</span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 text-right pr-lg-4">
                <span
                  className="search-btn"
                  role="button"
                  aria-hidden="true"
                  onClick={goToNext}
                >
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='notification_bg d-none'>
        <div className='container-fluid'>
          <div className='innerContent'>
            <h1> Important <br />
              Notice</h1>
            <p>Welcome to Lovrican! <img src={start} alt="start" /> <br />
              As you begin the registration process, we want to ensure that our community of high-quality Nigerian professionals remains exceptional. To help us verify and approve your account quickly, please provide complete and accurate information.</p>

            <p className=''><span>Here’s what we need from you:</span></p>
            <ul>
              <li><span>Honesty:</span> Your details should reflect the truth.</li>
              <li><span>Completeness:</span> Fill in all required fields.</li>
              <li><span>Accuracy:</span> Ensure all information is correct.</li>
            </ul>

            <p>If the information does not meet these standards, it may lead to delays or even rejection of your application.</p>

            <p>We’re excited about the possibility of having you join our distinguished community and <span>we look forward to welcoming you soon!</span></p>
            <div className="col-12 text-left pr-lg-4 pt-2">
                <span
                  className="search-btn"
                  role="button"
                  aria-hidden="true"
                >
                  <img src={arrowIcon} className="img-fluid" alt="arrow icon" />
                </span>
              </div>
          </div>

        </div>
      </div>

      <Modal
        className="filter-model lovrician-work-model gift-readmore"
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        centered
      // backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <h5 className="modal-title w-100">
              Become a Matchmaker
              <br /> and Spread the Magic
              <br /> of Love!
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Wouldn’t it be wonderful if everyone could find true love? We
            certainly think it would be nothing short of magical! At Lovrican,
            we’ve made it easy for you to be the matchmaker for your friends and
            relatives. Simply become a matchmaker and gift them a Lovrican plan
            to kickstart their love journey.
          </p>
          <p>
            Here’s how it works: When you play matchmaker and purchase a
            Lovrican plan for someone special, we will take it from there! We’ll
            reach out to the lucky recipient, letting them know about your
            thoughtful gift of love. With their enrollment into the Lovrican
            community, we’ll connect them with some amazing matches tailored to
            their preferences.
          </p>
          <p>
            Who knows, your small gesture may just lead to a love story that
            lasts a lifetime!
          </p>
          <p>
            And here’s the best part – the more people you gift this gesture of
            love to, the greater your impact becomes. Become a matchmaker today and
            join us in spreading the magic of love! Let’s make the world a
            brighter and more love-filled place, one gift at a time.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GiftLandingComponent;
