import { React, useState, useEffect } from 'react';
import Switch from 'react-switch';
import webServices from '../../services/webServices';

const EmailSettings = ({ userSettings }) => {
  const [checked, setChecked] = useState(false);
  const [checkedNews, setCheckedNews] = useState(false);
  const handleChange = (props) => async (nextChecked) => {
    if (props === 'news_email') {
      setCheckedNews(nextChecked);
    } else {
      setChecked(nextChecked);
    }
    await webServices.updateUserSettings({
      [props]: nextChecked === true ? 'ON' : 'OFF'
    });
  };

  useEffect(async () => {
    setChecked(userSettings?.remainder_email === 'ON' || false);
    setCheckedNews(userSettings?.news_email === 'ON' || false);
  }, [userSettings]);

  return (
    <>
      <div className="row settingEmail pb-3">
        <label className="d-block w-100" htmlFor="label">
          Reminder emails
        </label>
        <div className="reminder">
          <Switch
            checked={checked}
            onChange={handleChange('remainder_email')}
            handleDiameter={28}
            offColor="#634A65"
            onColor="#B800E0"
            offHandleColor="#fff"
            onHandleColor="#E4DCE6"
            height={40}
            width={120}
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  paddingRight: 20,
                  paddingTop: 2
                }}
              >
                OFF
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  paddingRight: 5,
                  paddingTop: 2,
                  marginLeft: '35px'
                }}
              >
                ON
              </div>
            }
            className="react-switch"
          />
        </div>
      </div>
      <div className="row settingEmail pb-3">
        <label className="d-block w-100" htmlFor="label">
          News emails
        </label>
        <div className="emailBtn mb-4 pb-4">
          <Switch
            checked={checkedNews}
            onChange={handleChange('news_email')}
            handleDiameter={28}
            offColor="#634A65"
            onColor="#B800E0"
            offHandleColor="#fff"
            onHandleColor="#E4DCE6"
            height={40}
            width={120}
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  paddingRight: 20,
                  paddingTop: 2
                }}
              >
                OFF
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  paddingRight: 5,
                  paddingTop: 2,
                  marginLeft: '35px'
                }}
              >
                ON
              </div>
            }
            className="react-switch"
          />
        </div>
      </div>
    </>
  );
};

export default EmailSettings;
