import { React } from 'react';
import { Modal } from 'react-bootstrap';
import heartIcon from '../assets/images/heart-2.svg';
import webServices from '../services/webServices';

const HoldMatchPopup = ({ modalStatus, handleClose, handleCloseHoldMatch }) => {
  const handleChange = async () => {
    handleCloseHoldMatch(false);
    await webServices.updateUserSettings({ hold_new_match: false });
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={handleClose}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">
              Hold New Match Activated!
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <img
              src={heartIcon}
              className="img-rose-fluid"
              alt="Lovrican heart"
            />
          </div>
          <p>
            Lovrican recognizes that culture and place of origin is a major
            factor in selecting a mate especially in the value-driven
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0"
              onClick={() => handleChange()}
            >
              Find Matches
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HoldMatchPopup;
