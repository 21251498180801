import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatepickerInput = (props) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const years = [];
  for (let i = 1947; i <= currentYear; i++) {
    years.push(i);
  }
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  function handleChange(date) {
    const { onChange } = props;
    onChange(date);
  }

  const { value, placeholderText } = props;

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            {'<'}
          </button>
          <select
            value={date.getFullYear()}
            onChange={({ target: { value: valueLocal } }) =>
              changeYear(valueLocal)
            }
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value: valueLocal } }) =>
              changeMonth(months.indexOf(valueLocal))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            {'>'}
          </button>
        </div>
      )}
      selected={value}
      placeholderText={placeholderText}
      className="custom_input"
      onChange={(date) => handleChange(date)}
    />
  );
};

export default DatepickerInput;
