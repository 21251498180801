import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import BasicDetails from '../components/edit-profile/basicDetails';
import Lifestyle from '../components/edit-profile/lifestyle';
import PersonalInfo from '../components/edit-profile/personal-info';
import ProfileImage from '../components/edit-profile/profileImage';
import AppConstants from '../appConstants';
import webServices from '../services/webServices';

const AccordionCustomToggle = ({
  title,
  eventKey,
  parentCallBack,
  status,
  arrowRight
}) => {
  // const decoratedOnClick = useAccordionToggle(eventKey);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });

  return (
    <div
      className="card-link"
      onClick={() => decoratedOnClick(title, status)}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      {arrowRight ? (
        <span>
          <i className="fa fa-angle-left" />
        </span>
      ) : (
        <span>
          {status === 'open' ? (
            <i className="fa fa-angle-down" />
          ) : (
            <i className="fa fa-angle-up" />
          )}
        </span>
      )}
    </div>
  );
};

const EditProfilePage = () => {
  const history = useHistory();
  const [accStatus, setAccStatus] = useState({
    personal: 'open',
    basic: 'close',
    lifestyle: 'close'
  });
  const [masterData, setMasterData] = useState({});
  // const location = useLocation();
  // const [userDetails, setUserDetails] = useState({});
  const userDetails = useSelector(
    (state) => (state.user && state.user.current) || null
  );

  // useEffect(() => {
  //   // setUserDetails(location.state.userDetail);
  // }, [location.state]);

  // useEffect(() => {
  //   // setUserDetails({});
  //   // console.log(userDetails)
  //   console.log('current', current);
  // }, [current]);

  const callBack = (key, data) => {
    const init = { ...accStatus };
    init.personal = 'close';
    init.basic = 'close';
    init.lifestyle = 'close';

    if (key === '0') {
      init.personal = data;
    }
    if (key === '1') {
      init.basic = data;
    }
    if (key === '2') {
      init.lifestyle = data;
    }
    setAccStatus(init);
  };

  useEffect(() => {
    webServices.getMasterData().then((response) => {
      if (response && response.success) {
        setMasterData(response.data);
        // setHobbies(response.data.hobbies);
        // console.log('response.data', response.data);
      }
    });
  }, []);

  const handleClickRedirect = () => {
    history.push({
      pathname: AppConstants.routes.editProfilePhotos
    });
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
       <div className="inner-wrapper-newdesign">
       <div className='largeScreen'>
       <div className='right-toggle'>
         <div className='toggle-icon'>
          <span />
          <span />
          <span />
         </div>
         <div className='toggleMenu'>
           <ul className='list-unstyled'>
             <li><Link className='active' to="/">My Matches</Link></li>
             <li><Link to="/">Chat</Link></li>
             <li><Link to="/">Match queue</Link></li>
             <li><Link to="/">Profile</Link></li>
             <li><Link to="/">Settings</Link></li>
             <li><Link to="/">Plans</Link></li>
           </ul>
           <button type='button' className='btn logout-btn' >Logout</button>
         </div>
      </div>
          <div className="row">
            <div className="col-md-12 profile-box profile-wrapper">
              <div className="main-container-profile">
                <div className="inner-profile">
                  <ProfileImage
                    fullName={`${userDetails.first_name || ''} ${
                      userDetails.last_name || ''
                    }`.trim()}
                    profileImage={userDetails.profile_image}
                    redirectToProfile={AppConstants.routes.publicProfile}
                    redirectToPhoto={AppConstants.routes.editProfilePhotos}
                  />
                  <Accordion defaultActiveKey="0" className="profile-accordion mt-4">
                    <Card>
                      <Card.Header className="pb-2" onClick={handleClickRedirect}>
                        <AccordionCustomToggle
                          title="My Photos"
                          arrowRight
                          status={accStatus.personal}
                          parentCallBack={callBack}
                        />
                      </Card.Header>
                    </Card>
                    <Card>
                      <Card.Header className="pb-2">
                        <AccordionCustomToggle
                          eventKey="0"
                          title="Personal Info"
                          status={accStatus.personal}
                          parentCallBack={callBack}
                        />
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <PersonalInfo
                            userDetail={userDetails}
                            masterData={masterData}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="pb-2">
                        <AccordionCustomToggle
                          eventKey="1"
                          title="Basic"
                          status={accStatus.basic}
                          parentCallBack={callBack}
                        />
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <BasicDetails
                            userDetail={userDetails}
                            masterData={masterData}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header className="pb-2">
                        <AccordionCustomToggle
                          eventKey="2"
                          title="Lifestyle"
                          status={accStatus.lifestyle}
                          parentCallBack={callBack}
                        />
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <Lifestyle userDetail={userDetails} masterData={masterData} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfilePage;
