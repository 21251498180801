import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/Logo.svg';
import HomeHeaderMobile from '../../layouts/partial/homeHeaderMobile';
import hardWorkGirl from '../../assets/images/Hard-Work-With-no-Fruits.webp';
import logoBanner from '../../assets/images/Logo-Banner.png';
import arrowIcon from '../../assets/images/arrow-circle.svg';

import thishomeyour1 from '../../assets/images/this-is-your-home-1.webp';
import thishomeyour2 from '../../assets/images/this-is-your-home-2.webp';
import thishomeyour3 from '../../assets/images/this-is-your-home-3.webp';
import logoBannerMobile from '../../assets/images/Logo-Banner_mobile.png';

function AboutUsPage() {
  return (
    <>
      <Helmet>
        <title>About Us | Lovrican</title>
        <meta
          name="description"
          content="Learn the story behind Lovrican, the premier platform helping high-quality African professionals in America find lasting love and happiness."
        />
        <meta name="keywords" content="About Us" />
      </Helmet>
      <div className="about_wrapper">
        <section className="about-top full-height">
          {/* Mobile Header */}
          <HomeHeaderMobile />
          {/* End Mobile Header */}
          {/* <div className="logo">
    <Link to="/">
      <img src={logo} className="img-fluid" alt="Lovrican Logo" />
    </Link>
  </div> */}
          <div className="title only-title">
            <h1>
              Connecting Nigerian <br />
              Singles in America <br />
              with Love
            </h1>
          </div>
        </section>

        <section className="love_and_happiness full-height">
          <div className="title">
            <h1>Inspiration</h1>
            <p>
              We started Lovrican out of the desire to connect Nigerians with
              love while they fulfill their dreams of a successful career. It
              all began with George and his struggle to find the love of his
              life.
            </p>
          </div>
        </section>
        <section className="hard_work_no_fruits ">
          <div className="title">
            <h1>
              Hard Work <br />
              With No Results
            </h1>
          </div>
          <div className="container-fluid">
            <div className="left-img-hard">
              <img
                src={hardWorkGirl}
                className="img-fluid"
                alt="Lovrican Logo"
              />
            </div>
            <div className="right-content">
              <h1>
                Hard Work <br />
                With no Fruits
              </h1>
              George had a great job and made more than enough money to support
              a family. He knew what he wanted: to meet an equally successful,
              culturally compatible partner who is interested in a long-term
              relationship. In other words, someone he could introduce to his
              mother. George was already 30 and in a hurry to meet his special
              someone, but was unable to do so even after diligently visiting
              clubs, churches, and Nigerian events. Friends even tried to set
              him up but nothing worked. That’s when he warmed up to dating
              apps. He tried at least a dozen of them, but the results were even
              worse.
            </div>
          </div>
        </section>
        <section className="Hope_at_last full-height">
          <div className="title">
            <h1>
              Hope At <br />
              Last
            </h1>
            <p>
              Finally, George met Betty. She was not only culturally <br />
              compatible, but was also as successful as he was. Her <br />
              outstanding character, gorgeous looks, and magnetic <br />
              personality were icing on the cake. Her smile lit up his <br />
              world. He didn’t think twice and ended up proposing to her <br />
              after six months. They were married within a year and have <br />
              been blissfully living together ever since.
            </p>
          </div>
        </section>
        <section className="lovrican_born full-height">
          <div className="lovrican_bg">
            <img src={logoBanner} className="desktop-img" alt="" />
            <img src={logoBannerMobile} className="mobile-img" alt="" />
          </div>
          <div className="lovrican_born_content">
            <h1>
              Lovrican is <br />
              Born
            </h1>
            <p>
              There are many Bettys and Georges in America who never had that
              “happily ever after” ending. Why? Their worlds never met. That’s
              why we created Lovrican. As education and career opportunities
              continue to pull the younger Nigerian generation into the global
              workforce, we wanted to build the best technology to conveniently
              bring together our young men and women so they can more easily
              find love while fulfilling their dreams of a successful career.
            </p>
          </div>
        </section>
        <section className="this_is_home ">
          <div className="this_home">
            <div className="row">
              <div className="col-md-4">
                <div className="title">
                  <h1>
                    This is Your <br />
                    Home
                  </h1>
                </div>
              </div>
              <div className="col-md-8">
                <p>
                  We built Lovrican with you in mind. As a time-constrained
                  Nigerian <br />
                  professional, you’ll find Lovrican to be the place where you
                  can <br />
                  conveniently meet successful and highly desirable Nigerian
                  professionals, <br />
                  investors, entrepreneurs, and celebrities who are looking for
                  a passionate <br />
                  long-term relationship with an equally successful and
                  culturally compatible <br />
                  partner.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="box">
                  <img
                    src={thishomeyour1}
                    className="img-fluid"
                    alt="Lovrican Logo"
                  />
                  <div className="description">
                    It’s the place where more Bettys and Georges can have happy
                    endings.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="box">
                  <img
                    src={thishomeyour2}
                    className="img-fluid"
                    alt="Lovrican Logo"
                  />
                  <div className="description">
                    It’s the place that thinks you are special.
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="box">
                  <img
                    src={thishomeyour3}
                    className="img-fluid"
                    alt="Lovrican Logo"
                  />
                  <div className="description">
                    We saved you a seat and can’t wait for you to join us.
                  </div>

                  <Link to="/register">
                    <div className="search-btn-common" role="button">
                      <img
                        src={arrowIcon}
                        className="img-fluid"
                        alt="arrow icon"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about_vision full-height">
          <div className="title">
            <h1>Vision</h1>
            <p>
              Lovrican is a dating service that matches you with a well-
              <br />
              to-do Nigerian soulmate. It is for affluent Nigerian singles in{' '}
              <br />
              America who desire to meet similarly successful and <br />
              culturally compatible life partners. Unlike other dating <br />
              sites that match you with just about anyone, Lovrican <br />
              specializes in the kind of people you’d be proud to <br />
              introduce to your mama.
            </p>
          </div>
        </section>

        <section className="our_mission_success full-height">
          <div className="title">
            <h1>
              Our Mission: <br />
              Cultivating Love,
              <br /> Elevating <br />
              Success.
            </h1>
            <p>
              Lovrican is a dating site for Nigerians living in America, <br />
              with a mission to bring together our young men and <br />
              women who are seeking compatible life partners. We <br />
              understand the importance of cultural identity and <br />
              shared experiences as a foundation for happier <br />
              marriages and elevated career success. That’s why our <br />
              platform celebrates and fosters these dimensions across <br />
              our members.
            </p>
          </div>
        </section>

        <section className="we_wish_you full-height">
          <div className="title">
            <h1>
              A Platform <br />
              for Success <br />
              and Affluence
            </h1>

            <p>
              Lovrican is more than just a dating site; <br />
              it’s a platform designed exclusively for <br />
              successful and affluent Nigerian singles <br />
              in America. We believe that shared <br />
              success and values are vital <br />
              foundations for fulfilling and lasting <br />
              relationships. Our screening and <br />
              approval process ensures that our <br />
              members are accomplished individuals <br />
              who share a drive for personal and <br />
              professional growth.
            </p>
          </div>
        </section>
        <section className="your_calture full-height">
          <div className="title">
            <h1>
              Connecting With <br />
              and Celebrating <br />
              Your Culture
            </h1>
            <p>
              At Lovrican, we celebrate the beauty of Nigerian <br />
              culture and heritage. We provide a space where <br />
              individuals can embrace their cultural pride and <br />
              connect with others who appreciate and respect <br />
              their roots. Whether you’re interested in exploring <br />
              your own Nigerian heritage or seeking to learn and <br />
              appreciate other Nigerian cultures, Lovrican offers a <br />
              diverse community that fosters cultural connections <br />
              and understanding.
            </p>
          </div>
        </section>

        <section className="rude-awakening full-height">
          <div className="rude_img_wrapper">
            {/* <img
              src={browserImg}
              className="browser"
              alt="arrow icon"
            /> */}
          </div>
          <div className="title">
            <h1>
              Expert Support <br />
              and Guidance
            </h1>
            <p>
              Lovrican is here to support you every step of the <br />
              way on your journey to find love and <br />
              companionship. Our team of experts is committed <br />
              to providing exceptional customer service and <br />
              guidance. Whether you have questions about <br />
              navigating the platform, need assistance with your <br />
              profile, or seek advice on building meaningful <br />
              connections – we are here to help. Our goal is to <br />
              create a supportive community where your voice is <br />
              heard, your needs are met and your journey to love <br />
              is enriched.
            </p>
          </div>
        </section>

        <section className="your_home full-height">
          <div className="title">
            <h1>
              Advanced <br />
              Matching <br />
              Algorithm
            </h1>
            <p>
              Our advanced matching algorithm sets Lovrican apart. <br />
              We go beyond superficial matching criteria to better <br />
              understand our members’ preferences, interests and <br />
              values. Through this, we connect individuals who are <br />
              highly compatible, increasing the chances of building <br />
              meaningful connections. Lovrican’s algorithm prioritizes
              <br />
              compatibility, allowing you to focus on building a strong <br />
              foundation with someone who truly complements your life <br />
              and aspirations.
            </p>
            <div className="join d-block text-center">
              <Link to="/register">
                <button className="btn theme-btn-purple" type="button">
                  Join
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AboutUsPage;
