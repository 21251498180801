import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';

const Step4Component = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    schoolName: userDetails?.userDetails?.schoolName || ''
  });

  const submitForm = async () => {
    if (validator.allValid()) {
      const formData = {
        schoolName: values.schoolName
      };
      setLoading(true);
      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        handleNext(step);
        setLoading(false);
      }
      setLoading(false);
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step4" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className='subtitle'>
                We’d love to hear about your alma mater. Share the 
name of your esteemed institution with your matches
                </div>
            </div>
          </div>
          <div className="row join-reg bottom-join fourth_step">
            <div className="col-md-12 col-lg-12 pb-4 custome_select mb-3">
              <input
                type="text"
                name="schoolName"
                id="schoolName"
                placeholder="School name (for highest education level)"
                className="custom_input"
                autoComplete="off"
                value={values.schoolName}
                onChange={(e) =>
                  setValues({ ...values, schoolName: e.target.value })
                }
              />
              {validator.message('schoolName', values.schoolName, 'required')}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step4Component;
