import React, { useCallback, useState } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import AppConstants from '../../appConstants';
import authService from '../../services/authServices';
import arrowSmall from '../../assets/images/arrow-small.svg';
import img1Sidebar from '../../assets/images/sidebar-img2.svg';
import img2 from '../../assets/images/sidebar-img1.svg';
import NoActivePlanPopup from '../../pages/noActivePlanPopup';

const RightSidebar = ({ matchCount }) => {
  const history = useHistory();
  const { logout } = authService;
  const { planDetails } = useSelector((state) => state.user);
  // const [matchCount] = useState(localStorage.getItem('matchCount'));
  const [showActivePlan, setShowActivePlan] = useState(false);
  const handleCloseActivePlan = () => setShowActivePlan(false);
  const handleRedirectChat = () => {
    if (!planDetails.haveActivePlan) {
      setShowActivePlan(true);
    } else if (matchCount > 0) {
      history.push({
        pathname: AppConstants.routes.chat
      });
    } else {
      toast.error(
        `We haven’t found any matches for you yet. While we continue to look for the best profiles that match your preferences, feel free to update your profile settings.`
      );
    }
  };

  const handleRedirectMyMatch = () => {
    if (matchCount > 0) {
      history.push({
        pathname: AppConstants.routes.matchQueue
      });
    } else {
      toast.error('No match queue for view');
    }
  };

  const isMatchQueueLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.afterLogin].includes(pathname),
    []
  );

  return (
    <>
      <div className="menubar-wrapper">
        <div
          className="head d-flex justify-content-between mb-2 pt-0"
          onClick={handleRedirectMyMatch}
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
        >
          <h2>My Matches:</h2>
          {matchCount > 0 && <div className="budge">{matchCount}</div>}
        </div>
        {/* <NavLink to={AppConstants.routes.matchQueue}> */}
        <div
          className="box"
          onClick={handleRedirectMyMatch}
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
        >
          <div className="icon">
            <img src={img1Sidebar} alt="" />
          </div>
          <p>
            Spotlight is the easiest way to up your odds of a match! <br />
            <img src={arrowSmall} alt="" />{' '}
          </p>
        </div>
        {/* </NavLink> */}

        <div
          className="head d-flex justify-content-between"
          onClick={handleRedirectChat}
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
        >
          <h2>Chat:</h2>
        </div>
        <div
          className="box"
          onClick={handleRedirectChat}
          aria-hidden="true"
          style={{ cursor: 'pointer' }}
        >
          <div className="icon">
            <img src={img2} className="" alt="" />
          </div>
          <p>
            Messanger is the easiest way to up your odds of a match! <br />
            <img src={arrowSmall} alt="" />{' '}
          </p>
        </div>
        <div className="menuList">
          <ul className="list-unstyled">
            <li>
              <NavLink
                to={AppConstants.routes.afterLogin}
                activeClassName="active"
                isActive={isMatchQueueLinkActive}
              >
                Match queue
              </NavLink>
            </li>
            <li>
              <Link to={AppConstants.routes.publicProfile}>Profile</Link>
            </li>
            <li>
              <Link to={AppConstants.routes.settings}>Settings</Link>
            </li>
            <li>
              <Link to={AppConstants.routes.plansView}>Plans</Link>
            </li>
          </ul>
        </div>
        <button
          className="btn btn-theme2 m-auto"
          type="button"
          onClick={() => {
            logout(history);
          }}
        >
          Logout
        </button>
      </div>
      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
        currentPlan={planDetails}
      />
    </>
  );
};
export default RightSidebar;
