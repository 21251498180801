import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Step1Component from './Step1Component';
import Step2Component from './Step2Component';
import Step3Component from './Step3Component';
import Step4Component from './Step4Component';
import Step5Component from './Step5Component';
// import Step5Component from './Step5Component';
import Step6Component from './Step6Component';
// import Step6Component from './Step6Component';
import Step7Component from './Step7Component';
import Step8Component from './Step8Component';
import Step9Component from './Step9Component';
import Step10Component from './Step10Component';
import Step11Component from './Step11Component';
import Step12Component from './Step12Component';
import Step13Component from './Step13Component';
import Step14Component from './Step14Component';
import Step15Component from './Step15Component';
import UpdateProfile from './UpdateProfile';
import AppConstants from '../../appConstants';

const NewRegisterAction = ({ masterData, userDetails }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const handleNext = (val) => {
    setStep(val);
  };

  useEffect(() => {
    if (userDetails?.is_profile_completed === 'NO') {
      setStep(16);
    } else if (userDetails?.is_profile_completed === 'YES') {
      history.push(AppConstants.routes.afterLogin);
    } else if (userDetails?.is_otp_valid === '1') {
    //   setStep(2);
      setStep(16);
    } else {
      setStep(1);
    }
  }, [userDetails]);

  return (
    <>
      {step === 1 && (
        <Step1Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={2}
        />
      )}
      {step === 2 && (
        <Step2Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={3}
        />
      )}
      {step === 3 && (
        <Step3Component
          handleNext={handleNext}
          userDetails={userDetails}
          masterData={masterData}
          step={4}
        />
      )}
      {step === 4 && (
        <Step4Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={5}
        />
      )}
      {step === 5 && (
        <Step5Component
          handleNext={handleNext}
          userDetails={userDetails}
          masterData={masterData}
          step={6}
        />
      )}
      {step === 6 && (
        <Step6Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={7}
        />
      )}
      {step === 7 && (
        <Step7Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={8}
        />
      )}
      {step === 8 && (
        <Step8Component
          handleNext={handleNext}
          userDetails={userDetails}
          masterData={masterData}
          step={9}
        />
      )}
      {step === 9 && (
        <Step9Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={10}
        />
      )}
      {step === 10 && (
        <Step10Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={11}
        />
      )}
      {step === 11 && (
        <Step11Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={12}
        />
      )}
      {step === 12 && (
        <Step12Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={13}
        />
      )}
      {step === 13 && (
        <Step13Component
          handleNext={handleNext}
          userDetails={userDetails}
          masterData={masterData}
          step={14}
        />
      )}
      {step === 14 && (
        <Step14Component
          handleNext={handleNext}
          userDetails={userDetails}
          step={15}
        />
      )}
      {step === 15 && (
        <Step15Component
          // handleNext={handleNext}
          userDetails={userDetails}
          // step={15}
        />
      )}
      {step === 16 && (
        <UpdateProfile
          userDetails={userDetails}
          masterData={masterData}
          // history={history}
          // step={15}
        />
      )}
    </>
  );
};

export default NewRegisterAction;
