import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/actions/user.actions';
import webServices from '../../../services/webServices';
import RenderFormElement from '../../editable-sections/RenderFormElement';
import CareerLevels from '../../../appConstants/careerLevels';
// import LoveGoals from '../../../appConstants/loveGoals';

const BasicDetails = ({ userDetail, masterData, viewMode }) => {
  const dispatch = useDispatch();
  const [formControls, setFormControls] = useState({});

  const handleSaveDetails = (values) =>
    webServices.updateUserDetails(userDetail.id, values).then((response) => {
      if (response && response.success) {
        dispatch(setUser(response.data));
      }
      return response;
    });

  useEffect(() => {
    if (!userDetail) {
      return;
    }

    setFormControls({
      linkedin_URL: {
        label: 'LinkedIn URL',
        type: !viewMode ? 'text' : '',
        placeholder: 'Enter your LinkedIn URL',
        value: userDetail?.linkedin_URL,
        isTextArea: true,
        isLock: !viewMode
      },
      // loveGoal: {
      //   label: 'Love Goal',
      //   type: 'dropdown',
      //   options: LoveGoals,
      //   value: userDetail?.loveGoal || '',
      //   isMulti: true
      // },
      occupation: {
        label: 'Occupation',
        type: 'dropdown',
        options: masterData.occupations,
        value: userDetail?.occupationValue
          ? {
              label: userDetail?.occupationLabel,
              value: userDetail?.occupationValue
            }
          : ''
      },
      jobTitle: {
        label: 'Job Title',
        type: !viewMode ? 'text' : '',
        placeholder: 'Enter your Job Title',
        value: userDetail?.userDetails?.jobTitle,
      },
      careerLevel: {
        label: 'Career Level',
        type: !viewMode ? 'dropdown' : '',
        options: CareerLevels,
        value: userDetail?.careerLevel
          ? {
              label: userDetail?.careerLevel,
              value: userDetail?.careerLevel
            }
          : '',
        isLock: !viewMode
      },
      company: {
        label: 'Company',
        type: 'text',
        placeholder: 'Enter your company',
        value: userDetail?.userDetails?.companyName
      },
      education: {
        label: 'Education',
        type: 'dropdown',
        options: masterData.degree,
        value: userDetail?.userDetails?.educationValue
          ? {
              label: userDetail?.userDetails?.educationLabel,
              value: userDetail?.userDetails?.educationValue
            }
          : ''
      },
      degree: {
        label: 'What did you study (your major)',
        type: 'text',
        placeholder: 'Enter your degree',
        value: userDetail?.userDetails?.degreeName
      },
      institution: {
        label: 'School name',
        type: 'text',
        placeholder: 'Enter your degree',
        value: userDetail?.userDetails?.institutionName
      }
    });
  }, [userDetail, masterData]);

  return (
    <>
      {Object.keys(formControls).map((formControlName) => (
        <RenderFormElement
          isViewMode={viewMode}
          key={formControlName}
          formControlName={formControlName}
          {...formControls[formControlName]}
          saveDetails={handleSaveDetails}
        />
      ))}
    </>
  );
};

export default BasicDetails;
