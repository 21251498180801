import React from 'react';
import { useHistory, Link } from 'react-router-dom';
// import HomeHeaderMobile from '../../layouts/partial/homeHeaderMobile';
import logo from '../../assets/images/Logo.svg';

const NotFoundComponent = () => {
  const history = useHistory();
  return (
    <>
      <div className="notfoundpageWrapper">
        {/* Mobile Header */}
        {/* <HomeHeaderMobile /> */}
        <div className="logo" role="button" tabIndex="0">
          <img
            src={logo}
            className="img-fluid"
            alt="Lovrican Logo"
            onClick={() => {
              history.push('/');
            }}
            onKeyDown={(event) => event.key === 'Enter' && history.push('/')}
            aria-hidden="true"
          />
        </div>
        {/* End Mobile Header */}
        <div className=" notFound-wrapper">
          <h1>404</h1>
          <p>Oh no… We lost this page</p>
          <Link to="/" className="btn mt-3 btn theme-btn-purple" role="button">
            Back to Homepage
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundComponent;
