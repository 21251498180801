import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';
// import AppConstants from '../appConstants';
import { setUser } from '../redux/actions/user.actions';
import webServices from '../services/webServices';
import CropperPopup from './cropperPopup';

const ProfileImages = ({
  images,
  removePhoto,
  isSelected,
  makeProfilePhoto,
  profileImg
}) => {
  if (!images || !images.length) {
    return null;
  }

  const [isModalShown, setIsModalShown] = useState(false);
  const [activeImageDetails, setActiveImageDetails] = useState({});

  const handleClose = () => {
    setActiveImageDetails({});
    setIsModalShown(false);
  };
  const handleShow = (imageDetails) => {
    setActiveImageDetails(imageDetails);
    setIsModalShown(true);
  };
  const handleFinalDelete = () => {
    removePhoto(activeImageDetails);
    handleClose();
  };

  return (
    <>
      {images.map((image, index) => (
        <div key={image} className="col-md-6">
          <div className="photo-frame">
            <img src={image} alt="" />
            {!isSelected && !image.includes(profileImg) && (
              <>
                <button
                  href="#"
                  className="profile-action-strip"
                  onClick={() => makeProfilePhoto(image)}
                  type="button"
                >
                  Make profile photo
                </button>

                <button
                  href="#"
                  className="img-action"
                  onClick={() => handleShow({ index, isSelected, image })}
                  type="button"
                >
                  <i className="fa fa-trash" aria-hidden="true" aria-label = "Delete Icon" />
                </button>
              </>
            )}
          </div>
        </div>
      ))}
      <Modal
        className="alertPhoto"
        show={isModalShown}
        onHide={handleClose}
        size="sm"
        centered
      >
        <Modal.Body className="text-center ">
          <p style={{ color: '#B800E0' }}>Are you sure, you want to delete?</p>
          <div className="btn-group mr-2" role="group" aria-label="First group">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleClose}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleFinalDelete}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const EditProfilePhotosPage = () => {
  const dispatch = useDispatch();
  const [fileDetails, setFileDetails] = useState(null);
  const [imageSRC, setImageSRC] = useState(null);
  const [show, setShow] = useState(false);

  const userProfile = useSelector(
    (state) => (state.user && state.user.current) || null
  );
  const [imagesToRemove] = useState([]);

  const handleFileChange = (event) => {
    const [firstFile] = event.target.files;
    if (!firstFile) {
      return;
    }
    Resizer.imageFileResizer(
      firstFile, // the file from input
      800, // width
      850, // height
      'JPEG', // compress format WEBP, JPEG, PNG
      90, // quality
      0, // rotation
      async (uri) => {
        setImageSRC(uri);
        setShow(true);
      },
      'base64' // blob or base64 default base64
    );

    /* eslint no-param-reassign: "error" */
    event.target.value = null;
  };

  const handleRemovePhoto = useCallback(
    ({ index, isSelected, image }) => {
      if (isSelected) {
        const copyOfFiles = [...fileDetails];
        copyOfFiles.splice(index, 1);
        setFileDetails(copyOfFiles);
        return;
      }

      (async () => {
        const imageName = image.split('/').pop();
        if (!imageName) {
          return;
        }

        const formData = new FormData();
        formData.append('imageToRemove', imageName);
        const response = await webServices.updateProfilePhotos(formData);
        if (response && response.success) {
          dispatch(setUser(response.data));
          toast.success('Profile photo has been removed successfully');
        } else {
          toast.error('Error occurred while removing profile photo');
        }
      })();
    },
    [fileDetails]
  );

  const handleMakeProfilePhoto = (image) => {
    (async () => {
      const response = await webServices.setProfilePhoto({
        imageName: image.split('/').pop()
      });
      if (response && response.success) {
        toast.success('Profile picture has been updated successfully');
        dispatch(setUser(response.data));
      } else {
        toast.error('Error occurred while updating profile photos');
      }
    })();
  };

  const profileImages = useMemo(
    () =>
      (userProfile.images || [])
        .filter((imageName) => !imagesToRemove.includes(imageName))
        .map(
          (imageName) => `${process.env.REACT_APP_PIC_BASE_URL}${imageName}`
        ),
    [userProfile, imagesToRemove]
  );

  const close = (val) => {
    setShow(val);
  };

  const callbak = async (action, data) => {
    if (action === 'click') {
      const formData = new FormData();
      formData.append('profileImage', data.blobFile);
      const response = await webServices.updateProfilePhotos(formData);
      if (response && response.success) {
        dispatch(setUser(response.data));
        setShow(false);
        toast.success('Profile photo has been added successfully');
      } else {
        toast.error('Error occurred while adding profile photo');
      }
    }
  };

  return (
    <>
      <div className="photo-outer-wrapper">
        <div className="row">
          <ProfileImages
            images={profileImages}
            removePhoto={handleRemovePhoto}
            makeProfilePhoto={handleMakeProfilePhoto}
            profileImg={userProfile.profile_image}
          />
          <ProfileImages
            images={fileDetails ? [fileDetails.src] : []}
            removePhoto={handleRemovePhoto}
            isSelected
            profileImg={userProfile.profile_image}
          />
          {profileImages.length + (fileDetails ? 1 : 0) < 6 && (
            <div className="col-md-6">
              <label
                className="photo-frame upload-photo"
                htmlFor="fileSelector"
              >
                {/* <button type="button" className="img-action" htmlFor="fileSelector"> */}
                <i
                  className="fa fa-plus-circle img-action"
                  aria-hidden="true"
                />
                {/* </button> */}
              </label>
              <input
                type="file"
                className="d-none"
                id="fileSelector"
                onChange={handleFileChange}
              />
            </div>
          )}
        </div>
      </div>

      <CropperPopup
        modalStatus={show}
        handleClose={close}
        parentCallback={callbak}
        imageSRC={imageSRC}
      />
    </>
  );
};

export default EditProfilePhotosPage;
