import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/images/Logo.svg';
import { isEmpty, isValidEmail } from '../../helpers/validator';
import { authCall } from '../../services';
import ConfirmEmailPopup from './ConfirmEmailPopup';
import HomeHeaderMobile from '../../layouts/partial/homeHeaderMobile';
import Spinner from '../general/spinner';

class RegisterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      step: 1,
      request: true,
      validRequest: false,
      confirmEmailMsg: false,
      isSpinner: true,
      otp: 0
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isSpinner: false });
    }, 1000);
  }

  validateForm = async () => {
    const { email } = this.state;
    if (isEmpty(email) || !isValidEmail(email)) {
      toast.error('Please enter a valid email.');
    } else {
      this.setState({ validRequest: true });
    }
  };

  handleClickConfirmEmailkMsg = async () => {
    await this.validateForm();
    const { validRequest, request, email } = this.state;
    if (validRequest && request) {
      const resp = await authCall('auth/action/send-otp-to-email', {
        email
      });
      if (resp && resp.success === true) {
        this.setState({
          confirmEmailMsg: true,
          otp: resp?.otp
        });
      }
    }
  };

  submitForm = async () => {
    await this.validateForm();
    const { validRequest, request } = this.state;
    if (validRequest && request) {
      this.setState({ request: false, confirmEmailMsg: false });
      this.goToNext();

      this.setState({ request: true });
    }
  };

  goToNext = () => {
    const { email, step, otp } = this.state;
    const { goToNextStep } = this.props;
    goToNextStep({
      email,
      otp,
      step: step + 2
    });
  };

  render() {
    const { history } = this.props;
    const { email, request, isSpinner, confirmEmailMsg } = this.state;
    return (
      <>
        {isSpinner ? (
          <Spinner active={isSpinner} />
        ) : (
          <>
            <section className="registerWrapper text-light position-relative">
              {/* Mobile Header */}
              <HomeHeaderMobile />
              {/* End Mobile Header */}
              <div className="container-fluid">
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  limit={3}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover

                />

                <div className="row">
                  <div className="col-12 text-left text-lg-center text-md-center">
                    <div
                      className="logo cursor-pointer d-none"
                      onClick={() => {
                        history.push('/');
                      }}
                      onKeyDown={(event) =>
                        event.key === 'Enter' && history.push('/')
                      }
                      role="button"
                      tabIndex="0"
                    >
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="Lovrican Logo"
                      />
                    </div>
                  </div>

                  <div className="reg-inner">
                    <div className="col-12">
                      <h1>
                        Welcome! <br /> Let’s get started...
                      </h1>
                    </div>

                    <div className="col-12 d-flex reg-login justify-content-between pr-0">
                      <input
                        className="login"
                        type="email"
                        name="user-email"
                        id="user-email"
                        placeholder="Enter your email"
                        value={email}
                        autoComplete="off"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          this.handleClickConfirmEmailkMsg(event)
                        }
                      />
                      <span
                        className={`search-btn ${
                          !request ? 'hks-btn-disable' : ''
                        }`}
                        onClick={this.handleClickConfirmEmailkMsg}
                        onKeyDown={(event) =>
                          event.key === 'Enter' &&
                          this.handleClickConfirmEmailkMsg()
                        }
                        role="button"
                        tabIndex="0"
                      >
                        <i className="fa fa-arrow-right" aria-label="Up Arrow"/>
                      </span>
                    </div>

                    <div className="col-12 declaration-box">
                      <p className="mb-4">
                        By continuing, you’re confirming that you’ve read and
                        agree
                        <br /> to our{' '}
                        <a
                          href={`${process.env.REACT_APP_BASE_URL}/terms-of-service`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms and Conditions
                        </a>{' '}
                        and{' '}
                        <a
                          href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {confirmEmailMsg && (
              <ConfirmEmailPopup
                modalStatus={confirmEmailMsg}
                handleConfirm={this.submitForm}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default RegisterComponent;
