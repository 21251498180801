import React, { useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import logoWhite from '../../adminAssets/images/dashbaord_Lovrican-logo-white.svg';
import hamburgerIcon from '../../adminAssets/images/more.png';
import filterIcon from '../../adminAssets/images/filter.svg';
import logoutIcon from '../../adminAssets/images/dashbaord_signout.svg';
import close from '../../adminAssets/images/dashbaord_X.svg';
import authService from '../../services/authServices';
import AppConstants from '../../appConstants';

const AdminHeader = (props) => {
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

	const { onFilterBtnClickHandler } = props;

	const { logoutAdmin } = authService;
	const history = useHistory();

	const toggleMobileMenuVisibility = () => {
		setIsMobileMenuVisible(prevValue => !prevValue);
	};

	return (
		<>
			<header>
				<div className="container">
					<div className="header_inner">
						<div className="left">
							<div className="burger-menu" role="button" aria-hidden="true" onClick={toggleMobileMenuVisibility}>
								<img src={hamburgerIcon} alt="Menu" />
							</div>
						</div>
						<div className="center">
							<img src={logoWhite} alt="Lovrican" />
						</div>
						<div className="right d-flex">
							<div className="filter_wrap" role="button" aria-hidden="true" onClick={onFilterBtnClickHandler}>
								<img title="folter" src={filterIcon} alt="Filter" />
							</div>
							<div className="sign_out" role="button" aria-hidden="true" onClick={() => { logoutAdmin(history); }}>
								<img title="Logout" src={logoutIcon} alt="Logout" />
							</div>
						</div>
					</div>
				</div>
			</header>
			<nav>
				<ul className="nav justify-content-center">
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.dashboard} className='nav-link' activeClassName='active'>Recent Profiles</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.premium_profiles} className='nav-link' activeClassName='active'>Premium Profiles</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.all_profiles} className='nav-link' activeClassName='active'>All Profiles</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.approved_profiles} className='nav-link' activeClassName='active'>Approved</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.suspended_profiles} className='nav-link' activeClassName='active'>Suspended</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.hidden_profiles} className='nav-link' activeClassName='active'>Hidden</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to={AppConstants.routes.admin.rejected_profiles} className='nav-link' activeClassName='active'>Rejected</NavLink>
					</li>
				</ul>
			</nav>
			<div className={`navbar-mobile ${isMobileMenuVisible ? '' : 'd-none'}`}>
				<div className="menu-wrapper">
					<div className="d-block position-relative">
						<button type="button" className="close" aria-hidden="true" onClick={toggleMobileMenuVisibility}>
							<span aria-hidden="true">
							<img title="close" src={close} alt="close" />
							</span>
							<span className="sr-only">Close</span>
						</button>
					</div>
					<ul className="list-unstyled">
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.dashboard} className='nav-link' activeClassName='active'>Recent Profiles</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.premium_profiles} className='nav-link' activeClassName='active'>Premium Profiles</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.all_profiles} className='nav-link' activeClassName='active'>All Profiles</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.approved_profiles} className='nav-link' activeClassName='active'>Approved</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.suspended_profiles} className='nav-link' activeClassName='active'>Suspended</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.hidden_profiles} className='nav-link' activeClassName='active'>Hidden</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={AppConstants.routes.admin.rejected_profiles} className='nav-link' activeClassName='active'>Rejected</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default AdminHeader;
